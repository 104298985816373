define('middle-office/pods/components/app-forms/partner-applications/auto-refi/approval/rejected/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    actions: {
      sendToReanalysis: function sendToReanalysis() {
        this.sendAction('on-save', 'approval', 'reanalysis');
      }
    }
  });
});