define('middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/relevant-info', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  function relevantInfo() {
    return 'Relevante para Crédito:\n\nRelevante para Formalização:\n  - Número do Whatsapp:\n  - Telefone de contato:\n  - Pessoa responsável para dar continuidade:';
  }

  exports.default = relevantInfo;
});