define('middle-office/pods/components/app-documents-list/component', ['exports', 'ember-cli-bkf-core/enum-options'], function (exports, _enumOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var getOwner = Ember.getOwner;
  exports.default = Component.extend({
    classNames: ['app-documents-list'],

    documents: computed('items', function () {
      var docEnum = (0, _enumOptions.default)('loan/auto-refi/bkf/document-types', getOwner(this));
      var items = this.get('items');

      var documents = docEnum.map(function (doc) {
        return items.findBy('name', doc.value);
      });

      return documents.compact();
    }),
    documentsOrder: ['isCompletable:desc'],
    sortedDocuments: computed.sort('documents', 'documentsOrder'),

    actions: {
      confirmDeletion: function confirmDeletion(document, uploadsList) {
        this.sendAction('confirmDeletion', document, uploadsList);
      }
    }
  });
});