define('middle-office/pods/components/app-forms/partials/phone-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var service = Ember.inject.service;
  var and = Ember.computed.and;
  exports.default = Component.extend({
    dialer: service(),

    attributeBindings: ['data-test-phone'],
    classNames: ['phone-form', 'field-group'],

    showDialer: false,

    showButton: and('dialer.isEnabled', 'showDialer'),

    actions: {
      dial: function dial() {
        this.get('dialer').dial(this.get('phoneCode'), this.get('phone'));
      }
    }
  });
});