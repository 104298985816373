define('middle-office/models/loan/auto-refi/bkf/person', ['exports', 'ember-data/attr', 'ember-cp-validations', 'ember-cli-bkf-core/models/loan/auto-refi/bkf/person'], function (exports, _attr, _emberCpValidations, _person) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var hash = Ember.RSVP.hash;
  var computed = Ember.computed;


  var personValidations = (0, _emberCpValidations.buildValidations)({
    residenceAddress: (0, _emberCpValidations.validator)('belongs-to'),
    garantor: (0, _emberCpValidations.validator)('belongs-to'),
    employments: (0, _emberCpValidations.validator)('has-many')
  });

  exports.default = _person.default.extend(personValidations, {
    bacenFormUploadUrl: (0, _attr.default)('string'),
    irpfUploadUrl: (0, _attr.default)('string'),
    bankAccountIdentificationUploadUrl: (0, _attr.default)('string'),
    classEntityRegistrationUploadUrl: (0, _attr.default)('string'),
    otherPersonalDocumentsUploadUrl: (0, _attr.default)('string'),
    creditInformationUploadUrl: (0, _attr.default)('string'),
    debtsSettlementInvoiceUploadUrl: (0, _attr.default)('string'),
    identityDocumentUploadUrl: (0, _attr.default)('string'),
    ctpsUploadUrl: (0, _attr.default)('string'),
    maritalStatusUploadUrl: (0, _attr.default)('string'),
    thirdPartyDocumentUploadUrl: (0, _attr.default)('string'),
    incomeTabulationUploadUrl: (0, _attr.default)('string'),

    shouldHaveSpouse: computed('maritalStatus', 'stableUnion', function () {
      return this.get('maritalStatus') === 'married' || this.get('stableUnion');
    }),

    acceptVisitor: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(visitor) {
        var _ref2, employment, residenceAddress, professionalAddress;

        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                visitor.visitRequester(this);

                _context.next = 3;
                return hash({
                  employment: this.get('employments.firstObject'),
                  residenceAddress: this.get('residenceAddress'),
                  professionalAddress: this.get('employments.firstObject.companyAddress')
                });

              case 3:
                _ref2 = _context.sent;
                employment = _ref2.employment;
                residenceAddress = _ref2.residenceAddress;
                professionalAddress = _ref2.professionalAddress;


                visitor.visitFirstEmployment(employment);
                visitor.visitResidenceAddress(residenceAddress);
                visitor.visitProfessionalAddress(professionalAddress);

              case 10:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function acceptVisitor(_x) {
        return _ref.apply(this, arguments);
      }

      return acceptVisitor;
    }()
  });
});