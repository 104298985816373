define('middle-office/models/underwriting/home-refi/fapa/application', ['exports', 'ember-cli-bkf-core/models/underwriting/home-refi/fapa/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _application.default.extend({
    hasShareholding: computed('shareholdingCompanyName', 'shareholdingCompanyTaxpayerRegistry', 'shareholdingCompanyNationality', 'shareholdingPercentage', function () {
      return Boolean(this.get('shareholdingCompanyName') || this.get('shareholdingCompanyTaxpayerRegistry') || this.get('shareholdingCompanyNationality') || this.get('shareholdingPercentage'));
    }),

    hasAdditionalShareholding: computed('additionalShareholdingCompanyName', 'additionalShareholdingCompanyTaxpayerRegistry', 'additionalShareholdingCompanyNationality', 'additionalShareholdingPercentage', function () {
      return Boolean(this.get('additionalShareholdingCompanyName') || this.get('additionalShareholdingCompanyTaxpayerRegistry') || this.get('additionalShareholdingCompanyNationality') || this.get('additionalShareholdingPercentage'));
    })
  });
});