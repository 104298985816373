define('middle-office/pods/auto-refi/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var map = Ember.RSVP.map;
  var hash = Ember.RSVP.hash;
  var service = Ember.inject.service;
  var Route = Ember.Route;


  var REQUESTER_TYPES = ['requester', 'guarantor'];

  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    contextHeaders: service(),
    personPreparation: service('auto-refi/person-preparation'),
    dialer: service(),

    getRequesters: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(application) {
        var _this = this;

        var prepareRequesters;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                prepareRequesters = REQUESTER_TYPES.map(function (relationship) {
                  return application.get(relationship);
                });
                return _context2.abrupt('return', map(prepareRequesters, function () {
                  var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee(requester) {
                    return regeneratorRuntime.wrap(function _callee$(_context) {
                      while (1) {
                        switch (_context.prev = _context.next) {
                          case 0:
                            if (!(requester != null)) {
                              _context.next = 3;
                              break;
                            }

                            _context.next = 3;
                            return _this.get('personPreparation').prepareRequester(requester);

                          case 3:
                            return _context.abrupt('return', requester);

                          case 4:
                          case 'end':
                            return _context.stop();
                        }
                      }
                    }, _callee, _this);
                  }));

                  return function (_x2) {
                    return _ref2.apply(this, arguments);
                  };
                }()));

              case 2:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function getRequesters(_x) {
        return _ref.apply(this, arguments);
      }

      return getRequesters;
    }(),
    getApplicationAttributes: function getApplicationAttributes(application) {
      var scheduledPartner = application.get('scheduledPartner');
      var discard = application.get('discard');
      var requesters = this.getRequesters(application);

      return hash({
        scheduledPartner: scheduledPartner,
        discard: discard,
        requesters: requesters
      });
    },
    model: function () {
      var _ref3 = _asyncToGenerator(regeneratorRuntime.mark(function _callee3(params) {
        var application;
        return regeneratorRuntime.wrap(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                _context3.next = 2;
                return this._getApplication(params);

              case 2:
                application = _context3.sent;

                if (!(application == null)) {
                  _context3.next = 6;
                  break;
                }

                this.replaceWith('application-not-found');
                return _context3.abrupt('return', null);

              case 6:

                this.get('contextHeaders').addAutoRefiApplicationId(application.get('id'));

                _context3.next = 9;
                return this.getApplicationAttributes(application);

              case 9:
                return _context3.abrupt('return', { application: application });

              case 10:
              case 'end':
                return _context3.stop();
            }
          }
        }, _callee3, this);
      }));

      function model(_x3) {
        return _ref3.apply(this, arguments);
      }

      return model;
    }(),
    afterModel: function () {
      var _ref5 = _asyncToGenerator(regeneratorRuntime.mark(function _callee4(_ref4) {
        var application = _ref4.application;
        return regeneratorRuntime.wrap(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                _context4.next = 2;
                return application.belongsTo('consultant').reload();

              case 2:
                this._setupDialer(application.get('id'));

              case 3:
              case 'end':
                return _context4.stop();
            }
          }
        }, _callee4, this);
      }));

      function afterModel(_x4) {
        return _ref5.apply(this, arguments);
      }

      return afterModel;
    }(),
    resetController: function resetController(controller) {
      controller.set('feedbackMessage', null);
    },
    _getApplication: function () {
      var _ref6 = _asyncToGenerator(regeneratorRuntime.mark(function _callee5(params) {
        var _this2 = this;

        var application;
        return regeneratorRuntime.wrap(function _callee5$(_context5) {
          while (1) {
            switch (_context5.prev = _context5.next) {
              case 0:
                application = void 0;
                _context5.next = 3;
                return catchAdapterErrors(function () {
                  return _this2.store.findRecord('loan/auto-refi/bkf/application', params['application-id']);
                });

              case 3:
                application = _context5.sent;
                _context5.t0 = application;

                if (_context5.t0) {
                  _context5.next = 9;
                  break;
                }

                _context5.next = 8;
                return catchAdapterErrors(function () {
                  return _this2._transactionCodeFallback(params);
                });

              case 8:
                _context5.t0 = _context5.sent;

              case 9:
                application = _context5.t0;
                return _context5.abrupt('return', application);

              case 11:
              case 'end':
                return _context5.stop();
            }
          }
        }, _callee5, this);
      }));

      function _getApplication(_x5) {
        return _ref6.apply(this, arguments);
      }

      return _getApplication;
    }(),
    _transactionCodeFallback: function _transactionCodeFallback(params) {
      return this.store.queryRecord('loan/auto-refi/bkf/application', {
        filter: {
          'transaction-code': params['application-id']
        }
      });
    },
    _setupDialer: function _setupDialer(applicationId) {
      var dialer = this.get('dialer');
      dialer.setup('core', 'auto-refi', applicationId);
    }
  });

  var catchAdapterErrors = function () {
    var _ref7 = _asyncToGenerator(regeneratorRuntime.mark(function _callee6(fn) {
      var result;
      return regeneratorRuntime.wrap(function _callee6$(_context6) {
        while (1) {
          switch (_context6.prev = _context6.next) {
            case 0:
              _context6.prev = 0;
              _context6.next = 3;
              return fn();

            case 3:
              result = _context6.sent;
              return _context6.abrupt('return', result);

            case 7:
              _context6.prev = 7;
              _context6.t0 = _context6['catch'](0);

              if (_context6.t0.isAdapterError) {
                _context6.next = 11;
                break;
              }

              throw _context6.t0;

            case 11:
              return _context6.abrupt('return', null);

            case 12:
            case 'end':
              return _context6.stop();
          }
        }
      }, _callee6, this, [[0, 7]]);
    }));

    return function catchAdapterErrors(_x6) {
      return _ref7.apply(this, arguments);
    };
  }();
});