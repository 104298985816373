define('middle-office/models/servicing/onboarding/customer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    cpf: attr('string'),
    email: attr('string'),
    fullName: attr('string'),
    birthDate: attr('date'),
    contactResidentialTelephoneCode: attr('string'),
    contactResidentialTelephone: attr('string'),
    contactAdditionalPhoneCode: attr('string'),
    contactAdditionalPhone: attr('string'),
    contactCellphoneCode: attr('string'),
    contactCellphone: attr('string'),
    fullResidentialTelephone: computed('contactResidentialTelephoneCode', 'contactResidentialTelephone', function () {
      return '' + this.get('contactResidentialTelephoneCode') + this.get('contactResidentialTelephone');
    }),
    fullAdditionalPhone: computed('contactAdditionalPhoneCode', 'contactAdditionalPhone', function () {
      return '' + this.get('contactAdditionalPhoneCode') + this.get('contactAdditionalPhone');
    }),
    fullCellphone: computed('contactCellphoneCode', 'contactCellphone', function () {
      return '' + this.get('contactCellphoneCode') + this.get('contactCellphone');
    }),

    loans: hasMany('servicing/onboarding/loan', { polymorphic: true }),
    address: belongsTo('servicing/onboarding/address')
  });
});