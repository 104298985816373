define('middle-office/models/loan/auto-refi/bkf/application-submission', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var reads = Ember.computed.reads;


  var TimelineEvent = EmberObject.extend({
    type: 'application-submission',
    createdAt: reads('_model.createdAt'),
    model: reads('_model'),

    _model: null
  });

  exports.default = _model.default.extend({
    application: (0, _relationships.belongsTo)('loan/auto-refi/bkf/application'),
    submittedBy: (0, _relationships.belongsTo)('crm/consultant'),

    vehicleModelYear: (0, _attr.default)('number'),
    vehicleBrand: (0, _attr.default)('string'),
    vehicleModel: (0, _attr.default)('string'),
    vehicleOwner: (0, _attr.default)(),
    vehicleDebt: (0, _attr.default)('number'),
    vehicleManufacturingYear: (0, _attr.default)('number'),
    vehicleCategory: (0, _attr.default)('string'),
    molicarCode: (0, _attr.default)('string'),
    licensePlate: (0, _attr.default)('string'),
    renavam: (0, _attr.default)('string'),
    purchaseValue: (0, _attr.default)('number'),
    downPayment: (0, _attr.default)('number'),

    loanAmount: (0, _attr.default)('number'),
    interestTable: (0, _attr.default)('string'),
    financingTimeMonths: (0, _attr.default)('number'),
    installmentValue: (0, _attr.default)('number'),
    returnValue: (0, _attr.default)('string'),
    insuranceValue: (0, _attr.default)('number'),
    partnerName: (0, _attr.default)('string'),

    creditDefense: (0, _attr.default)('string'),
    requesterUrl: (0, _attr.default)('string'),
    guarantorUrl: (0, _attr.default)('string'),

    createdAt: (0, _attr.default)('date'),

    toTimelineEvent: function toTimelineEvent() {
      return TimelineEvent.create({ _model: this });
    }
  });
});