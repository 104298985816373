define('middle-office/pods/components/app-inspection/address/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['form-field', 'field'],
    readonly: false,

    layout: Ember.HTMLBars.template({
      "id": "u3gOUhBo",
      "block": "{\"symbols\":[],\"statements\":[[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui middle aligned grid\"],[7],[0,\"\\n      \"],[6,\"label\"],[9,\"class\",\"five wide right aligned column\"],[7],[0,\"\\n        Endereço:\\n      \"],[8],[0,\"\\n\\n      \"],[6,\"div\"],[9,\"class\",\"ten wide left aligned column address\"],[7],[0,\"\\n\"],[4,\"if\",[[19,0,[\"readonly\"]]],null,{\"statements\":[[0,\"          \"],[1,[20,[\"inspection\",\"address\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[25,\"app-textarea\",null,[[\"rows\",\"value\"],[2,[19,0,[\"inspection\",\"address\"]]]]],false],[0,\"\\n          \"],[1,[25,\"app-errors\",null,[[\"model\",\"attribute\",\"showErrors\"],[[19,0,[\"inspection\"]],\"address\",[19,0,[\"showErrors\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"]],\"hasEval\":false}",
      "meta": {}
    })
  });
});