define('middle-office/models/servicing/onboarding/contract', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    createdAt: attr('date'),
    updatedAt: attr('date'),
    signedAt: attr('pure-date'),
    type: attr('string'),
    number: attr('string'),
    link: attr('string'),

    loan: belongsTo('servicing/onboarding/loan', { polymorphic: true })
  });
});