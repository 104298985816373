define('middle-office/services/blob-download', ['exports', 'ember-cli-bkf-core/services/blob-download'], function (exports, _blobDownload) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _blobDownload.default;
    }
  });
});