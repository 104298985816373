define('middle-office/pods/components/app-forms/partner-applications/auto-refi/approval/partials/reanalysis-comment/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['reanalysis-comment'],
    classNameBindings: ['reanalysisComment:visible:hidden'],

    reanalysisComment: alias('partnerApplication.reanalysisComment')
  });
});