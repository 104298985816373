define('middle-office/pods/components/app-documents-list/partials/document/action-buttons/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Component = Ember.Component;
  var reads = Ember.computed.reads;
  var notEmpty = Ember.computed.notEmpty;
  var equal = Ember.computed.equal;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['action-buttons'],
    classNameBindings: ['hasAttachments:visible:hidden'],

    rollbar: service(),
    status: reads('document.status'),
    isSaving: reads('document.isSaving'),
    hasAttachments: notEmpty('document.attachments'),
    isComplete: equal('status', 'complete'),
    isIncomplete: equal('status', 'incomplete'),
    isValidated: equal('status', 'validated'),
    isValidating: equal('status', 'validating'),

    confirmationSettings: {
      header: 'Validar documento'
    },

    _setErrorMessage: function _setErrorMessage(errorMessage) {
      var message = {
        type: 'error',
        text: errorMessage
      };
      this.set('feedbackMessage', message);
    },
    _handleError: function _handleError(e, payload) {
      var message = e.message,
          errors = e.errors;


      this._setErrorMessage(message);
      this.get('rollbar').error('Failure when updating document', e, {
        payload: payload,
        errors: errors
      });
    },
    _updateDocument: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(status) {
        var document, payload;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                document = this.get('document');

                document.set('status', status);

                _context.prev = 2;
                _context.next = 5;
                return document.save();

              case 5:
                _context.next = 12;
                break;

              case 7:
                _context.prev = 7;
                _context.t0 = _context['catch'](2);
                payload = JSON.stringify(document);


                document.rollbackAttributes();
                this._handleError(_context.t0, payload);

              case 12:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this, [[2, 7]]);
      }));

      function _updateDocument(_x) {
        return _ref.apply(this, arguments);
      }

      return _updateDocument;
    }(),


    actions: {
      onConfirmValidation: function onConfirmValidation() {
        this.set('showConfirmValidation', false);

        return this._updateDocument('validated');
      },
      onUpdateDocument: function onUpdateDocument(status) {
        return this._updateDocument(status);
      },
      onCancelModal: function onCancelModal() {
        this.set('showConfirmValidation', false);
      }
    }
  });
});