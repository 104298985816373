define('middle-office/pods/components/app-tags/tags/base', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  exports.default = EmberObject.extend({
    name: '',
    key: '',
    available: false,
    application: null
  });
});