define('middle-office/pods/auto-refi/partner-applications/service', ['exports', 'middle-office/services/application-form'], function (exports, _applicationForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  exports.default = _applicationForm.default.extend({
    modelDescription: 'underwriting partner application',

    saveModel: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(partnerApplication) {
        var closingLoan;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return partnerApplication.get('closingLoan');

              case 2:
                closingLoan = _context.sent;

                if (!this._isCreatingClosingLoan(closingLoan)) {
                  _context.next = 6;
                  break;
                }

                _context.next = 6;
                return closingLoan.save();

              case 6:
                _context.next = 8;
                return partnerApplication.save();

              case 8:
                return _context.abrupt('return', partnerApplication);

              case 9:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function saveModel(_x) {
        return _ref.apply(this, arguments);
      }

      return saveModel;
    }(),
    _isCreatingClosingLoan: function _isCreatingClosingLoan(closingLoan) {
      return closingLoan && closingLoan.get('hasDirtyAttributes');
    }
  });
});