define('middle-office/instance-initializers/configure-moment-locale', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(appInstance) {
    var moment = appInstance.lookup('service:moment');
    moment.changeLocale('pt-br');
  }

  exports.default = {
    name: 'configure-moment-locale',
    initialize: initialize
  };
});