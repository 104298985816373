define('middle-office/pods/main/auto-fin/controller', ['exports', 'middle-office/pods/main/auto-fin/settings'], function (exports, _settings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    appLayoutConfig: _settings.appLayoutConfig
  });
});