define('middle-office/pods/components/app-forms/application/auto-fin/partials/action-bar/component', ['exports', 'ember-cli-bkf-core/utils/translate-from-enum', 'middle-office/utils/mailto', 'vanilla-masker'], function (exports, _translateFromEnum, _mailto, _vanillaMasker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var later = Ember.run.later;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Component = Ember.Component;


  var SUBMISSION_EMAIL = 'youngling@creditas.com.br';

  exports.default = Component.extend({
    classNames: ['action-bar', 'ui', 'grid', 'container', 'centered', 'middle', 'aligned'],

    rollbar: service(),
    translatedIncomeProof: (0, _translateFromEnum.default)('income-proof', 'application.requesterIncomeProof'),

    mailTo: computed('emailSubject', 'emailBody', function () {
      return (0, _mailto.default)({
        to: SUBMISSION_EMAIL,
        subject: this.get('emailSubject'),
        body: this.get('emailBody')
      });
    }),

    emailSubject: computed('application.requesterCpf', function () {
      var defaultText = 'Proposta de Financiamento Creditas-Uber';
      var cpf = this._fetchAndFormatCpf();

      return defaultText + ' CPF: ' + cpf;
    }),

    emailBody: computed('application.comment', 'application.requesterMonthlyIncome', 'application.requesterEmploymentJobTitle', 'translatedIncomeProof', function () {
      var host = document.location.origin;
      var statusPath = this._buildStatusPath();
      var url = new URL(statusPath, host);

      var jobTitle = this.get('application.requesterEmploymentJobTitle');
      var monthlyIncome = _vanillaMasker.default.toMoney((this.get('application.requesterMonthlyIncome') || 0).toFixed(2));
      var comment = this.get('application.comment') || '';

      return [url.toString(), 'Segue ficha p/ análise.', 'Dados confirmados com cliente via telefone:', 'N° Proposta:', 'Atividade e renda: ' + jobTitle + ' - R$ ' + monthlyIncome + '; Comprova usando: ' + this.get('translatedIncomeProof'), 'Defesa ou Coment\xE1rio: ' + comment, 'Att,'].join('\n\n');
    }),

    feedbackMessage: null,

    _buildStatusPath: function _buildStatusPath() {
      var id = this.get('application.id');
      return '/main/auto-fin/' + id + '/ficha';
    },
    _fetchAndFormatCpf: function _fetchAndFormatCpf() {
      var cpfValue = this.get('application.requesterCpf') || '';
      var maskedCpf = _vanillaMasker.default.toPattern(cpfValue, '999.999.999-99');

      return maskedCpf;
    },
    setFeedbackMessage: function setFeedbackMessage(message) {
      var _this = this;

      this.set('feedbackMessage', message);

      later(function () {
        return _this.set('feedbackMessage', null);
      }, 3500);
    },


    feedbackStatusClass: computed('application.{isValid,isError}', function () {
      if (this.get('application.isError') || !this.get('application.isValid')) {
        return 'error negative';
      }

      if (this.get('application.isValid')) {
        return 'success';
      }

      return null;
    }),

    saveLoadingClass: computed('application.isSaving', function () {
      if (this.get('application.isSaving')) {
        return 'loading';
      }

      return null;
    }),

    saveAndDownloadLoadingClass: computed('application.isSaving', function () {
      if (this.get('application.isSaving')) {
        return 'loading';
      }

      return null;
    }),

    _save: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
        var application, success;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                application = this.get('application');
                success = true;
                _context.prev = 2;
                _context.next = 5;
                return application.save();

              case 5:
                success = application.get('isValid') && !application.get('isError');
                _context.next = 11;
                break;

              case 8:
                _context.prev = 8;
                _context.t0 = _context['catch'](2);

                success = false;

              case 11:
                if (success) {
                  _context.next = 14;
                  break;
                }

                this.setFeedbackMessage('Falha ao salvar o formulário :(');
                return _context.abrupt('return');

              case 14:

                this.setFeedbackMessage('Salvo com sucesso!');

              case 15:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this, [[2, 8]]);
      }));

      function _save() {
        return _ref.apply(this, arguments);
      }

      return _save;
    }(),


    actions: {
      save: function () {
        var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2() {
          return regeneratorRuntime.wrap(function _callee2$(_context2) {
            while (1) {
              switch (_context2.prev = _context2.next) {
                case 0:
                  this._save();

                case 1:
                case 'end':
                  return _context2.stop();
              }
            }
          }, _callee2, this);
        }));

        function save() {
          return _ref2.apply(this, arguments);
        }

        return save;
      }()
    }
  });
});