define('middle-office/pods/components/app-header/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['app-header'],
    tagName: 'header',
    auth: service('auth'),

    consultant: computed(function () {
      return this.get('auth.user');
    }),

    actions: {
      logout: function logout() {
        this.get('auth').logout();
      }
    }
  });
});