define('middle-office/pods/components/app-documents-list/mixins/upload-document-model', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var equal = Ember.computed.equal;
  var EmberObject = Ember.Object;
  var computed = Ember.computed;
  exports.default = EmberObject.extend({
    uploadedBy: 'consultant',

    file: null,

    uploadSize: 0, // byte

    uploaded: 0, // byte

    uploadProgress: computed('uploadSize', 'uploaded', function () {
      var uploadSize = this.get('uploadSize');
      var uploaded = this.get('uploaded');

      return uploaded * 100 / uploadSize;
    }),

    status: null,

    isDone: equal('status', 'done'),

    isError: equal('status', 'error'),

    isLoading: equal('status', 'loading'),

    onProgress: function onProgress(event) {
      if (!this.get('isLoading')) {
        this.set('status', 'loading');
      }

      this.setProperties({
        uploadSize: event.total,
        uploaded: event.loaded
      });
    },
    onError: function onError() {
      this.set('status', 'error');
      this._setInvalidDocument();
    },
    onDone: function onDone() {
      this.setProperties({
        status: 'done',
        uploaded: this.get('uploadSize')
      });
    },
    _setInvalidDocument: function _setInvalidDocument() {
      this.setProperties({
        uploadedSize: 0,
        uploaded: 0
      });
    }
  });
});