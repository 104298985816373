define('middle-office/services/routing', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    emberRouter: service('-routing'),

    currentRouteName: alias('emberRouter.currentRouteName'),

    transitionTo: function transitionTo() {
      var _get;

      (_get = this.get('emberRouter')).transitionTo.apply(_get, arguments);
    }
  });
});