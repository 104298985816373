define('middle-office/pods/components/application-modal/auto-refi/scheduling/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Component = Ember.Component;
  var computed = Ember.computed;
  var reads = computed.reads;


  var defaultOption = {
    text: 'Nenhum',
    value: null
  };

  exports.default = Component.extend({
    classNames: ['ui', 'form', 'modal-scheduling'],

    feedbackMessage: null,
    errorMessage: 'Os campos Data/Hora devem ser preenchidos',

    application: reads('model.application'),
    partners: reads('model.partners'),
    selectedPartnerName: reads('application.scheduledPartner.name'),
    partnersSelection: computed('partners', function () {
      var mappedPartners = this._createPartnerEnum();
      return [defaultOption].concat(_toConsumableArray(mappedPartners));
    }),

    _createPartnerEnum: function _createPartnerEnum() {
      var partners = this.get('partners');
      var mappedPartners = partners.map(function (partner) {
        var partnerName = partner.get('name');

        return {
          text: partnerName,
          value: partnerName.toLowerCase()
        };
      });

      return mappedPartners;
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var scheduling = this.get('application.scheduling');

      this.set('date', scheduling);
      this.set('time', scheduling);

      this._super.apply(this, arguments);
    },
    parseDateTime: function parseDateTime(_ref) {
      var date = _ref.date,
          time = _ref.time;

      var parsedDate = (0, _moment.default)(date).format('DD/MM/YYYY');
      var parsedTime = (0, _moment.default)(time).format('HH:mm');
      var dateTime = (0, _moment.default)(parsedDate + ' ' + parsedTime, 'DD/MM/YYYY HH:mm');

      if (dateTime.isValid()) {
        return dateTime.utc().toDate();
      }

      return null;
    },
    setDateTime: function setDateTime() {
      var date = this.get('date');
      var time = this.get('time');

      if (!date || !time) {
        return null;
      }

      var dateTime = this.parseDateTime({ date: date, time: time });

      return dateTime;
    },


    actions: {
      onApprove: function onApprove() {
        var date = this.setDateTime();
        var selectedPartnerName = this.get('selectedPartnerName');
        var selectedPartner = this.get('partners').find(function (partner) {
          var loweredPartnerName = partner.get('name').toLowerCase();
          return loweredPartnerName === selectedPartnerName;
        });
        var scheduling = {
          date: date,
          partner: selectedPartner
        };

        if (!date) {
          this.set('feedbackMessage', {
            type: 'error',
            title: 'Ooops...',
            text: this.get('errorMessage')
          });

          return;
        }

        this.set('feedbackMessage', null);
        this.sendAction('onApprove', scheduling);
      },
      onDeny: function onDeny() {
        this.set('feedbackMessage', null);
        this.sendAction('onDeny');
      }
    }
  });
});