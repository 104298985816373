define('middle-office/pods/home-refi/index/route', ['exports', 'middle-office/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    redirect: function redirect() {
      var host = _environment.default.APP.bkfOperational;
      var url = new URL('/crm/home_refinancing', host);

      window.location = url.href;
    }
  });
});