define('middle-office/pods/application-not-found/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    appLayoutConfig: {
      menu: {
        links: []
      }
    }
  });
});