define('middle-office/enums/auto-refi/lead-origins', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.leadOrigins = undefined;
  var leadOrigins = exports.leadOrigins = [{
    text: 'Promotora',
    value: 'promoter'
  }];

  exports.default = leadOrigins;
  var values = exports.values = _lodash.default.map(leadOrigins, 'value');
});