define('middle-office/enums/credit/auto-refi/committee', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.committee = undefined;
  var committee = exports.committee = [{
    text: 'Pendente',
    value: 'committee'
  }];

  exports.default = committee;
  var values = exports.values = _lodash.default.map(committee, 'value');
});