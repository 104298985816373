define('middle-office/pods/components/app-forms/partials/address-form/component', ['exports', 'middle-office/utils/address-description'], function (exports, _addressDescription) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['address-form', 'field-group'],

    formattedAddress: computed('address', function () {
      return (0, _addressDescription.simplifiedAddressDescription)(this.get('address'));
    }),

    gmapsLink: computed('formattedAddress', function () {
      var formattedAddress = this.get('formattedAddress');
      var url = new URL('https://www.google.com/maps');

      url.searchParams.append('q', formattedAddress);
      return url.toString();
    }),

    showGMapsLink: false

  });
});