define('middle-office/enums/auto-refi/marital-statuses', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.textForMaritalStatus = exports.maritalStatuses = undefined;
  var maritalStatuses = exports.maritalStatuses = [{
    text: 'solteiro(a)',
    value: 'single'
  }, {
    text: 'divorciado(a)',
    value: 'divorced'
  }, {
    text: 'casado(a)',
    value: 'married'
  }, {
    text: 'separado(a)',
    value: 'separated'
  }, {
    text: 'viúvo(a)',
    value: 'widower'
  }];

  exports.default = maritalStatuses;
  var textForMaritalStatus = exports.textForMaritalStatus = function textForMaritalStatus(value) {
    return _lodash.default.find(maritalStatuses, { value: value }).text;
  };
  var values = exports.values = _lodash.default.map(maritalStatuses, 'value');
});