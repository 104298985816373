define('middle-office/pods/servicing/home-loan/installments/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model() {
      var loan = this.modelFor('servicing/home-loan');

      return loan;
    }
  });
});