define('middle-office/pods/components/app-forms/eligibility-parameters/home-refi/steps/real-estate/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['real-estate-data', 'form-step']
  });
});