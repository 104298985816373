define('middle-office/pods/application/route', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend(_applicationRouteMixin.default, {
    actions: {
      loading: function loading(transition) {
        var controller = this.controllerFor('application');

        controller.set('isLoading', true);

        transition.promise.finally(function () {
          controller.set('isLoading', false);
        });

        return true;
      }
    }
  });
});