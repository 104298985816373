define('middle-office/enums/loan/auto-refi/bkf/inspection-person', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.person = undefined;
  var person = exports.person = [{
    text: 'Avaliador',
    value: 'partner'
  }, {
    text: 'Cliente',
    value: 'customer'
  }];

  exports.default = person;
  var values = exports.values = _lodash.default.map(person, 'value');
});