define('middle-office/pods/auto-refi/workflow/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    presentPartnerApplications: alias('model.presentPartnerApplications')
  });
});