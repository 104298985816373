define('middle-office/pods/auto-refi/inspections/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model(_ref) {
      var id = _ref.id;

      return this.store.peekRecord('loan/auto-refi/bkf/inspection', id);
    },
    resetController: function resetController(controller, isExiting) {
      var inspection = controller.get('inspection');

      if (isExiting) {
        inspection.rollbackAttributes();
      }

      controller.setProperties({
        feedbackMessage: null,
        showErrors: false
      });
    }
  });
});