define('middle-office/models/underwriting/partner', ['exports', 'ember-data/model', 'ember-data'], function (exports, _model, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var sort = Ember.computed.sort;
  exports.default = _model.default.extend({
    branches: _emberData.default.hasMany('underwriting/branch'),

    name: _emberData.default.attr('string'),
    nameAlias: _emberData.default.attr('string'),
    orderedBranches: sort('branches', function (current, next) {
      var currentName = current.get('name').toUpperCase();
      var nextName = next.get('name').toUpperCase();

      if (currentName > nextName) {
        return 1;
      } else if (currentName < nextName) {
        return -1;
      }

      return 0;
    })
  });
});