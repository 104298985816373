define('middle-office/pods/components/external-user-information/component', ['exports', 'middle-office/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var isNone = Ember.isNone;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    externalInformation: service(),
    rollbar: service(),

    tagName: 'button',
    classNames: ['ui', 'button', 'primary', 'active', 'nowrap'],
    classNameBindings: ['isLoading:loading', 'isLoading:disabled'],

    isLoading: false,
    showModal: false,
    cpf: null,

    modalSettings: {
      header: 'Consultas Externas',
      type: 'approve-only'
    },

    responses: null,

    init: function init() {
      this.set('responses', {});
      this._super();
    },
    click: function click() {
      this._consultCpf();
    },
    _consultCpf: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
        var cpf;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                cpf = this.get('cpf');

                if (isNone(cpf)) {
                  _context.next = 6;
                  break;
                }

                this.toggleProperty('isLoading');
                _context.next = 5;
                return this._fetchAllCpfInformation(cpf);

              case 5:
                this.toggleProperty('isLoading');

              case 6:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function _consultCpf() {
        return _ref.apply(this, arguments);
      }

      return _consultCpf;
    }(),
    _fetchAllCpfInformation: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(cpf) {
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _context2.next = 2;
                return this._fetchSource('allcheck', cpf);

              case 2:
                if (!_environment.default.APP.autoRefiSerasaConsult) {
                  _context2.next = 5;
                  break;
                }

                _context2.next = 5;
                return this._fetchSource('serasa', cpf);

              case 5:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function _fetchAllCpfInformation(_x) {
        return _ref2.apply(this, arguments);
      }

      return _fetchAllCpfInformation;
    }(),
    _fetchSource: function () {
      var _ref3 = _asyncToGenerator(regeneratorRuntime.mark(function _callee3(source, cpf) {
        var externalInformation, response;
        return regeneratorRuntime.wrap(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                externalInformation = this.get('externalInformation');
                _context3.prev = 1;
                _context3.next = 4;
                return externalInformation.consultCpf(cpf, source);

              case 4:
                response = _context3.sent;

                this._handleResponseFor(source, response);
                _context3.next = 11;
                break;

              case 8:
                _context3.prev = 8;
                _context3.t0 = _context3['catch'](1);

                this._handleErrorFor(source, _context3.t0);

              case 11:
              case 'end':
                return _context3.stop();
            }
          }
        }, _callee3, this, [[1, 8]]);
      }));

      function _fetchSource(_x2, _x3) {
        return _ref3.apply(this, arguments);
      }

      return _fetchSource;
    }(),
    _handleResponseFor: function _handleResponseFor(source, data) {
      this.set('responses.' + source, {
        type: 'success',
        data: data
      });

      this._showModal();
    },
    _handleErrorFor: function _handleErrorFor(source, error) {
      this.set('responses.' + source, {
        type: 'error',
        message: 'Falha ao consultar ' + source + ' :('
      });

      this._reportRollbarFor(source, error);
      this._showModal();
    },
    _reportRollbarFor: function _reportRollbarFor(source, error) {
      var rollbar = this.get('rollbar');

      rollbar.error(new Error('Failure while consulting ' + source), {
        errorEvent: error
      });
    },
    _showModal: function _showModal() {
      this.set('showModal', true);
    },
    _hideModal: function _hideModal() {
      this.set('showModal', false);
    },


    actions: {
      hideModal: function hideModal() {
        this._hideModal();
      }
    }
  });
});