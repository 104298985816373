define('middle-office/pods/auto-refi/documents/controller', ['exports', 'middle-office/enums/loan/auto-refi/bkf/document-types'], function (exports, _documentTypes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var isNone = Ember.isNone;
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  exports.default = Controller.extend({
    application: reads('model.application'),
    requester: reads('model.requester'),
    guarantor: reads('model.guarantor'),
    bkfDocuments: reads('model.bkfDocuments'),
    requesterCollections: reads('model.requesterDocuments'),
    guarantorCollections: reads('model.guarantorDocuments'),

    applicationCollections: computed.filter('bkfDocuments', function (document) {
      return _documentTypes.applicationDocuments.findBy('value', document.get('name'));
    }),
    formalizationCollections: computed.filter('bkfDocuments', function (document) {
      return _documentTypes.formalizationDocuments.findBy('value', document.get('name'));
    }),

    showConfirmationModal: false,

    confirmationSettings: {
      header: 'Excluir documento',
      type: 'delete'
    },

    _deletableAttachment: null,

    actions: {
      onCancelModal: function onCancelModal() {
        this.toggleProperty('showConfirmationModal');
      },
      onDeleteModal: function onDeleteModal() {
        this.toggleProperty('showConfirmationModal');
        this.send('deleteAttachment', this.get('_deletableAttachment'));
      },
      confirmDeletion: function confirmDeletion(attachment, uploadsList) {
        this.toggleProperty('showConfirmationModal');
        this.set('_deletableAttachment', {
          attachment: attachment,
          uploadsList: uploadsList
        });
      },
      deleteAttachment: function () {
        var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee(_ref) {
          var attachment = _ref.attachment,
              uploadsList = _ref.uploadsList;
          return regeneratorRuntime.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  if (!isNone(attachment)) {
                    _context.next = 2;
                    break;
                  }

                  return _context.abrupt('return');

                case 2:
                  if (!attachment.destroyRecord) {
                    _context.next = 5;
                    break;
                  }

                  _context.next = 5;
                  return attachment.destroyRecord();

                case 5:

                  uploadsList.removeObject(attachment);

                case 6:
                case 'end':
                  return _context.stop();
              }
            }
          }, _callee, this);
        }));

        function deleteAttachment(_x) {
          return _ref2.apply(this, arguments);
        }

        return deleteAttachment;
      }()
    }
  });
});