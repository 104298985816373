define('middle-office/enums/admin/consultant-filters', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.filterAll = exports.filterInactive = exports.filterActive = exports.values = exports.filterTypes = undefined;


  var filterActive = 'ativos';
  var filterInactive = 'inativos';
  var filterAll = 'todos';

  var filterTypes = exports.filterTypes = [{
    text: 'ativos',
    value: filterActive
  }, {
    text: 'inativos',
    value: filterInactive
  }, {
    text: 'todos',
    value: filterAll
  }];

  exports.default = filterTypes;
  var values = exports.values = _lodash.default.map(filterTypes, 'value');
  exports.filterActive = filterActive;
  exports.filterInactive = filterInactive;
  exports.filterAll = filterAll;
});