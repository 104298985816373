define('middle-office/pods/components/app-forms/application/auto-fin/steps/vehicle/component', ['exports', 'ember-cli-bkf-core/utils/translate-from-enum'], function (exports, _translateFromEnum) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var reads = Ember.computed.reads;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    store: service('store'),
    vehicleHasNoDebts: reads('application.vehicleHasNoDebts'),

    classNames: ['real-estate-data', 'form-step'],

    vehicleCategory: (0, _translateFromEnum.default)('auto-refi/vehicle-category', 'application.vehicleCategory'),

    actions: {
      resetVehicleDebt: function resetVehicleDebt(newValue) {
        if (newValue) {
          this.set('application.vehicleDebt', 0);
        }
      }
    }
  });
});