define('middle-office/pods/main/auto-refi/partner-tasks/route', ['exports', 'middle-office/pods/main/auto-refi/partner-tasks/query-params'], function (exports, _queryParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Route = Ember.Route;
  var service = Ember.inject.service;
  var all = Ember.RSVP.all;
  var hash = Ember.RSVP.hash;
  var map = Ember.RSVP.map;
  exports.default = Route.extend({
    rollbar: service(),
    queryParams: _queryParams.route,

    _extractFilters: function _extractFilters(params) {
      var team = params.team,
          statusFlow = params.statusFlow,
          taskStatus = params.taskStatus,
          taskAvailability = params.taskAvailability,
          discarded = params.discarded,
          isFromPromoter = params.isFromPromoter;


      return {
        'is-from-promoter': isFromPromoter,
        'status-flow': statusFlow,
        'task-status': taskStatus,
        'task-availability': taskAvailability,
        'remove-discarded-applications': discarded,
        team: team
      };
    },
    loadRelationships: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(partnerApplication) {
        var pipelineWithTasks, loadApplication, loadApplicationWithRelationships, loadRecords;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                pipelineWithTasks = partnerApplication.belongsTo('pipeline').reload().then(function (pipeline) {
                  return pipeline.get('tasks');
                });
                loadApplication = partnerApplication.get('bkfApplication');
                loadApplicationWithRelationships = loadApplication.then(function (application) {
                  var requester = application.get('requester');
                  var lead = application.get('lead');
                  var scheduledPartner = application.get('scheduledPartner');

                  return hash({ requester: requester, lead: lead, scheduledPartner: scheduledPartner });
                });
                loadRecords = loadApplicationWithRelationships.then(function (_ref2) {
                  var lead = _ref2.lead;

                  return lead.hasMany('records').reload();
                });
                return _context.abrupt('return', all([loadRecords, pipelineWithTasks]));

              case 5:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function loadRelationships(_x) {
        return _ref.apply(this, arguments);
      }

      return loadRelationships;
    }(),
    extractQueryParams: function extractQueryParams(params) {
      var offset = (params.page - 1) * params.perPage;
      var filter = this._extractFilters(params);
      var sort = params.sortBy.toString();

      return {
        page: {
          offset: offset,
          limit: params.perPage
        },
        filter: filter,
        sort: sort
      };
    },
    model: function () {
      var _ref3 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(params) {
        var _this = this;

        var partnerApplications, query, customError;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                partnerApplications = [];
                query = this.extractQueryParams(params);
                _context2.prev = 2;
                _context2.next = 5;
                return this.store.query('underwriting/auto-refi/fidc/application', query);

              case 5:
                partnerApplications = _context2.sent;
                _context2.next = 8;
                return map(partnerApplications.toArray(), function (pa) {
                  return _this.loadRelationships(pa);
                });

              case 8:
                _context2.next = 15;
                break;

              case 10:
                _context2.prev = 10;
                _context2.t0 = _context2['catch'](2);
                customError = new Error('Failure while loading the partner application task list (partner: FIDC)');


                Ember.Logger.error(_context2.t0);
                this.get('rollbar').error(customError, {
                  errorEvent: _context2.t0
                });

              case 15:
                _context2.prev = 15;
                return _context2.abrupt('return', partnerApplications);

              case 18:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this, [[2, 10, 15, 18]]);
      }));

      function model(_x2) {
        return _ref3.apply(this, arguments);
      }

      return model;
    }(),


    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });
});