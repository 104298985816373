define('middle-office/pods/components/app-inspection/status/refused/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['ui', 'two', 'column', 'centered', 'form', 'grid', 'refused'],
    readonly: false,

    layout: Ember.HTMLBars.template({
      "id": "0fhZiQEU",
      "block": "{\"symbols\":[],\"statements\":[[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"nine wide column\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ui compact tiny info message\"],[7],[0,\"\\n        \"],[6,\"i\"],[7],[0,\"O cliente foi recusado na vistoria e o laudo já se encontra disponível.\"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"nine wide column\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ui middle aligned grid\"],[7],[0,\"\\n        \"],[6,\"label\"],[9,\"class\",\"three wide column\"],[7],[0,\"\\n          Motivo:\\n        \"],[8],[0,\"\\n\\n        \"],[6,\"div\"],[9,\"class\",\"eleven wide column\"],[7],[0,\"\\n\"],[4,\"if\",[[19,0,[\"readonly\"]]],null,{\"statements\":[[0,\"            \"],[1,[20,[\"inspection\",\"observation\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[25,\"app-textarea\",null,[[\"rows\",\"value\"],[2,[19,0,[\"inspection\",\"observation\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"]],\"hasEval\":false}",
      "meta": {}
    })
  });
});