define('middle-office/initializers/rollbar', ['exports', 'middle-office/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'rollbar',

    initialize: function initialize() {
      if (_environment.default.environment !== 'production') {
        return;
      }

      var originalNotifier = Ember.onerror || function () {};
      Ember.onerror = function () {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        if (window.Rollbar) {
          var _Rollbar;

          (_Rollbar = Rollbar).error.apply(_Rollbar, args);
        }

        return originalNotifier.apply(this, args);
      };
    }
  };
});