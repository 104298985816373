define('middle-office/pods/components/app-modal/abstract-modal', ['exports', 'semantic-ui-ember/components/ui-modal'], function (exports, _uiModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var alias = Ember.computed.alias;
  exports.default = _uiModal.default.extend({
    classNames: ['small'],

    header: alias('settings.header'),
    type: alias('settings.type'),
    padding: alias('settings.padding'),

    willInitSemantic: function willInitSemantic(settings) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, [settings].concat(_toConsumableArray(args)));

      settings.autofocus = false;
      settings.closable = false;
      settings.detachable = true;
      settings.duration = 0;
      settings.transition = 'fade';

      if (Ember.testing) {
        settings.detachable = false;
        settings.context = '#ember-testing-container';
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$().modal('show');
    },
    willDestroyElement: function willDestroyElement() {
      this.$().modal('hide').modal('destroy').remove();
    }
  });
});