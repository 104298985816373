define('middle-office/models/loan/auto-refi/bkf/document', ['exports', 'ember-cli-bkf-core/models/loan/auto-refi/bkf/document', 'middle-office/enums/loan/auto-refi/bkf/document-types', 'ember-data/attr'], function (exports, _document, _documentTypes, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var equal = Ember.computed.equal;
  exports.default = _document.default.extend({
    completable: (0, _attr.default)('boolean'),
    documentModelType: 'loan/auto-refi/bkf/attachment',
    documentNamesEnum: _documentTypes.default,
    isUploadable: true,

    checklistDocuments: alias('attachments'),
    isIncomplete: equal('status', 'incomplete'),
    isComplete: equal('status', 'complete'),
    isValidated: equal('status', 'validated'),
    isCompletable: alias('completable')
  });
});