define('middle-office/pods/components/app-table-filters/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['app-table-filters'],
    selectedFilters: [],

    actions: {
      onChange: function onChange(filter) {
        var filters = this.get('selectedFilters');
        var updatedFilters = void 0;

        if (this.get('isRadio')) {
          updatedFilters = filter;
        } else if (filters.includes(filter)) {
          updatedFilters = filters.without(filter);
        } else {
          updatedFilters = filters.concat(filter);
        }

        this.sendAction('onChange', updatedFilters);
      }
    }
  });
});