define('middle-office/pods/components/app-inspection/external-code/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['form-field', 'field'],
    readonly: false,

    layout: Ember.HTMLBars.template({
      "id": "c4g7hvPb",
      "block": "{\"symbols\":[],\"statements\":[[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui middle aligned grid\"],[7],[0,\"\\n      \"],[6,\"label\"],[9,\"class\",\"five wide right aligned column\"],[7],[0,\"\\n        Núm. do Protocolo:\\n      \"],[8],[0,\"\\n\\n      \"],[6,\"div\"],[9,\"class\",\"ten wide left aligned column\"],[7],[0,\"\\n\"],[4,\"if\",[[19,0,[\"readonly\"]]],null,{\"statements\":[[0,\"          \"],[1,[20,[\"inspection\",\"externalCode\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[25,\"app-input\",null,[[\"disabled\",\"value\",\"on-change\"],[[19,0,[\"readonly\"]],[19,0,[\"inspection\",\"externalCode\"]],[25,\"action\",[[19,0,[]],[25,\"mut\",[[19,0,[\"inspection\",\"externalCode\"]]],null]],null]]]],false],[0,\"\\n          \"],[1,[25,\"app-errors\",null,[[\"model\",\"attribute\",\"showErrors\"],[[19,0,[\"inspection\"]],\"externalCode\",[19,0,[\"showErrors\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"]],\"hasEval\":false}",
      "meta": {}
    })
  });
});