define('middle-office/models/loan/auto-refi/bkf/submission-queue', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    size: _emberData.default.attr('number'),

    firstSubmission: _emberData.default.belongsTo('loan/auto-refi/bkf/application-submission')
  });
});