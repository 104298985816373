define('middle-office/pods/home-refi/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    appLayoutConfig: {
      headerMenu: true,
      menu: {
        links: [
        // {
        //   text: 'Parâmetros de elegibilidade',
        //   route: 'home-refi.eligibility-parameters',
        // },
        {
          text: 'Ficha',
          route: 'home-refi.application-form'
        }, {
          text: 'Documentos',
          route: 'home-refi.documents'
        }]
      }
    }
  });
});