define('middle-office/services/surprise', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var $ = Ember.$;
  var EmberPromise = Ember.RSVP.Promise;
  var get = Ember.get;
  var getOwner = Ember.getOwner;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    session: service(),
    store: service(),

    _createURL: function _createURL(url) {
      var adapter = getOwner(this).lookup('adapter:application');
      var host = get(adapter, 'host');

      return host + '/' + url;
    },
    _createSurpriseUrl: function _createSurpriseUrl(priority) {
      var endpoint = priority === 'fidc' ? 'surprise-fidc' : 'surprise';
      return this._createURL('loan/auto-refi/bkf/applications/' + endpoint);
    },
    _setRequestHeader: function _setRequestHeader(xhr) {
      var accessToken = this.get('session.data.authenticated.access_token');
      xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
      xhr.setRequestHeader('Accept-Version', 'v1');
    },
    _XMLHttpRequest: function _XMLHttpRequest(url, method, beforeSend) {
      return new EmberPromise(function (resolve, reject) {
        $.ajax({
          url: url,
          method: method,
          beforeSend: beforeSend
        }).done(function (response) {
          return resolve(response.data);
        }).fail(function (jqXHR, textStatus, errorThrown) {
          return reject(errorThrown);
        });
      });
    },
    nextApplication: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(priority) {
        var _this = this;

        var url, beforeSend, nextApplication, store, normalized, application;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                url = this._createSurpriseUrl(priority);

                beforeSend = function beforeSend(xhr) {
                  return _this._setRequestHeader(xhr);
                };

                _context.next = 4;
                return this._XMLHttpRequest(url, 'GET', beforeSend);

              case 4:
                nextApplication = _context.sent;

                if (nextApplication) {
                  _context.next = 7;
                  break;
                }

                throw new Error('Nenhum lead disponível');

              case 7:
                store = this.get('store');
                normalized = store.normalize('loan/auto-refi/bkf/application', nextApplication);
                application = store.push(normalized);
                return _context.abrupt('return', application);

              case 11:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function nextApplication(_x) {
        return _ref.apply(this, arguments);
      }

      return nextApplication;
    }()
  });
});