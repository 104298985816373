define('middle-office/enums/underwriting/auto-refi/rejection-reasons', ['exports', 'lodash', 'middle-office/enums/underwriting/auto-refi/rejection-reasons/approval', 'middle-office/enums/underwriting/auto-refi/rejection-reasons/formalization', 'middle-office/enums/underwriting/auto-refi/rejection-reasons/system'], function (exports, _lodash, _approval, _formalization, _system) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = undefined;


  var reasons = _approval.default.concat(_formalization.default, _system.default);

  exports.default = reasons;
  var values = exports.values = _lodash.default.map(reasons, 'value');
});