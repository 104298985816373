define('middle-office/pods/components/application-modal/auto-refi/component', ['exports', 'middle-office/pods/components/app-modal/abstract-modal'], function (exports, _abstractModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  exports.default = _abstractModal.default.extend({
    classNames: ['application-modal'],
    name: alias('settings.name'),

    nestedComponentName: computed('name', function () {
      var modalPatch = 'application-modal/auto-refi';
      return this.get('name') ? modalPatch + '/' + this.get('name') : null;
    })
  });
});