define('middle-office/pods/components/file-card/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    tagName: '',
    layout: Ember.HTMLBars.template({
      "id": "UUcK4LS0",
      "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"app-card\",null,[[\"color\",\"class\"],[[19,0,[\"appCardColor\"]],\"file-card\"]],{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"content\"],[7],[0,\"\\n        \"],[6,\"a\"],[9,\"class\",\"delete-button\"],[10,\"onClick\",[25,\"action\",[[19,0,[]],\"clearFile\"],null],null],[7],[0,\"\\n          \"],[6,\"i\"],[9,\"class\",\"right floated trash icon grey\"],[7],[8],[0,\"\\n        \"],[8],[0,\"\\n\\n        \"],[6,\"div\"],[9,\"class\",\"header\"],[7],[1,[20,[\"file\",\"name\"]],false],[8],[0,\"\\n\\n        \"],[6,\"div\"],[9,\"class\",\"description\"],[7],[0,\"\\n\"],[4,\"if\",[[19,0,[\"file\",\"hasError\"]]],null,{\"statements\":[[0,\"            \"],[6,\"p\"],[7],[0,\"Erro no upload. Tente novamente por favor.\"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[6,\"p\"],[7],[0,\"Última alteração: \"],[1,[25,\"mask\",[[19,0,[\"file\",\"lastModified\"]],\"date\"],null],false],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"hasEval\":false}",
      "meta": {}
    }),

    file: computed(function () {
      return {};
    }),

    appCardColor: computed('file.hasError', function () {
      var hasError = this.get('file.hasError');

      return hasError ? 'red' : 'green';
    }),

    actions: {
      clearFile: function clearFile() {
        var file = this.get('file');

        this.sendAction('clearFile', file);
      }
    }
  });
});