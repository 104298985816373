define('middle-office/enums/loan/auto-refi/bkf/document-types', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.formalizationDocuments = exports.applicationDocuments = exports.personDocuments = undefined;
  var personDocuments = exports.personDocuments = [{
    text: 'Carteira de Motorista',
    value: 'cnh'
  }, {
    text: 'Documentos de Identidade',
    value: 'identity_document'
  }, {
    text: 'Carteira de Trabalho e Previdência Social',
    value: 'ctps'
  }, {
    text: 'Comprovação de Estado Civil',
    value: 'marital_status'
  }, {
    text: 'Consultas (Serasa, Crivo, Seguro desemprego, etc.)',
    value: 'third_party_document'
  }, {
    text: 'Comprovante de renda (Holerite/Extratos/Tabela Uber)',
    value: 'income_proof'
  }, {
    text: 'Tabulação de renda',
    value: 'income_tabulation'
  }, {
    text: 'Comprovante de residência',
    value: 'residence_proof'
  }, {
    text: 'Declaração de IRPF',
    value: 'irpf'
  }, {
    text: 'Autorização de consulta ao BACEN',
    value: 'bacen_form'
  }, {
    text: 'Identificação de conta',
    value: 'bank_account_identification'
  }, {
    text: 'Carteirinha de classe',
    value: 'class_entity_registration'
  }, {
    text: 'Informações de crédito',
    value: 'credit_information'
  }, {
    text: 'Outros documentos pessoais',
    value: 'other_personal_documents'
  }];

  var applicationDocuments = exports.applicationDocuments = [{
    text: 'DUT',
    value: 'dut'
  }, {
    text: 'Consultas do Veículo',
    value: 'vehicle_inqueries'
  }, {
    text: 'Laudo de Avaliação',
    value: 'appraisal'
  }, {
    text: 'Outros documentos do veículo',
    value: 'other_vehicle_documents'
  }];

  var formalizationDocuments = exports.formalizationDocuments = [{
    text: 'Ficha Cadastral',
    value: 'registration_form'
  }, {
    text: 'Simulações',
    value: 'loan_simulation'
  }, {
    text: 'CET',
    value: 'cet'
  }, {
    text: 'Autorização de Quitação',
    value: 'discharge_authorization'
  }, {
    text: 'Boleto de quitação (IQ)',
    value: 'debts_settlement_invoice'
  }, {
    text: 'CCB',
    value: 'ccb'
  }, {
    text: 'Outros documentos de formalização',
    value: 'other_formalization_documents'
  }];

  var allDocumentTypes = [].concat(personDocuments, applicationDocuments, formalizationDocuments);

  exports.default = allDocumentTypes;
  var values = exports.values = _lodash.default.map(allDocumentTypes, 'value');
});