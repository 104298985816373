define('middle-office/pods/components/todo-list/component', ['exports', 'middle-office/enums/loan/auto-refi/workflow/pipeline/task-status'], function (exports, _taskStatus) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var computed = Ember.computed;
  var service = Ember.inject.service;
  var sort = Ember.computed.sort;
  var reject = Ember.RSVP.reject;
  var Component = Ember.Component;
  exports.default = Component.extend({
    rollbar: service(),
    classNames: ['todo-list__container'],
    orderedItems: sort('items', 'orderedBy'),
    taskStatus: _taskStatus.default,

    orderedBy: [],
    feedbackMessage: null,
    showConfirmationModal: false,

    notFoundMessage: {
      title: 'Ooops...',
      text: 'Nenhuma tarefa encontrada.'
    },
    confirmationModalMessage: 'Ao desmarcar esta tarefa as dependentes poderão ser desmarcadas também.',
    confirmationSettings: {
      header: 'Desmarcar tarefa?',
      type: 'delete'
    },

    hasSavingItems: computed.filterBy('orderedItems', 'isSaving', true),
    isSaving: computed.notEmpty('hasSavingItems'),

    logError: function logError(e) {
      var error = new Error('Failure while saving task');
      var message = {
        type: 'error',
        title: 'Ooops...',
        text: 'Erro ao salvar informação...'
      };

      this.get('rollbar').error(error, {
        errorEvent: e
      });
      this.set('feedbackMessage', message);
    },
    _saveTask: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(item) {
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.prev = 0;
                _context.next = 3;
                return item.save();

              case 3:
                _context.next = 9;
                break;

              case 5:
                _context.prev = 5;
                _context.t0 = _context['catch'](0);

                item.rollbackAttributes();
                this.logError(_context.t0);

              case 9:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this, [[0, 5]]);
      }));

      function _saveTask(_x) {
        return _ref.apply(this, arguments);
      }

      return _saveTask;
    }(),


    actions: {
      openConfirmationModal: function openConfirmationModal(item) {
        this.set('confirmationModalData', { item: item });
        this.set('showConfirmationModal', true);
      },
      onApproveConfirmationModal: function onApproveConfirmationModal(_ref2) {
        var item = _ref2.item;

        this.set('showConfirmationModal', false);

        if (!item.changeToRework()) return reject();

        return this._saveTask(item);
      },
      onDenyConfirmationMoldal: function onDenyConfirmationMoldal() {
        this.set('showConfirmationModal', false);
      },
      onRework: function onRework(item) {
        this.send('openConfirmationModal', item);
      },
      onDoing: function onDoing(item) {
        if (!item.changeToDoing()) return reject();

        return this._saveTask(item);
      },
      onDone: function onDone(item) {
        if (!item.changeToDone()) return reject();

        return this._saveTask(item);
      }
    }
  });
});