define('middle-office/services/auto-refi/credit/credit-reference-persist', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  exports.default = Service.extend({
    saveCreditReference: function saveCreditReference(_ref) {
      var creditReference = _ref.creditReference,
          fidcApplication = _ref.fidcApplication,
          residenceAddress = _ref.residenceAddress,
          companyAddress = _ref.companyAddress;

      creditReference.set('fidcApplication', fidcApplication);
      creditReference.set('residenceAddress', residenceAddress);
      creditReference.set('companyAddress', companyAddress);

      return creditReference.save();
    }
  });
});