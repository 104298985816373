define('middle-office/services/rollbar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  exports.default = Service.extend({
    configure: delegateToRollbar('configure'),
    error: delegateToRollbar('error'),
    info: delegateToRollbar('info'),
    warn: delegateToRollbar('warn'),
    warning: delegateToRollbar('warning'),
    debug: delegateToRollbar('debug')
  });


  function delegateToRollbar(method) {
    return function () {
      /* global Rollbar */
      if (window.Rollbar) {
        var _Rollbar;

        return (_Rollbar = Rollbar)[method].apply(_Rollbar, arguments);
      }

      return null;
    };
  }
});