define('middle-office/pods/components/app-documents-list/mixins/file-upload', ['exports', 'middle-office/pods/components/app-documents-list/mixins/upload-document-model'], function (exports, _uploadDocumentModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var getOwner = Ember.getOwner;
  var bind = Ember.run.bind;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  exports.default = Mixin.create({
    uploadsList: computed(function () {
      return [];
    }),
    rollbar: service(),

    _startUpload: function _startUpload(files) {
      var _this = this;

      Array.from(files).forEach(function (file) {
        var uploadData = _uploadDocumentModel.default.create({
          file: file,
          filename: file.name
        });
        var xhr = _this._uploadXhr(uploadData);
        var formData = new FormData();

        formData.append('data', file);

        _this.get('uploadsList').pushObject(uploadData);

        xhr.setRequestHeader('accept-version', 'v1');
        xhr.send(formData);
      });
    },
    _uploadXhr: function _uploadXhr(uploadData) {
      var _this2 = this;

      var xhr = new XMLHttpRequest();

      xhr.open('POST', this._fullUrl());

      xhr.onreadystatechange = function () {
        _this2._handleUpload(xhr, uploadData);
      };

      if ('upload' in xhr) {
        xhr.upload.onprogress = bind(uploadData, 'onProgress');
      }

      return xhr;
    },
    _handleUpload: function _handleUpload(xhr, uploadData) {
      var validResponseCodes = [200, 201, 202];

      if (xhr.readyState !== XMLHttpRequest.DONE) {
        return;
      }

      var response = xhr.response;

      if (validResponseCodes.includes(xhr.status)) {
        this._uploadDone(uploadData, response);
      } else {
        this._uploadError(uploadData, response);
      }
    },
    _uploadDone: function _uploadDone(uploadData, response) {
      var store = this.get('store');
      store.pushPayload(JSON.parse(response));

      this.get('rollbar').info('Consultant has uploaded an attachment', {
        uploadData: uploadData,
        response: response
      });
      var newDoc = store.peekAll(this.get('document.documentModelType')).get('lastObject');
      var uploadsList = this.get('uploadsList');
      var currentIndex = uploadsList.indexOf(uploadData);

      uploadData.onDone();
      uploadsList.replace(currentIndex, 1, [newDoc]);
    },
    _uploadError: function _uploadError(uploadData, response) {
      this.get('rollbar').warning('Failure when trying to upload an attachment', {
        uploadData: uploadData,
        errors: response
      });
      uploadData.onError();
    },
    _fullUrl: function _fullUrl() {
      var url = this.get('document.uploadUrl');
      var apiHost = getOwner(this).lookup('adapter:application').get('host');

      if (!apiHost) {
        apiHost = document.location.origin;
      }

      return new URL(url, apiHost).href;
    }
  });
});