define('middle-office/services/auto-refi/application-change-requests', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Service = Ember.Service;
  var service = Ember.inject.service;
  var all = Ember.RSVP.all;
  exports.default = Service.extend({
    store: service(),

    createChangeRequest: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(partnerApplication) {
        var _ref2, _ref3, application, partner, changeRequest;

        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return all([partnerApplication.get('bkfApplication'), partnerApplication.get('partner')]);

              case 2:
                _ref2 = _context.sent;
                _ref3 = _slicedToArray(_ref2, 2);
                application = _ref3[0];
                partner = _ref3[1];
                changeRequest = this.get('store').createRecord('loan/auto-refi/bkf/application-change-request', {
                  application: application,
                  partner: partner
                });
                _context.next = 9;
                return changeRequest.save();

              case 9:
                _context.next = 11;
                return partnerApplication.reload();

              case 11:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function createChangeRequest(_x) {
        return _ref.apply(this, arguments);
      }

      return createChangeRequest;
    }()
  });
});