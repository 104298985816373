define('middle-office/pods/auto-refi/timeline/settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var modalSettings = exports.modalSettings = {
    registry: {
      type: 'registry',
      header: 'Nova informação',
      name: 'timeline',
      approveAction: 'newRegister'
    },
    contact: {
      type: 'contact',
      header: 'Novo contato',
      name: 'timeline',
      approveAction: 'newContact'
    }
  };

  exports.default = modalSettings;
});