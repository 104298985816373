define('middle-office/mixins/close-loan', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  var service = Ember.inject.service;
  exports.default = Mixin.create({
    store: service(),

    partnerApplication: computed(function () {
      return {};
    }),
    bkfApplication: reads('partnerApplication.bkfApplication'),
    closingLoan: computed('bkfApplication', function () {
      var _getProperties = this.getProperties('bkfApplication', 'partnerApplication', 'store'),
          bkfApplication = _getProperties.bkfApplication,
          partnerApplication = _getProperties.partnerApplication,
          store = _getProperties.store;

      var requester = bkfApplication.get('requester');
      var loan = store.createRecord('closing/auto-refi/loan');

      loan.preFill(partnerApplication, bkfApplication, requester);

      return loan;
    }),

    actions: {
      closeLoan: function closeLoan() {
        var _getProperties2 = this.getProperties('closingLoan', 'partnerApplication'),
            closingLoan = _getProperties2.closingLoan,
            partnerApplication = _getProperties2.partnerApplication;

        var _closingLoan$getPrope = closingLoan.getProperties('isFidc', 'validations'),
            isFidc = _closingLoan$getPrope.isFidc,
            validations = _closingLoan$getPrope.validations;

        this.set('isInvalid', validations.get('isInvalid'));
        if (validations.get('isValid') || isFidc) {
          partnerApplication.set('closingLoan', closingLoan);

          this.sendAction('on-save');
        }
      }
    }
  });
});