define('middle-office/pods/components/app-forms/application/auto-refi/steps/vehicle/component', ['exports', 'ember-cli-bkf-core/utils/translate-from-enum'], function (exports, _translateFromEnum) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var bool = Ember.computed.bool;
  var reads = Ember.computed.reads;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    store: service('store'),
    vehicleHasNoDebts: reads('application.vehicleHasNoDebts'),

    classNames: ['real-estate-data', 'form-step'],

    showMolicarModal: false,

    isMolicar: bool('application.molicarCode'),

    vehicleTable: computed('isMolicar', function () {
      var isMolicar = this.get('isMolicar');

      return isMolicar ? 'Molicar' : 'FIPE';
    }),

    vehicleCategory: (0, _translateFromEnum.default)('auto-refi/vehicle-category', 'application.vehicleCategory'),

    actions: {
      openMolicarSearchModal: function openMolicarSearchModal(event) {
        event.preventDefault();
        this.set('showMolicarModal', true);
      },
      closeMolicarSearchModal: function closeMolicarSearchModal() {
        this.set('showMolicarModal', false);
      },
      resetVehicleDebt: function resetVehicleDebt(newValue) {
        if (newValue) {
          this.set('application.vehicleDebt', 0);
        }
      }
    }
  });
});