define('middle-office/pods/components/app-reset-password-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var service = Ember.inject.service;
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    auth: service(),
    routing: service(),
    rollbar: service(),

    tagName: 'form',
    classNames: ['login-form', 'ui', 'large', 'form'],

    rules: computed(function () {
      return [{
        type: 'empty',
        prompt: 'Email é obrigatório'
      }, {
        type: 'email',
        prompt: 'Email inválido'
      }];
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var rules = this.get('rules');
      var email = {
        identifier: 'email',
        rules: rules
      };
      var fields = { email: email };

      this.$().form({
        on: 'blur',
        inline: true,
        fields: fields
      });
    },
    submit: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(event) {
        var valid, _getProperties, email, auth, routing;

        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                event.preventDefault();

                valid = this.$().form('is valid');

                if (valid) {
                  _context.next = 4;
                  break;
                }

                return _context.abrupt('return');

              case 4:
                _getProperties = this.getProperties('email', 'auth', 'routing'), email = _getProperties.email, auth = _getProperties.auth, routing = _getProperties.routing;
                _context.prev = 5;
                _context.next = 8;
                return auth.requestReset(email);

              case 8:

                routing.transitionTo('auth.reset-request-success');
                _context.next = 14;
                break;

              case 11:
                _context.prev = 11;
                _context.t0 = _context['catch'](5);

                this.handleErrors(_context.t0);

              case 14:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this, [[5, 11]]);
      }));

      function submit(_x) {
        return _ref.apply(this, arguments);
      }

      return submit;
    }(),
    handleErrors: function handleErrors(exception) {
      var message = {
        type: 'error',
        title: 'Não foi possível trocar a senha',
        text: exception.message
      };

      this.set('errorMessage', message);

      var errors = exception.errors;
      this.get('rollbar').error(message.title, exception, { errors: errors });
    }
  });
});