define('middle-office/pods/components/app-forms/application/auto-refi/steps/personal/component', ['exports', 'lodash', 'ember-cli-bkf-core/enums/document-type'], function (exports, _lodash, _documentType) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['personal-data', 'form-step'],

    store: service('store'),

    documentTypeAlias: computed('person.documentType', function () {
      var options = _documentType.default;
      var docType = this.get('person.documentType');
      var obj = _lodash.default.find(options, { value: docType });

      if (obj) {
        return obj.text;
      }

      return null;
    })
  });
});