define('middle-office/enums/underwriting/auto-refi/credit-approval-situation', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.creditApprovalSituation = undefined;
  var creditApprovalSituation = exports.creditApprovalSituation = [{
    text: 'Proposta em análise',
    value: 'pending'
  }, {
    text: 'Proposta rejeitada',
    value: 'rejected'
  }, {
    text: 'Reanálise',
    value: 'reanalysis'
  }, {
    text: 'Em andamento',
    value: 'doing'
  }];

  exports.default = creditApprovalSituation;
  var values = exports.values = _lodash.default.map(creditApprovalSituation, 'value');
});