define('middle-office/pods/components/app-table/auto-inspections/accordion-line/open-content/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: 'td',
    attributeBindings: ['colspan'],
    colspan: 4,

    layout: Ember.HTMLBars.template({
      "id": "44Jg3jpn",
      "block": "{\"symbols\":[],\"statements\":[[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui two column grid\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"four column row\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"column\"],[7],[0,\"\\n          \"],[1,[25,\"app-table/auto-inspections/accordion-line/open-content/period\",null,[[\"inspection\"],[[19,0,[\"inspection\"]]]]],false],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"column\"],[7],[0,\"\\n          \"],[1,[25,\"app-table/auto-inspections/accordion-line/open-content/external-code\",null,[[\"inspection\"],[[19,0,[\"inspection\"]]]]],false],[0,\"\\n        \"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"column\"],[7],[0,\"\\n          \"],[1,[25,\"app-table/auto-inspections/accordion-line/open-content/location\",null,[[\"inspection\"],[[19,0,[\"inspection\"]]]]],false],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"column\"],[7],[0,\"\\n          \"],[1,[25,\"app-table/auto-inspections/accordion-line/open-content/address\",null,[[\"inspection\"],[[19,0,[\"inspection\"]]]]],false],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"]],\"hasEval\":false}",
      "meta": {}
    })
  });
});