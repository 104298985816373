define('middle-office/models/loan/home-refi/bkf/person', ['exports', 'ember-data', 'ember-cli-bkf-core/models/loan/home-refi/bkf/person', 'moment'], function (exports, _emberData, _person, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var service = Ember.inject.service;
  var computed = Ember.computed;
  var observer = Ember.observer;
  exports.default = _person.default.extend({
    bacenAuthorizationsBariguiDocumentUrl: _emberData.default.attr('string'),
    bacenAuthorizationsBvDocumentUrl: _emberData.default.attr('string'),
    bacenAuthorizationsMaximaDocumentUrl: _emberData.default.attr('string'),

    isSameResidenceMailingAddress: computed('residenceAddress.content', 'mailingAddress.content', function () {
      var residenceAddress = this.get('residenceAddress.content');
      var mailingAddress = this.get('mailingAddress.content');

      if (residenceAddress != null && mailingAddress != null && mailingAddress === residenceAddress) {
        return true;
      }

      return false;
    }),

    shouldHaveSpouse: computed('maritalStatus', 'stableUnion', function () {
      return this.get('maritalStatus') === 'married' || this.get('stableUnion');
    }),

    personPreparation: service('home-refi/person-preparation'),

    _handleHasSpouse: observer('shouldHaveSpouse', _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
      var shouldHaveSpouse, spouse, store;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              shouldHaveSpouse = this.get('shouldHaveSpouse');
              _context.next = 3;
              return this.get('spouse');

            case 3:
              spouse = _context.sent;

              if (!(shouldHaveSpouse && spouse == null)) {
                _context.next = 10;
                break;
              }

              store = this.get('store');

              spouse = store.createRecord('loan/home-refi/bkf/person');
              _context.next = 9;
              return this.get('personPreparation').prepareSpouse(spouse);

            case 9:

              this.set('spouse', spouse);

            case 10:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    }))),

    hasPreviousEmployment: computed('currentEmployment.startDate', function () {
      var startDate = this.get('currentEmployment.startDate');
      var employment = this.get('previousEmployment.content');
      var previousEmployment = this.retrieveEmployment(startDate, employment);

      if (previousEmployment != null) {
        this.set('previousEmployment', previousEmployment);

        return true;
      }

      return false;
    }),

    hasAdditionalPreviousEmployment: computed('previousEmployment.startDate', function () {
      var startDate = this.get('previousEmployment.startDate');
      var employment = this.get('additionalPreviousEmployment.content');
      var additionalPreviousEmployment = this.retrieveEmployment(startDate, employment);

      if (additionalPreviousEmployment != null) {
        this.set('additionalPreviousEmployment', additionalPreviousEmployment);

        return true;
      }

      return false;
    }),

    hasFinanceDebts: computed('financeDebtDescription', 'financeDebtMonthlyCost', 'financeDebtMonthsLeft', function () {
      return Boolean(this.get('financeDebtDescription') || this.get('financeDebtMonthlyCost') || this.get('financeDebtMonthsLeft'));
    }),

    hasAdditionalIncome: computed('additionalIncomeType', 'additionalIncomeDescription', 'additionalIncomeValue', function () {
      return Boolean(this.get('additionalIncomeType') || this.get('additionalIncomeDescription') || this.get('additionalIncomeValue'));
    }),

    retrieveEmployment: function retrieveEmployment(startDate, employment) {
      var utcStartDate = _moment.default.utc(startDate);

      if (!startDate || _moment.default.utc().diff(utcStartDate, 'months') > 6) {
        return null;
      }

      if (employment == null) {
        var companyAddress = this.get('store').createRecord('loan/home-refi/bkf/address');
        return this.get('store').createRecord('loan/home-refi/bkf/employment', { companyAddress: companyAddress });
      }

      return employment;
    }
  });
});