define('middle-office/pods/components/auto-fin/application-search-result/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var sort = Ember.computed.sort;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    store: service(),
    routing: service(),

    _creatingApplication: false,
    _creatingCustomer: false,
    _customerError: null,

    _applicationsOrder: ['createdAt:desc'],
    sortedApplications: sort('result.autoFinancingApplications', '_applicationsOrder'),

    createNewApplication: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(customer) {
        var application;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                this.set('_creatingApplication', true);
                application = void 0;
                _context.prev = 2;

                application = this.get('store').createRecord('auto/financing/application', {
                  statusFlow: 'preparation',
                  requesterFullName: customer.get('fullName'),
                  requesterCpf: customer.get('cpf'),
                  requesterContactEmail: customer.get('email'),
                  requesterContactCellphoneNumber: customer.get('contactCellphone'),
                  requesterContactCellphoneCode: customer.get('contactCellphoneCode'),

                  customer: customer
                });

                _context.next = 6;
                return application.save();

              case 6:
                _context.prev = 6;

                this.get('_creatingApplication', false);
                return _context.finish(6);

              case 9:

                this.get('routing').transitionTo('main.auto-fin.edit-application', [application]);

              case 10:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this, [[2,, 6, 9]]);
      }));

      function createNewApplication(_x) {
        return _ref.apply(this, arguments);
      }

      return createNewApplication;
    }(),


    actions: {
      createNewApplication: function createNewApplication() {
        return this.createNewApplication(this.get('result.customer'));
      },
      createCustomer: function () {
        var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2() {
          var customer;
          return regeneratorRuntime.wrap(function _callee2$(_context2) {
            while (1) {
              switch (_context2.prev = _context2.next) {
                case 0:
                  customer = this.get('newCustomer');

                  this.set('_creatingCustomer', true);
                  this.set('_customerError', null);

                  _context2.prev = 3;
                  _context2.next = 6;
                  return customer.save();

                case 6:
                  _context2.next = 8;
                  return this.createNewApplication(customer);

                case 8:
                  this.$('.customer-creation-modal').modal('hide');
                  _context2.next = 14;
                  break;

                case 11:
                  _context2.prev = 11;
                  _context2.t0 = _context2['catch'](3);

                  this.set('_customerError', _context2.t0);

                case 14:
                  _context2.prev = 14;

                  this.set('_creatingCustomer', false);
                  return _context2.finish(14);

                case 17:
                case 'end':
                  return _context2.stop();
              }
            }
          }, _callee2, this, [[3, 11, 14, 17]]);
        }));

        function createCustomer() {
          return _ref2.apply(this, arguments);
        }

        return createCustomer;
      }(),
      cancelCreation: function cancelCreation() {
        this.set('newCustomer', null);
      },
      showCustomerModal: function showCustomerModal() {
        var customer = this.get('store').createRecord('crm/customer', {
          sendEmailConfirmation: false,
          cpf: this.get('result.searchedCpf')
        });

        this.set('newCustomer', customer);
        this.$('.customer-creation-modal').modal('show');
      }
    }
  });
});