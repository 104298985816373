define('middle-office/pods/components/app-table/partner-tasks/accordion-line/pending-task-list/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['pending-task-list__container']
  });
});