define('middle-office/enums/servicing/billing/installment/statuses', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.statuses = undefined;
  var statuses = exports.statuses = [{
    text: 'Vencida',
    value: 'overdue'
  }, {
    text: 'Paga',
    value: 'paid'
  }, {
    text: 'Em Aberto',
    value: 'open'
  }];

  exports.default = statuses;
  var values = exports.values = _lodash.default.map(statuses, 'value');
});