define('middle-office/pods/components/app-menu/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['app-menu', 'ui', 'secondary', 'compact', 'menu'],

    tagName: 'nav'
  });
});