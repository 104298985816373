define('middle-office/pods/components/application-modal/auto-refi/timeline/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    path: 'application-modal/auto-refi/timeline',

    nestedComponent: computed('path', 'settings.type', function () {
      var path = this.get('path');
      var type = this.get('settings.type');
      return path + '/' + type;
    })
  });
});