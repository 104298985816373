define('middle-office/pods/components/app-table/auto-inspections/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['auto-inspections'],
    tagName: 'tbody',

    layout: Ember.HTMLBars.template({
      "id": "zpcDHzq+",
      "block": "{\"symbols\":[\"inspection\"],\"statements\":[[0,\"\\n\"],[4,\"each\",[[19,0,[\"records\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,\"app-table/auto-inspections/accordion-line\",null,[[\"inspection\"],[[19,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"      \"],[6,\"h4\"],[9,\"class\",\"no-records-message\"],[9,\"class\",\"ui disabled header\"],[7],[0,\"\\n        Nenhum registro encontrado...\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"]],\"hasEval\":false}",
      "meta": {}
    })
  });
});