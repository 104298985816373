define('middle-office/pods/components/auto-refi/application-assignment-info/component', ['exports', 'ember-cli-bkf-core/utils/pusher'], function (exports, _pusher) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var service = Ember.inject.service;
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    assignmentService: service('auto-refi/application-assignment'),
    authService: service('auth'),

    classNames: ['ui', 'icon', 'message'],

    _isWaitingForResponse: false,
    _errorReason: null,
    _consultant: computed.reads('application.consultant'),

    _consultantLoaded: computed.reads('_consultant.isFulfilled'),

    _hasConsultant: computed.and('_consultantLoaded', '_consultant.content'),

    _hasNoConsultant: computed('_consultantLoaded', '_consultant.content', function () {
      return this.get('_consultantLoaded') && this.get('_consultant.content') == null;
    }),

    _assignedToLoggedUser: computed('_consultant.id', 'authService.user.id', function () {
      return this.get('_consultant.id') === this.get('authService.user.id');
    }),

    _pusherChannelName: computed('application.id', function () {
      return 'auto-refi-application-' + this.get('application.id');
    }),

    _consultantUnassigned: (0, _pusher.onEvent)((0, _pusher.fromProperty)('_pusherChannelName'), 'auto-refi-application-unassignment', function () {
      this.set('application.consultant', null);
    }),

    _triggerAssignment: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(trigger) {
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                this.set('_isWaitingForResponse', true);
                this.set('_errorReason', null);

                _context.prev = 2;
                _context.next = 5;
                return trigger();

              case 5:
                this.sendAction('on-application-assigned');
                _context.next = 11;
                break;

              case 8:
                _context.prev = 8;
                _context.t0 = _context['catch'](2);

                this.set('_errorReason', _context.t0);

              case 11:
                _context.prev = 11;

                this.set('_isWaitingForResponse', false);
                return _context.finish(11);

              case 14:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this, [[2, 8, 11, 14]]);
      }));

      function _triggerAssignment(_x) {
        return _ref.apply(this, arguments);
      }

      return _triggerAssignment;
    }(),


    actions: {
      manualAssignment: function manualAssignment() {
        var _this = this;

        this._triggerAssignment(function () {
          var assignmentService = _this.get('assignmentService');
          return assignmentService.assignApplicationManually(_this.get('application'));
        });
      },
      takeOnApplication: function takeOnApplication() {
        var _this2 = this;

        this._triggerAssignment(function () {
          var assignmentService = _this2.get('assignmentService');
          return assignmentService.takeOnApplication(_this2.get('application'));
        });
      }
    }
  });
});