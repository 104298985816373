define('middle-office/models/loan/auto-refi/bkf/address', ['exports', 'ember-cli-bkf-core/models/loan/auto-refi/bkf/address', 'ember-cp-validations'], function (exports, _address, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;


  var addressValidations = (0, _emberCpValidations.buildValidations)({
    zipcode: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      is: 8
    })
  });

  exports.default = _address.default.extend(addressValidations, {
    zipcode: alias('zipCode')
  });
});