define('middle-office/pods/components/app-forms/partials/form-field/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var equal = Ember.computed.equal;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['form-field', 'field'],

    classNameBindings: ['isError:error'],

    isError: equal('status', 'error'),

    status: null,

    showErrors: true,

    actions: {
      onInput: function onInput() {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        this.sendAction.apply(this, ['on-input'].concat(_toConsumableArray(args)));
        this.set('status', null);
      },
      setStatus: function setStatus(status) {
        this.set('status', status);
      }
    }
  });
});