define('middle-office/utils/masks', ['exports', 'moment', 'middle-office/utils/remove-punctuation'], function (exports, _moment, _removePunctuation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.percent = exports.tax = exports.ssn = exports.number = exports.money = exports.fullPhone = exports.phone = exports.ddd = exports.time = exports.datetime = exports.date = exports.day = exports.pis = exports.cpf = exports.cnpj = exports.licensePlate = exports.renavam = exports.cnh = exports.cep = exports.nullMask = undefined;
  var EmberObject = Ember.Object;


  var VMasker = window.VMasker;

  var Mask = EmberObject.extend({
    pattern: null,

    setup: function setup(element) {
      var maskInstance = VMasker(element);
      this.setMask(maskInstance);
    },
    setMask: function setMask(maskInstance) {
      if (this.pattern) {
        maskInstance.maskPattern(this.pattern);
      }
    },
    parseIn: function parseIn(value) {
      if (this.pattern) {
        return VMasker.toPattern(value, this.pattern);
      }

      return value;
    },
    parseOut: function parseOut(value) {
      return (0, _removePunctuation.default)(value);
    }
  });

  exports.default = Mask;
  var nullMask = exports.nullMask = Mask.extend({
    setup: function setup() {},
    setMask: function setMask() {},
    parseIn: function parseIn(value) {
      return value;
    },
    parseOut: function parseOut(value) {
      return value;
    }
  });

  var cep = exports.cep = Mask.extend({
    placeholder: '12345-678',

    pattern: '99999-999'
  });

  var cnh = exports.cnh = Mask.extend({
    placeholder: '12345678900',

    pattern: '99999999999'
  });

  var renavam = exports.renavam = Mask.extend({
    placeholder: '00123456789',

    pattern: '99999999999'
  });

  var licensePlate = exports.licensePlate = Mask.extend({
    placeholder: 'ABC-1234',

    pattern: 'AAA-9999',

    parse: function parse(value) {
      var normalized = value.replace(/-/g, '');
      var regex = {
        letters: /[a-zA-Z]{3}/,
        digits: /\d{4}/
      };

      var letters = normalized.split(regex.digits)[0];
      var digits = normalized.split(regex.letters)[1];

      return {
        letters: letters,
        digits: digits
      };
    },
    isValid: function isValid(_ref) {
      var letters = _ref.letters,
          digits = _ref.digits;

      return letters.length === 3 && digits.length === 4;
    },
    parseIn: function parseIn(value) {
      var _parse = this.parse(value),
          letters = _parse.letters,
          digits = _parse.digits;

      if (!this.isValid({ letters: letters, digits: digits })) {
        return null;
      }

      return letters.toUpperCase() + '-' + digits;
    }
  });

  var cnpj = exports.cnpj = Mask.extend({
    placeholder: '12.345.678/9999-00',

    pattern: '99.999.999/9999-99'
  });

  var cpf = exports.cpf = Mask.extend({
    placeholder: '123.456.789-00',

    pattern: '999.999.999-99'
  });

  var pis = exports.pis = Mask.extend({
    placeholder: '051.40170.57-0',

    pattern: '999.99999.99-9'
  });

  var day = exports.day = Mask.extend({
    placeholder: 'DD',

    pattern: '99',

    parseIn: function parseIn(value) {
      var momentDate = (0, _moment.default)(value);

      if (momentDate.isValid()) {
        return momentDate.format('DD');
      }

      return null;
    }
  });

  var date = exports.date = Mask.extend({
    placeholder: 'DD/MM/AAAA',

    pattern: '99/99/9999',

    parseIn: function parseIn(value) {
      var momentDate = (0, _moment.default)(value);

      if (momentDate.isValid()) {
        return momentDate.format('DD/MM/YYYY');
      }

      return null;
    },
    parseOut: function parseOut(value) {
      var momentDate = (0, _moment.default)(value, 'DD/MM/YYYY');

      if (momentDate.isValid()) {
        return momentDate.toDate();
      }

      return null;
    }
  });

  var datetime = exports.datetime = Mask.extend({
    parseIn: function parseIn(value) {
      var momentDate = (0, _moment.default)(value);

      if (momentDate.isValid()) {
        return momentDate.format('DD/MM/YYYY HH:mm');
      }

      return null;
    }
  });

  var time = exports.time = Mask.extend({
    placeholder: 'hh:mm',

    pattern: '99:99',

    parseIn: function parseIn(value) {
      var momentTime = (0, _moment.default)(new Date(value));

      if (momentTime.isValid()) {
        return momentTime.format('HH:mm');
      }

      return null;
    },
    parseOut: function parseOut(value) {
      var momentTime = (0, _moment.default)(value, 'HH:mm');

      if (momentTime.isValid()) {
        return momentTime.toDate();
      }

      return null;
    }
  });

  var ddd = exports.ddd = Mask.extend({
    pattern: '99'
  });

  var phone = exports.phone = Mask.extend({
    pattern: '999999999'
  });

  var fullPhone = exports.fullPhone = Mask.extend({
    parseIn: function parseIn(value) {
      if (value.length > 0) {
        var sliceIndex = value.length === 11 ? 7 : 6;
        var code = '(' + value.slice(0, 2) + ')';
        var _number = value.slice(2, sliceIndex) + '-' + value.slice(sliceIndex);

        return code + ' ' + _number;
      }

      return null;
    }
  });

  var money = exports.money = Mask.extend({
    setMask: function setMask(maskInstance, options) {
      maskInstance.maskMoney(options);
    },
    parseIn: function parseIn(value) {
      var moneyValue = parseFloat(value).toFixed(2);
      return VMasker.toMoney(moneyValue);
    },
    parseOut: function parseOut(value) {
      var floatValue = value.replace(/\./g, '').replace(/,/, '.');

      if (!isNaN(floatValue)) {
        return parseFloat(floatValue);
      }

      return null;
    }
  });

  var number = exports.number = Mask.extend({
    setMask: function setMask(maskInstance) {
      maskInstance.maskNumber();
    },
    parseOut: function parseOut(value) {
      if (value && !isNaN(value)) {
        var _value = value.substr(0, 13);
        return parseInt(_value, 10);
      }

      return null;
    }
  });

  var ssn = exports.ssn = Mask.extend({
    placeholder: '123-45-6789',

    pattern: '999-99-9999'
  });

  var tax = exports.tax = Mask.extend({
    placeholder: '9,99',

    pattern: '9,99',

    parseIn: function parseIn(value) {
      var taxValue = parseFloat(value).toFixed(2);
      return VMasker.toMoney(taxValue);
    },
    parseOut: function parseOut(value) {
      var floatValue = value.replace(/\./g, '').replace(/,/, '.');

      if (!isNaN(floatValue)) {
        return parseFloat(floatValue);
      }

      return null;
    }
  });

  var percent = exports.percent = Mask.extend({
    parseIn: function parseIn(value) {
      var taxValue = parseFloat(value).toFixed(2);
      return VMasker.toMoney(taxValue);
    },
    parseOut: function parseOut(value) {
      var floatValue = value.replace(/\./g, '').replace(/,/, '.').replace(/%/, '');

      if (!isNaN(floatValue)) {
        return parseFloat(floatValue);
      }

      return null;
    }
  });
});