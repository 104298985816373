define('middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/professional/self-employed', ['exports', 'middle-office/enums/auto-refi/time-ranges'], function (exports, _timeRanges) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function selfEmployed(employment) {
    var timeOfEmployment = (0, _timeRanges.textForTimeRange)(employment.timeOfEmployment);
    var jobTitle = employment.jobTitle;
    return '\xC9 aut\xF4nomo(a) e trabalha como ' + jobTitle + ' ' + timeOfEmployment + '.\nAcrescentar informa\xE7\xF5es sobre o neg\xF3cio:\nTrabalha para/com:\nO que vende/faz:\nRefer\xEAncia comercial (site/ telefone/ m\xEDdias sociais):\nDescri\xE7\xE3o da renda comprovada no extrato ou imposto de renda.';
  }

  exports.default = selfEmployed;
});