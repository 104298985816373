define('middle-office/pods/components/calculator-operation/auto-refi/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var reads = Ember.computed.reads;
  var Component = Ember.Component;
  exports.default = Component.extend({
    calculator: null,
    showErrors: false,
    nextStatus: 'formalization',
    bkfApplication: reads('partnerApplication.bkfApplication'),

    _hasErrors: function _hasErrors(operation, bkfApplication) {
      return operation.get('validations.errors').length > 0 || bkfApplication.get('validations.errors').length > 0;
    },


    actions: {
      save: function () {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
          var operation, bkfApplication;
          return regeneratorRuntime.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  operation = this.get('calculator');
                  _context.next = 3;
                  return this.get('bkfApplication');

                case 3:
                  bkfApplication = _context.sent;

                  if (!(operation == null || this._hasErrors(operation, bkfApplication))) {
                    _context.next = 7;
                    break;
                  }

                  this.set('showErrors', true);
                  return _context.abrupt('return', null);

                case 7:
                  _context.next = 9;
                  return operation.save();

                case 9:
                  _context.next = 11;
                  return bkfApplication.save();

                case 11:
                  this.get('on-save')(this.get('nextStatus'));

                  return _context.abrupt('return', null);

                case 13:
                case 'end':
                  return _context.stop();
              }
            }
          }, _callee, this);
        }));

        function save() {
          return _ref.apply(this, arguments);
        }

        return save;
      }()
    }
  });
});