define('middle-office/pods/components/app-table/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['ui table app-table'],
    tagName: 'table',
    columnQtd: 6
  });
});