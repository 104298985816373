define('middle-office/pods/components/app-forms/application/home-refi/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var or = Ember.computed.or;
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['app-form', 'ui', 'form'],

    tagName: 'form',

    allPeople: computed('application.requester.fullName', 'application.additionalRequester.fullName', 'application.otherAdditionalRequester.fullName', 'application.requester.spouse.fullName', 'application.additionalRequester.spouse.fullName', 'application.otherAdditionalRequester.spouse.fullName', function () {
      var requester = this.get('application.requester');
      var additionalRequester = this.get('application.additionalRequester');
      var otherAdditionalRequester = this.get('application.otherAdditionalRequester');
      var requesterSpouse = this.get('application.requester.spouse');
      var additionalRequesterSpouse = this.get('application.additionalRequester.spouse');
      var otherAdditionalRequesterSpouse = this.get('application.otherAdditionalRequester.spouse');

      return [requester, additionalRequester, otherAdditionalRequester, requesterSpouse, additionalRequesterSpouse, otherAdditionalRequesterSpouse].filter(function (person) {
        return person && person.content;
      });
    }),

    hasPartnerApplications: or('application.bariguiApplication.content', 'application.bancoPanApplication.content', 'application.bancoPaulistaApplication.content', 'application.chbApplication.content', 'application.domusApplication.content', 'application.fapaApplication.content', 'application.chpApplication.content'),

    submit: function submit() {
      this.sendAction('on-submit');
    }
  });
});