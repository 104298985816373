define('middle-office/pods/auto-refi/application-form/service', ['exports', 'middle-office/services/application-form'], function (exports, _applicationForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var all = Ember.RSVP.all;
  var isNone = Ember.isNone;
  exports.default = _applicationForm.default.extend({
    modelDescription: 'loan auto-refi bkf application',

    saveModel: function saveModel() {
      return this.saveApplication.apply(this, arguments);
    },
    saveApplication: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(application) {
        var requester, guarantor, currentPartnerApplication;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return application.get('requester');

              case 2:
                requester = _context.sent;
                _context.next = 5;
                return application.get('guarantor');

              case 5:
                guarantor = _context.sent;
                _context.next = 8;
                return application.get('currentPartnerApplication');

              case 8:
                currentPartnerApplication = _context.sent;

                if (isNone(requester)) {
                  _context.next = 12;
                  break;
                }

                _context.next = 12;
                return this.savePerson(requester);

              case 12:
                if (isNone(guarantor)) {
                  _context.next = 15;
                  break;
                }

                _context.next = 15;
                return this.savePerson(guarantor);

              case 15:
                if (isNone(currentPartnerApplication)) {
                  _context.next = 18;
                  break;
                }

                _context.next = 18;
                return currentPartnerApplication.save();

              case 18:
                _context.next = 20;
                return application.save();

              case 20:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function saveApplication(_x) {
        return _ref.apply(this, arguments);
      }

      return saveApplication;
    }(),
    savePerson: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(person) {
        var _this = this;

        var residenceAddress, employments;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _context2.next = 2;
                return person.get('residenceAddress');

              case 2:
                residenceAddress = _context2.sent;
                _context2.next = 5;
                return person.get('employments');

              case 5:
                employments = _context2.sent;

                if (isNone(residenceAddress)) {
                  _context2.next = 9;
                  break;
                }

                _context2.next = 9;
                return this.saveAddress(residenceAddress);

              case 9:
                _context2.next = 11;
                return person.save();

              case 11:
                _context2.next = 13;
                return all(employments.map(function (employment) {
                  return _this.saveEmployment(employment);
                }));

              case 13:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function savePerson(_x2) {
        return _ref2.apply(this, arguments);
      }

      return savePerson;
    }(),
    saveEmployment: function () {
      var _ref3 = _asyncToGenerator(regeneratorRuntime.mark(function _callee3(employment) {
        var companyAddress;
        return regeneratorRuntime.wrap(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                _context3.next = 2;
                return employment.get('companyAddress');

              case 2:
                companyAddress = _context3.sent;

                if (isNone(companyAddress)) {
                  _context3.next = 6;
                  break;
                }

                _context3.next = 6;
                return this.saveAddress(companyAddress);

              case 6:
                return _context3.abrupt('return', employment.save());

              case 7:
              case 'end':
                return _context3.stop();
            }
          }
        }, _callee3, this);
      }));

      function saveEmployment(_x3) {
        return _ref3.apply(this, arguments);
      }

      return saveEmployment;
    }(),
    saveAddress: function () {
      var _ref4 = _asyncToGenerator(regeneratorRuntime.mark(function _callee4(address) {
        return regeneratorRuntime.wrap(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                _context4.next = 2;
                return address.save();

              case 2:
              case 'end':
                return _context4.stop();
            }
          }
        }, _callee4, this);
      }));

      function saveAddress(_x4) {
        return _ref4.apply(this, arguments);
      }

      return saveAddress;
    }()
  });
});