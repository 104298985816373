define('middle-office/pods/components/app-forms/credit/auto-refi/personal-data/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    _calculateAge: function _calculateAge(birthDate) {
      var today = _moment.default.utc();
      var utcBirthDate = _moment.default.utc(birthDate);
      var age = today.diff(utcBirthDate, 'years');

      return age < 0 ? 0 : age;
    },


    age: computed('creditReference.birthDate', function () {
      var birthDate = this.get('creditReference.birthDate');
      return this._calculateAge(birthDate);
    })
  });
});