define('middle-office/enums/credit/auto-refi/alienation', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.alienation = undefined;
  var alienation = exports.alienation = [{
    text: 'Sim',
    value: 'true'
  }, {
    text: 'Não',
    value: 'false'
  }];

  exports.default = alienation;
  var values = exports.values = _lodash.default.map(alienation, 'value');
});