define('middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/professional/businessman', ['exports', 'middle-office/enums/auto-refi/time-ranges', 'middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/helpers/humanized'], function (exports, _timeRanges, _humanized) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function businessman(employment) {
    var companyName = employment.companyName;
    var cnpj = employment.companyCnpj;
    var timeOfEmployment = (0, _timeRanges.textForTimeRange)(employment.timeOfEmployment);
    var address = (0, _humanized.humanizedAddress)(employment.companyAddress);

    return 'Possui a empresa ' + companyName + ', ' + address + ' ' + timeOfEmployment + '.\nCNPJ da empresa: ' + cnpj + '\n\nAcrescentar informa\xE7\xF5es sobre o neg\xF3cio:\nRamo da empresa:\nData de cria\xE7\xE3o do CNPJ:\nFaturamento da empresa:\nValor de retirada mensal como s\xF3cio (pro labore):';
  }

  exports.default = businessman;
});