define('middle-office/pods/components/auto-refi/portfolio-row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var reads = Ember.computed.reads;
  var computed = Ember.computed;


  var MESSAGES = {
    approval: 'Ficha voltou por pendências em crédito',
    submission: 'Ficha voltou por problemas no cadastro',
    fallback: 'Ficha voltou por problemas em outra etapa'
  };

  exports.default = Component.extend({
    tagName: 'tr',
    classNameBindings: ['_pendingChangeRequest:warning'],

    application: null,

    _pendingChangeRequest: reads('application.pendingChangeRequests.firstObject'),
    _changeRequestStatusFlow: reads('_pendingChangeRequest.requestedOnStatusFlow'),

    _changeRequestPopup: computed('_changeRequestStatusFlow', function () {
      if (this.get('_pendingChangeRequest') == null) {
        return null;
      }

      return this._messageForStatus(this.get('_changeRequestStatusFlow'));
    }),

    _popupClasses: computed('_changeRequestStatusFlow', function () {
      var statusFlow = this.get('_changeRequestStatusFlow');

      return 'warning-popup ' + statusFlow;
    }),

    _messageForStatus: function _messageForStatus(status) {
      if (MESSAGES[status] != null) {
        return { status: status, message: MESSAGES[status] };
      }

      return { status: 'fallback', message: MESSAGES.fallback };
    }
  });
});