define('middle-office/models/loan/home-refi/bkf/employment', ['exports', 'ember-cli-bkf-core/models/loan/home-refi/bkf/employment'], function (exports, _employment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var computed = Ember.computed;
  exports.default = _employment.default.extend({
    shouldHaveCompanyAddress: computed('professionalStatus', function () {
      var professionalStatus = this.get('professionalStatus');
      var professionalStatusForAddress = ['clt', 'freelancer', 'businessman', 'civil_servant', 'self_employed'];

      return professionalStatusForAddress.indexOf(professionalStatus) >= 0;
    }),

    handleCompanyAddress: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
        var store, companyAddress;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                store = this.get('store');
                _context.next = 3;
                return this.get('companyAddress');

              case 3:
                companyAddress = _context.sent;


                if (this.get('shouldHaveCompanyAddress') && companyAddress == null) {
                  this.set('companyAddress', store.createRecord('loan/home-refi/bkf/address'));
                }

              case 5:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function handleCompanyAddress() {
        return _ref.apply(this, arguments);
      }

      return handleCompanyAddress;
    }()
  });
});