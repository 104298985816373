define('middle-office/pods/components/app-timeline/application-submission/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var bool = Ember.computed.bool;
  exports.default = Component.extend({
    classNames: ['app-timeline-submission'],
    attributeBindings: ['data-date'],

    hasConsultant: bool('record.submittedBy.fullName')
  });
});