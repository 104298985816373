define('middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/divider', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  function divider() {
    return '====================================';
  }

  exports.default = divider;
});