define('middle-office/pods/components/app-card/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['ui', 'card'],
    classNameBindings: ['color'],
    layout: Ember.HTMLBars.template({
      "id": "4eJUEHRU",
      "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,1]],\"hasEval\":false}",
      "meta": {}
    })
  });
});