define('middle-office/pods/components/molicar-search-form/component', ['exports', 'lodash', 'middle-office/utils/build-enum', 'middle-office/config/environment', 'middle-office/utils/vehicle'], function (exports, _lodash, _buildEnum, _environment, _vehicle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var computed = Ember.computed;
  var Component = Ember.Component;


  var externalData = _environment.default.APP.externalData;

  exports.default = Component.extend({
    tagName: 'form',
    classNames: ['ui', 'form'],

    possibleModelYears: computed('possible-model-years', function () {
      var years = this.get('possible-model-years') || (0, _vehicle.possibleModelYears)();

      return (0, _buildEnum.default)({
        items: _lodash.default.invokeMap(years, 'toString'),
        includeBlank: true
      });
    }),

    _modelYear: null,
    _brand: null,
    _model: null,

    _brandsUrl: computed('_modelYear', function () {
      var year = encodeURIComponent(this.get('_modelYear'));

      return new URL('/external/molicar/brands/' + year, externalData).toString();
    }),

    _brandsPromise: computed('_modelYear', _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
      var response, brands;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return fetch(this.get('_brandsUrl'));

            case 2:
              response = _context.sent;
              _context.next = 5;
              return response.json();

            case 5:
              brands = _context.sent;
              return _context.abrupt('return', (0, _buildEnum.default)({
                items: brands.sort(),
                includeBlank: true
              }));

            case 7:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    }))),

    _vehiclesUrl: computed('_modelYear', '_brand', function () {
      var brand = encodeURIComponent(this.get('_brand'));
      var modelYear = encodeURIComponent(this.get('_modelYear'));

      return new URL('/external/molicar/models/' + brand + '/' + modelYear, externalData).toString();
    }),

    _vehiclesPromise: computed('_vehiclesUrl', _asyncToGenerator(regeneratorRuntime.mark(function _callee2() {
      var _this = this;

      var response, payload;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.next = 2;
              return fetch(this.get('_vehiclesUrl'));

            case 2:
              response = _context2.sent;
              _context2.next = 5;
              return response.json();

            case 5:
              payload = _context2.sent;
              return _context2.abrupt('return', payload.map(function (vehiclePayload) {
                var vehicle = _vehicle.default.fromExternalData(vehiclePayload);
                vehicle.modelYear = parseInt(_this.get('_modelYear'), 10);
                vehicle.brand = _this.get('_brand');

                return vehicle;
              }));

            case 7:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    }))),

    _modelOptionsPromise: computed('_vehiclesPromise', _asyncToGenerator(regeneratorRuntime.mark(function _callee3() {
      var vehicles, uniqueModels;
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              _context3.next = 2;
              return this.get('_vehiclesPromise');

            case 2:
              vehicles = _context3.sent;
              uniqueModels = (0, _lodash.default)(vehicles).map('model').uniq();
              return _context3.abrupt('return', (0, _buildEnum.default)({
                items: uniqueModels.sort().value(),
                includeBlank: true
              }));

            case 5:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    }))),

    _previousVehicle: null,
    _loadedVehicles: [],

    _selectedModelVersions: computed('_loadedVehicles', '_model', function () {
      var model = this.get('_model');
      var vehicles = this.get('_loadedVehicles') || [];

      return (0, _lodash.default)(vehicles).filter({ model: model });
    }),

    _versions: computed('_selectedModelVersions.[]', function () {
      var options = this.get('_selectedModelVersions');
      var uniqueFormatted = (0, _lodash.default)(options).map('fullModelVersion').uniq();

      return (0, _buildEnum.default)({
        items: uniqueFormatted.sort().value(),
        includeBlank: true
      });
    }),

    _notifyVehicleChange: function _notifyVehicleChange(vehicle) {
      if (vehicle === this.get('_previousVehicle')) {
        return;
      }

      this.set('_previousVehicle', vehicle);
      this.sendAction('on-selected', vehicle);
    },


    actions: {
      resetBrand: function resetBrand() {
        this.set('_brand', null);
        this.send('resetModel');
      },
      resetModel: function resetModel() {
        this.setProperties({
          _model: null,
          _selectedVehicle: null
        });

        this._notifyVehicleChange(null);
      },
      updateModel: function () {
        var _ref4 = _asyncToGenerator(regeneratorRuntime.mark(function _callee4(model) {
          return regeneratorRuntime.wrap(function _callee4$(_context4) {
            while (1) {
              switch (_context4.prev = _context4.next) {
                case 0:
                  if (!(model == null)) {
                    _context4.next = 2;
                    break;
                  }

                  return _context4.abrupt('return');

                case 2:

                  this._notifyVehicleChange(null);
                  this.set('_model', model);

                  _context4.t0 = this;
                  _context4.next = 7;
                  return this.get('_vehiclesPromise');

                case 7:
                  _context4.t1 = _context4.sent;

                  _context4.t0.set.call(_context4.t0, '_loadedVehicles', _context4.t1);

                case 9:
                case 'end':
                  return _context4.stop();
              }
            }
          }, _callee4, this);
        }));

        function updateModel(_x) {
          return _ref4.apply(this, arguments);
        }

        return updateModel;
      }(),
      updateVersion: function () {
        var _ref5 = _asyncToGenerator(regeneratorRuntime.mark(function _callee5(version) {
          var displayedVersions, selectedVehicle;
          return regeneratorRuntime.wrap(function _callee5$(_context5) {
            while (1) {
              switch (_context5.prev = _context5.next) {
                case 0:
                  this.set('_version', version);

                  displayedVersions = this.get('_selectedModelVersions');
                  selectedVehicle = (0, _lodash.default)(displayedVersions).find({ fullModelVersion: version });


                  this._notifyVehicleChange(selectedVehicle);

                case 4:
                case 'end':
                  return _context5.stop();
              }
            }
          }, _callee5, this);
        }));

        function updateVersion(_x2) {
          return _ref5.apply(this, arguments);
        }

        return updateVersion;
      }()
    }
  });
});