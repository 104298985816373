define('middle-office/pods/admin/auto-refi/branches/list/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var filter = Ember.computed.filter;
  var map = Ember.computed.map;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    selectedConsultantName: null,

    filteredPartners: filter('filteredBranchesPartner', function (partner) {
      return partner.branches.length > 0;
    }),

    filteredBranchesPartner: map('model.partners', function (partner) {
      var branches = partner.get('orderedBranches');

      return this.filterPartnerBranches({ branches: branches, partner: partner });
    }).property('model.partners', 'model.consultants', 'selectedConsultantName'),

    filterPartnerBranches: function filterPartnerBranches(_ref) {
      var branches = _ref.branches,
          partner = _ref.partner;

      var selectedConsultantName = this.get('selectedConsultantName');
      var filteredBranches = branches.filter(function (branch) {
        return branch.get('responsible.fullName') === selectedConsultantName;
      });

      return {
        name: partner.get('name'),
        branches: filteredBranches
      };
    },


    actions: {
      onSelectedConsultant: function onSelectedConsultant(name) {
        this.set('selectedConsultantName', name);
      }
    }
  });
});