define('middle-office/pods/components/app-tags/tags/special-flow', ['exports', 'middle-office/pods/components/app-tags/tags/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var equal = Ember.computed.equal;
  var reads = Ember.computed.reads;
  var computed = Ember.computed;
  exports.default = _base.default.extend({
    name: 'Fluxo-Especial',
    key: 'special-flow',

    currentPartnerApplication: reads('application.currentPartnerApplication'),
    calculator: reads('currentPartnerApplication.calculator'),
    requestedLoanValue: reads('calculator.loanAmountRequested'),
    paidLoanValue: reads('calculator.paidValue'),

    isSpecial: computed('requestedLoanValue', 'paidLoanValue', function () {
      var _getProperties = this.getProperties('requestedLoanValue', 'paidLoanValue'),
          requestedLoanValue = _getProperties.requestedLoanValue,
          paidLoanValue = _getProperties.paidLoanValue;

      return paidLoanValue < requestedLoanValue;
    }),
    available: equal('isSpecial', true)
  });
});