define('middle-office/utils/serasa/normalize', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var empty = {};

  var extractAttributes = function extractAttributes(json) {
    if (!json || !json.data || !json.data.attributes) {
      return empty;
    }

    return json.data.attributes;
  };

  var amountMapper = function amountMapper(attributes, property) {
    return attributes[property].amount || 0;
  };

  var totalValueMapper = function totalValueMapper(attributes, property) {
    return attributes[property].total_value || 0;
  };

  var sumPropertiesValues = function sumPropertiesValues(attributes, properties, mapper) {
    var attributeMapper = mapper.bind(null, attributes);

    var mappedValues = properties.map(attributeMapper);

    return mappedValues.reduce(function (currentValue, accumulated) {
      return currentValue + accumulated;
    });
  };

  exports.default = function (json) {
    var attributes = extractAttributes(json);

    if (_lodash.default.isEmpty(attributes)) {
      return attributes;
    }

    var bouncedChecks = sumPropertiesValues(attributes, ['cheques-sem-fundo'], amountMapper);

    var totalDebts = sumPropertiesValues(attributes, ['dividas-vencidas', 'pendencias-bancarias', 'pendencias-comerciais', 'pendencias-imobiliaria', 'pendencias-internas', 'protestos'], totalValueMapper);

    return {
      bouncedChecks: bouncedChecks,
      totalDebts: totalDebts
    };
  };
});