define('middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/helpers/humanized', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.humanizedAddress = humanizedAddress;
  exports.humanizedVehicle = humanizedVehicle;
  function humanizedAddress(address) {
    var streetAddress = address.streetAddress;
    var streetNumber = address.streetNumber;
    var streetComplement = address.streetComplement || 'sem complemento';
    var neighborhood = address.neighborhood;
    var city = address.city;
    var state = address.state;

    return streetAddress + ', ' + streetNumber + ', ' + streetComplement + ', ' + neighborhood + ', ' + city + ', ' + state;
  }

  function humanizedVehicle(application) {
    var vehicleBrand = application.vehicleBrand;
    var vehicleModel = application.vehicleModel;
    var vehicleManufacturingYear = application.vehicleManufacturingYear;
    var vehicleModelYear = application.vehicleModelYear;

    return vehicleBrand + ' ' + vehicleModel + ', ' + vehicleManufacturingYear + '/' + vehicleModelYear;
  }
});