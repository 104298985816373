define('middle-office/models/loan/auto-refi/bkf/application', ['exports', 'ember-data', 'lodash', 'ember-cp-validations', 'ember-cli-bkf-core/models/loan/auto-refi/bkf/application', 'middle-office/models/loan/auto-refi/bkf/pending-documents'], function (exports, _emberData, _lodash, _emberCpValidations, _application, _pendingDocuments) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.PENDING_DOCUMENTS = undefined;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var isNone = Ember.isNone;
  var reads = Ember.computed.reads;
  var filterBy = Ember.computed.filterBy;
  var alias = Ember.computed.alias;
  var computed = Ember.computed;
  var RSVP = Ember.RSVP;


  var applicationValidations = (0, _emberCpValidations.buildValidations)({
    requester: (0, _emberCpValidations.validator)('belongs-to'),
    garantor: (0, _emberCpValidations.validator)('belongs-to')
  });

  exports.PENDING_DOCUMENTS = _pendingDocuments.default;
  exports.default = _application.default.extend(applicationValidations, {
    evaluation: _emberData.default.belongsTo('engine/eligibility/auto-refi/evaluation'),
    lead: _emberData.default.belongsTo('crm/auto-refi/lead'),
    consultant: _emberData.default.belongsTo('crm/consultant'),
    assignments: _emberData.default.hasMany('loan/auto-refi/bkf/assignment'),
    penalizations: _emberData.default.hasMany('loan/auto-refi/bkf/penalization'),
    submissions: _emberData.default.hasMany('loan/auto-refi/bkf/application-submissions'),
    changeRequests: _emberData.default.hasMany('loan/auto-refi/bkf/application-change-request'),
    inspections: _emberData.default.hasMany('loan/auto-refi/bkf/inspection'),
    serasaConsultedAt: _emberData.default.attr('date'),

    requesterBinaryDocumentUrl: _emberData.default.attr('string'),
    guarantorBinaryDocumentUrl: _emberData.default.attr('string'),

    requesterGoogleSheetsUrl: _emberData.default.attr('string'),
    guarantorGoogleSheetsUrl: _emberData.default.attr('string'),

    dutUploadUrl: _emberData.default.attr('string'),
    appraisalUploadUrl: _emberData.default.attr('string'),
    otherVehicleDocumentsUploadUrl: _emberData.default.attr('string'),
    loanSimulationUploadUrl: _emberData.default.attr('string'),
    registrationFormUploadUrl: _emberData.default.attr('string'),
    cetUploadUrl: _emberData.default.attr('string'),
    ccbUploadUrl: _emberData.default.attr('string'),
    dischargeAuthorizationUploadUrl: _emberData.default.attr('string'),
    vehicleInqueriesUploadUrl: _emberData.default.attr('string'),
    otherFormalizationDocumentsUploadUrl: _emberData.default.attr('string'),
    iq: _emberData.default.attr('boolean'),
    isIQ: alias('iq'),

    scheduling: _emberData.default.attr('date'),
    scheduledPartner: _emberData.default.belongsTo('underwriting/partner'),

    discard: _emberData.default.belongsTo('loan/auto-refi/bkf/discard'),

    evaluatedPartnerApplications: computed('evaluation.presentPartnerApplications.[]', function () {
      return this.get('evaluation.presentPartnerApplications') || [];
    }),

    sentBranch: reads('currentPartnerApplication.sentBranch'),

    currentPartnerApplication: computed('partnerName', 'evaluatedPartnerApplications', function () {
      return this.get('evaluatedPartnerApplications').findBy('name', this.get('partnerName'));
    }),

    pendingChangeRequests: filterBy('changeRequests', 'completed', false),

    hasChangeRequestsFor: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(partner, statusFlow) {
        var allRequests, completedOnStatus;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return this.get('changeRequests');

              case 2:
                allRequests = _context.sent;
                completedOnStatus = allRequests.filterBy('requestedOnStatusFlow', statusFlow).toArray();
                return _context.abrupt('return', anyChangeRequestMatchesPartner(completedOnStatus, partner));

              case 5:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function hasChangeRequestsFor(_x, _x2) {
        return _ref.apply(this, arguments);
      }

      return hasChangeRequestsFor;
    }(),


    vehicleHasNoDebts: computed('vehicleDebt', function () {
      var vehicleDebt = this.get('vehicleDebt');
      return vehicleDebt == null || vehicleDebt <= 0;
    }).readOnly(),

    setPendingDocuments: function setPendingDocuments() {
      this.set('pendingDocuments', _pendingDocuments.default);
      return null;
    },
    updateDownPayment: function updateDownPayment() {
      var purchaseValue = this.get('purchaseValue') || 0;
      var loanAmount = this.get('loanAmount') || 0;

      this.set('downPayment', purchaseValue - loanAmount);
    },
    resetPendingDocuments: function resetPendingDocuments() {
      this.set('pendingDocuments', null);
      return null;
    },
    _acceptVisitorForModel: function _acceptVisitorForModel(relatedModel, visitor) {
      if (!isNone(relatedModel)) {
        relatedModel.acceptVisitor(visitor);
      }
    },
    acceptVisitor: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(visitor) {
        var requester;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                visitor.visitApplication(this);
                _context2.next = 3;
                return this.get('requester');

              case 3:
                requester = _context2.sent;

                this._acceptVisitorForModel(requester, visitor);

              case 5:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function acceptVisitor(_x3) {
        return _ref2.apply(this, arguments);
      }

      return acceptVisitor;
    }(),
    timelineEvents: function () {
      var _ref3 = _asyncToGenerator(regeneratorRuntime.mark(function _callee3() {
        var allSources;
        return regeneratorRuntime.wrap(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                _context3.next = 2;
                return RSVP.all([this._leadTimelineEvents(), this._timelineEventsFromRelationship('assignments'), this._timelineEventsFromRelationship('penalizations'), this._timelineEventsFromRelationship('submissions')]);

              case 2:
                allSources = _context3.sent;
                return _context3.abrupt('return', _lodash.default.flatten(allSources));

              case 4:
              case 'end':
                return _context3.stop();
            }
          }
        }, _callee3, this);
      }));

      function timelineEvents() {
        return _ref3.apply(this, arguments);
      }

      return timelineEvents;
    }(),
    _leadTimelineEvents: function () {
      var _ref4 = _asyncToGenerator(regeneratorRuntime.mark(function _callee4() {
        var lead, leadEvent, leadRecords, recordEvents;
        return regeneratorRuntime.wrap(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                _context4.next = 2;
                return this.get('lead');

              case 2:
                lead = _context4.sent;

                if (!(lead == null)) {
                  _context4.next = 5;
                  break;
                }

                return _context4.abrupt('return', []);

              case 5:
                _context4.next = 7;
                return lead.toTimelineEvent();

              case 7:
                leadEvent = _context4.sent;
                _context4.next = 10;
                return lead.hasMany('records').reload();

              case 10:
                leadRecords = _context4.sent;
                _context4.next = 13;
                return RSVP.map(leadRecords.toArray(), function (record) {
                  return record.toTimelineEvent();
                });

              case 13:
                recordEvents = _context4.sent;
                return _context4.abrupt('return', [leadEvent].concat(_toConsumableArray(recordEvents)));

              case 15:
              case 'end':
                return _context4.stop();
            }
          }
        }, _callee4, this);
      }));

      function _leadTimelineEvents() {
        return _ref4.apply(this, arguments);
      }

      return _leadTimelineEvents;
    }(),
    _timelineEventsFromRelationship: function () {
      var _ref5 = _asyncToGenerator(regeneratorRuntime.mark(function _callee5(name) {
        var relationshipRecords;
        return regeneratorRuntime.wrap(function _callee5$(_context5) {
          while (1) {
            switch (_context5.prev = _context5.next) {
              case 0:
                _context5.next = 2;
                return this.hasMany(name).reload();

              case 2:
                relationshipRecords = _context5.sent;
                return _context5.abrupt('return', RSVP.map(relationshipRecords.toArray(), function (record) {
                  return record.toTimelineEvent();
                }));

              case 4:
              case 'end':
                return _context5.stop();
            }
          }
        }, _callee5, this);
      }));

      function _timelineEventsFromRelationship(_x4) {
        return _ref5.apply(this, arguments);
      }

      return _timelineEventsFromRelationship;
    }()
  });

  var anyChangeRequestMatchesPartner = function () {
    var _ref6 = _asyncToGenerator(regeneratorRuntime.mark(function _callee6(requests, partner) {
      var i, changeRequest, changeRequestPartner;
      return regeneratorRuntime.wrap(function _callee6$(_context6) {
        while (1) {
          switch (_context6.prev = _context6.next) {
            case 0:
              i = 0;

            case 1:
              if (!(i < requests.length)) {
                _context6.next = 11;
                break;
              }

              changeRequest = requests[i];
              _context6.next = 5;
              return changeRequest.get('partner');

            case 5:
              changeRequestPartner = _context6.sent;

              if (!(changeRequestPartner === partner)) {
                _context6.next = 8;
                break;
              }

              return _context6.abrupt('return', true);

            case 8:
              i += 1;
              _context6.next = 1;
              break;

            case 11:
              return _context6.abrupt('return', false);

            case 12:
            case 'end':
              return _context6.stop();
          }
        }
      }, _callee6, this);
    }));

    return function anyChangeRequestMatchesPartner(_x5, _x6) {
      return _ref6.apply(this, arguments);
    };
  }();
});