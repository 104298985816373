define('middle-office/router', ['exports', 'middle-office/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberRouter = Ember.Router;


  var Router = EmberRouter.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('login', { path: '/entrar' });

    this.route('auth', { path: '/auth' }, function () {
      this.route('request-reset-password', { path: '/recuperar' });
      this.route('new-password', { path: '/nova-senha' });
      this.route('reset-request-success', { path: '/reset-solicitado' });
    });

    this.route('lead', { path: '/lead' }, function () {
      this.route('auto-refi', { path: '/auto-refi' });
      this.route('home-refi', { path: '/home-refi' });
    });

    this.route('home-refi', { path: '/home-refi/:transaction-code' }, function () {
      this.route('eligibility-parameters', { path: '/parametros-de-elegibilidade' });
      this.route('application-form', { path: '/ficha' });
      this.route('documents', { path: '/documentos' });
    });

    this.route('auto-refi', { path: '/auto-refi/:application-id' }, function () {
      this.route('application-form', { path: '/ficha' });
      this.route('documents', { path: '/documentos' });
      this.route('partner-applications', { path: '/fichas-parceiros' });
      this.route('workflow', { path: '/lista-de-tarefas' });
      this.route('timeline', { path: '/linha-do-tempo' });
      this.route('credit', { path: '/analise-de-credito' });
      this.route('inspections', { path: '/vistorias' }, function () {
        this.route('unavailable', { path: '/indisponivel' });
        this.route('list', { path: '/listar' });
        this.route('new', { path: '/cadastrar' });
        this.route('edit', { path: '/editar/:id' });
        this.route('cancel', { path: '/cancelar/:id' });
      });
    });

    this.route('application-not-found', { path: '/ficha-nao-encontrada' });
    this.route('not-found', { path: '/*path' });

    this.route('main', function () {
      this.route('auto-refi', function () {
        this.route('leads', { path: '/clientes' });
        this.route('portfolio', { path: '/carteira' });
        this.route('partner-applications', { path: '/fichas-parceiros' });
        this.route('molicar', { path: '/busca-molicar' });
        this.route('partner-tasks', { path: '/listagem-de-tarefas' });
        this.route('submission-queue', { path: '/cadastro' });
      });

      this.route('auto-fin', function () {
        this.route('customer', { path: '/clientes' });
        this.route('edit-application', { path: '/:id/ficha' });
      });
    });

    this.route('admin', function () {
      this.route('users', { path: '/consultores' }, function () {
        this.route('edit', { path: '/editar/:user_id' });
        this.route('new', { path: '/novo' });
      });
      this.route('auto-refi', function () {
        this.route('branches', { path: '/lojas' }, function () {
          this.route('edit', { path: '/editar' });
          this.route('list', { path: '/listar' });
        });
      });
    });

    this.route('servicing', function () {
      this.route('loans', { path: '/emprestimos' });
      this.route('assignments', { path: '/cessao-de-credito' });
      this.route('auto-loan', { path: '/emprestimo-auto/:loan_id' }, function () {
        this.route('installments', { path: '/parcelas' });
      });
      this.route('home-loan', { path: '/emprestimo-home/:loan_id' }, function () {
        this.route('installments', { path: '/parcelas' });
      });
    });
  });

  exports.default = Router;
});