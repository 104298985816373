define('middle-office/pods/auto-refi/timeline/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hash = Ember.RSVP.hash;
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model() {
      var application = this.modelFor('auto-refi').application;

      var timeline = application.timelineEvents({ reload: true });
      var lead = application.get('lead');
      var partners = this.store.findAll('underwriting/partner');

      return hash({
        timeline: timeline,
        partners: partners,
        application: application,
        lead: lead
      });
    },
    resetController: function resetController(controller) {
      controller.set('feedbackMessage', null);
    }
  });
});