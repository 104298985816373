define('middle-office/enums/auto-refi/rounded-up-time-ranges', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.textForTimeRange = exports.timeRanges = undefined;
  var timeRanges = exports.timeRanges = [{
    text: '6 meses',
    value: 'less_than_six_months'
  }, {
    text: '1 ano',
    value: 'between_six_months_and_one_year'
  }, {
    text: '2 anos',
    value: 'between_one_and_two_years'
  }, {
    text: '3 anos',
    value: 'between_two_and_three_years'
  }, {
    text: '4 anos',
    value: 'between_three_and_four_years'
  }, {
    text: '5 anos',
    value: 'between_four_and_five_years'
  }, {
    text: '10 anos',
    value: 'between_five_and_ten_years'
  }, {
    text: 'mais de 10 anos',
    value: 'more_than_ten_years'
  }];

  exports.default = timeRanges;
  var textForTimeRange = exports.textForTimeRange = function textForTimeRange(value) {
    return _lodash.default.find(timeRanges, { value: value }).text;
  };
  var values = exports.values = _lodash.default.map(timeRanges, 'value');
});