define('middle-office/pods/home-refi/eligibility-parameters/service', ['exports', 'middle-office/services/application-form'], function (exports, _applicationForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var REAL_ESTATE_DEBTS_ATTRIBUTES = ['realEstateDebtsTotalValue', 'realEstateDebtsCreditorBankNumber'];

  var REQUESTER_FINANCE_DEBTS_ATTRIBUTES = ['requesterFinanceDebtDescription', 'requesterFinanceDebtMonthlyCost', 'requesterFinanceDebtMonthsLeft'];

  exports.default = _applicationForm.default.extend({
    modelDescription: 'engine home-refi eligibility-parameters',

    saveModel: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(eligibilityParameters) {
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                this._cleanNotVisibleAttributes(eligibilityParameters);

                _context.next = 3;
                return eligibilityParameters.save();

              case 3:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function saveModel(_x) {
        return _ref.apply(this, arguments);
      }

      return saveModel;
    }(),
    _cleanNotVisibleAttributes: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(eligibilityParameters) {
        var requesterHasFinanceDebts;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                if (!eligibilityParameters.get('realEstateHasDebts')) {
                  this._cleanAttributes(eligibilityParameters, REAL_ESTATE_DEBTS_ATTRIBUTES);
                }

                _context2.next = 3;
                return eligibilityParameters.get('requester.hasFinanceDebts');

              case 3:
                requesterHasFinanceDebts = _context2.sent;

                if (!requesterHasFinanceDebts) {
                  this._cleanAttributes(eligibilityParameters, REQUESTER_FINANCE_DEBTS_ATTRIBUTES);
                }

              case 5:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function _cleanNotVisibleAttributes(_x2) {
        return _ref2.apply(this, arguments);
      }

      return _cleanNotVisibleAttributes;
    }(),
    _cleanAttributes: function _cleanAttributes(eligibilityParameters, attributes) {
      attributes.forEach(function (attribute) {
        eligibilityParameters.set(attribute, null);
      });
    }
  });
});