define('middle-office/pods/components/app-search/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;


  var errorNoResults = 'Nenhum resultado foi encontrado';

  exports.default = Component.extend({
    classNames: ['app-search'],

    minCharacters: 1,

    apiSettings: computed(function () {
      var _this = this;

      return {
        responseAsync: function responseAsync() {
          for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments[_key];
          }

          return _this.sendAction('onSearch', args);
        }
      };
    }),

    _createHtml: function _createHtml(message, type) {
      var html = '<div class="message ' + type + '">';

      var descriptionContent = message;
      if (type === 'empty') {
        html += '<div class="header">Ooops...</div>';

        // The error property isn't being replaced by Semantic-UI
        // There is a branch with the fix https://github.com/Semantic-Org/Semantic-UI-Ember/pull/94
        // Untill the fix get merged the message shall be written by hand here
        // html += `<div class="description">${message}</div>`;
        descriptionContent = errorNoResults;
      }

      html += '<div class="description">' + descriptionContent + '</div>';
      html += '</div>';

      return html;
    },


    templates: {
      message: function message(_message, type) {
        if (!(_message && type)) {
          return '';
        }
        return this._createHtml(_message, type);
      }
    },

    error: {
      noResults: errorNoResults,
      serverError: 'Erro ao buscar resultados no servidor'
    }
  });
});