define('middle-office/models/servicing/onboarding/home-loan', ['exports', 'ember-data', 'middle-office/models/servicing/onboarding/loan'], function (exports, _emberData, _loan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _loan.default.extend({
    referenceIndexType: attr('string')
  });
});