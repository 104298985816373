define('middle-office/models/loan/auto-refi/bkf/penalization', ['exports', 'ember-data/model', 'ember-data/attr', 'moment'], function (exports, _model, _attr, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var computed = Ember.computed;
  var reads = Ember.computed.reads;


  var TimelineEvent = EmberObject.extend({
    type: 'application-penalization',

    createdAt: computed('_model.createdAt', function () {
      return (0, _moment.default)(this.get('_model.createdAt')).add(1, 'second').toDate();
    }),

    model: reads('_model'),

    _model: null
  });

  exports.default = _model.default.extend({
    createdAt: (0, _attr.default)('date'),
    durationInSeconds: (0, _attr.default)('number'),

    toTimelineEvent: function toTimelineEvent() {
      return TimelineEvent.create({ _model: this });
    }
  });
});