define('middle-office/pods/components/app-tags/component', ['exports', 'middle-office/pods/components/app-tags/tags'], function (exports, _tags) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var filterBy = Ember.computed.filterBy;
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['app-tags', 'inline', 'nowrap'],
    application: null,
    tags: computed(function () {
      return [];
    }),
    availableTags: filterBy('tags', 'available', true),

    init: function init() {
      this._super.apply(this, arguments);

      var application = this.get('application');
      var tags = _tags.default.map(function (tag) {
        return tag.create({ application: application });
      });

      this.set('tags', tags);
    }
  });
});