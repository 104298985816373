define('middle-office/pods/auto-refi/credit/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Controller = Ember.Controller;
  var service = Ember.inject.service;
  var observer = Ember.observer;
  var isNone = Ember.isNone;
  var equal = Ember.computed.equal;
  var RSVP = Ember.RSVP;
  exports.default = Controller.extend({
    rollbar: service(),
    creditReferencePersist: service('auto-refi/credit/credit-reference-persist'),
    changeRequests: service('auto-refi/application-change-requests'),

    isSaving: false,
    isSendingToCommittee: false,
    isFeedbackWarning: false,
    feedbackMessage: null,
    isFidcApproval: equal('model.fidcApplication.statusFlow', 'approval'),
    isReanalysis: equal('model.fidcApplication.creditApprovalSituation', 'reanalysis'),

    warningMessageChange: observer('model.showWarningMessage', function () {
      if (this.get('model.showWarningMessage')) {
        this._setWarningMessage('Esta análise já está em andamento');
      } else {
        this._clearWarningMessage();
      }
    }),

    _setWarningMessage: function _setWarningMessage(feedbackMessage) {
      this.setProperties({
        feedbackMessage: feedbackMessage,
        isFeedbackWarning: true
      });
    },
    _setErrorMessage: function _setErrorMessage(feedbackMessage) {
      this.setProperties({
        feedbackMessage: feedbackMessage,
        isFeedbackWarning: false
      });
    },
    _clearWarningMessage: function _clearWarningMessage() {
      this.setProperties({
        feedbackMessage: null,
        isFeedbackWarning: false
      });
    },
    _saveCreditReference: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
        var model, creditReferencePersist, residenceAddress, companyAddress;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                model = this.get('model');
                creditReferencePersist = this.get('creditReferencePersist');
                _context.prev = 2;
                _context.next = 5;
                return model.creditResidenceAddress.save();

              case 5:
                residenceAddress = _context.sent;
                _context.next = 8;
                return model.creditProfessionalAddress.save();

              case 8:
                companyAddress = _context.sent;
                _context.next = 11;
                return creditReferencePersist.saveCreditReference({
                  creditReference: model.creditReference,
                  fidcApplication: model.fidcApplication,
                  residenceAddress: residenceAddress,
                  companyAddress: companyAddress
                });

              case 11:
                _context.next = 17;
                break;

              case 13:
                _context.prev = 13;
                _context.t0 = _context['catch'](2);

                this._setErrorMessage('Erro ao salvar :(');
                this.get('rollbar').error(new Error('Failure saving credit models'), {
                  errorEvent: _context.t0
                });

              case 17:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this, [[2, 13]]);
      }));

      function _saveCreditReference() {
        return _ref.apply(this, arguments);
      }

      return _saveCreditReference;
    }(),
    _saveCalculator: function _saveCalculator(calculator) {
      var fidcApplication = this.get('model.fidcApplication');
      calculator.set('fidcApplication', fidcApplication);
      return calculator.save();
    },
    _changeWorkFlow: function _changeWorkFlow(partnerApplicationChanges) {
      var partnerApplication = this.get('model.fidcApplication');

      partnerApplication.setProperties(partnerApplicationChanges);
      return partnerApplication.save();
    },
    _saveCreditModels: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(workflowChanging, optionalCalculator) {
        var application;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                application = this.get('model.application');
                _context2.prev = 1;
                _context2.next = 4;
                return this._saveCreditReference();

              case 4:
                _context2.next = 6;
                return application.save();

              case 6:
                if (isNone(optionalCalculator)) {
                  _context2.next = 9;
                  break;
                }

                _context2.next = 9;
                return this._saveCalculator(optionalCalculator);

              case 9:
                _context2.next = 11;
                return this._changeWorkFlow(workflowChanging);

              case 11:
                return _context2.abrupt('return', this._transitionToPartnerApplications());

              case 14:
                _context2.prev = 14;
                _context2.t0 = _context2['catch'](1);
                throw new Error('Unable to save CreditReference or related models');

              case 17:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this, [[1, 14]]);
      }));

      function _saveCreditModels(_x, _x2) {
        return _ref2.apply(this, arguments);
      }

      return _saveCreditModels;
    }(),
    _hasValidationErrors: function _hasValidationErrors() {
      var calculator = this.get('model.calculator');
      var creditReference = this.get('model.creditReference');
      var fidcApplication = this.get('model.fidcApplication');
      var application = this.get('model.application');

      return this._hasErrorsInModels(calculator, creditReference, fidcApplication, application);
    },
    _hasErrorsInModels: function _hasErrorsInModels() {
      for (var _len = arguments.length, models = Array(_len), _key = 0; _key < _len; _key++) {
        models[_key] = arguments[_key];
      }

      var hasErrors = models.some(function (m) {
        if (m.get('validations.errors').length > 0) {
          return true;
        }

        return false;
      });

      if (hasErrors) {
        this._setErrorMessage('Erro ao salvar :(');
        this.set('showErrors', true);
      }

      return hasErrors;
    },
    _saveProposalAndCalculator: function _saveProposalAndCalculator(calculator) {
      this._setResidenceStateForCalculator(calculator);

      if (this._hasValidationErrors()) {
        return RSVP.reject();
      }

      return this._saveCreditModels({ statusFlow: 'formalization', creditApprovalSituation: null }, calculator);
    },
    _setResidenceStateForCalculator: function _setResidenceStateForCalculator(calculator) {
      var residenceAddress = this.get('model.creditResidenceAddress');
      calculator.setDetranState(residenceAddress.get('state'));
    },
    _transitionToPartnerApplications: function _transitionToPartnerApplications() {
      var application = this.get('model.application');

      return this.transitionToRoute('auto-refi.partner-applications', application.get('id'), {
        queryParams: {
          partner: 'FIDC'
        }
      });
    },
    _sendToCommittee: function () {
      var _ref3 = _asyncToGenerator(regeneratorRuntime.mark(function _callee3() {
        var creditReference;
        return regeneratorRuntime.wrap(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                creditReference = this.get('model.creditReference');

                if (!this._hasErrorsInModels(creditReference)) {
                  _context3.next = 3;
                  break;
                }

                return _context3.abrupt('return', RSVP.reject());

              case 3:

                creditReference.set('committee', true);

                _context3.next = 6;
                return this._saveCreditReference();

              case 6:
                return _context3.abrupt('return', this._transitionToPartnerApplications());

              case 7:
              case 'end':
                return _context3.stop();
            }
          }
        }, _callee3, this);
      }));

      function _sendToCommittee() {
        return _ref3.apply(this, arguments);
      }

      return _sendToCommittee;
    }(),
    _rejectCredit: function () {
      var _ref4 = _asyncToGenerator(regeneratorRuntime.mark(function _callee4(changeRequested) {
        return regeneratorRuntime.wrap(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                _context4.next = 2;
                return this._saveCreditModels({
                  creditApprovalSituation: 'rejected'
                });

              case 2:
                if (changeRequested) {
                  _context4.next = 4;
                  break;
                }

                return _context4.abrupt('return');

              case 4:
                _context4.next = 6;
                return this.get('changeRequests').createChangeRequest(this.get('model.fidcApplication'));

              case 6:
              case 'end':
                return _context4.stop();
            }
          }
        }, _callee4, this);
      }));

      function _rejectCredit(_x3) {
        return _ref4.apply(this, arguments);
      }

      return _rejectCredit;
    }(),


    actions: {
      sendToCommittee: function () {
        var _ref5 = _asyncToGenerator(regeneratorRuntime.mark(function _callee5() {
          return regeneratorRuntime.wrap(function _callee5$(_context5) {
            while (1) {
              switch (_context5.prev = _context5.next) {
                case 0:
                  _context5.prev = 0;

                  this.toggleProperty('isSendingToCommittee');
                  _context5.next = 4;
                  return this._sendToCommittee();

                case 4:
                  return _context5.abrupt('return', _context5.sent);

                case 5:
                  _context5.prev = 5;

                  this.toggleProperty('isSendingToCommittee');
                  return _context5.finish(5);

                case 8:
                case 'end':
                  return _context5.stop();
              }
            }
          }, _callee5, this, [[0,, 5, 8]]);
        }));

        function sendToCommittee() {
          return _ref5.apply(this, arguments);
        }

        return sendToCommittee;
      }(),
      saveProposal: function () {
        var _ref6 = _asyncToGenerator(regeneratorRuntime.mark(function _callee6() {
          var calculator;
          return regeneratorRuntime.wrap(function _callee6$(_context6) {
            while (1) {
              switch (_context6.prev = _context6.next) {
                case 0:
                  calculator = this.get('model.calculator');
                  _context6.prev = 1;

                  this.toggleProperty('isSaving');
                  _context6.next = 5;
                  return this._saveProposalAndCalculator(calculator);

                case 5:
                  return _context6.abrupt('return', _context6.sent);

                case 8:
                  _context6.prev = 8;
                  _context6.t0 = _context6['catch'](1);

                  this._setErrorMessage('Erro ao salvar :(');
                  this.get('rollbar').error(new Error('Failure while saving credit analysis'), {
                    errorEvent: _context6.t0
                  });

                  return _context6.abrupt('return', RSVP.reject());

                case 13:
                  _context6.prev = 13;

                  this.toggleProperty('isSaving');
                  return _context6.finish(13);

                case 16:
                case 'end':
                  return _context6.stop();
              }
            }
          }, _callee6, this, [[1, 8, 13, 16]]);
        }));

        function saveProposal() {
          return _ref6.apply(this, arguments);
        }

        return saveProposal;
      }(),
      rejectProposal: function () {
        var _ref8 = _asyncToGenerator(regeneratorRuntime.mark(function _callee7(_ref7) {
          var changeRequested = _ref7.changeRequested;
          var creditReference;
          return regeneratorRuntime.wrap(function _callee7$(_context7) {
            while (1) {
              switch (_context7.prev = _context7.next) {
                case 0:
                  creditReference = this.get('model.creditReference');
                  _context7.prev = 1;

                  this.toggleProperty('isSaving');

                  if (!this._hasErrorsInModels(creditReference)) {
                    _context7.next = 5;
                    break;
                  }

                  return _context7.abrupt('return', RSVP.reject());

                case 5:
                  _context7.next = 7;
                  return this._rejectCredit(changeRequested);

                case 7:
                  return _context7.abrupt('return', _context7.sent);

                case 10:
                  _context7.prev = 10;
                  _context7.t0 = _context7['catch'](1);

                  this._setErrorMessage('Erro ao rejeitar o crédito :(');
                  this.get('rollbar').error(new Error('Failure on reject credit analysis'), {
                    errorEvent: _context7.t0
                  });

                  return _context7.abrupt('return', RSVP.reject());

                case 15:
                  _context7.prev = 15;

                  this.toggleProperty('isSaving');
                  return _context7.finish(15);

                case 18:
                case 'end':
                  return _context7.stop();
              }
            }
          }, _callee7, this, [[1, 10, 15, 18]]);
        }));

        function rejectProposal(_x4) {
          return _ref8.apply(this, arguments);
        }

        return rejectProposal;
      }()
    }
  });
});