define('middle-office/models/bacen-authorizations/santana/credit-check', ['exports', 'ember-cli-bkf-core/models/bacen-authorizations/santana/credit-check'], function (exports, _creditCheck) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _creditCheck.default;
    }
  });
});