define('middle-office/enums/loan/auto-refi/bkf/discard-reasons', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.writableValues = exports.reasons = undefined;
  var reasons = exports.reasons = [{
    text: 'Dados inválidos',
    value: 'invalid_data'
  }, {
    text: 'Contato sem sucesso',
    value: 'contact_without_success'
  }, {
    text: 'Descartado no CRM',
    value: 'discarded_in_legacy_system',
    readOnly: true
  }, {
    text: 'Cliente não possui carro',
    value: 'customer_doesnt_own_a_car'
  }, {
    text: 'Sem interesse - veículo vendido',
    value: 'uninterest|vehicle_sold'
  }, {
    text: 'Sem interesse - só simulando/pesquisando',
    value: 'uninterest|customer_only_exploring'
  }, {
    text: 'Sem interesse - já fechou com outro serviço',
    value: 'uninterest|closed_with_competitor'
  }, {
    text: 'Sem interesse - quer outro produto (Empréstimo pessoal, etc)',
    value: 'uninterest|interest_in_another_product'
  }, {
    text: 'Sem interesse - taxa/Prestação alta',
    value: 'uninterest|high_rates'
  }, {
    text: 'Sem interesse - cliente não deseja fornecer informações',
    value: 'uninterest|did_not_want_to_pass_information'
  }, {
    text: 'Fora da política - carro em nome de terceiros',
    value: 'out_of_policy|vehicle_registered_to_third_part'
  }, {
    text: 'Fora da política - ano/modelo do veículo não aceito',
    value: 'out_of_policy|vehicle_year'
  }, {
    text: 'Fora da política - categoria do veículo não aceita',
    value: 'out_of_policy|vehicle_model'
  }, {
    text: 'Fora da política - dívida do veículo',
    value: 'out_of_policy|vehicle_debt'
  }, {
    text: 'Fora da política - Serasa rejeitado',
    value: 'out_of_policy|serasa_report'
  }, {
    text: 'Fora da política - score insuficiente',
    value: 'out_of_policy|score'
  }, {
    text: 'Fora da política - cliente não comprova renda',
    value: 'out_of_policy|income_not_proven'
  }, {
    text: 'Fora da política - carro em nome de PJ',
    value: 'out_of_policy|vehicle_in_behalf_of_pj'
  }, {
    text: 'Fora da política - não existe parceiro elegível',
    value: 'out_of_policy|elegible_for_no_partner'
  }, {
    text: 'Excedida a quantidade máxima de contatos sem sucesso',
    value: 'max_unsuccessful_contact_tries',
    readOnly: true
  }];

  var writableValues = exports.writableValues = reasons.filter(function (item) {
    return !item.readOnly;
  });
  var values = exports.values = _lodash.default.map(reasons, 'value');

  exports.default = reasons;
});