define('middle-office/models/loan/home-refi/bkf/checklist/document-item', ['exports', 'ember-cli-bkf-core/models/loan/home-refi/bkf/checklist/document-item', 'ember-cli-bkf-core/enums/checklist-document'], function (exports, _documentItem, _checklistDocument) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _documentItem.default.extend({
    documentModelType: 'loan/home-refi/bkf/checklist/document',

    documentNamesEnum: _checklistDocument.default
  });
});