define('middle-office/pods/components/servicing/modal/mark-as-paid/component', ['exports', 'middle-office/pods/components/app-modal/abstract-modal', 'moment'], function (exports, _abstractModal, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  var or = Ember.computed.or;
  var readOnly = Ember.computed.readOnly;
  exports.default = _abstractModal.default.extend({
    classNames: ['mark-as-paid-modal__container'],

    installment: computed(function () {
      return {};
    }),

    combinedAmount: readOnly('installment.combinedAmount'),
    installmentNumber: readOnly('installment.number'),
    repayment: readOnly('installment.repayments.firstObject'),
    isSaving: or('repayment.isSaving', 'installment.isReloading'),
    newRepayment: computed(function () {
      var yesterday = (0, _moment.default)().subtract(1, 'day').toDate();

      var newRepayment = {
        nossoNumero: null,
        paidAt: yesterday,
        amount: this.get('combinedAmount')
      };

      return newRepayment;
    }),
    paidAt: alias('newRepayment.paidAt'),
    nossoNumero: alias('newRepayment.nossoNumero'),
    amount: alias('newRepayment.amount'),

    actions: {
      onApprove: function onApprove() {
        var newRepayment = this.get('newRepayment');
        var repayment = this.get('repayment');

        this.sendAction('onMarkAsPaid', _extends({
          repayment: repayment
        }, newRepayment));
      },
      onDeny: function onDeny() {
        this.sendAction('onDismissModal');
      }
    }
  });
});