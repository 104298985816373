define('middle-office/models/loan/auto-refi/bkf/chosen-simulation', ['exports', 'ember-cli-bkf-core/models/loan/auto-refi/bkf/chosen-simulation'], function (exports, _chosenSimulation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _chosenSimulation.default;
    }
  });
});