define('middle-office/pods/auto-refi/partner-applications/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var hash = Ember.RSVP.hash;
  var map = Ember.RSVP.map;
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model() {
      var application = this.modelFor('auto-refi').application;
      var requester = application.get('requester');
      var guarantor = application.get('guarantor');

      return hash({
        application: application,
        requester: requester,
        guarantor: guarantor
      });
    },
    afterModel: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(model) {
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                return _context.abrupt('return', map(model.application.get('presentPartnerApplications'), function (partnerApplication) {
                  return partnerApplication.reload();
                }));

              case 1:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function afterModel(_x) {
        return _ref.apply(this, arguments);
      }

      return afterModel;
    }()
  });
});