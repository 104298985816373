define('middle-office/pods/components/partner-applications/partials/statuses/formalization/component', ['exports', 'middle-office/mixins/close-loan'], function (exports, _closeLoan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var Component = Ember.Component;
  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  var reads = Ember.computed.reads;
  exports.default = Component.extend(_closeLoan.default, {
    reanalysisComment: alias('partnerApplication.reanalysisComment'),
    requestedLoanValue: reads('partnerApplication.calculator.loanAmountRequested'),
    paidLoanValue: reads('partnerApplication.calculator.paidValue'),

    hasCalculator: computed('partnerApplication', function () {
      var partnerApplication = this.get('partnerApplication');
      return _typeof(partnerApplication.calculator) === 'object';
    }),
    confirmationSettings: {
      header: 'Pagamento de proposta'
    },
    showConfirmationModal: false,

    actions: {
      toggleForm: function toggleForm(nextStep) {
        var _this = this;

        var filteredSteps = ['reanalysisForm', 'rejectionForm', 'closingForm'].filter(function (step) {
          return step !== nextStep;
        });

        this.toggleProperty(nextStep);
        filteredSteps.forEach(function (step) {
          return _this.set(step, false);
        });
      },
      sendToApproval: function sendToApproval() {
        this.sendAction('on-save', 'approval', 'reanalysis');
      },
      rejectPartnerApplication: function rejectPartnerApplication() {
        this.sendAction('on-save', 'no_closed');
      },
      onConfirmModal: function onConfirmModal() {
        this.set('showConfirmationModal', false);
        this.send('closeLoan');
      }
    }
  });
});