define('middle-office/pods/components/partner-applications/partials/statuses/approval/component', ['exports', 'ember-cli-bkf-core/utils/translate-from-enum'], function (exports, _translateFromEnum) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var reads = Ember.computed.reads;
  var Component = Ember.Component;
  exports.default = Component.extend({
    creditApprovalRejected: reads('partnerApplication.creditApprovalRejected'),

    creditApprovalSituation: (0, _translateFromEnum.default)('underwriting/auto-refi/credit-approval-situation', 'partnerApplication.creditApprovalSituation'),

    actions: {
      onSave: function onSave(statusFlow, subStatus) {
        if (statusFlow === 'formalization') {
          this.set('partnerApplication.rejectionReason', null);
          this.set('partnerApplication.rejectionDescription', null);
        }
        this.sendAction('on-save', statusFlow, subStatus);
      }
    }
  });
});