define('middle-office/pods/components/app-forms/eligibility-parameters/home-refi/partials/action-bar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var computed = Ember.computed;
  var debounce = Ember.run.debounce;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['action-bar', 'ui', 'grid', 'container', 'centered', 'middle', 'aligned'],

    eligibilityParametersService: service('home-refi/eligibility-parameters'),

    feedbackMessage: null,

    setFeedbackMessage: function setFeedbackMessage(message) {
      this.set('feedbackMessage', message);
      debounce(this, '_resetFeedbackMessage', 3500);
    },
    _resetFeedbackMessage: function _resetFeedbackMessage() {
      this.set('feedbackMessage', null);
    },


    feedbackStatusClass: computed('eligibilityParametersService.status', function () {
      var eligibilityParametersServiceStatus = this.get('eligibilityParametersService.status');
      if (eligibilityParametersServiceStatus === 'saved') {
        return 'success';
      } else if (eligibilityParametersServiceStatus === 'error') {
        return 'error negative';
      }

      return null;
    }),

    saveLoadingClass: computed('eligibilityParametersService.isSaving', function () {
      if (this.get('eligibilityParametersService.isSaving')) {
        return 'loading';
      }

      return null;
    }),

    _save: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
        var eligibilityParametersService;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return this.get('on-save')();

              case 2:
                eligibilityParametersService = this.get('eligibilityParametersService');


                if (eligibilityParametersService.get('isSaved')) {
                  this.setFeedbackMessage('Salvo com sucesso!');
                }

                if (eligibilityParametersService.get('isError')) {
                  this.setFeedbackMessage('Falha ao salvar o formulário :(');
                }

              case 5:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function _save() {
        return _ref.apply(this, arguments);
      }

      return _save;
    }(),


    actions: {
      save: function () {
        var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2() {
          return regeneratorRuntime.wrap(function _callee2$(_context2) {
            while (1) {
              switch (_context2.prev = _context2.next) {
                case 0:
                  this._save();

                case 1:
                case 'end':
                  return _context2.stop();
              }
            }
          }, _callee2, this);
        }));

        function save() {
          return _ref2.apply(this, arguments);
        }

        return save;
      }()
    }
  });
});