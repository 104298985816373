define('middle-office/pods/admin/users/new/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      return this.store.createRecord('crm/consultant');
    },
    deactivate: function deactivate() {
      this._super();

      var model = this.currentModel;

      if (model.get('isNew')) {
        model.deleteRecord();
      }
      this.controller.set('showErrors', false);
    }
  });
});