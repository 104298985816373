define('middle-office/enums/loan/auto-refi/bkf/inspection-period', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.location = undefined;
  var location = exports.location = [{
    text: 'Manhã',
    value: 'morning'
  }, {
    text: 'Tarde',
    value: 'afternoon'
  }, {
    text: 'Manhã/Tarde',
    value: 'all_day'
  }];

  exports.default = location;
  var values = exports.values = _lodash.default.map(location, 'value');
});