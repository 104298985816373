define('middle-office/models/loan/auto-refi/bkf/discard', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var discardValidations = (0, _emberCpValidations.buildValidations)({
    reason: (0, _emberCpValidations.validator)('presence', true)
  });

  exports.default = _emberData.default.Model.extend(discardValidations, {
    application: _emberData.default.belongsTo('loan/auto-refi/bkf/application'),
    reason: _emberData.default.attr('string')
  });
});