define('middle-office/pods/main/auto-fin/customer/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var map = Ember.RSVP.map;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    _searchAutoFinApplications: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(customersResult) {
        var _this = this;

        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                if (!(customersResult.customers.length === 0)) {
                  _context2.next = 2;
                  break;
                }

                return _context2.abrupt('return', [{
                  searchedCpf: customersResult.searchQuery,
                  autoFinancingApplications: [],
                  customer: null
                }]);

              case 2:
                return _context2.abrupt('return', map(customersResult.customers, function () {
                  var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee(customer) {
                    var applications;
                    return regeneratorRuntime.wrap(function _callee$(_context) {
                      while (1) {
                        switch (_context.prev = _context.next) {
                          case 0:
                            _context.next = 2;
                            return customer.get('autoFinancingApplications');

                          case 2:
                            applications = _context.sent;
                            return _context.abrupt('return', {
                              searchedCpf: customersResult.searchQuery,
                              autoFinancingApplications: applications,
                              customer: customer
                            });

                          case 4:
                          case 'end':
                            return _context.stop();
                        }
                      }
                    }, _callee, _this);
                  }));

                  return function (_x2) {
                    return _ref2.apply(this, arguments);
                  };
                }()));

              case 3:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function _searchAutoFinApplications(_x) {
        return _ref.apply(this, arguments);
      }

      return _searchAutoFinApplications;
    }(),


    actions: {
      searchTriggered: function searchTriggered(customersPromise) {
        var _this2 = this;

        var searchPromise = customersPromise.then(function (result) {
          return _this2._searchAutoFinApplications(result);
        });

        this.set('_searchPromise', searchPromise);
      }
    }
  });
});