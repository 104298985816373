define('middle-office/enums/loan/auto-refi/bkf/insurance-value', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.insuranceValue = undefined;
  var insuranceValue = exports.insuranceValue = [{
    text: '-',
    value: null
  }, {
    text: '600,00',
    value: 600
  }, {
    text: '750,00',
    value: 750
  }, {
    text: '900,00',
    value: 900
  }, {
    text: '1.210,00',
    value: 1210
  }, {
    text: '1.410,00',
    value: 1410
  }];

  exports.default = insuranceValue;
  var values = exports.values = _lodash.default.map(insuranceValue, 'value');
});