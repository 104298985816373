define('middle-office/pods/components/app-layout/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['app-layout'],

    didInsertElement: function didInsertElement() {
      this._onContentClick();
    },
    _onContentClick: function _onContentClick() {
      var _this = this;

      this.$('.app-content').click(function () {
        _this.set('toggle', false);
      });
    }
  });
});