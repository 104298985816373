define('middle-office/pods/servicing/loans/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Route = Ember.Route;
  var inject = Ember.inject.service;
  var all = Ember.RSVP.all;
  exports.default = Route.extend({
    polymorphicFind: inject('polymorphic-find'),

    model: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
        var polymorphicFind, loans, assignments, contracts, customers, installments;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                polymorphicFind = this.get('polymorphicFind');
                _context.next = 3;
                return polymorphicFind.findAll('servicing/onboarding/loan');

              case 3:
                loans = _context.sent;
                assignments = loans.getEach('assignments');
                contracts = loans.getEach('contract');
                customers = loans.getEach('customers');
                installments = loans.getEach('installments');
                _context.next = 10;
                return all([contracts, customers, assignments, installments]);

              case 10:
                return _context.abrupt('return', loans);

              case 11:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function model() {
        return _ref.apply(this, arguments);
      }

      return model;
    }()
  });
});