define('middle-office/pods/components/app-file-upload/drop-file-with-selection/file-list/component', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var all = Ember.RSVP.all;
  var computed = Ember.computed;
  var isEmpty = Ember.isEmpty;
  var service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['ui row file-list'],
    layout: Ember.HTMLBars.template({
      "id": "deZ6LUq/",
      "block": "{\"symbols\":[\"selectedFile\"],\"statements\":[[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui sixteen wide column grid\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ui divider\"],[7],[8],[0,\"\\n\\n      \"],[6,\"div\"],[9,\"class\",\"ui two cards\"],[7],[0,\"\\n\"],[4,\"each\",[[19,0,[\"selectedFiles\"]]],null,{\"statements\":[[0,\"          \"],[1,[25,\"file-card\",null,[[\"clearFile\",\"file\"],[[25,\"action\",[[19,0,[]],\"clearFile\"],null],[19,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[8],[0,\"\\n\\n      \"],[6,\"div\"],[9,\"class\",\"ui sixteen wide column\"],[7],[0,\"\\n\"],[4,\"if\",[[19,0,[\"uploadProgress\"]]],null,{\"statements\":[[4,\"ui-progress\",null,[[\"percent\",\"class\"],[[19,0,[\"uploadProgress\"]],\"green indicating tiny\"]],{\"statements\":[[0,\"            \"],[6,\"div\"],[9,\"class\",\"bar\"],[7],[0,\"\\n              \"],[6,\"div\"],[9,\"class\",\"progress\"],[7],[8],[0,\"\\n            \"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n        \"],[1,[25,\"app-button\",null,[[\"text\",\"classNames\",\"onClick\",\"isLoading\",\"isDisabled\"],[\"Enviar\",\"primary send-button\",[25,\"action\",[[19,0,[]],\"upload\"],null],[19,0,[\"isUploading\"]],[25,\"or\",[[19,0,[\"isUploading\"]],[19,0,[\"isUploaded\"]]],null]]]],false],[0,\"\\n\\n        \"],[1,[25,\"app-button\",null,[[\"text\",\"classNames\",\"onClick\",\"isDisabled\"],[\"Limpar\",\"grey clear-all-files-button\",[25,\"action\",[[19,0,[]],\"clearAllFiles\"],null],[19,0,[\"isUploading\"]]]]],false],[0,\"\\n      \"],[8],[0,\"\\n\\n\"],[4,\"if\",[[19,0,[\"feedbackMessage\"]]],null,{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"ui sixteen wide column\"],[7],[0,\"\\n          \"],[1,[25,\"app-message\",null,[[\"feedbackMessage\"],[[19,0,[\"feedbackMessage\"]]]]],false],[0,\"\\n        \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[8],[0,\"\\n  \"]],\"hasEval\":false}",
      "meta": {}
    }),

    rollbar: service(),
    fileUpload: service(),

    isUploading: computed('selectedFiles.@each.isLoading', function () {
      var selectedFiles = this.get('selectedFiles');
      var uploadingFiles = selectedFiles.filterBy('isLoading', true);

      return !isEmpty(uploadingFiles);
    }),
    isUploaded: computed('selectedFiles.@each.isDone', function () {
      var selectedFiles = this.get('selectedFiles');

      return selectedFiles.every(function (file) {
        return file.get('isDone') === true;
      });
    }),
    uploadProgress: computed('selectedFiles.@each.uploadProgress', function () {
      var selectedFiles = this.get('selectedFiles').toArray();
      var filesAmount = selectedFiles.length;
      var totalUploadProgess = _lodash.default.sumBy(selectedFiles, function (file) {
        return file.get('uploadProgress');
      });
      var avarageUploadProgress = totalUploadProgess / filesAmount;

      return avarageUploadProgress;
    }),
    selectedFiles: computed(function () {
      return [];
    }),
    successMessage: computed(function () {
      return {
        type: 'success',
        title: 'Yay!',
        text: 'Arquivo enviado com sucesso!'
      };
    }),
    errorMessage: computed(function () {
      return {
        type: 'error',
        title: 'Ooops...',
        text: 'Erro ao tentar enviar o arquivo...'
      };
    }),

    _handleUploadError: function _handleUploadError(event) {
      var error = new Error('Failure uploading assignments list file');

      this.set('feedbackMessage', this.get('errorMessage'));
      this.get('rollbar').error(error, { errorEvent: event });
    },
    _sendFiles: function _sendFiles(selectedFiles, fileUpload) {
      var promises = selectedFiles.map(function (file) {
        return fileUpload.send(file);
      });

      return all(promises);
    },


    actions: {
      clearFile: function clearFile(file) {
        this.sendAction('clearFile', file);
      },
      clearAllFiles: function clearAllFiles() {
        this.sendAction('clearAllFiles');
      },
      upload: function () {
        var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
          var selectedFiles, fileUpload;
          return regeneratorRuntime.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  selectedFiles = this.get('selectedFiles');
                  fileUpload = this.get('fileUpload');
                  _context.prev = 2;
                  _context.next = 5;
                  return this._sendFiles(selectedFiles, fileUpload);

                case 5:

                  this.set('feedbackMessage', this.get('successMessage'));
                  _context.next = 11;
                  break;

                case 8:
                  _context.prev = 8;
                  _context.t0 = _context['catch'](2);

                  this._handleUploadError(_context.t0);

                case 11:
                case 'end':
                  return _context.stop();
              }
            }
          }, _callee, this, [[2, 8]]);
        }));

        function upload() {
          return _ref2.apply(this, arguments);
        }

        return upload;
      }()
    }
  });
});