define('middle-office/models/underwriting/auto-refi/partner-application-mixin', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var computed = Ember.computed;
  var equal = Ember.computed.equal;
  var Mixin = Ember.Mixin;
  var all = Ember.RSVP.all;
  var resolve = Ember.RSVP.resolve;
  var PromiseObject = _emberData.default.PromiseObject;
  exports.default = Mixin.create({
    statusFlow: _emberData.default.attr('string'),
    rejectionReason: _emberData.default.attr('string'),
    rejectionDescription: _emberData.default.attr('string'),
    creditApprovalSituation: _emberData.default.attr('string'),
    reanalysisComment: _emberData.default.attr('string'),

    bkfApplication: _emberData.default.belongsTo('loan/auto-refi/bkf/application'),
    pipeline: _emberData.default.belongsTo('loan/auto-refi/workflow/pipeline'),
    closingLoan: _emberData.default.belongsTo('closing/auto-refi/loan'),

    creditApprovalRejected: equal('creditApprovalSituation', 'rejected'),
    creditApprovalReanalysis: equal('creditApprovalSituation', 'reanalysis'),
    initialCreditApprovalSituation: 'pending',

    changeStatus: function changeStatus(status, creditApprovalSituation) {
      if (status === 'approval') {
        return this._sendToApproval(creditApprovalSituation);
      }

      this.set('statusFlow', status);
      return resolve();
    },
    _sendToApproval: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(requestedApprovalSituation) {
        var creditApprovalSituation, currentStatusFlow;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                creditApprovalSituation = void 0;
                currentStatusFlow = this.get('statusFlow');

                if (!(currentStatusFlow === 'submission')) {
                  _context.next = 8;
                  break;
                }

                _context.next = 5;
                return this._initialCreditApprovalSituation();

              case 5:
                creditApprovalSituation = _context.sent;
                _context.next = 9;
                break;

              case 8:
                creditApprovalSituation = requestedApprovalSituation;

              case 9:

                this.setProperties({
                  creditApprovalSituation: creditApprovalSituation,
                  statusFlow: 'approval'
                });

              case 10:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function _sendToApproval(_x) {
        return _ref.apply(this, arguments);
      }

      return _sendToApproval;
    }(),
    _initialCreditApprovalSituation: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2() {
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _context2.next = 2;
                return this._hasChangeRequests('approval');

              case 2:
                if (!_context2.sent) {
                  _context2.next = 4;
                  break;
                }

                return _context2.abrupt('return', 'reanalysis');

              case 4:
                return _context2.abrupt('return', this.get('initialCreditApprovalSituation'));

              case 5:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function _initialCreditApprovalSituation() {
        return _ref2.apply(this, arguments);
      }

      return _initialCreditApprovalSituation;
    }(),
    _hasChangeRequests: function () {
      var _ref3 = _asyncToGenerator(regeneratorRuntime.mark(function _callee3(statusFlow) {
        var _ref4, _ref5, application, partner;

        return regeneratorRuntime.wrap(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                _context3.next = 2;
                return all([this.get('bkfApplication'), this.get('partner')]);

              case 2:
                _ref4 = _context3.sent;
                _ref5 = _slicedToArray(_ref4, 2);
                application = _ref5[0];
                partner = _ref5[1];
                return _context3.abrupt('return', application.hasChangeRequestsFor(partner, statusFlow));

              case 7:
              case 'end':
                return _context3.stop();
            }
          }
        }, _callee3, this);
      }));

      function _hasChangeRequests(_x2) {
        return _ref3.apply(this, arguments);
      }

      return _hasChangeRequests;
    }(),


    partner: computed('partnerName', function () {
      return PromiseObject.create({ promise: this._findPartner() });
    }),

    _findPartner: function () {
      var _ref6 = _asyncToGenerator(regeneratorRuntime.mark(function _callee4() {
        var nameAlias, allPartners, partner;
        return regeneratorRuntime.wrap(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                nameAlias = this.get('partnerName');
                _context4.next = 3;
                return this.store.findAll('underwriting/partner');

              case 3:
                allPartners = _context4.sent;
                partner = allPartners.findBy('nameAlias', nameAlias);

                if (!(partner == null)) {
                  _context4.next = 7;
                  break;
                }

                throw new Error('No partner found for name ' + nameAlias);

              case 7:
                return _context4.abrupt('return', partner);

              case 8:
              case 'end':
                return _context4.stop();
            }
          }
        }, _callee4, this);
      }));

      function _findPartner() {
        return _ref6.apply(this, arguments);
      }

      return _findPartner;
    }()
  });
});