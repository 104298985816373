define('middle-office/pods/components/app-errors/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['app-errors'],
    showErrors: true,

    errors: computed('model.validations.errors.[]', 'model.errors.[]', function () {
      // ember-cp-validations creates a object (validations) in the model
      // which are injected the errors found in the models
      var attributes = this.get('attribute').split(' ');
      var serverErrors = this.get('model.errors');
      var clientErrors = this.get('model.validations.errors') || [];
      var errors = [];

      attributes.forEach(function (attribute) {
        var _serverErrors = serverErrors ? serverErrors.errorsFor(attribute) : [];
        var _clientErrors = clientErrors.filter(function (error) {
          return error.attribute === attribute;
        });

        errors.push.apply(errors, _toConsumableArray(_serverErrors).concat(_toConsumableArray(_clientErrors)));
      });

      return errors;
    }),

    _onError: computed('errors.[]', function () {
      var errors = this.get('errors');

      if (errors.length) {
        this.sendAction('on-error');
      }
    })
  });
});