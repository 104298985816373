define('middle-office/pods/components/app-calendar/labels', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var days = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
  var months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  var monthsShort = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
  var today = 'Hoje';
  var now = 'Agora';

  exports.default = {
    days: days,
    months: months,
    monthsShort: monthsShort,
    today: today,
    now: now
  };
});