define('middle-office/helpers/image-type', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.imageType = imageType;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var helper = Ember.Helper.helper;


  var IMAGES = {
    docx: '/images/documents/word.png',
    xls: '/images/documents/excel.png',
    default: '/images/fake-document.png'
  };

  function imageType(_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
        url = _ref2[0];

    return url ? _getImageUrl(url) : IMAGES.default;
  }

  function _getImageUrl(url) {
    if (hasExtensions(url, ['jpg', 'jpeg', 'png'])) {
      return url;
    } else if (hasExtensions(url, ['xlsx', 'xltx', 'xls'])) {
      return IMAGES.xls;
    } else if (hasExtensions(url, ['odt', 'doc', 'docx'])) {
      return IMAGES.docx;
    }

    return IMAGES.default;
  }

  function hasExtensions(url, params) {
    var extensions = params.join('|');
    var exp = '.\\.(' + extensions + ')';
    var re = new RegExp(exp);

    return re.test(url);
  }

  exports.default = helper(imageType);
});