define('middle-office/enums/underwriting/auto-refi/rejection-reasons/approval', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.reasons = undefined;
  var reasons = exports.reasons = [{
    text: 'Cliente - Apontamento - Bacen Prejuízo',
    value: 'bacen_loss'
  }, {
    text: 'Cliente - Apontamento - Bacen Vencido',
    value: 'bacen_expired'
  }, {
    text: 'Cliente - Apontamento - Cheque sem Fundo',
    value: 'dishonored_check'
  }, {
    text: 'Cliente - Apontamento - Serasa PF',
    value: 'individual_restriction'
  }, {
    text: 'Cliente - Apontamento - Serasa PJ',
    value: 'legal_entity_issue'
  }, {
    text: 'Cliente - Apontamento - TJ',
    value: 'court'
  }, {
    text: 'Cliente - Capacidade - Comprometimento',
    value: 'income_commitment'
  }, {
    text: 'Cliente - Capacidade - Renda Insuficiente',
    value: 'income_restriction'
  }, {
    text: 'Cliente - Capacidade - Seguro Desemprego',
    value: 'unemployment_insurance'
  }, {
    text: 'Cliente - Capacidade - Tempo de Atividade',
    value: 'newly_employed'
  }, {
    text: 'Cliente - Perfil - Rating',
    value: 'score'
  }, {
    text: 'Cliente - Perfil - Finalidade do Empréstimo',
    value: 'loan_purpose'
  }, {
    text: 'Cliente - Perfil - Outros Fora da Política',
    value: 'others_out_of_policy'
  }, {
    text: 'Cliente - Perfil - Idade',
    value: 'age_restriction'
  }, {
    text: 'Informações - Complementares',
    value: 'divergent_information',
    changeRequest: true
  }, {
    text: 'Informações - Pendências',
    value: 'pending_information',
    changeRequest: true
  }, {
    text: 'Veículo - Alienado',
    value: 'car_lien'
  }, {
    text: 'Veículo - Bloqueio Judicial',
    value: 'car_judicial_bar'
  }, {
    text: 'Veículo - Débito',
    value: 'car_debts'
  }, {
    text: 'Veículo - Fora do Perfil',
    value: 'car_out_of_policy'
  }, {
    text: 'Veículo - Leilão',
    value: 'car_auction'
  }, {
    text: 'Restrição interna',
    value: 'internal_issue'
  }];

  exports.default = reasons;
  var values = exports.values = _lodash.default.map(reasons, 'value');
});