define('middle-office/services/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  var Service = Ember.Service;
  exports.default = Service.extend({
    componentExists: function componentExists(componentName) {
      var owner = getOwner(this);
      var lookup = owner.lookup('component-lookup:main');
      if (!lookup.componentFor) {
        return !!lookup.lookupFactory(componentName);
      }

      return !!(lookup.componentFor(componentName, owner) || lookup.layoutFor(componentName, owner));
    }
  });
});