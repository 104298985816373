define('middle-office/helpers/enum-options', ['exports', 'ember-cli-bkf-core/helpers/enum-options'], function (exports, _enumOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _enumOptions.default;
    }
  });
  Object.defineProperty(exports, 'enumOptions', {
    enumerable: true,
    get: function () {
      return _enumOptions.enumOptions;
    }
  });
});