define('middle-office/enums/credit/auto-refi/declares-income-tax', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.declaresIncomeTax = undefined;
  var declaresIncomeTax = exports.declaresIncomeTax = [{
    text: 'Sim',
    value: 'true'
  }, {
    text: 'Não',
    value: 'false'
  }];

  exports.default = declaresIncomeTax;
  var values = exports.values = _lodash.default.map(declaresIncomeTax, 'value');
});