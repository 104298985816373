define('middle-office/pods/servicing/auto-loan/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Route = Ember.Route;
  var all = Ember.RSVP.all;
  exports.default = Route.extend({
    model: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee(_ref) {
        var loan_id = _ref.loan_id;
        var loan, contract, customers, addresses, assignments, installments, repayments;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return this.store.findRecord('servicing/onboarding/auto-loan', loan_id);

              case 2:
                loan = _context.sent;
                contract = loan.get('contract');
                _context.next = 6;
                return loan.get('customers');

              case 6:
                customers = _context.sent;
                addresses = customers.getEach('address');
                assignments = loan.get('assignments');
                _context.next = 11;
                return loan.get('installments');

              case 11:
                installments = _context.sent;
                repayments = installments.getEach('repayments');
                _context.next = 15;
                return all([contract, addresses, assignments, repayments]);

              case 15:
                return _context.abrupt('return', loan);

              case 16:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function model(_x) {
        return _ref2.apply(this, arguments);
      }

      return model;
    }()
  });
});