define('middle-office/pods/components/application-modal/auto-refi/discard/component', ['exports', 'middle-office/enums/loan/auto-refi/bkf/discard-reasons'], function (exports, _discardReasons) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var alias = Ember.computed.alias;
  var reads = Ember.computed.reads;
  exports.default = Component.extend({
    classNames: ['ui', 'equal', 'width', 'form', 'application-modal', 'discard'],
    classNameBindings: ['isEditable:editable:readonly'],

    store: service(),

    discardReason: alias('discard.content.reason'),
    discardReasonsEnum: _discardReasons.writableValues,
    application: reads('model.application'),
    discard: computed('application.discard', function () {
      var application = this.get('application');
      var store = this.get('store');
      var discard = application.get('discard');

      if (application.get('discard.isNew') == null) {
        discard = store.createRecord('loan/auto-refi/bkf/discard');
      }

      return discard;
    }),

    isEditable: reads('discard.isNew'),
    isInvalid: reads('discard.validations.isInvalid'),
    isSaving: reads('discard.isSaving'),

    _destroyDiscard: function _destroyDiscard() {
      var discard = this.get('discard');
      if (discard.get('isNew')) {
        discard.deleteRecord();
      }
    },


    actions: {
      onApprove: function onApprove() {
        var discard = this.get('discard');
        var isInvalid = this.get('isInvalid');

        this.set('showErrors', isInvalid);

        if (discard.get('validations.isValid')) {
          var application = this.get('application');
          discard.set('application', application);
          this.sendAction('onApprove', discard);
        }
      },
      onDeny: function onDeny() {
        this._destroyDiscard();
        this.sendAction('onDeny');
      }
    }
  });
});