define('middle-office/enums/auto-refi/auto-refi-teams', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.teams = undefined;
  var teams = exports.teams = [{
    text: 'Aprovação',
    value: 'Aprovação'
  }, {
    text: 'Docs',
    value: 'Docs'
  }, {
    text: 'Emissão e conferência',
    value: 'Emissão e conferência'
  }, {
    text: 'Tabulação de renda',
    value: 'Tabulação de renda'
  }, {
    text: 'Contrato',
    value: 'Contrato'
  }, {
    text: 'Liberação',
    value: 'Liberação'
  }];

  exports.default = teams;
  var values = exports.values = _lodash.default.map(teams, 'value');
});