define('middle-office/pods/application/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    pageClass: computed('currentPath', function () {
      var currentPath = this.get('currentPath');

      return currentPath.replace(/\./g, ' ');
    })
  });
});