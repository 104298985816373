define('middle-office/pods/auto-refi/workflow/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var all = Ember.RSVP.all;
  var map = Ember.RSVP.map;
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
        var application;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                application = this.modelFor('auto-refi').application;
                _context.next = 3;
                return this.loadPipelines(application);

              case 3:
                return _context.abrupt('return', application);

              case 4:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function model() {
        return _ref.apply(this, arguments);
      }

      return model;
    }(),
    loadPipelines: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(application) {
        var presentPartnerApplications, resultPipeline;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _context2.next = 2;
                return all(application.get('partnerApplications'));

              case 2:
                _context2.next = 4;
                return all(application.get('presentPartnerApplications'));

              case 4:
                presentPartnerApplications = _context2.sent;
                _context2.next = 7;
                return map(presentPartnerApplications, function (pa) {
                  return pa.belongsTo('pipeline').reload();
                });

              case 7:
                resultPipeline = _context2.sent;
                return _context2.abrupt('return', resultPipeline);

              case 9:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function loadPipelines(_x) {
        return _ref2.apply(this, arguments);
      }

      return loadPipelines;
    }()
  });
});