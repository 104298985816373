define('middle-office/pods/components/app-banks/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var map = Ember.computed.map;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    store: service(),

    tagName: '',

    bankRecords: computed(function () {
      return this.get('store').findAll('bank');
    }),

    bankList: map('bankRecords', function (bank) {
      return {
        text: bank.get('name'),
        value: bank.get('id')
      };
    })
  });
});