define('middle-office/pods/auto-refi/credit/route', ['exports', 'middle-office/utils/analysis/auto-refi/data/credit-reference-visitor'], function (exports, _creditReferenceVisitor) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var hash = Ember.RSVP.hash;
  var Route = Ember.Route;


  var DOING = 'doing';

  exports.default = Route.extend({
    model: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
        var _ref2, application, guarantor, requester, fidcApplication, calculator, savedCreditReference, model;

        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return this._getRelatedModels();

              case 2:
                _ref2 = _context.sent;
                application = _ref2.application;
                guarantor = _ref2.guarantor;
                requester = _ref2.requester;
                fidcApplication = _ref2.fidcApplication;
                calculator = _ref2.calculator;
                savedCreditReference = _ref2.savedCreditReference;
                model = {
                  application: application,
                  requester: requester,
                  guarantor: guarantor,
                  fidcApplication: fidcApplication,
                  calculator: calculator,
                  creditResidenceAddress: this.store.createRecord('analysis/auto-refi/data/address', {}),
                  creditProfessionalAddress: this.store.createRecord('analysis/auto-refi/data/address', {})
                };

                if (!savedCreditReference.get('requestCreditReferenceAttributes')) {
                  _context.next = 15;
                  break;
                }

                _context.next = 13;
                return this.createModelWithCreditReferenceVisitor(model, savedCreditReference, application);

              case 13:
                _context.next = 17;
                break;

              case 15:
                _context.next = 17;
                return this.createModelWithPersistedCreditReference(model, savedCreditReference);

              case 17:
                return _context.abrupt('return', hash(model));

              case 18:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function model() {
        return _ref.apply(this, arguments);
      }

      return model;
    }(),
    afterModel: function afterModel(resolvedModel) {
      var fidcApplication = resolvedModel.fidcApplication;
      resolvedModel.showWarningMessage = this._shouldShowCreditWarning(fidcApplication);
      return fidcApplication.updateCreditApprovalSituationDoing();
    },
    _shouldShowCreditWarning: function _shouldShowCreditWarning(fidcApplication) {
      if (fidcApplication.get('creditApprovalSituation') === DOING) {
        return true;
      }

      return false;
    },
    _getRelatedModels: function () {
      var _ref3 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2() {
        var application, guarantor, requester, fidcApplication, savedCreditReference, savedCalculator, calculator;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                application = this.modelFor('auto-refi').application;
                guarantor = application.get('guarantor');
                requester = application.get('requester');
                _context2.next = 5;
                return application.get('fidcApplication');

              case 5:
                fidcApplication = _context2.sent;
                _context2.next = 8;
                return fidcApplication.get('creditReference');

              case 8:
                savedCreditReference = _context2.sent;
                _context2.next = 11;
                return fidcApplication.get('calculator');

              case 11:
                savedCalculator = _context2.sent;
                calculator = fidcApplication.createCalculatorOperation(savedCalculator);
                return _context2.abrupt('return', hash({
                  application: application,
                  guarantor: guarantor,
                  requester: requester,
                  fidcApplication: fidcApplication,
                  calculator: calculator,
                  savedCreditReference: savedCreditReference
                }));

              case 14:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function _getRelatedModels() {
        return _ref3.apply(this, arguments);
      }

      return _getRelatedModels;
    }(),
    createModelWithCreditReferenceVisitor: function () {
      var _ref4 = _asyncToGenerator(regeneratorRuntime.mark(function _callee3(model, savedCreditReference, application) {
        var visitor;
        return regeneratorRuntime.wrap(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                _context3.t0 = _creditReferenceVisitor.default;
                _context3.next = 3;
                return savedCreditReference.duplicate();

              case 3:
                _context3.t1 = _context3.sent;
                _context3.t2 = this.store.createRecord('analysis/auto-refi/data/address', {});
                _context3.t3 = this.store.createRecord('analysis/auto-refi/data/address', {});
                _context3.t4 = {
                  creditReference: _context3.t1,
                  creditResidenceAddress: _context3.t2,
                  creditProfessionalAddress: _context3.t3
                };
                visitor = _context3.t0.create.call(_context3.t0, _context3.t4);


                application.acceptVisitor(visitor);

                model.creditReference = visitor.get('creditReference');
                model.creditProfessionalAddress = visitor.get('creditProfessionalAddress');
                model.creditResidenceAddress = visitor.get('creditResidenceAddress');

              case 12:
              case 'end':
                return _context3.stop();
            }
          }
        }, _callee3, this);
      }));

      function createModelWithCreditReferenceVisitor(_x, _x2, _x3) {
        return _ref4.apply(this, arguments);
      }

      return createModelWithCreditReferenceVisitor;
    }(),
    createModelWithPersistedCreditReference: function () {
      var _ref5 = _asyncToGenerator(regeneratorRuntime.mark(function _callee4(model, creditReferencePersisted) {
        var _ref6, creditResidenceAddress, creditProfessionalAddress;

        return regeneratorRuntime.wrap(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                _context4.next = 2;
                return creditReferencePersisted.duplicateWithoutCommittee();

              case 2:
                model.creditReference = _context4.sent;
                _context4.next = 5;
                return creditReferencePersisted.addresses();

              case 5:
                _ref6 = _context4.sent;
                creditResidenceAddress = _ref6.creditResidenceAddress;
                creditProfessionalAddress = _ref6.creditProfessionalAddress;


                model.creditResidenceAddress.fillWith(creditResidenceAddress);
                model.creditProfessionalAddress.fillWith(creditProfessionalAddress);

                model.creditReference.setProperties({
                  residenceAddress: model.creditResidenceAddress,
                  companyAddress: model.creditProfessionalAddress
                });

              case 11:
              case 'end':
                return _context4.stop();
            }
          }
        }, _callee4, this);
      }));

      function createModelWithPersistedCreditReference(_x4, _x5) {
        return _ref5.apply(this, arguments);
      }

      return createModelWithPersistedCreditReference;
    }()
  });
});