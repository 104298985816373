define('middle-office/pods/components/partner-applications/partials/statuses/reject/component', ['exports', 'ember-cli-bkf-core/enum-options'], function (exports, _enumOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['rejection-form'],
    maxLength: 256,

    reasons: null,
    rejectionReason: null,

    rejectionDescription: null,

    _rejectionReasonOption: computed('reasons', 'rejectionReason', function () {
      var options = (0, _enumOptions.default)(this.get('reasons'), getOwner(this));
      return options.findBy('value', this.get('rejectionReason'));
    }),

    _createChangeRequest: computed('_rejectionReasonOption.changeRequest', function () {
      var reasonCreatesChangeRequest = this.get('_rejectionReasonOption.changeRequest');

      return !!reasonCreatesChangeRequest;
    }),

    actions: {
      rejectPartnerApplication: function rejectPartnerApplication() {
        this._setPartnerApplicationRejectionFields();

        var changeRequested = this.get('_createChangeRequest');
        this.sendAction('on-close', { changeRequested: changeRequested });
      }
    },

    _setPartnerApplicationRejectionFields: function _setPartnerApplicationRejectionFields() {
      this.set('partnerApplication.rejectionReason', this.get('rejectionReason'));
      this.set('partnerApplication.rejectionDescription', this.get('rejectionDescription'));
    }
  });
});