define('middle-office/pods/components/app-forms/application/auto-fin/steps/loan/component', ['exports', 'middle-office/pods/auto-refi/partners', 'middle-office/utils/build-enum'], function (exports, _partners, _buildEnum) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var observer = Ember.observer;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['financial-data', 'form-step'],

    rollbar: service('rollbar'),

    partner: computed(function () {
      return _partners.default.FIDC;
    }),

    interestTable: computed('partner.interestTable.[]', function () {
      var interestTable = this.get('partner.interestTable');

      return (0, _buildEnum.default)({
        items: interestTable,
        includeBlank: true
      });
    }),

    handleDownPaymentObserver: observer('application.purchaseValue', 'application.loanAmount', 'application.downPayment', function () {
      this._handleDownPayment();
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this._handleDownPayment();
    },
    _handleDownPayment: function _handleDownPayment() {
      var application = this.get('application');
      var purchaseValue = application.get('purchaseValue') || 0;
      var loanAmount = application.get('loanAmount') || 0;

      application.set('downPayment', purchaseValue - loanAmount);
    }
  });
});