define('middle-office/pods/main/auto-refi/portfolio/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var all = Ember.RSVP.all;
  var hash = Ember.RSVP.hash;
  var service = Ember.inject.service;
  var Route = Ember.Route;
  exports.default = Route.extend({
    auth: service(),
    rollbar: service(),

    queryParams: {
      page: {
        refreshModel: true
      },
      perPage: {
        refreshModel: true
      },
      statusFlow: {
        refreshModel: true
      },
      discarded: {
        refreshModel: true
      }
    },

    model: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(params) {
        var _this = this;

        var consultant, portfolio, prepareRequesters;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                consultant = [];
                portfolio = [];
                _context2.prev = 2;
                _context2.next = 5;
                return this.get('auth.user');

              case 5:
                consultant = _context2.sent;
                _context2.next = 8;
                return consultant.getPortfolio({
                  type: 'autoRefiPortfolio',
                  page: params.page,
                  perPage: params.perPage,
                  statusFlow: params.statusFlow,
                  discarded: params.discarded
                });

              case 8:
                portfolio = _context2.sent;
                prepareRequesters = portfolio.data.map(function () {
                  var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee(application) {
                    var requester;
                    return regeneratorRuntime.wrap(function _callee$(_context) {
                      while (1) {
                        switch (_context.prev = _context.next) {
                          case 0:
                            _context.next = 2;
                            return application.get('requester');

                          case 2:
                            requester = _context.sent;
                            return _context.abrupt('return', requester);

                          case 4:
                          case 'end':
                            return _context.stop();
                        }
                      }
                    }, _callee, _this);
                  }));

                  return function (_x2) {
                    return _ref2.apply(this, arguments);
                  };
                }());
                _context2.next = 12;
                return all(prepareRequesters);

              case 12:
                _context2.next = 18;
                break;

              case 14:
                _context2.prev = 14;
                _context2.t0 = _context2['catch'](2);

                Ember.Logger.error(_context2.t0);
                this.get('rollbar').error(new Error('Failure while loading the consultant portfolio'), {
                  errorEvent: _context2.t0
                });

              case 18:
                _context2.prev = 18;
                return _context2.abrupt('return', hash({
                  consultant: consultant,
                  portfolio: portfolio
                }));

              case 21:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this, [[2, 14, 18, 21]]);
      }));

      function model(_x) {
        return _ref.apply(this, arguments);
      }

      return model;
    }(),
    resetController: function resetController(controller) {
      controller.set('feedbackMessage', null);
    }
  });
});