define('middle-office/pods/components/partner-applications/partials/statuses/formalization/loan-value/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['loan-value'],

    requested: 0,
    paid: 0
  });
});