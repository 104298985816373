define('middle-office/services/file-upload', ['exports', 'lodash', 'middle-office/utils/factories/file'], function (exports, _lodash, _file) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  var get = Ember.get;
  var bind = Ember.run.bind;
  var service = Ember.inject.service;
  exports.default = Ember.Service.extend({
    rollbar: service(),

    send: function send(file) {
      var isFile = file instanceof _file.default;
      var attributes = ['data', 'resource', 'onProgress', 'onError', 'onDone'];
      var isValidFile = _lodash.default.every(attributes, _lodash.default.partial(_lodash.default.has, Object.getPrototypeOf(file)));

      if (!isFile || !isValidFile) throw new Error();

      return this._sendRequest(file);
    },
    _sendRequest: function _sendRequest(file) {
      var xhr = this._mountXhr(file);
      var formData = new FormData();

      formData.append('data', file.get('data'));

      return xhr.send(formData);
    },
    _mountXhr: function _mountXhr(file) {
      var _this = this;

      var xhr = new XMLHttpRequest();

      xhr.open('POST', this._createURL(file));
      xhr.setRequestHeader('accept-version', 'v1');

      xhr.onreadystatechange = function () {
        _this._handleStatus(xhr, file);
      };

      if ('upload' in xhr) {
        xhr.upload.onprogress = bind(file, 'onProgress');
      }

      return xhr;
    },
    _getAdapter: function _getAdapter() {
      var owner = getOwner(this);

      return owner.lookup('adapter:application');
    },
    _createURL: function _createURL(_ref) {
      var resource = _ref.resource;

      var adapter = this._getAdapter();
      var host = get(adapter, 'host');
      var url = host + '/' + resource;

      return url;
    },
    _handleStatus: function _handleStatus(xhr, file) {
      var status = xhr.status,
          readyState = xhr.readyState,
          response = xhr.response;
      var _XMLHttpRequest = XMLHttpRequest,
          DONE = _XMLHttpRequest.DONE;

      var validResponseCodes = [200, 201, 202];

      if (readyState !== DONE) return;

      if (validResponseCodes.includes(status)) {
        file.onDone();
      } else {
        this._onError(file, response);
      }
    },
    _onError: function _onError(file, response) {
      var error = 'Failure when trying to upload an attachment';

      this.get('rollbar').warning(error, {
        file: file,
        errorEvent: response
      });
      file.onError();
    }
  });
});