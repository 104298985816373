define('middle-office/pods/components/app-tags/tags/iq', ['exports', 'middle-office/pods/components/app-tags/tags/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var equal = Ember.computed.equal;
  var reads = Ember.computed.reads;
  exports.default = _base.default.extend({
    name: 'IQ',
    key: 'iq',

    isIQ: reads('application.isIQ'),
    available: equal('isIQ', true)
  });
});