define('middle-office/pods/components/external-user-information/response/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var equal = Ember.computed.equal;
  var reads = Ember.computed.reads;
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    layout: Ember.HTMLBars.template({
      "id": "KJAXGbtk",
      "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"if\",[[19,0,[\"isError\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[9,\"class\",\"external-user-information-error\"],[9,\"data-test\",\"external-user-information-error\"],[7],[0,\"\\n        \"],[1,[18,\"errorMessage\"],false],[0,\"\\n      \"],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[25,\"component\",[[19,0,[\"responseType\"]]],[[\"response\"],[[19,0,[\"response\",\"data\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"]],\"hasEval\":false}",
      "meta": {}
    }),

    isError: equal('response.type', 'error'),
    errorMessage: reads('response.message'),
    allCheckResponse: reads('responses.allcheck.data'),
    responseType: computed('type', function () {
      var type = this.get('type');
      return 'external-user-information/response/' + type;
    })
  });
});