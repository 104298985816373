define('middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/professional/retired', ['exports', 'middle-office/enums/auto-refi/rounded-up-time-ranges'], function (exports, _roundedUpTimeRanges) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function humanizedRetirementNumberText(retirementNumber) {
    if (retirementNumber) {
      return ' O n\xFAmero do seu benef\xEDcio \xE9 ' + retirementNumber + '.';
    }
    return '';
  }

  function retired(employment) {
    var timeOfEmployment = (0, _roundedUpTimeRanges.textForTimeRange)(employment.timeOfEmployment);
    var retirementNumberText = humanizedRetirementNumberText(employment.retirementNumber);
    return '\xC9 aposentado(a) h\xE1 ' + timeOfEmployment + ', e atuava na profiss\xE3o [PROFISS\xC3O].' + retirementNumberText;
  }

  exports.default = retired;
});