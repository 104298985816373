define('middle-office/pods/auto-refi/controller', ['exports', 'middle-office/pods/auto-refi/settings'], function (exports, _settings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var reads = Ember.computed.reads;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    appLayoutConfig: _settings.appLayoutConfig,
    store: service(),
    disableHeaderBtn: false,
    feedbackMessage: null,
    showSettingsModal: false,
    showConfirmationModal: false,
    isLoadingPartners: false,
    confirmationSettings: {
      header: 'Desmarcar agendamento de próximo contato',
      type: 'delete'
    },
    confirmationModalMessage: 'Você tem certeza que deseja desmarcar o agendamento deste contato?',

    disableDiscardBtn: computed('model.application.statusFlow', 'discardReason', function () {
      var discardReason = this.get('discardReason');
      var statusFlow = this.get('model.application.statusFlow');

      return discardReason || statusFlow === 'closed';
    }),

    discardReason: reads('model.application.discard.content.reason'),
    partners: reads('model.partners'),
    scheduledPartner: reads('model.application.scheduledPartner'),
    partnerName: computed('scheduledPartner', function () {
      var scheduledPartner = this.get('scheduledPartner');

      return scheduledPartner.get('name');
    }),

    setFeedbackMessage: function setFeedbackMessage(message) {
      this.set('feedbackMessage', message);
    },
    dismissScheduledApplication: function dismissScheduledApplication() {
      var scheduling = {
        date: null,
        partner: null
      };
      var errorMessage = 'Erro ao desmarcar o agendamento de próximo contato =(';

      return this.saveApplicationScheduling(scheduling, errorMessage);
    },
    closeConfirmationModal: function closeConfirmationModal() {
      this.set('showConfirmationModal', false);
    },
    saveApplicationScheduling: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(scheduling, errorMessage) {
        var application, date, partner, message;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                application = this.get('model.application');
                date = scheduling.date, partner = scheduling.partner;


                application.set('scheduling', date);
                application.set('scheduledPartner', partner);

                _context.prev = 4;
                _context.next = 7;
                return application.save();

              case 7:
                _context.next = 13;
                break;

              case 9:
                _context.prev = 9;
                _context.t0 = _context['catch'](4);
                message = {
                  type: 'error',
                  title: 'Ooops...',
                  text: errorMessage
                };


                this.setFeedbackMessage(message);

              case 13:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this, [[4, 9]]);
      }));

      function saveApplicationScheduling(_x, _x2) {
        return _ref.apply(this, arguments);
      }

      return saveApplicationScheduling;
    }(),


    actions: {
      onCancelConfirmationModal: function onCancelConfirmationModal() {
        this.closeConfirmationModal();
      },
      openDismissScheduleModal: function openDismissScheduleModal() {
        this.set('showConfirmationModal', true);
      },
      onApproveConfirmationModal: function () {
        var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2() {
          return regeneratorRuntime.wrap(function _callee2$(_context2) {
            while (1) {
              switch (_context2.prev = _context2.next) {
                case 0:
                  _context2.next = 2;
                  return this.dismissScheduledApplication();

                case 2:

                  this.closeConfirmationModal();

                case 3:
                case 'end':
                  return _context2.stop();
              }
            }
          }, _callee2, this);
        }));

        function onApproveConfirmationModal() {
          return _ref2.apply(this, arguments);
        }

        return onApproveConfirmationModal;
      }(),
      openScheduleModal: function () {
        var _ref3 = _asyncToGenerator(regeneratorRuntime.mark(function _callee3() {
          var partners;
          return regeneratorRuntime.wrap(function _callee3$(_context3) {
            while (1) {
              switch (_context3.prev = _context3.next) {
                case 0:
                  this.set('isLoadingPartners', true);

                  _context3.next = 3;
                  return this.get('store').query('underwriting/partner', {
                    'filter[product]': 'auto_refi'
                  });

                case 3:
                  partners = _context3.sent;


                  this.setProperties({
                    isLoadingPartners: false,
                    'model.partners': partners
                  });

                  this.send('onShowModal', 'scheduling');

                case 6:
                case 'end':
                  return _context3.stop();
              }
            }
          }, _callee3, this);
        }));

        function openScheduleModal() {
          return _ref3.apply(this, arguments);
        }

        return openScheduleModal;
      }(),
      onShowModal: function onShowModal(type) {
        this.setProperties({
          showSettingsModal: true,
          modalSettings: _settings.modalSettings[type]
        });
      },
      onCloseSettingsModal: function onCloseSettingsModal() {
        this.set('showSettingsModal', false);
      },
      onApproveSettingsModal: function onApproveSettingsModal(type, modalContent) {
        var actionName = _settings.modalSettings[type].approveAction;

        this.send(actionName, modalContent);
      },
      reviveApplication: function () {
        var _ref4 = _asyncToGenerator(regeneratorRuntime.mark(function _callee4() {
          var store, discard, message;
          return regeneratorRuntime.wrap(function _callee4$(_context4) {
            while (1) {
              switch (_context4.prev = _context4.next) {
                case 0:
                  store = this.get('store');
                  _context4.prev = 1;
                  _context4.next = 4;
                  return this.get('model.application.discard');

                case 4:
                  discard = _context4.sent;
                  _context4.next = 7;
                  return discard.destroyRecord();

                case 7:
                  store._removeFromIdMap(discard._internalModel);
                  _context4.next = 15;
                  break;

                case 10:
                  _context4.prev = 10;
                  _context4.t0 = _context4['catch'](1);

                  Ember.Logger.error(_context4.t0);
                  message = {
                    type: 'error',
                    title: 'Ooops...',
                    text: 'Erro ao tentar resgatar solicitação...'
                  };

                  this.setFeedbackMessage(message);

                case 15:
                  _context4.prev = 15;

                  this.send('onCloseSettingsModal');
                  return _context4.finish(15);

                case 18:
                case 'end':
                  return _context4.stop();
              }
            }
          }, _callee4, this, [[1, 10, 15, 18]]);
        }));

        function reviveApplication() {
          return _ref4.apply(this, arguments);
        }

        return reviveApplication;
      }(),
      discardApplication: function () {
        var _ref5 = _asyncToGenerator(regeneratorRuntime.mark(function _callee5(discard) {
          var message;
          return regeneratorRuntime.wrap(function _callee5$(_context5) {
            while (1) {
              switch (_context5.prev = _context5.next) {
                case 0:
                  _context5.prev = 0;
                  _context5.next = 3;
                  return discard.save();

                case 3:
                  _context5.next = 9;
                  break;

                case 5:
                  _context5.prev = 5;
                  _context5.t0 = _context5['catch'](0);
                  message = {
                    type: 'error',
                    title: 'Ooops...',
                    text: 'Erro ao tentar descartar solicitação...'
                  };


                  this.setFeedbackMessage(message);

                case 9:
                  _context5.prev = 9;

                  this.send('onCloseSettingsModal');
                  return _context5.finish(9);

                case 12:
                case 'end':
                  return _context5.stop();
              }
            }
          }, _callee5, this, [[0, 5, 9, 12]]);
        }));

        function discardApplication(_x3) {
          return _ref5.apply(this, arguments);
        }

        return discardApplication;
      }(),
      scheduleApplication: function () {
        var _ref6 = _asyncToGenerator(regeneratorRuntime.mark(function _callee6(scheduling) {
          var errorMessage;
          return regeneratorRuntime.wrap(function _callee6$(_context6) {
            while (1) {
              switch (_context6.prev = _context6.next) {
                case 0:
                  errorMessage = 'Erro ao agendar próximo contato...';
                  _context6.next = 3;
                  return this.saveApplicationScheduling(scheduling, errorMessage);

                case 3:

                  this.send('onCloseSettingsModal');

                case 4:
                case 'end':
                  return _context6.stop();
              }
            }
          }, _callee6, this);
        }));

        function scheduleApplication(_x4) {
          return _ref6.apply(this, arguments);
        }

        return scheduleApplication;
      }()
    }
  });
});