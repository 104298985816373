define('middle-office/services/dialer', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var and = Ember.computed.and;


  var ROLLOUT_CONSULTANTS = {
    dialer: ['consultor@bankfacil.com.br', 'dev@bankfacil.com.br', 'maria.fernanda@creditas.com.br', 'guilherme.siqueira@creditas.com.br', 'rebecca.alvarez@creditas.com.br', 'daniel.vaidergorin@creditas.com.br', 'consultornaocomercial@creditas.com.br', 'rodrigo.etelli@creditas.com.br', 'ana.akisawa@creditas.com.br', 'debora.silva@creditas.com.br', 'gabriela.costa@creditas.com.br', 'ki.suk@creditas.com.br', 'gabriel.munhoz@creditas.com.br', 'nadylla.dantas@creditas.com.br', 'bruna.mathias@creditas.com.br', 'elaine.oliveira@creditas.com.br'],
    notifications: ['consultor@bankfacil.com.br', 'dev@bankfacil.com.br', 'consultornaocomercial@creditas.com.br']
  };

  exports.default = Service.extend({
    hermesDialer: service('hermes-dialer'),
    auth: service(),
    session: service(),

    isEnabled: and('auth.user.extension_number', 'isDialerRollout'),

    _isSetup: false,

    setup: function setup(origin, product, reference) {
      this.setProperties({
        origin: origin,
        product: product,
        reference: reference,
        _isSetup: true
      });

      this._setupAuthorization();
    },


    isDialerRollout: computed(function computedDialerRollout() {
      return this._isRolloutFor('dialer');
    }),

    isNotificationsRollout: computed(function computedNotificationsRollout() {
      return this._isRolloutFor('notifications');
    }),

    dial: function dial(code, phone) {
      if (!this.get('_isSetup')) {
        throw new Error('Not configured: ' + code + phone);
      }

      var dialer = this.get('hermesDialer');

      dialer.set('enableNotifications', this.get('isNotificationsRollout'));

      dialer.call(this.get('origin'), this.get('product'), this.get('reference'), this.get('auth.user.extension_number'), '' + code + phone);
    },
    _setupAuthorization: function _setupAuthorization() {
      this.get('hermesDialer').defineToken(this.get('session.data.authenticated.access_token'));
    },
    _isRolloutFor: function _isRolloutFor(rolloutType) {
      var email = this.get('auth.user.email');
      var rolloutConsultantList = ROLLOUT_CONSULTANTS[rolloutType];
      return _lodash.default.indexOf(rolloutConsultantList, email) !== -1;
    }
  });
});