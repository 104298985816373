define('middle-office/utils/vehicle', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.MIN_YEAR = undefined;
  exports.possibleModelYears = possibleModelYears;
  exports.possibleManufacturingYears = possibleManufacturingYears;

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var MIN_YEAR = exports.MIN_YEAR = 1972;

  var Vehicle = function () {
    _createClass(Vehicle, null, [{
      key: 'fromExternalData',
      value: function fromExternalData(payload) {
        return new this({
          model: payload.model,
          modelVersion: payload.model_version,
          numberOfDoors: payload.number_of_doors,
          accessoryPackage: payload.accessory_package,
          fuelType: payload.fuel_type,
          value: payload.value,
          molicarCode: payload.molicar_code
        });
      }
    }]);

    function Vehicle(properties) {
      _classCallCheck(this, Vehicle);

      var filteredProperties = _lodash.default.pick(properties, ['model', 'modelVersion', 'numberOfDoors', 'accessoryPackage', 'fuelType', 'value', 'molicarCode']);

      Object.assign(this, filteredProperties);
    }

    _createClass(Vehicle, [{
      key: 'fullModelVersion',
      get: function get() {
        return this.modelVersion + ' ' + this.numberOfDoors + 'pt ' + this.fuelType + ' ' + this.accessoryPackage;
      }
    }]);

    return Vehicle;
  }();

  exports.default = Vehicle;
  function possibleModelYears(baseYear) {
    if (baseYear != null) {
      return yearRange(baseYear, baseYear + 1);
    }

    return yearRange(MIN_YEAR, currentYear() + 1);
  }

  function possibleManufacturingYears() {
    return yearRange(MIN_YEAR, currentYear());
  }

  function yearRange(minYear, maxYear) {
    return (0, _lodash.default)(minYear).range(maxYear + 1).reverse().value();
  }

  function currentYear() {
    return new Date().getFullYear();
  }
});