define('middle-office/pods/main/auto-fin/edit-application/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var computed = Ember.computed;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    updatingStatus: false,

    steps: computed(function () {
      return [{
        name: 'preparation',
        title: 'Preparação'
      }, {
        name: 'submission',
        title: 'Cadastro'
      }, {
        name: 'approval',
        title: 'Análise de crédito'
      }, {
        name: 'formalization',
        title: 'Formalização'
      }, {
        name: 'closed',
        title: 'Pago'
      }];
    }),

    actions: {
      updateStatus: function () {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(newStatus) {
          var application;
          return regeneratorRuntime.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  this.set('updatingStatus', true);
                  application = this.get('model');

                  application.set('statusFlow', newStatus);

                  _context.prev = 3;
                  _context.next = 6;
                  return application.save();

                case 6:
                  _context.prev = 6;

                  this.set('updatingStatus', false);
                  return _context.finish(6);

                case 9:
                case 'end':
                  return _context.stop();
              }
            }
          }, _callee, this, [[3,, 6, 9]]);
        }));

        function updateStatus(_x) {
          return _ref.apply(this, arguments);
        }

        return updateStatus;
      }()
    }
  });
});