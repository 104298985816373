define('middle-office/pods/components/app-table/servicing-loans/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['servicing-loans'],
    tagName: 'tbody'
  });
});