define('middle-office/pods/components/app-forms/partner-applications/auto-refi/approval/partials/analysis/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var equal = Ember.computed.equal;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    isFidc: equal('partnerApplication.name', 'FIDC'),
    isDoing: equal('partnerApplication.creditApprovalSituation', 'doing'),

    changeRequests: service('auto-refi/application-change-requests'),

    'on-save': function onSave() {},

    actions: {
      rejectPartnerApplication: function () {
        var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee(_ref) {
          var changeRequested = _ref.changeRequested;
          var partnerApplication;
          return regeneratorRuntime.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  partnerApplication = this.get('partnerApplication');
                  _context.next = 3;
                  return this.sendAction('on-save', 'approval', 'rejected');

                case 3:
                  if (!changeRequested) {
                    _context.next = 6;
                    break;
                  }

                  _context.next = 6;
                  return this.get('changeRequests').createChangeRequest(partnerApplication);

                case 6:
                case 'end':
                  return _context.stop();
              }
            }
          }, _callee, this);
        }));

        function rejectPartnerApplication(_x) {
          return _ref2.apply(this, arguments);
        }

        return rejectPartnerApplication;
      }()
    }
  });
});