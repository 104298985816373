define('middle-office/initializers/hermes-config', ['exports', 'ember-cli-hermes/configuration', 'ember-cli-hermes/service-worker', 'middle-office/config/environment'], function (exports, _configuration, _serviceWorker, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'hermes-config',

    initialize: function initialize(applicationInstance) {
      var config = _environment.default['hermes-config'] || {};
      _configuration.default.load(config);
      (0, _serviceWorker.default)(applicationInstance, config);
    }
  };
});