define('middle-office/pods/home-refi/application-form/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var EmberPromise = Ember.RSVP.Promise;
  var service = Ember.inject.service;
  var Route = Ember.Route;


  var APPLICATION_RELATIONSHIPS = ['realEstateAddress', 'bariguiApplication', 'bancoPanApplication', 'chbApplication', 'fapaApplication', 'chpApplication'];

  var REQUESTER_TYPES = ['requester', 'additionalRequester', 'otherAdditionalRequester'];

  exports.default = Route.extend({
    personPreparation: service('home-refi/person-preparation'),

    model: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee2() {
        var _this = this;

        var application, mapRelationship, relationshipPromises, prepareRequesters;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                application = this.modelFor('home-refi');

                mapRelationship = function mapRelationship(relationship) {
                  return application.get(relationship);
                };

                relationshipPromises = APPLICATION_RELATIONSHIPS.map(mapRelationship);
                prepareRequesters = REQUESTER_TYPES.map(function () {
                  var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee(relationship) {
                    var requester;
                    return regeneratorRuntime.wrap(function _callee$(_context) {
                      while (1) {
                        switch (_context.prev = _context.next) {
                          case 0:
                            _context.next = 2;
                            return application.get(relationship);

                          case 2:
                            requester = _context.sent;

                            if (!(requester != null)) {
                              _context.next = 6;
                              break;
                            }

                            _context.next = 6;
                            return _this.get('personPreparation').prepareRequester(requester);

                          case 6:
                            return _context.abrupt('return', requester);

                          case 7:
                          case 'end':
                            return _context.stop();
                        }
                      }
                    }, _callee, _this);
                  }));

                  return function (_x) {
                    return _ref2.apply(this, arguments);
                  };
                }());
                _context2.next = 6;
                return EmberPromise.all(relationshipPromises);

              case 6:
                _context2.next = 8;
                return EmberPromise.all(prepareRequesters);

              case 8:
                return _context2.abrupt('return', application);

              case 9:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function model() {
        return _ref.apply(this, arguments);
      }

      return model;
    }(),
    setupController: function setupController(controller, model) {
      controller.set('application', model);
    }
  });
});