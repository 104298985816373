define('middle-office/enums/underwriting/auto-refi/rejection-reasons/system', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.reason = undefined;
  var reason = exports.reason = [{
    text: 'Solicitação descartada',
    value: 'bkf_application_rejected'
  }];

  exports.default = reason;
  var values = exports.values = _lodash.default.map(reason, 'value');
});