define('middle-office/enums/loan/auto-refi/bkf/inspection-partner', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.partner = undefined;
  var partner = exports.partner = [{
    text: 'DEKRA',
    value: 'dekra'
  }];

  exports.default = partner;
  var values = exports.values = _lodash.default.map(partner, 'value');
});