define('middle-office/pods/admin/users/controller', ['exports', 'middle-office/enums/admin/consultant-filters'], function (exports, _consultantFilters) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var computed = Ember.computed;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    activeFilter: 'ativos',
    sortedConsultants: computed('activeFilter', 'model.@each.id', function () {
      var getConsultants = this.get('model').filterBy('id').sortBy('fullName');
      var isFilteringActives = this.get('activeFilter') === _consultantFilters.filterActive;
      var isFilteringInactives = this.get('activeFilter') === _consultantFilters.filterInactive;

      if (isFilteringActives) {
        return getConsultants.filterBy('active', true);
      }
      if (isFilteringInactives) {
        return getConsultants.filterBy('active', false);
      }
      return getConsultants;
    }),

    headers: ['Consultor', 'Email', 'Ramal', 'Status', ''],
    status: _consultantFilters.filterActive,
    filters: _consultantFilters.default,

    actions: {
      toggleActiveStatus: function () {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(consultant) {
          return regeneratorRuntime.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  _context.prev = 0;

                  consultant.toggleProperty('active');
                  _context.next = 4;
                  return consultant.save();

                case 4:
                  _context.next = 9;
                  break;

                case 6:
                  _context.prev = 6;
                  _context.t0 = _context['catch'](0);

                  consultant.toggleProperty('active');

                case 9:
                case 'end':
                  return _context.stop();
              }
            }
          }, _callee, this, [[0, 6]]);
        }));

        function toggleActiveStatus(_x) {
          return _ref.apply(this, arguments);
        }

        return toggleActiveStatus;
      }(),
      changeFilter: function changeFilter(value) {
        this.set('activeFilter', value);
      }
    }
  });
});