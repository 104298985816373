define('middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/income', ['exports', 'middle-office/helpers/mask', 'middle-office/enums/auto-refi/rounded-up-time-ranges', 'middle-office/enums/auto-refi/income-proofs', 'middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/helpers/humanized'], function (exports, _mask, _roundedUpTimeRanges, _incomeProofs, _humanized) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function humanizedVehicleDebts(vehicleDebt) {
    return vehicleDebt <= 0 ? 'quitado' : 'não quitado';
  }

  function humanizedVehicleInformation(application) {
    var vehicle = (0, _humanized.humanizedVehicle)(application);
    var vehicleDebts = humanizedVehicleDebts(application.vehicleDebt);

    return 'Est\xE1 refinanciando o ve\xEDculo ' + vehicle + ' ' + vehicleDebts;
  }

  function income(application) {
    var requester = application.requester;
    var monthlyIncome = (0, _mask.mask)([requester.monthlyIncome, 'money']);
    var incomeProof = (0, _incomeProofs.textForIncomeProof)(requester.incomeProof);
    var bank = requester.financeBank;
    var bankAccountTime = (0, _roundedUpTimeRanges.textForTimeRange)(requester.financeBankAccountTime);
    var loanAmount = (0, _mask.mask)([application.loanAmount, 'money']);
    var vehicleInformation = humanizedVehicleInformation(application);

    return 'Comprova rendimentos de R$' + monthlyIncome + ' por meio de ' + incomeProof + '.\nTem experi\xEAncia de cr\xE9dito no banco ' + bank + ' h\xE1 ' + bankAccountTime + '.\n' + vehicleInformation + ' e quer um valor de R$' + loanAmount + '.';
  }

  exports.default = income;
});