define('middle-office/models/loan/auto-refi/workflow/pipeline/task', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  var equal = Ember.computed.equal;
  var not = Ember.computed.not;
  var or = Ember.computed.or;


  var TaskState = EmberObject.create({
    changeToRework: function changeToRework() {
      return false;
    },
    changeToDoing: function changeToDoing() {
      return false;
    },
    changeToDone: function changeToDone() {
      return false;
    }
  });

  var NotDone = EmberObject.extend(TaskState, {
    changeToDoing: function changeToDoing(task) {
      task.set('status', 'doing');
      return true;
    }
  });

  var Doing = EmberObject.extend(TaskState, {
    changeToRework: function changeToRework(task) {
      task.set('status', 'rework');
      return true;
    },
    changeToDone: function changeToDone(task) {
      task.set('status', 'done');
      return true;
    }
  });

  var Rework = EmberObject.extend(TaskState, {
    changeToDoing: function changeToDoing(task) {
      task.set('status', 'doing');
      return true;
    }
  });

  var Done = EmberObject.extend(TaskState, {
    changeToRework: function changeToRework(task) {
      task.set('status', 'rework');
      return true;
    }
  });

  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    order: _emberData.default.attr('number'),
    team: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    status: _emberData.default.attr('string'),
    available: _emberData.default.attr('boolean'),
    unavailable: not('available'),
    automaticCompletion: _emberData.default.attr('boolean'),
    isDisabled: or('unavailable', 'automaticCompletion'),
    assigneeName: _emberData.default.attr('string'),

    isDone: equal('status', 'done'),
    isDoing: equal('status', 'doing'),
    isRework: equal('status', 'rework'),
    isNotDone: equal('status', 'not_done'),
    isToDo: or('isRework', 'isNotDone'),

    color: reads('_state.color'),

    dependents: _emberData.default.hasMany('loan/auto-refi/workflow/pipeline/task', { inverse: null }),
    assignee: _emberData.default.belongsTo('crm/consultant'),
    pipeline: _emberData.default.belongsTo('loan/auto-refi/workflow/pipeline'),

    changeToDoing: function changeToDoing() {
      return this.get('_state').changeToDoing(this);
    },
    changeToRework: function changeToRework() {
      return this.get('_state').changeToRework(this);
    },
    changeToDone: function changeToDone() {
      return this.get('_state').changeToDone(this);
    },


    _state: computed('status', function () {
      var statusState = void 0;
      var status = this.get('status');

      switch (status) {
        case 'not_done':
          statusState = NotDone;
          break;
        case 'rework':
          statusState = Rework;
          break;
        case 'done':
          statusState = Done;
          break;
        case 'doing':
          statusState = Doing;
          break;
        default:
          throw Error('Task state not defined to status ' + status);
      }

      return statusState.create();
    })
  });
});