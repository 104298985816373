define('middle-office/pods/components/app-forms/application/home-refi/partials/action-bar/component', ['exports', 'lodash', 'ember-cli-bkf-core/enums/partner'], function (exports, _lodash, _partner2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var later = Ember.run.later;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['action-bar', 'ui', 'grid', 'container', 'centered', 'middle', 'aligned'],

    applicationForm: service('home-refi/application-form'),

    blobDownload: service('blob-download'),

    selectedPartner: null,

    partnerList: computed('application.bariguiApplication.content', 'application.fapaApplication.content', 'application.chpApplication.content', function () {
      var _this = this;

      return _partner2.default.map(function (partner) {
        var _partner = _this.get('application.' + partner.value + 'Application.content');

        if (_partner) {
          return partner;
        }

        return null;
      }).filter(function (partner) {
        return partner;
      });
    }),

    feedbackMessage: null,

    setFeedbackMessage: function setFeedbackMessage(message) {
      var _this2 = this;

      this.set('feedbackMessage', message);

      later(function () {
        _this2.set('feedbackMessage', null);
      }, 3500);
    },


    feedbackStatusClass: computed('applicationForm.status', 'blobDownload.status', function () {
      var applicationFormStatus = this.get('applicationForm.status');
      var blobDownloadStatus = this.get('blobDownload.status');

      if (applicationFormStatus === 'saved' && blobDownloadStatus !== 'error') {
        return 'success';
      }

      if (applicationFormStatus === 'error' || blobDownloadStatus === 'error') {
        this.set('blobDownload.status', false);

        return 'error negative';
      }

      return null;
    }),

    saveLoadingClass: computed('applicationForm.isSaving', function () {
      if (this.get('applicationForm.isSaving')) {
        return 'loading';
      }

      return null;
    }),

    saveAndDownloadLoadingClass: computed('applicationForm.isSaving', 'blobDownload.isDownloading', function () {
      if (this.get('applicationForm.isSaving') || this.get('blobDownload.isDownloading')) {
        return 'loading';
      }

      return null;
    }),

    _save: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
        var applicationForm;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return (this.get('on-save') || Ember.k)();

              case 2:
                applicationForm = this.get('applicationForm');


                if (applicationForm.get('isSaved')) {
                  this.setFeedbackMessage('Salvo com sucesso!');
                }

                if (applicationForm.get('isError')) {
                  this.setFeedbackMessage('Falha ao salvar o formulário :(');
                }

              case 5:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function _save() {
        return _ref.apply(this, arguments);
      }

      return _save;
    }(),
    _downloadFile: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(selectedPartner) {
        var partner, partnerName, fileName, url, blobDownload;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                partner = _lodash.default.find(_partner2.default, { value: selectedPartner });
                partnerName = partner.text;
                _context2.next = 4;
                return this._createFileName(partnerName, partner.extension);

              case 4:
                fileName = _context2.sent;
                _context2.next = 7;
                return this.get('application.' + selectedPartner + 'Application.binaryDocumentUrl');

              case 7:
                url = _context2.sent;
                blobDownload = this.get('blobDownload');
                _context2.prev = 9;
                _context2.next = 12;
                return blobDownload.downloadFile(url, fileName);

              case 12:
                _context2.next = 17;
                break;

              case 14:
                _context2.prev = 14;
                _context2.t0 = _context2['catch'](9);

                this.setFeedbackMessage('Falha ao realizar download da ficha :(');

              case 17:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this, [[9, 14]]);
      }));

      function _downloadFile(_x) {
        return _ref2.apply(this, arguments);
      }

      return _downloadFile;
    }(),
    _createFileName: function () {
      var _ref3 = _asyncToGenerator(regeneratorRuntime.mark(function _callee3(partnerName, fileExtension) {
        var requester, requesterFullName, requesterCpf;
        return regeneratorRuntime.wrap(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                _context3.next = 2;
                return this.get('application.requester');

              case 2:
                requester = _context3.sent;
                requesterFullName = requester.get('fullName');
                requesterCpf = requester.get('cpf');
                return _context3.abrupt('return', 'Ficha ' + partnerName + ' - ' + requesterFullName + ' - ' + requesterCpf + '.' + fileExtension);

              case 6:
              case 'end':
                return _context3.stop();
            }
          }
        }, _callee3, this);
      }));

      function _createFileName(_x2, _x3) {
        return _ref3.apply(this, arguments);
      }

      return _createFileName;
    }(),


    actions: {
      selectPartner: function selectPartner(value) {
        this.set('selectedPartner', value);
      },
      save: function () {
        var _ref4 = _asyncToGenerator(regeneratorRuntime.mark(function _callee4() {
          return regeneratorRuntime.wrap(function _callee4$(_context4) {
            while (1) {
              switch (_context4.prev = _context4.next) {
                case 0:
                  this._save();

                case 1:
                case 'end':
                  return _context4.stop();
              }
            }
          }, _callee4, this);
        }));

        function save() {
          return _ref4.apply(this, arguments);
        }

        return save;
      }(),
      saveAndDownload: function () {
        var _ref5 = _asyncToGenerator(regeneratorRuntime.mark(function _callee5() {
          var selectedPartner;
          return regeneratorRuntime.wrap(function _callee5$(_context5) {
            while (1) {
              switch (_context5.prev = _context5.next) {
                case 0:
                  selectedPartner = this.get('selectedPartner');

                  if (selectedPartner) {
                    _context5.next = 4;
                    break;
                  }

                  this.$('.partners').dropdown('show');
                  return _context5.abrupt('return');

                case 4:
                  _context5.next = 6;
                  return this._save();

                case 6:
                  _context5.next = 8;
                  return this._downloadFile(selectedPartner);

                case 8:
                case 'end':
                  return _context5.stop();
              }
            }
          }, _callee5, this);
        }));

        function saveAndDownload() {
          return _ref5.apply(this, arguments);
        }

        return saveAndDownload;
      }()
    }
  });
});