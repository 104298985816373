define('middle-office/pods/components/app-forms/application/auto-refi/credit-defense/build-plain-application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var buildPlainEmployment = function () {
    var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(employment) {
      var employmentProperties, companyAddress;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return employment.getProperties('professionalStatus', 'timeOfEmployment', 'retirementNumber', 'companyCnpj', 'companyName', 'classEntityRegistrationNumber', 'jobTitle');

            case 2:
              employmentProperties = _context.sent;
              _context.next = 5;
              return buildPlainCompanyAddress(employment);

            case 5:
              companyAddress = _context.sent;
              return _context.abrupt('return', _extends({
                companyAddress: companyAddress
              }, employmentProperties));

            case 7:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    }));

    return function buildPlainEmployment(_x) {
      return _ref.apply(this, arguments);
    };
  }();

  var buildPlainEmployments = function () {
    var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(requester) {
      var employments, promisedEmploymentBuilds, plainEmployments;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.next = 2;
              return requester.get('employments');

            case 2:
              employments = _context2.sent;
              promisedEmploymentBuilds = employments.map(buildPlainEmployment);
              _context2.next = 6;
              return Promise.all(promisedEmploymentBuilds);

            case 6:
              plainEmployments = _context2.sent;
              return _context2.abrupt('return', plainEmployments);

            case 8:
            case 'end':
              return _context2.stop();
          }
        }
      }, _callee2, this);
    }));

    return function buildPlainEmployments(_x2) {
      return _ref2.apply(this, arguments);
    };
  }();

  var buildPlainCompanyAddress = function () {
    var _ref3 = _asyncToGenerator(regeneratorRuntime.mark(function _callee3(employment) {
      var companyAddress, companyAddressProperties;
      return regeneratorRuntime.wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              _context3.next = 2;
              return employment.get('companyAddress');

            case 2:
              companyAddress = _context3.sent;
              companyAddressProperties = void 0;

              if (!companyAddress) {
                _context3.next = 10;
                break;
              }

              _context3.next = 7;
              return companyAddress.getProperties('streetAddress', 'streetComplement', 'streetNumber', 'neighborhood', 'city', 'state');

            case 7:
              companyAddressProperties = _context3.sent;
              _context3.next = 11;
              break;

            case 10:
              companyAddressProperties = {};

            case 11:
              return _context3.abrupt('return', companyAddressProperties);

            case 12:
            case 'end':
              return _context3.stop();
          }
        }
      }, _callee3, this);
    }));

    return function buildPlainCompanyAddress(_x3) {
      return _ref3.apply(this, arguments);
    };
  }();

  var buildPlainResidenceAddress = function () {
    var _ref4 = _asyncToGenerator(regeneratorRuntime.mark(function _callee4(requester) {
      var residenceAddress;
      return regeneratorRuntime.wrap(function _callee4$(_context4) {
        while (1) {
          switch (_context4.prev = _context4.next) {
            case 0:
              _context4.next = 2;
              return requester.get('residenceAddress');

            case 2:
              residenceAddress = _context4.sent;
              _context4.next = 5;
              return residenceAddress.get('timeOfResidence');

            case 5:
              _context4.t0 = _context4.sent;
              return _context4.abrupt('return', {
                timeOfResidence: _context4.t0
              });

            case 7:
            case 'end':
              return _context4.stop();
          }
        }
      }, _callee4, this);
    }));

    return function buildPlainResidenceAddress(_x4) {
      return _ref4.apply(this, arguments);
    };
  }();

  var buildPlainPartnerApplication = function () {
    var _ref5 = _asyncToGenerator(regeneratorRuntime.mark(function _callee5(partner, application) {
      var partnerApplication;
      return regeneratorRuntime.wrap(function _callee5$(_context5) {
        while (1) {
          switch (_context5.prev = _context5.next) {
            case 0:
              _context5.next = 2;
              return application.get(partner + 'Application');

            case 2:
              partnerApplication = _context5.sent;
              _context5.next = 5;
              return partnerApplication.get('status');

            case 5:
              _context5.t0 = _context5.sent;
              return _context5.abrupt('return', {
                status: _context5.t0
              });

            case 7:
            case 'end':
              return _context5.stop();
          }
        }
      }, _callee5, this);
    }));

    return function buildPlainPartnerApplication(_x5, _x6) {
      return _ref5.apply(this, arguments);
    };
  }();

  var bankNameFromNumber = function () {
    var _ref6 = _asyncToGenerator(regeneratorRuntime.mark(function _callee6(bankNumber, store) {
      var bank;
      return regeneratorRuntime.wrap(function _callee6$(_context6) {
        while (1) {
          switch (_context6.prev = _context6.next) {
            case 0:
              _context6.next = 2;
              return store.findRecord('bank', bankNumber);

            case 2:
              bank = _context6.sent;
              return _context6.abrupt('return', bank.get('name'));

            case 4:
            case 'end':
              return _context6.stop();
          }
        }
      }, _callee6, this);
    }));

    return function bankNameFromNumber(_x7, _x8) {
      return _ref6.apply(this, arguments);
    };
  }();

  var buildPlainRequester = function () {
    var _ref7 = _asyncToGenerator(regeneratorRuntime.mark(function _callee7(application, store) {
      var requester, employments, residenceAddress, financeBankNumber, financeBank, requesterProperties;
      return regeneratorRuntime.wrap(function _callee7$(_context7) {
        while (1) {
          switch (_context7.prev = _context7.next) {
            case 0:
              _context7.next = 2;
              return application.get('requester');

            case 2:
              requester = _context7.sent;
              _context7.next = 5;
              return buildPlainEmployments(requester);

            case 5:
              employments = _context7.sent;
              _context7.next = 8;
              return buildPlainResidenceAddress(requester);

            case 8:
              residenceAddress = _context7.sent;
              _context7.next = 11;
              return requester.get('financeBankNumber');

            case 11:
              financeBankNumber = _context7.sent;
              _context7.next = 14;
              return bankNameFromNumber(financeBankNumber, store);

            case 14:
              financeBank = _context7.sent;
              _context7.next = 17;
              return requester.getProperties('monthlyIncome', 'incomeProof', 'fullName', 'birthDate', 'maritalStatus', 'stableUnion', 'residenceOwner', 'financeBankAccountTime');

            case 17:
              requesterProperties = _context7.sent;
              return _context7.abrupt('return', _extends({
                employments: employments,
                residenceAddress: residenceAddress,
                financeBank: financeBank
              }, requesterProperties));

            case 19:
            case 'end':
              return _context7.stop();
          }
        }
      }, _callee7, this);
    }));

    return function buildPlainRequester(_x9, _x10) {
      return _ref7.apply(this, arguments);
    };
  }();

  exports.default = function () {
    var _ref8 = _asyncToGenerator(regeneratorRuntime.mark(function _callee8(application, store) {
      var requester, bvApplication, santanaApplication, fidcApplication, applicationProperties;
      return regeneratorRuntime.wrap(function _callee8$(_context8) {
        while (1) {
          switch (_context8.prev = _context8.next) {
            case 0:
              _context8.next = 2;
              return buildPlainRequester(application, store);

            case 2:
              requester = _context8.sent;
              _context8.next = 5;
              return buildPlainPartnerApplication('bv', application);

            case 5:
              bvApplication = _context8.sent;
              _context8.next = 8;
              return buildPlainPartnerApplication('santana', application);

            case 8:
              santanaApplication = _context8.sent;
              _context8.next = 11;
              return buildPlainPartnerApplication('fidc', application);

            case 11:
              fidcApplication = _context8.sent;
              _context8.next = 14;
              return application.getProperties('loanAmount', 'vehicleBrand', 'vehicleModelYear', 'vehicleManufacturingYear', 'vehicleModel', 'vehicleDebt', 'scribble');

            case 14:
              applicationProperties = _context8.sent;
              return _context8.abrupt('return', _extends({
                requester: requester,
                bvApplication: bvApplication,
                fidcApplication: fidcApplication,
                santanaApplication: santanaApplication
              }, applicationProperties));

            case 16:
            case 'end':
              return _context8.stop();
          }
        }
      }, _callee8, this);
    }));

    function buildPlainApplication(_x11, _x12) {
      return _ref8.apply(this, arguments);
    }

    return buildPlainApplication;
  }();
});