define('middle-office/pods/components/app-forms/partner-applications/auto-refi/approval/partials/rejection-reason/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNameBindings: ['rejectionReason:visible:hidden'],

    rejectionReason: alias('partnerApplication.rejectionReason')
  });
});