define('middle-office/pods/components/auto-refi/task-list/component', ['exports', 'ember-cli-bkf-core/utils/pusher'], function (exports, _pusher) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var EmberPromise = Ember.RSVP.Promise;
  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  var Component = Ember.Component;
  exports.default = Component.extend({
    errorMessage: {
      type: 'error',
      title: 'Ooops...',
      text: 'Não foi possível carregar as tarefas. Tente novamente.'
    },

    selectedPartnerName: 'FIDC',

    orderedBy: ['order'],

    selectedPipeline: reads('selectedPartnerApplication.pipeline'),

    _pipelineTasksUpdated: _pusher.onEvent(_pusher.fromProperty('_pusherChannelName'), 'tasks-updated', _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              this.set('isReloading', true);

              _context.next = 3;
              return this.reloadPipeline();

            case 3:

              this.set('isReloading', false);

            case 4:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    }))),

    _pusherChannelName: computed('selectedPipeline', 'selectedPipeline.id', function () {
      var pipelineId = this.get('selectedPipeline.id');

      return 'auto-refinancing-pipeline-' + pipelineId;
    }),

    currentTasks: computed('selectedPipeline', function () {
      var pipeline = this.get('selectedPipeline');

      return pipeline.get('tasks') || EmberPromise.resolve([]);
    }),

    selectedPartnerApplication: computed('selectedPartnerName', 'presentPartnerApplications', function () {
      return this.filterSelectedPartner();
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('isReloading', false);
    },
    reloadPipeline: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2() {
        var pa, relationshipLink, pipeline;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _context2.next = 2;
                return this.get('selectedPartnerApplication');

              case 2:
                pa = _context2.sent;
                relationshipLink = pa.belongsTo('pipeline').link();
                _context2.next = 6;
                return pa.get('pipeline');

              case 6:
                pipeline = _context2.sent;
                return _context2.abrupt('return', pipeline.reloadWithoutCache(relationshipLink));

              case 8:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function reloadPipeline() {
        return _ref2.apply(this, arguments);
      }

      return reloadPipeline;
    }(),
    filterSelectedPartner: function filterSelectedPartner() {
      var name = this.get('selectedPartnerName');

      return this.get('presentPartnerApplications').findBy('name', name);
    }
  });
});