define('middle-office/models/engine/home-refi/eligibility-parameters', ['exports', 'ember-data', 'ember-cp-validations', 'ember-cli-bkf-core/enums'], function (exports, _emberData, _emberCpValidations, _enums) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var getOwner = Ember.getOwner;
  var EmberObject = Ember.Object;
  var computed = Ember.computed;


  var RealEstateAddressValidations = (0, _emberCpValidations.buildValidations)({
    zipcode: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      is: 8
    }),

    streetAddress: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 3,
      max: 80
    }),

    streetNumber: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 1,
      max: 5
    }),

    streetComplement: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      max: 80
    }),

    neighborhood: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 3,
      max: 60
    }),

    city: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 2,
      max: 40
    }),

    state: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.stateValues
    })
  });

  var RealEstateAddress = EmberObject.extend(RealEstateAddressValidations, {
    _eligibility: null,

    zipcode: computed.alias('_eligibility.realEstateAddressZipcode'),
    streetAddress: computed.alias('_eligibility.realEstateAddressStreetAddress'),
    streetNumber: computed.alias('_eligibility.realEstateAddressStreetNumber'),
    streetComplement: computed.alias('_eligibility.realEstateAddressStreetComplement'),
    neighborhood: computed.alias('_eligibility.realEstateAddressNeighborhood'),
    city: computed.alias('_eligibility.realEstateAddressCity'),
    state: computed.alias('_eligibility.realEstateAddressState')
  });

  var RequesterValidations = (0, _emberCpValidations.buildValidations)({
    financeDebtDescription: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      max: 80
    }),

    financeDebtMonthsLeft: (0, _emberCpValidations.validator)('number', {
      allowBlank: true,
      gte: 1,
      lte: 360
    }),

    monthlyIncome: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      gte: 0
    }),

    employmentProfessionalStatus: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.professionalStatusValues
    }),

    incomeProof: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.incomeProofValues
    }),

    additionalIncomeType: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.incomeTypeValues
    })
  });

  var Requester = EmberObject.extend(RequesterValidations, {
    _eligibility: null,

    hasFinanceDebts: computed(function () {
      return this.get('financeDebtMonthlyCost') > 0;
    }),
    financeDebtDescription: computed.alias('_eligibility.requesterFinanceDebtDescription'),
    financeDebtMonthlyCost: computed.alias('_eligibility.requesterFinanceDebtMonthlyCost'),
    financeDebtMonthsLeft: computed.alias('_eligibility.requesterFinanceDebtMonthsLeft'),
    fullName: computed.alias('_eligibility.requesterFullName'),
    cpf: computed.alias('_eligibility.requesterCpf'),
    monthlyIncome: computed.alias('_eligibility.requesterMonthlyIncome'),
    employmentProfessionalStatus: computed.alias('_eligibility.requesterEmploymentProfessionalStatus'),
    incomeProof: computed.alias('_eligibility.requesterIncomeProof'),
    additionalIncomeValue: computed.alias('_eligibility.requesterAdditionalIncomeValue'),
    additionalIncomeType: computed.alias('_eligibility.requesterAdditionalIncomeType')
  });

  var SpouseValidations = (0, _emberCpValidations.buildValidations)({
    monthlyIncome: (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      gte: 0
    }),

    employmentProfessionalStatus: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.professionalStatusValues
    }),

    incomeProof: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.incomeProofValues
    }),

    additionalIncomeType: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.incomeTypeValues
    })
  });

  var Spouse = EmberObject.extend(SpouseValidations, {
    _eligibility: null,

    fullName: computed.alias('_eligibility.requesterSpouseFullName'),
    cpf: computed.alias('_eligibility.requesterSpouseCpf'),
    monthlyIncome: computed.alias('_eligibility.requesterSpouseMonthlyIncome'),
    employmentProfessionalStatus: computed.alias('_eligibility.requesterSpouseEmploymentProfessionalStatus'),
    incomeProof: computed.alias('_eligibility.requesterSpouseIncomeProof'),
    additionalIncomeValue: computed.alias('_eligibility.requesterSpouseAdditionalIncomeValue'),
    additionalIncomeType: computed.alias('_eligibility.requesterSpouseAdditionalIncomeType')
  });

  var Validations = (0, _emberCpValidations.buildValidations)({
    purpose: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.purposeValues
    }),

    realEstateType: (0, _emberCpValidations.validator)('inclusion', {
      allowBlank: true,
      in: _enums.realEstateTypeValues
    })
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    purpose: _emberData.default.attr('string'),
    intendedCredit: _emberData.default.attr('number'),
    realEstateEstimatedValue: _emberData.default.attr('number'),
    financingTimeMonths: _emberData.default.attr('number'),
    realEstateType: _emberData.default.attr('string'),
    realEstateDebtsTotalValue: _emberData.default.attr('number'),
    realEstateDebtsCreditorBankNumber: _emberData.default.attr('string'),
    realEstateAddressZipcode: _emberData.default.attr('string'),
    realEstateAddressStreetAddress: _emberData.default.attr('string'),
    realEstateAddressStreetNumber: _emberData.default.attr('string'),
    realEstateAddressStreetComplement: _emberData.default.attr('string'),
    realEstateAddressNeighborhood: _emberData.default.attr('string'),
    realEstateAddressCity: _emberData.default.attr('string'),
    realEstateAddressState: _emberData.default.attr('string'),
    requesterFinanceDebtDescription: _emberData.default.attr('string'),
    requesterFinanceDebtMonthlyCost: _emberData.default.attr('number'),
    requesterFinanceDebtMonthsLeft: _emberData.default.attr('number'),
    requesterFullName: _emberData.default.attr('string'),
    requesterCpf: _emberData.default.attr('string'),
    requesterMonthlyIncome: _emberData.default.attr('number'),
    requesterEmploymentProfessionalStatus: _emberData.default.attr('string'),
    requesterIncomeProof: _emberData.default.attr('string'),
    requesterAdditionalIncomeValue: _emberData.default.attr('number'),
    requesterAdditionalIncomeType: _emberData.default.attr('string'),
    isSpouseAdditionalRequester: _emberData.default.attr('boolean'),
    requesterSpouseFullName: _emberData.default.attr('string'),
    requesterSpouseMonthlyIncome: _emberData.default.attr('number'),
    requesterSpouseEmploymentProfessionalStatus: _emberData.default.attr('string'),
    requesterSpouseIncomeProof: _emberData.default.attr('string'),
    requesterSpouseAdditionalIncomeValue: _emberData.default.attr('number'),
    requesterSpouseAdditionalIncomeType: _emberData.default.attr('string'),

    application: _emberData.default.belongsTo('loan/home-refi/bkf/application'),

    realEstateAddress: computed(function () {
      return RealEstateAddress.create(this.get('_owner'), { _eligibility: this });
    }),

    requester: computed(function () {
      return Requester.create(this.get('_owner'), { _eligibility: this });
    }),

    requesterSpouse: computed(function () {
      return Spouse.create(this.get('_owner'), { _eligibility: this });
    }),

    realEstateHasDebts: computed('realEstateDebtsTotalValue', function () {
      return this.get('realEstateDebtsTotalValue') > 0;
    }),

    _owner: computed(function () {
      return getOwner(this).ownerInjection();
    })
  });
});