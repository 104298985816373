define('middle-office/pods/components/app-login-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var computed = Ember.computed;
  var on = Ember.on;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    auth: service('auth'),

    isLoading: false,

    _bindEvents: on('didInsertElement', function () {
      this.$().form({
        on: 'blur',
        inline: true,
        fields: {
          email: {
            identifier: 'email',
            rules: [{
              type: 'empty',
              prompt: 'E-mail deve ser preenchido'
            }, {
              type: 'minLength[6]',
              prompt: 'Mínimo 6 caractéres'
            }, {
              type: 'email',
              prompt: 'Email inválido'
            }]
          },
          password: {
            identifier: 'password',
            rules: [{
              type: 'empty',
              prompt: 'Senha deve ser preenchida'
            }, {
              type: 'minLength[6]',
              prompt: 'Mínimo 6 carácteres'
            }]
          }
        }
      });
    }),

    loadingClass: computed('isLoading', function () {
      if (this.get('isLoading')) {
        return 'loading';
      }

      return null;
    }),

    actions: {
      authenticate: function () {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(event) {
          var _getProperties, email, password;

          return regeneratorRuntime.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  this.set('isLoading', true);

                  event.preventDefault();

                  _getProperties = this.getProperties('email', 'password'), email = _getProperties.email, password = _getProperties.password;
                  _context.prev = 3;
                  _context.next = 6;
                  return this.get('auth').authenticate(email, password);

                case 6:
                  _context.next = 11;
                  break;

                case 8:
                  _context.prev = 8;
                  _context.t0 = _context['catch'](3);

                  this.set('errorMessage', _context.t0.error || _context.t0);

                case 11:

                  this.set('isLoading', false);

                case 12:
                case 'end':
                  return _context.stop();
              }
            }
          }, _callee, this, [[3, 8]]);
        }));

        function authenticate(_x) {
          return _ref.apply(this, arguments);
        }

        return authenticate;
      }()
    }
  });
});