define('middle-office/initializers/bkf-core', ['exports', 'ember-cli-bkf-core/configuration', 'middle-office/config/environment'], function (exports, _configuration, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'bkf-core',

    initialize: function initialize(app) {
      app.registerOptionsForType('enum', { instantiate: false });

      var config = _environment.default['bkf-core'] || {};
      _configuration.default.load(config);
    }
  };
});