define('middle-office/utils/remove-punctuation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = removePunctuation;
  function removePunctuation(formatted) {
    if (formatted == null || formatted === '') {
      return null;
    }

    var unformatted = formatted.replace(/[\W_]/g, '');
    return unformatted;
  }
});