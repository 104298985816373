define('middle-office/models/servicing/billing/repayment', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;


  var validations = (0, _emberCpValidations.buildValidations)({
    paidAt: (0, _emberCpValidations.validator)('presence', true),
    amount: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      allowBlank: false
    })],
    nossoNumero: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })
  });

  exports.default = Model.extend(validations, {
    paidAt: attr('pure-date'),
    amount: attr('number'),
    nossoNumero: attr('string'),
    installmentId: attr('number'),

    installments: hasMany('servicing/billing/installment')
  });
});