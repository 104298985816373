define('middle-office/pods/components/app-timeline/application-assignment/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var equal = Ember.computed.equal;
  exports.default = Component.extend({
    classNames: ['app-timeline-assignment'],
    attributeBindings: ['data-date'],

    _hasSurpriseReason: equal('record.reason', 'surprise'),
    _hasApplicationCreatedManuallyReason: equal('record.reason', 'application_created_manually')
  });
});