define('middle-office/models/consultant', ['exports', 'ember-cli-bkf-core/models/consultant'], function (exports, _consultant) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _consultant.default;
    }
  });
});