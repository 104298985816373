define('middle-office/pods/components/partner-applications/partials/statuses/closed/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var reads = Ember.computed.reads;
  var Component = Ember.Component;
  exports.default = Component.extend({
    closingLoan: reads('partnerApplication.closingLoan'),
    isComplete: reads('closingLoan.isComplete')
  });
});