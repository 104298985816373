define('middle-office/enums/timeline/interlocutor-type', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.interlocutorType = undefined;
  var interlocutorType = exports.interlocutorType = [{
    text: 'Consultor',
    value: 'consultant'
  }, {
    text: 'Cliente',
    value: 'customer'
  }, {
    text: 'Parceiro',
    value: 'partner'
  }];

  exports.default = interlocutorType;
  var values = exports.values = _lodash.default.map(interlocutorType, 'value');
});