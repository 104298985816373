define('middle-office/pods/components/app-new-password-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var service = Ember.inject.service;
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    auth: service(),
    routing: service(),
    rollbar: service(),

    tagName: 'form',
    classNames: ['login-form', 'ui', 'large', 'form'],

    passwordConfirmation: null,
    password: null,

    passwordRules: computed(function () {
      return [{
        type: 'empty',
        prompt: 'Senha é obrigatória'
      }, {
        type: 'minLength[6]',
        prompt: 'Senha precisa ter no minimo 6 caracteres'
      }];
    }),

    passwordConfirmationRules: computed(function () {
      return [{
        type: 'empty',
        prompt: 'Confirmação de senha é obrigatória'
      }, {
        type: 'match[password]',
        prompt: 'Senha e confirmação de senha devem ser iguais'
      }];
    }),

    showSuccessModal: false,

    confirmationSettings: {
      header: 'Recuperação de senha',
      type: 'approve-only'
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var passwordRules = this.get('passwordRules');
      var passwordConfirmationRules = this.get('passwordConfirmationRules');

      var password = {
        identifier: 'password',
        rules: passwordRules
      };

      var passwordConfirmation = {
        identifier: 'passwordConfirmation',
        rules: passwordConfirmationRules
      };

      var fields = { password: password, passwordConfirmation: passwordConfirmation };

      this.$().form({
        on: 'blur',
        inline: true,
        fields: fields
      });
    },
    submit: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(event) {
        var valid, token, _getProperties, password, auth;

        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                event.preventDefault();

                valid = this.$().form('is valid');

                if (valid) {
                  _context.next = 4;
                  break;
                }

                return _context.abrupt('return');

              case 4:
                token = this.get('queryParams.values.token');
                _getProperties = this.getProperties('password', 'auth'), password = _getProperties.password, auth = _getProperties.auth;
                _context.prev = 6;
                _context.next = 9;
                return auth.resetPassword(token, password);

              case 9:

                this.set('showSuccessModal', true);
                _context.next = 15;
                break;

              case 12:
                _context.prev = 12;
                _context.t0 = _context['catch'](6);

                this.handleErrors(_context.t0);

              case 15:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this, [[6, 12]]);
      }));

      function submit(_x) {
        return _ref.apply(this, arguments);
      }

      return submit;
    }(),


    actions: {
      onApproveModal: function onApproveModal() {
        this.set('showSuccessModal', false);
        this.get('routing').transitionTo('login');
      }
    },

    handleErrors: function handleErrors(exception) {
      var message = {
        type: 'error',
        title: 'Não foi possível trocar a senha',
        text: exception.message
      };
      this.set('errorMessage', message);

      var errors = exception.errors;
      this.get('rollbar').error(message.title, exception, { errors: errors });
    }
  });
});