define('middle-office/initializers/global-promise', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  var EmberPromise = Ember.RSVP.Promise;
  function initialize() {
    window.Promise = EmberPromise;
  }

  exports.default = {
    name: 'global-promise',
    initialize: initialize
  };
});