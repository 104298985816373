define('middle-office/pods/components/app-modal/confirmation/component', ['exports', 'middle-office/pods/components/app-modal/abstract-modal'], function (exports, _abstractModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var isNone = Ember.isNone;
  exports.default = _abstractModal.default.extend({
    modalButtons: computed('type', function () {
      var type = this.get('type');

      if (isNone(type)) return false;

      return 'app-modal/confirmation/' + type;
    }),

    callbackData: null
  });
});