define('middle-office/models/analysis/auto-refi/data/address', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    zipCode: [(0, _emberCpValidations.validator)('length', {
      is: 8,
      allowBlank: true
    })],
    city: [(0, _emberCpValidations.validator)('length', {
      min: 2,
      max: 60,
      allowBlank: true
    })],
    neighborhood: [(0, _emberCpValidations.validator)('length', {
      min: 3,
      max: 60,
      allowBlank: true
    })],
    streetAddress: [(0, _emberCpValidations.validator)('length', {
      min: 3,
      max: 250,
      allowBlank: true
    })],
    streetNumber: [(0, _emberCpValidations.validator)('length', {
      max: 10,
      allowBlank: true
    })],
    streetComplement: [(0, _emberCpValidations.validator)('length', {
      max: 200,
      allowBlank: true
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    fillWith: function fillWith(address) {
      if (address != null) {
        return this.setProperties({
          zipCode: address.get('zipCode'),
          city: address.get('city'),
          state: address.get('state'),
          streetAddress: address.get('streetAddress'),
          streetNumber: address.get('streetNumber'),
          streetComplement: address.get('streetComplement'),
          neighborhood: address.get('neighborhood')
        });
      }
      return null;
    },


    zipCode: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    streetAddress: _emberData.default.attr('string'),
    streetNumber: _emberData.default.attr('number'),
    streetComplement: _emberData.default.attr('string'),
    neighborhood: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date')
  });
});