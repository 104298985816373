define('middle-office/pods/components/page-numbers/component', ['exports', 'ember-cli-pagination/lib/page-items'], function (exports, _pageItems) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;
  var alias = Ember.computed.alias;
  var reads = Ember.computed.reads;


  var PageItemsWithParent = _pageItems.default.extend({
    _parent: null,
    currentPage: reads('_parent.currentPage'),
    totalPages: reads('_parent.totalPages'),
    truncatePages: reads('_parent.truncatePages'),
    numPagesToShow: reads('_parent.numPagesToShow'),
    showFL: reads('_parent.showFL')
  });

  exports.default = Component.extend({
    currentPage: alias('content.page'),
    totalPages: alias('content.totalPages'),
    truncatePages: true,
    numPagesToShow: 5,

    showFL: computed('currentPage', 'totalPages', function () {
      var currentPage = this.get('currentPage');
      var totalPages = this.get('totalPages');
      return totalPages >= currentPage;
    }),

    pageItemsObj: computed(function () {
      return PageItemsWithParent.create({ _parent: this });
    }),

    pageItems: alias('pageItemsObj.pageItems'),

    canStepForward: computed('currentPage', 'totalPages', function () {
      var page = Number(this.get('currentPage'));
      var totalPages = Number(this.get('totalPages'));
      return page < totalPages;
    }),

    canStepBackward: computed('currentPage', function () {
      var page = Number(this.get('currentPage'));
      return page > 1;
    }),

    actions: {
      pageClicked: function pageClicked(number) {
        this.set('currentPage', number);
        this.sendAction('changePage', number);
      },
      incrementPage: function incrementPage() {
        if (this.get('canStepForward')) {
          this.incrementProperty('currentPage');
          this.sendAction('changePage', this.get('currentPage'));
        }
      },
      decrementPage: function decrementPage() {
        if (this.get('canStepBackward')) {
          this.decrementProperty('currentPage');
          this.sendAction('changePage', this.get('currentPage'));
        }
      }
    }
  });
});