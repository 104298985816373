define('middle-office/pods/components/app-textarea/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var on = Ember.on;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: 'textarea',

    classNames: ['app-textarea'],

    attributeBindings: ['value', 'name', 'rows'],

    _setValue: on('change', function () {
      this.set('value', this.element.value);
    })
  });
});