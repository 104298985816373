define('middle-office/pods/components/app-timeline/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var sort = Ember.computed.sort;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['app-timeline'],
    tagName: 'ul',

    timelineSortingDesc: ['createdAt:desc'],
    sortedTimelineDesc: sort('timeline', 'timelineSortingDesc')
  });
});