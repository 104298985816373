define('middle-office/pods/components/app-documents-list/partials/document/component', ['exports', 'lodash', 'middle-office/pods/components/app-documents-list/mixins/file-upload', 'middle-office/mixins/drag-and-drop'], function (exports, _lodash, _fileUpload, _dragAndDrop) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  var equal = Ember.computed.equal;
  var union = Ember.computed.union;
  var filterBy = Ember.computed.filterBy;
  var notEmpty = Ember.computed.notEmpty;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend(_fileUpload.default, _dragAndDrop.default, {
    store: service(),

    classNames: ['document-item', 'item'],
    classNameBindings: ['hasErrors:error'],

    readOnly: equal('document.isIncomplete', false),
    uploadsList: computed(function () {
      return [];
    }),
    status: reads('document.status'),
    isCompletable: reads('document.isCompletable'),

    uploadsProgress: computed('uploadsList.@each.uploaded', function () {
      var uploadsList = this.get('uploadsList');
      var uploadsSize = uploadsList.getEach('uploadSize').filter(_lodash.default.isNumber);
      var uploadsUploaded = uploadsList.getEach('uploaded').filter(_lodash.default.isNumber);
      var uploadsProgress = _lodash.default.sum(uploadsUploaded) * 100 / _lodash.default.sum(uploadsSize);

      return _lodash.default.isNaN(uploadsProgress) ? 0 : uploadsProgress;
    }),

    documentName: computed('document.name', function () {
      var documentName = this.get('document.name');
      var documentNamesEnum = this.get('document.documentNamesEnum');

      var matchingEnum = _lodash.default.find(documentNamesEnum, { value: documentName });

      if (matchingEnum == null) {
        return documentName;
      }

      return matchingEnum.text;
    }),

    hasErrors: notEmpty('errorAttachments'),
    errorAttachments: filterBy('attachments', 'isError', true),
    attachments: union('document.checklistDocuments.[]', 'uploadsList.[]'),

    dragOver: function dragOver(event) {
      this._super(event);

      var enabled = !this.get('readOnly');
      this.set('isDragging', enabled);
    },
    drop: function drop(event) {
      this._super(event);

      if (this.get('readOnly')) return;

      var files = event.dataTransfer.files;
      this.send('filesSelected', files);
    },


    actions: {
      filesSelected: function filesSelected(files) {
        this._startUpload(files);
      },
      deleteAttachment: function deleteAttachment(_document) {
        var uploadsList = this.get('uploadsList');

        if (!_document.get('isError')) {
          this.sendAction('confirmDeletion', _document, uploadsList);
        } else {
          uploadsList.removeObject(_document);
        }
      }
    }
  });
});