define('middle-office/pods/components/app-documents-list/partials/attachment/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  var equal = Ember.computed.equal;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['ui', 'fluid', 'card', 'attachment'],
    classNameBindings: ['uploadedByConsultant:attachment--uploaded-by-consultant'],

    extension: reads('_fileProperty.extension'),
    filename: reads('_fileProperty.filename'),
    isPdf: reads('_fileProperty.type.pdf'),
    isError: reads('attachment.isError'),

    uploadedByConsultant: equal('attachment.uploadedBy', 'consultant'),

    uploader: computed('uploadedByConsultant', function () {
      return this.get('uploadedByConsultant') ? 'consultor' : 'cliente';
    }),

    _fileProperty: computed('attachment.filename', function () {
      var filename = this.get('attachment.filename');
      var lastDot = filename.lastIndexOf('.');

      return {
        filename: filename.substr(0, lastDot),
        extension: filename.substr(lastDot),
        type: {
          pdf: filename.substr(lastDot) === '.pdf'
        }
      };
    })
  });
});