define('middle-office/models/loan/auto-refi/workflow/pipeline', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var computed = Ember.computed;
  var filterBy = computed.filterBy,
      union = computed.union,
      notEmpty = computed.notEmpty;
  exports.default = _emberData.default.Model.extend({
    tasks: _emberData.default.hasMany('loan/auto-refi/workflow/pipeline/task'),
    availableTasks: filterBy('tasks', 'available', true),
    doneTasks: filterBy('availableTasks', 'status', 'done'),
    undoneTasks: filterBy('availableTasks', 'status', 'not_done'),
    reworkTasks: filterBy('availableTasks', 'status', 'rework'),
    doingTasks: filterBy('availableTasks', 'status', 'doing'),

    hasRework: notEmpty('reworkTasks'),
    pendingTasks: union('reworkTasks', 'undoneTasks'),

    doingByTeam: function doingByTeam(team) {
      var doingTasks = this.get('doingTasks');

      return doingTasks.filterBy('team', team);
    },
    reloadWithoutCache: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(path) {
        var modelName, adapter, pipelineId, headers, host, url, payload;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                modelName = this.constructor.modelName;
                adapter = this.store.adapterFor(modelName);
                pipelineId = this.get('id');
                headers = { 'Cache-Control': 'no-cache, no-store, must-revalidate' };
                host = adapter.get('host') || document.location.origin;
                url = new URL(path, host);
                _context.next = 8;
                return adapter.ajax(url, 'GET', { headers: headers });

              case 8:
                payload = _context.sent;

                this.store.pushPayload(payload);

                return _context.abrupt('return', this.store.peekRecord(modelName, pipelineId));

              case 11:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function reloadWithoutCache(_x) {
        return _ref.apply(this, arguments);
      }

      return reloadWithoutCache;
    }()
  });
});