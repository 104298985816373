define("middle-office/pods/main/auto-fin/settings", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var appLayoutConfig = exports.appLayoutConfig = {
    headerMenu: false,
    sideMenu: []
  };

  exports.default = appLayoutConfig;
});