define('middle-office/models/closing/auto-refi/loan', ['exports', 'ember-data', 'ember-cp-validations', 'moment'], function (exports, _emberData, _emberCpValidations, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;
  var equal = computed.equal,
      gt = computed.gt;


  var onlySig = function onlySig() {
    return {
      presence: true,
      disabled: equal('model.sig', false)
    };
  };

  var loanValidations = (0, _emberCpValidations.buildValidations)({
    sig: (0, _emberCpValidations.validator)('presence', true),
    cpf: (0, _emberCpValidations.validator)('presence', true),
    score: (0, _emberCpValidations.validator)('presence', true),
    paidAt: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('date', {
      after: (0, _moment.default)().subtract(6, 'months').format('MM/DD/YYYY'),
      before: (0, _moment.default)().add(1, 'months').format('MM/DD/YYYY')
    })],
    firstInstallmentDueDate: [(0, _emberCpValidations.validator)('date', {
      after: (0, _moment.default)().subtract(6, 'months').format('MM/DD/YYYY'),
      before: (0, _moment.default)().add(6, 'months').format('MM/DD/YYYY')
    })],
    maturity: (0, _emberCpValidations.validator)('presence', true),
    amountClosed: (0, _emberCpValidations.validator)('presence', true),
    customerName: (0, _emberCpValidations.validator)('presence', true),
    monthlyPayment: (0, _emberCpValidations.validator)('presence', true),
    monthlyInterestRate: (0, _emberCpValidations.validator)('presence', true),
    vehicleManufacturingYear: (0, _emberCpValidations.validator)('presence', true),
    totalEffectiveCostMonthly: (0, _emberCpValidations.validator)('presence', true),

    partnerOperationCode: (0, _emberCpValidations.validator)('presence', onlySig()),
    appraisalValue: (0, _emberCpValidations.validator)('presence', onlySig()),
    iof: (0, _emberCpValidations.validator)('presence', onlySig()),
    tac: (0, _emberCpValidations.validator)('presence', onlySig()),
    ccbCost: (0, _emberCpValidations.validator)('presence', onlySig()),
    ccbNumber: [(0, _emberCpValidations.validator)('presence', onlySig()), (0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      is: 9
    })],
    amount: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      gt: 1999.99
    })]
  });

  exports.default = Model.extend(loanValidations, {
    preFill: function preFill(partner, bkfApplication, requester) {
      this.setProperties({
        returnValue: bkfApplication.get('returnValue'),
        insuranceValue: bkfApplication.get('insuranceValue'),
        score: bkfApplication.get('serasaScoreRange'),
        customerName: requester.get('fullName'),
        cpf: requester.get('cpf'),
        vehicleManufacturingYear: bkfApplication.get('vehicleManufacturingYear'),
        sig: partner.get('sig') || false,
        partnerName: partner.get('name'),
        paidAt: new Date()
      });
    },


    amount: attr('number'),
    amountClosed: attr('number'),
    maturity: attr('number'),
    monthlyInterestRate: attr('number'),
    monthlyPayment: attr('number'),
    appraisalValue: attr('number'),
    returnValue: attr('string'),
    insuranceValue: attr('number'),
    partnerOperationCode: attr('string'),
    ccbNumber: attr('string'),
    paidAt: attr('pure-date'),
    cpf: attr('string'),
    customerName: attr('string'),
    score: attr('string'),
    vehicleManufacturingYear: attr('number'),
    sig: attr('boolean'),
    totalEffectiveCostMonthly: attr('number'),
    tac: attr('number'),
    iof: attr('number'),
    ccbCost: attr('number'),
    firstInstallmentDueDate: attr('pure-date'),
    partnerName: attr('string'),
    isFidc: equal('partnerName', 'FIDC'),

    isComplete: gt('amount', 0)
  });
});