define('middle-office/enums/loan/auto-refi/bkf/return-value', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.returnValue = undefined;
  var returnValue = exports.returnValue = [{
    text: 'R0',
    value: 'R0'
  }, {
    text: 'R1',
    value: 'R1'
  }, {
    text: 'R2',
    value: 'R2'
  }, {
    text: 'R3',
    value: 'R3'
  }];

  exports.default = returnValue;
  var values = exports.values = _lodash.default.map(returnValue, 'value');
});