define('middle-office/pods/servicing/home-loan/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var alias = Ember.computed.alias;
  exports.default = Controller.extend({
    loan: alias('model')
  });
});