define('middle-office/pods/components/app-forms/application/auto-refi/steps/professional/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var map = Ember.computed.map;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['professional-data', 'form-step'],

    employments: map('person.employments', function (employment, index) {
      return EmberObject.create({
        count: index + 1,
        model: employment
      });
    }),

    actions: {
      updateCompanyAddress: function updateCompanyAddress(employment) {
        employment.handleCompanyAddress();
      }
    }
  });
});