define('middle-office/pods/components/application-modal/auto-refi/revive/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var reads = Ember.computed.reads;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['revive-modal'],
    application: reads('model.application'),
    discard: reads('application.discard'),
    isSaving: reads('discard.isSaving')
  });
});