define('middle-office/pods/components/partner-applications/partials/partner-application/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var debounce = Ember.run.debounce;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['content'],

    classNameBindings: ['isActive:active'],

    component: service(),

    partnerApplicationService: service('auto-refi/partner-applications'),

    feedbackMessage: null,

    currentStatusComponentName: computed('partnerApplication.statusFlow', function () {
      var statusFlow = this.get('partnerApplication.statusFlow');

      return 'partner-applications/partials/statuses/' + statusFlow;
    }),

    currentStatusComponentExists: computed('currentStatusComponentName', function () {
      var componentName = this.get('currentStatusComponentName');

      return this.get('component').componentExists(componentName);
    }),

    feedbackStatusClass: computed('partnerApplicationService.status', function () {
      var partnerApplicationService = this.get('partnerApplicationService');
      if (partnerApplicationService.get('isSaved')) {
        return 'success';
      } else if (partnerApplicationService.get('isError')) {
        return 'error negative';
      }

      return null;
    }),

    _setFeedbackMessage: function _setFeedbackMessage(message) {
      this.set('feedbackMessage', message);
      debounce(this, '_resetFeedbackMessage', 3500);
    },
    _resetFeedbackMessage: function _resetFeedbackMessage() {
      this.set('feedbackMessage', null);
    },


    actions: {
      submitPartnerApplication: function () {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(statusFlow, subStatus) {
          var partnerApplication, partnerApplicationService;
          return regeneratorRuntime.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  partnerApplication = this.get('partnerApplication');
                  _context.next = 3;
                  return partnerApplication.changeStatus(statusFlow, subStatus);

                case 3:
                  _context.next = 5;
                  return this.get('partnerApplicationService').save(partnerApplication);

                case 5:
                  partnerApplicationService = this.get('partnerApplicationService');

                  if (partnerApplicationService.get('isSaved')) {
                    this._setFeedbackMessage('Status atualizado com sucesso!');
                  }

                  if (partnerApplicationService.get('isError')) {
                    this._setFeedbackMessage('Falha ao alterar o status');
                    this.get('partnerApplication').rollbackAttributes();
                  }

                case 8:
                case 'end':
                  return _context.stop();
              }
            }
          }, _callee, this);
        }));

        function submitPartnerApplication(_x, _x2) {
          return _ref.apply(this, arguments);
        }

        return submitPartnerApplication;
      }()
    }
  });
});