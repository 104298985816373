define('middle-office/pods/components/partner-applications/partials/statuses/no-closed/component', ['exports', 'ember-cli-bkf-core/utils/translate-from-enum'], function (exports, _translateFromEnum) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var reads = Ember.computed.reads;
  var Component = Ember.Component;
  exports.default = Component.extend({
    rejectionReason: (0, _translateFromEnum.default)('underwriting/auto-refi/rejection-reasons', 'partnerApplication.rejectionReason'),

    rejectionReasonText: reads('rejectionReason')
  });
});