define('middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/loan-type', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  function loanType() {
    return 'Modalidade do Empréstimo:\n( ) Refinanciamento\n( ) Financiamento\n( ) IQ\n  - Banco do Financiamento:\n    ( ) DDA\n    ( )Boleto';
  }

  exports.default = loanType;
});