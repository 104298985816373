define('middle-office/pods/main/auto-refi/partner-applications/route', ['exports', 'middle-office/pods/main/auto-refi/partner-applications/query-params'], function (exports, _queryParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var all = Ember.RSVP.all;
  var service = Ember.inject.service;
  var Route = Ember.Route;
  exports.default = Route.extend({
    rollbar: service(),
    queryParams: _queryParams.route,

    _extractFilters: function _extractFilters(params) {
      var statusFlow = params.statusFlow,
          creditSituation = params.creditSituation,
          isFromPromoter = params.isFromPromoter,
          removeDiscardedApplications = params.removeDiscardedApplications,
          committee = params.committee;


      return {
        'status-flow': statusFlow,
        'credit-approval-situation': creditSituation,
        'is-from-promoter': isFromPromoter,
        'remove-discarded-applications': removeDiscardedApplications,
        committee: committee
      };
    },
    loadRelationships: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(partnerApplication) {
        var bkfApplication;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return partnerApplication.get('sentBranch');

              case 2:
                _context.next = 4;
                return partnerApplication.get('bkfApplication');

              case 4:
                bkfApplication = _context.sent;
                _context.next = 7;
                return bkfApplication.get('requester');

              case 7:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function loadRelationships(_x) {
        return _ref.apply(this, arguments);
      }

      return loadRelationships;
    }(),
    extractQueryParams: function extractQueryParams(params) {
      var offset = (params.page - 1) * params.perPage;
      var filter = this._extractFilters(params);
      var sort = params.sortBy.toString();

      return {
        page: {
          offset: offset,
          limit: params.perPage
        },
        filter: filter,
        sort: sort
      };
    },
    model: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(params) {
        var partnerApplications, partner, query;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                partnerApplications = [];
                partner = params.partner;
                query = this.extractQueryParams(params);
                _context2.prev = 3;
                _context2.next = 6;
                return this.store.query('underwriting/auto-refi/' + partner + '/application', query);

              case 6:
                partnerApplications = _context2.sent;
                _context2.next = 9;
                return all(partnerApplications.map(this.loadRelationships));

              case 9:
                _context2.next = 15;
                break;

              case 11:
                _context2.prev = 11;
                _context2.t0 = _context2['catch'](3);

                Ember.Logger.error(_context2.t0);
                this.get('rollbar').error(new Error('Failure while loading the partner application list (partner:' + partner + ')'), {
                  errorEvent: _context2.t0
                });

              case 15:
                _context2.prev = 15;
                return _context2.abrupt('return', partnerApplications);

              case 18:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this, [[3, 11, 15, 18]]);
      }));

      function model(_x2) {
        return _ref2.apply(this, arguments);
      }

      return model;
    }()
  });
});