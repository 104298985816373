define('middle-office/pods/components/app-timeline/lead-created/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  var and = Ember.computed.and;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    dialer: service(),

    classNames: ['app-timeline-lead-created'],
    attributeBindings: ['data-date'],

    showDialer: and('dialer.isEnabled'),

    lead: reads('record'),
    customer: reads('record.customer'),
    simulation: reads('record.application.chosenSimulation.simulation'),

    actions: {
      dial: function dial() {
        var phoneCode = this.get('customer.contactCellphoneCode');
        var phone = this.get('customer.contactCellphone');

        this.get('dialer').dial(phoneCode, phone);
      }
    },

    _totalValue: computed('simulation.installmentAmount', 'simulation.financingTimeMonths', function () {
      return this.get('simulation.installmentAmount') * this.get('simulation.financingTimeMonths');
    }),

    _interestRate: computed('simulation.interestRate', function () {
      return this.get('simulation.interestRate') * 100.00;
    }),

    _calculationMethod: computed('simulation.calculationMethod', function () {
      var calculationMethod = this.get('simulation.calculationMethod') || '';
      return calculationMethod.toUpperCase();
    }),

    _fullCustomerCellphone: computed('customer.contactCellphone', 'customer.contactCellphoneCode', function () {
      return ['0', this.get('customer.contactCellphoneCode'), this.get('customer.contactCellphone')].join('');
    }),

    _hasChosenSimulation: computed('simulation.content', function () {
      return this.get('simulation.content') != null;
    })
  });
});