define('middle-office/pods/components/app-forms/partner-applications/reanalysis/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['ui', 'content'],
    maxLength: 256,
    required: true,

    actions: {
      onSave: function onSave() {
        var reanalysisComment = this.get('reanalysisComment');
        var partnerApplication = this.get('partnerApplication');

        if (reanalysisComment) {
          partnerApplication.set('reanalysisComment', reanalysisComment);
          this.sendAction('on-save');
        } else {
          this.set('errorMessage', {
            type: 'error',
            title: 'Ooops...',
            text: 'O campo "Comentário" deve ser preenchido'
          });
        }
      }
    }
  });
});