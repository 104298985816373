define('middle-office/services/auth', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var bind = Ember.run.bind;
  var get = Ember.get;
  var observer = Ember.observer;
  var getOwner = Ember.getOwner;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var PromiseProxyMixin = Ember.PromiseProxyMixin;
  var ObjectProxy = Ember.ObjectProxy;


  var UserProxy = ObjectProxy.extend(PromiseProxyMixin);

  exports.default = Service.extend({
    session: service(),
    rollbar: service(),
    store: service(),

    user: null,

    fetchUser: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
        var adapter, host, userPayload, store, normalizedPayload;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                adapter = getOwner(this).lookup('adapter:application');
                host = get(adapter, 'host');
                _context.next = 4;
                return adapter.ajax(host + '/me', 'GET');

              case 4:
                userPayload = _context.sent;
                store = this.get('store');
                normalizedPayload = store.normalize('crm/consultant', userPayload.data);
                return _context.abrupt('return', store.push(normalizedPayload));

              case 8:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function fetchUser() {
        return _ref.apply(this, arguments);
      }

      return fetchUser;
    }(),
    authenticate: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(identification, password) {
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                return _context2.abrupt('return', this.get('session').authenticate('authenticator:bkf-oauth2-consultant', identification, password));

              case 1:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function authenticate(_x, _x2) {
        return _ref2.apply(this, arguments);
      }

      return authenticate;
    }(),
    requestReset: function requestReset(email) {
      var type = 'crm consultants password-resets';
      var attributes = { email: email };

      return this._adapterRequest('/crm/consultants/password-resets', 'POST', type, attributes);
    },
    resetPassword: function resetPassword(token, password) {
      var type = 'crm consultants password-resets';
      var attributes = { new_password: password };

      return this._adapterRequest('/crm/consultants/password-resets/' + token, 'PATCH', type, attributes);
    },
    logout: function logout() {
      this.get('session').invalidate();
    },


    _userDidAuthenticateObserver: observer('session.isAuthenticated', function () {
      this._userDidAuthenticate();
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this._userDidAuthenticate();
    },
    _userDidLoad: function _userDidLoad(user) {
      this.get('rollbar').configure({
        payload: {
          person: {
            id: user.get('id'),
            email: user.get('email')
          }
        }
      });
    },
    _userRejected: function _userRejected() {
      if (this.get('user.isRejected')) {
        this.get('rollbar').warning('Consultant logged out', this.get('user.reason'));

        this.logout();
      }
    },
    _userDidAuthenticate: function _userDidAuthenticate() {
      if (!this.get('session.isAuthenticated')) {
        return;
      }

      var userProxy = UserProxy.create({ promise: this.fetchUser() });
      userProxy.then(bind(this, '_userDidLoad'), bind(this, '_userRejected'));

      this.set('user', userProxy);
    },
    _adapterRequest: function () {
      var _ref3 = _asyncToGenerator(regeneratorRuntime.mark(function _callee3(url, verb, type, attributes) {
        var adapter, host, payload, request;
        return regeneratorRuntime.wrap(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                adapter = getOwner(this).lookup('adapter:application');
                host = get(adapter, 'host');
                payload = { data: { data: { type: type, attributes: attributes } } };
                _context3.next = 5;
                return adapter.ajax('' + host + url, verb, payload);

              case 5:
                request = _context3.sent;
                return _context3.abrupt('return', request);

              case 7:
              case 'end':
                return _context3.stop();
            }
          }
        }, _callee3, this);
      }));

      function _adapterRequest(_x3, _x4, _x5, _x6) {
        return _ref3.apply(this, arguments);
      }

      return _adapterRequest;
    }()
  });
});