define('middle-office/pods/main/auto-refi/leads/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    queryParams: ['search'],
    search: null
  });
});