define('middle-office/pods/components/app-status-display/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: 'span',

    classNames: ['app-status-display', 'ui', 'basic', 'label'],

    classNameBindings: ['_colorStatus'],

    _colorStatus: computed('value', function () {
      return this.get('value') ? 'green' : 'red';
    })
  });
});