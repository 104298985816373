define('middle-office/pods/components/app-forms/application/auto-refi/credit-defense/defense-template', ['exports', 'middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/professional', 'middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/divider', 'middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/personal', 'middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/income', 'middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/eligibility', 'middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/credit-reason', 'middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/loan-type', 'middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/relevant-info'], function (exports, _professional, _divider, _personal, _income, _eligibility, _creditReason, _loanType, _relevantInfo) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (application, partialsToBeJoined) {
    var partials = partialsToBeJoined || defaultPartials;

    return partials.map(function (partial) {
      return partial(application);
    }).join('\n\n');
  };

  var defaultPartials = [_loanType.default, _eligibility.default, _divider.default, _relevantInfo.default, _divider.default, _personal.default, _divider.default, _professional.default, _divider.default, _income.default, _divider.default, _creditReason.default];
});