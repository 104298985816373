define('middle-office/pods/components/partner-applications/partials/statuses/submission/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var equal = Ember.computed.equal;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ['rejection-form'],
    maxLength: 256,
    rejectionReason: null,
    rejectionDescription: null,
    feedbackMessage: null,
    showValidationErrors: false,
    validationErrors: [],

    rollbar: service(),
    changeRequests: service('auto-refi/application-change-requests'),

    isFidc: equal('partnerApplication.name', 'FIDC'),

    validateOperationCode: function validateOperationCode() {
      if (!this.get('isFidc')) {
        return true;
      }

      var partnerApplication = this.get('partnerApplication');
      var operationCode = partnerApplication.get('partnerOperationCode');

      var isValid = operationCode != null && operationCode.trim() > 0;

      if (!isValid) {
        this.get('validationErrors').setObjects([{
          message: 'Preencha esse campo para continuar'
        }]);
      }

      return isValid;
    },


    actions: {
      sendToApproval: function sendToApproval() {
        var isValid = this.validateOperationCode();

        if (isValid) {
          this.sendAction('on-save', 'approval');
        }
      },
      returnToPreparation: function returnToPreparation() {
        return this.get('changeRequests').createChangeRequest(this.get('partnerApplication'));
      },
      onChange: function onChange(value) {
        this.set('partnerApplication.partnerOperationCode', value);
        this.get('validationErrors').clear();
      }
    }
  });
});