define('middle-office/pods/components/app-forms/application/home-refi/steps/real-estate/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    store: service('store'),

    classNames: ['real-estate-data', 'form-step'],

    showInCondominium: computed('application.realEstateType', function () {
      return this._isHouseOrLand(this.get('application.realEstateType'));
    }),

    _isHouseOrLand: function _isHouseOrLand(realEstateType) {
      return ['house', 'land'].includes(realEstateType);
    },


    actions: {
      updateInCondominium: function updateInCondominium(realEstateType) {
        if (!this._isHouseOrLand(realEstateType)) {
          this.set('application.inCondominium', null);
        }
      },
      updateRealEstateOwner: function () {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(check) {
          var realEstateOwner, requester;
          return regeneratorRuntime.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  if (!check) {
                    _context.next = 2;
                    break;
                  }

                  return _context.abrupt('return');

                case 2:
                  _context.next = 4;
                  return this.get('application.realEstateOwner');

                case 4:
                  realEstateOwner = _context.sent;
                  _context.next = 7;
                  return this.get('application.requester');

                case 7:
                  requester = _context.sent;


                  if (!realEstateOwner || realEstateOwner === requester) {
                    this.set('application.realEstateOwner', this.get('store').createRecord('loan/home-refi/bkf/person'));
                  }

                case 9:
                case 'end':
                  return _context.stop();
              }
            }
          }, _callee, this);
        }));

        function updateRealEstateOwner(_x) {
          return _ref.apply(this, arguments);
        }

        return updateRealEstateOwner;
      }()
    }
  });
});