define('middle-office/pods/components/auto-refi/lead-card/create-application-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    store: service(),

    tagName: 'button',
    classNames: ['ui', 'compact', 'labeled', 'icon', 'button', 'creditas-colored', 'create-application'],
    classNameBindings: ['loading', 'loading:disabled'],

    click: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
        var application;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                if (!this.get('loading')) {
                  _context.next = 2;
                  break;
                }

                return _context.abrupt('return');

              case 2:
                application = this.get('store').createRecord('loan/auto-refi/bkf/application', {
                  lead: this.get('lead')
                });


                this.set('loading', true);

                _context.prev = 4;
                _context.next = 7;
                return application.save();

              case 7:
                this.sendAction('on-application-created', application);
                _context.next = 13;
                break;

              case 10:
                _context.prev = 10;
                _context.t0 = _context['catch'](4);

                this.sendAction('on-error', _context.t0);

              case 13:
                _context.prev = 13;

                this.set('loading', false);
                return _context.finish(13);

              case 16:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this, [[4, 10, 13, 16]]);
      }));

      function click() {
        return _ref.apply(this, arguments);
      }

      return click;
    }()
  });
});