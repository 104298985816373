define('middle-office/pods/home-refi/application-form/service', ['exports', 'middle-office/services/application-form'], function (exports, _applicationForm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var isNone = Ember.isNone;
  var hash = Ember.RSVP.hash;
  exports.default = _applicationForm.default.extend({
    modelDescription: 'loan home-refi bkf application',

    saveModel: function saveModel(application) {
      return this.saveApplication(application);
    },
    saveSameRealEstateOwnerRequester: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(application, requester) {
        var realEstateOwner;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return application.get('realEstateOwner');

              case 2:
                realEstateOwner = _context.sent;

                if (!application.get('isSameRealEstateOwnerRequester')) {
                  _context.next = 7;
                  break;
                }

                application.set('realEstateOwner', requester);
                _context.next = 9;
                break;

              case 7:
                _context.next = 9;
                return this.saveRequester(realEstateOwner);

              case 9:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function saveSameRealEstateOwnerRequester(_x, _x2) {
        return _ref.apply(this, arguments);
      }

      return saveSameRealEstateOwnerRequester;
    }(),
    saveAdditionalRequester: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(application) {
        var response, additionalRequester, otherAdditionalRequester;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                response = [];

                if (!application.get('hasAdditionalRequester')) {
                  _context2.next = 8;
                  break;
                }

                _context2.next = 4;
                return application.get('additionalRequester');

              case 4:
                additionalRequester = _context2.sent;


                response.push(this.saveRequester(additionalRequester));
                _context2.next = 9;
                break;

              case 8:
                application.set('additionalRequester', null);

              case 9:
                if (!application.get('hasOtherAdditionalRequester')) {
                  _context2.next = 16;
                  break;
                }

                _context2.next = 12;
                return application.get('otherAdditionalRequester');

              case 12:
                otherAdditionalRequester = _context2.sent;


                response.push(this.saveRequester(otherAdditionalRequester));
                _context2.next = 17;
                break;

              case 16:
                application.set('otherAdditionalRequester', null);

              case 17:
                return _context2.abrupt('return', Promise.all(response));

              case 18:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function saveAdditionalRequester(_x3) {
        return _ref2.apply(this, arguments);
      }

      return saveAdditionalRequester;
    }(),
    saveAllPartnerApplications: function () {
      var _ref3 = _asyncToGenerator(regeneratorRuntime.mark(function _callee3(application) {
        var pas, requests;
        return regeneratorRuntime.wrap(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                _context3.next = 2;
                return hash(application.getProperties('chbApplication', 'bariguiApplication', 'bancoPanApplication', 'chpApplication', 'fapaApplication'));

              case 2:
                pas = _context3.sent;
                requests = this.saveApplications(pas);
                return _context3.abrupt('return', Promise.all(requests));

              case 5:
              case 'end':
                return _context3.stop();
            }
          }
        }, _callee3, this);
      }));

      function saveAllPartnerApplications(_x4) {
        return _ref3.apply(this, arguments);
      }

      return saveAllPartnerApplications;
    }(),
    saveApplications: function saveApplications(applications) {
      var _this = this;

      var requests = [];

      Array.from(Object.entries(applications)).forEach(function (_ref4) {
        var _ref5 = _slicedToArray(_ref4, 2),
            applicationName = _ref5[0],
            application = _ref5[1];

        if (!isNone(application)) {
          var saveFunction = _this.savePartnerApplication({ applicationName: applicationName, application: application });

          requests.push(saveFunction);
        }
      });

      return requests;
    },
    saveRealStateAddress: function () {
      var _ref6 = _asyncToGenerator(regeneratorRuntime.mark(function _callee4(application) {
        var realEstateAddress;
        return regeneratorRuntime.wrap(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                _context4.next = 2;
                return application.get('realEstateAddress');

              case 2:
                realEstateAddress = _context4.sent;
                return _context4.abrupt('return', this.saveAddress(realEstateAddress));

              case 4:
              case 'end':
                return _context4.stop();
            }
          }
        }, _callee4, this);
      }));

      function saveRealStateAddress(_x5) {
        return _ref6.apply(this, arguments);
      }

      return saveRealStateAddress;
    }(),
    saveApplication: function () {
      var _ref7 = _asyncToGenerator(regeneratorRuntime.mark(function _callee5(application) {
        var requester;
        return regeneratorRuntime.wrap(function _callee5$(_context5) {
          while (1) {
            switch (_context5.prev = _context5.next) {
              case 0:
                _context5.next = 2;
                return application.get('requester');

              case 2:
                requester = _context5.sent;
                _context5.next = 5;
                return this.saveRequester(requester);

              case 5:

                this.saveSameRealEstateOwnerRequester(application, requester);

                _context5.next = 8;
                return this.saveAdditionalRequester(application);

              case 8:
                _context5.next = 10;
                return this.saveAllPartnerApplications(application);

              case 10:
                _context5.next = 12;
                return this.saveRealStateAddress(application);

              case 12:
                _context5.next = 14;
                return application.save();

              case 14:
                return _context5.abrupt('return', application.belongsTo('formStatus').reload());

              case 15:
              case 'end':
                return _context5.stop();
            }
          }
        }, _callee5, this);
      }));

      function saveApplication(_x6) {
        return _ref7.apply(this, arguments);
      }

      return saveApplication;
    }(),
    setFinanceAttrs: function setFinanceAttrs(requester) {
      if (!requester.get('hasFinanceDebts')) {
        requester.set('financeDebtDescription', null);
        requester.set('financeDebtMonthlyCost', null);
        requester.set('financeDebtMonthsLeft', null);
      }
    },
    setAdditionalIncomeAttrs: function setAdditionalIncomeAttrs(requester) {
      if (!requester.get('hasAdditionalIncome')) {
        requester.set('additionalIncomeType', null);
        requester.set('additionalIncomeDescription', null);
        requester.set('additionalIncomeValue', null);
      }
    },
    getRequesterInfos: function () {
      var _ref8 = _asyncToGenerator(regeneratorRuntime.mark(function _callee6(requester) {
        var residenceAddress, mailingAddress, currentEmployment, spouse;
        return regeneratorRuntime.wrap(function _callee6$(_context6) {
          while (1) {
            switch (_context6.prev = _context6.next) {
              case 0:
                _context6.next = 2;
                return requester.get('residenceAddress');

              case 2:
                residenceAddress = _context6.sent;
                _context6.next = 5;
                return requester.get('mailingAddress');

              case 5:
                mailingAddress = _context6.sent;
                _context6.next = 8;
                return requester.get('currentEmployment');

              case 8:
                currentEmployment = _context6.sent;
                _context6.next = 11;
                return requester.get('spouse');

              case 11:
                spouse = _context6.sent;
                return _context6.abrupt('return', {
                  residenceAddress: residenceAddress,
                  mailingAddress: mailingAddress,
                  currentEmployment: currentEmployment,
                  spouse: spouse
                });

              case 13:
              case 'end':
                return _context6.stop();
            }
          }
        }, _callee6, this);
      }));

      function getRequesterInfos(_x7) {
        return _ref8.apply(this, arguments);
      }

      return getRequesterInfos;
    }(),
    saveMailingAddress: function saveMailingAddress(requester, _ref9) {
      var mailingAddress = _ref9.mailingAddress,
          residenceAddress = _ref9.residenceAddress;

      var response = void 0;

      if (requester.get('isSameResidenceMailingAddress')) {
        requester.set('mailingAddress', residenceAddress);
      } else {
        response = this.saveAddress(mailingAddress);
      }

      return response;
    },
    savePreviousEmployment: function () {
      var _ref10 = _asyncToGenerator(regeneratorRuntime.mark(function _callee7(requester) {
        var response, previousEmployment;
        return regeneratorRuntime.wrap(function _callee7$(_context7) {
          while (1) {
            switch (_context7.prev = _context7.next) {
              case 0:
                response = void 0;

                if (!requester.get('hasPreviousEmployment')) {
                  _context7.next = 8;
                  break;
                }

                _context7.next = 4;
                return requester.get('previousEmployment');

              case 4:
                previousEmployment = _context7.sent;


                response = this.saveEmployment(previousEmployment);
                _context7.next = 9;
                break;

              case 8:
                response = requester.set('previousEmployment', null);

              case 9:
                return _context7.abrupt('return', response);

              case 10:
              case 'end':
                return _context7.stop();
            }
          }
        }, _callee7, this);
      }));

      function savePreviousEmployment(_x8) {
        return _ref10.apply(this, arguments);
      }

      return savePreviousEmployment;
    }(),
    saveAdditionalPreviousEmployment: function () {
      var _ref11 = _asyncToGenerator(regeneratorRuntime.mark(function _callee8(requester) {
        var response, additionalPreviousEmployment;
        return regeneratorRuntime.wrap(function _callee8$(_context8) {
          while (1) {
            switch (_context8.prev = _context8.next) {
              case 0:
                response = void 0;

                if (!(requester.get('hasPreviousEmployment') && requester.get('hasAdditionalPreviousEmployment'))) {
                  _context8.next = 8;
                  break;
                }

                _context8.next = 4;
                return requester.get('additionalPreviousEmployment');

              case 4:
                additionalPreviousEmployment = _context8.sent;


                response = this.saveEmployment(additionalPreviousEmployment);
                _context8.next = 9;
                break;

              case 8:
                response = requester.set('additionalPreviousEmployment', null);

              case 9:
                return _context8.abrupt('return', response);

              case 10:
              case 'end':
                return _context8.stop();
            }
          }
        }, _callee8, this);
      }));

      function saveAdditionalPreviousEmployment(_x9) {
        return _ref11.apply(this, arguments);
      }

      return saveAdditionalPreviousEmployment;
    }(),
    setMarritalStatus: function setMarritalStatus(requester) {
      if (requester.get('maritalStatus') !== 'married') {
        requester.set('regimen', null);
      }
    },
    setRequesterInfos: function setRequesterInfos(requester) {
      this.setFinanceAttrs(requester);
      this.setAdditionalIncomeAttrs(requester);
      this.setMarritalStatus(requester);
    },
    saveRequesterInfos: function () {
      var _ref12 = _asyncToGenerator(regeneratorRuntime.mark(function _callee9(requester) {
        var infos, response;
        return regeneratorRuntime.wrap(function _callee9$(_context9) {
          while (1) {
            switch (_context9.prev = _context9.next) {
              case 0:
                _context9.next = 2;
                return this.getRequesterInfos(requester);

              case 2:
                infos = _context9.sent;
                response = [];


                response.push(this.saveAddress(infos.residenceAddress));
                response.push(this.saveMailingAddress(requester, infos));
                response.push(this.saveSpouse(infos.spouse));
                response.push(this.saveEmployment(infos.currentEmployment));
                response.push(this.savePreviousEmployment(requester));
                response.push(this.saveAdditionalPreviousEmployment(requester));

                return _context9.abrupt('return', Promise.all(response));

              case 11:
              case 'end':
                return _context9.stop();
            }
          }
        }, _callee9, this);
      }));

      function saveRequesterInfos(_x10) {
        return _ref12.apply(this, arguments);
      }

      return saveRequesterInfos;
    }(),
    saveRequester: function () {
      var _ref13 = _asyncToGenerator(regeneratorRuntime.mark(function _callee10(requester) {
        return regeneratorRuntime.wrap(function _callee10$(_context10) {
          while (1) {
            switch (_context10.prev = _context10.next) {
              case 0:
                if (!(requester == null)) {
                  _context10.next = 2;
                  break;
                }

                return _context10.abrupt('return', requester);

              case 2:

                this.setRequesterInfos(requester);

                _context10.next = 5;
                return this.saveRequesterInfos(requester);

              case 5:
                return _context10.abrupt('return', requester.save());

              case 6:
              case 'end':
                return _context10.stop();
            }
          }
        }, _callee10, this);
      }));

      function saveRequester(_x11) {
        return _ref13.apply(this, arguments);
      }

      return saveRequester;
    }(),
    saveSpouse: function () {
      var _ref14 = _asyncToGenerator(regeneratorRuntime.mark(function _callee11(spouse) {
        var currentEmployment;
        return regeneratorRuntime.wrap(function _callee11$(_context11) {
          while (1) {
            switch (_context11.prev = _context11.next) {
              case 0:
                if (!(spouse == null)) {
                  _context11.next = 2;
                  break;
                }

                return _context11.abrupt('return', spouse);

              case 2:
                _context11.next = 4;
                return spouse.get('currentEmployment');

              case 4:
                currentEmployment = _context11.sent;
                _context11.next = 7;
                return this.saveEmployment(currentEmployment);

              case 7:
                return _context11.abrupt('return', spouse.save());

              case 8:
              case 'end':
                return _context11.stop();
            }
          }
        }, _callee11, this);
      }));

      function saveSpouse(_x12) {
        return _ref14.apply(this, arguments);
      }

      return saveSpouse;
    }(),
    saveEmployment: function () {
      var _ref15 = _asyncToGenerator(regeneratorRuntime.mark(function _callee12(employment) {
        var companyAddress;
        return regeneratorRuntime.wrap(function _callee12$(_context12) {
          while (1) {
            switch (_context12.prev = _context12.next) {
              case 0:
                if (!(employment == null)) {
                  _context12.next = 2;
                  break;
                }

                return _context12.abrupt('return', employment);

              case 2:
                _context12.next = 4;
                return employment.get('companyAddress');

              case 4:
                companyAddress = _context12.sent;

                if (!(companyAddress != null)) {
                  _context12.next = 8;
                  break;
                }

                _context12.next = 8;
                return this.saveAddress(companyAddress);

              case 8:
                return _context12.abrupt('return', employment.save());

              case 9:
              case 'end':
                return _context12.stop();
            }
          }
        }, _callee12, this);
      }));

      function saveEmployment(_x13) {
        return _ref15.apply(this, arguments);
      }

      return saveEmployment;
    }(),
    saveAddress: function saveAddress(address) {
      if (address == null) {
        return address;
      }

      return address.save();
    },
    savePartnerApplication: function savePartnerApplication(_ref16) {
      var applicationName = _ref16.applicationName,
          application = _ref16.application;

      if (applicationName === 'fapaApplication') {
        return this.saveFapaPartnerApplication(application);
      }

      return application.save();
    },
    setRelatedPublicAgentOrPepAttrs: function setRelatedPublicAgentOrPepAttrs(fapaApplication) {
      if (!fapaApplication.get('relatedPublicAgentOrPep')) {
        fapaApplication.set('relatedPublicAgentOrPepName', null);
        fapaApplication.set('relatedPublicAgentOrPepCpf', null);
      }
    },
    setMultipleCitizenshipAttrs: function setMultipleCitizenshipAttrs(fapaApplication) {
      if (!fapaApplication.get('multipleCitizenship')) {
        fapaApplication.set('multipleCitizenshipReason', null);
      }
    },
    setAdressAbroadAttrs: function setAdressAbroadAttrs(fapaApplication) {
      if (!fapaApplication.get('addressAbroad')) {
        fapaApplication.set('addressAbroadCountry', null);
        fapaApplication.set('addressAbroadPhone', null);
        fapaApplication.set('addressAbroadFullAddress', null);
      }
    },
    setPermanentResidencyAttrs: function setPermanentResidencyAttrs(fapaApplication) {
      if (!fapaApplication.get('permanentResidency')) {
        fapaApplication.set('permanentResidencyCountry', null);
      }
    },
    setAbroadAttrs: function setAbroadAttrs(fapaApplication) {
      if (!fapaApplication.get('abroadOneMonthForTheLastYear')) {
        fapaApplication.set('abroadCountry', null);
        fapaApplication.set('usaAbroadDaysCurrentYear', null);
        fapaApplication.set('usaAbroadDaysFirstYearPriorToCurrent', null);
        fapaApplication.set('usaAbroadDaysSecondYearPriorToCurrent', null);
      }

      if (fapaApplication.get('abroadCountry') !== 'Estados Unidos') {
        fapaApplication.set('usaAbroadDaysCurrentYear', null);
        fapaApplication.set('usaAbroadDaysFirstYearPriorToCurrent', null);
        fapaApplication.set('usaAbroadDaysSecondYearPriorToCurrent', null);
      }
    },
    setRenoucedCitizenshipAttrs: function setRenoucedCitizenshipAttrs(fapaApplication) {
      if (!fapaApplication.get('renouncedCitizenship')) {
        fapaApplication.set('renouncedCitizenshipReason', null);
      }
    },
    setShareholdingAttrs: function setShareholdingAttrs(fapaApplication) {
      if (!fapaApplication.get('hasShareholding')) {
        fapaApplication.set('shareholdingCompanyName', null);
        fapaApplication.set('shareholdingCompanyTaxpayerRegistry', null);
        fapaApplication.set('shareholdingCompanyNationality', null);
        fapaApplication.set('shareholdingPercentage', null);
      }
    },
    setAdditionalShareholdingAttrs: function setAdditionalShareholdingAttrs(fapaApplication) {
      if (!fapaApplication.get('hasShareholding') || !fapaApplication.get('hasAdditionalShareholding')) {
        fapaApplication.set('additionalShareholdingCompanyName', null);
        fapaApplication.set('additionalShareholdingCompanyTaxpayerRegistry', null);
        fapaApplication.set('additionalShareholdingCompanyNationality', null);
        fapaApplication.set('additionalShareholdingPercentage', null);
      }
    },
    setPoliticalExposedPersonAttrs: function setPoliticalExposedPersonAttrs(fapaApplication) {
      if (!fapaApplication.get('bkfApplication.requester.politicallyExposedPerson')) {
        fapaApplication.set('politicallyExposedPersonReason', null);
      }
    },
    saveFapaPartnerApplication: function saveFapaPartnerApplication(fapaApplication) {
      this.setRelatedPublicAgentOrPepAttrs(fapaApplication);
      this.setMultipleCitizenshipAttrs(fapaApplication);
      this.setAdressAbroadAttrs(fapaApplication);
      this.setPermanentResidencyAttrs(fapaApplication);
      this.setAbroadAttrs(fapaApplication);
      this.setRenoucedCitizenshipAttrs(fapaApplication);
      this.setShareholdingAttrs(fapaApplication);
      this.setAdditionalShareholdingAttrs(fapaApplication);
      this.setPoliticalExposedPersonAttrs(fapaApplication);

      return fapaApplication.save();
    }
  });
});