define('middle-office/pods/components/tri-toggle/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['tri-toggle'],
    classNameBindings: ['disabled', 'doing', 'done'],
    toDo: true,
    doing: false,
    done: false,
    disabled: false
  });
});