define('middle-office/pods/components/app-forms/application/auto-fin/partials/floating-section/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var notEmpty = Ember.computed.notEmpty;
  var reads = Ember.computed.reads;
  exports.default = Component.extend({
    classNames: ['auto-refi-floating-section'],

    hasError: notEmpty('_commentErrors'),
    maxCommentSize: reads('_validation.options.length.max'),

    _validation: reads('application.validations.attrs.comment'),
    _commentErrors: reads('_validation.messages')
  });
});