define('middle-office/services/auto-refi/person-preparation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var all = Ember.RSVP.all;
  var map = Ember.RSVP.map;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    store: service(),

    prepareRequester: function prepareRequester(requester) {
      return all([this._prepareAddresses(requester), this._prepareEmployments(requester)]);
    },
    _prepareAddresses: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(person) {
        var store, residenceAddress;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                store = this.get('store');
                _context.next = 3;
                return person.get('residenceAddress');

              case 3:
                residenceAddress = _context.sent;


                if (residenceAddress == null) {
                  person.set('residenceAddress', store.createRecord('loan/auto-refi/bkf/address'));
                }

              case 5:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function _prepareAddresses(_x) {
        return _ref.apply(this, arguments);
      }

      return _prepareAddresses;
    }(),
    _prepareEmployments: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(person) {
        var store, employments, employment;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                store = this.get('store');
                _context2.next = 3;
                return person.get('employments');

              case 3:
                employments = _context2.sent;


                if (employments.length < 1) {
                  employment = store.createRecord('loan/auto-refi/bkf/employment', { person: person });

                  employments.pushObject(employment);
                }

                return _context2.abrupt('return', map(employments.toArray(), function (employment) {
                  return employment.handleCompanyAddress();
                }));

              case 6:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function _prepareEmployments(_x2) {
        return _ref2.apply(this, arguments);
      }

      return _prepareEmployments;
    }()
  });
});