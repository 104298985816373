define('middle-office/utils/mailto', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = mailTo;
  function mailTo(config) {
    var url = new URL('mailto:' + config.to);
    var carbonCopies = config.cc || [];

    carbonCopies.forEach(function (carbonCopy) {
      url.searchParams.append('cc', carbonCopy);
    });

    url.searchParams.append('subject', config.subject);
    url.searchParams.append('body', config.body);

    return url.toString();
  }
});