define('middle-office/instance-initializers/feature-flag', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(_ref) {
    var application = _ref.application;

    var featureFlag = application.mdoFeatureFlag;
    Ember.Controller.reopen({
      featureFlag: featureFlag
    });

    Ember.Component.reopen({
      featureFlag: featureFlag
    });
  }

  exports.default = {
    name: 'feature-flag',
    initialize: initialize
  };
});