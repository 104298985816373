define('middle-office/pods/components/partner-applications/partials/statuses/flow/step/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['step'],

    classNameBindings: ['active'],

    active: computed('partnerApplicationsStatus', function () {
      var step = this.get('step');

      return step === this.get('partnerApplicationsStatus');
    })
  });
});