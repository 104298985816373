define('middle-office/pods/components/app-documents-list-accordion/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['app-documents-list'],

    actions: {
      confirmDeletion: function confirmDeletion(document, uploadsList) {
        this.sendAction('confirmDeletion', document, uploadsList);
      }
    }
  });
});