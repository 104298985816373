define('middle-office/services/home-refi/person-preparation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var all = Ember.RSVP.all;
  var hash = Ember.RSVP.hash;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    store: service(),

    prepareRequester: function prepareRequester(requester) {
      return all([this._prepareAddresses(requester), this._prepareEmployment(requester), this._prepareRequesterSpouse(requester)]);
    },
    prepareSpouse: function prepareSpouse(spouse) {
      return all([this._prepareAddresses(spouse), this._prepareEmployment(spouse)]);
    },
    _prepareRequesterSpouse: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(requester) {
        var spouse;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return requester.get('spouse');

              case 2:
                spouse = _context.sent;

                if (!(spouse != null)) {
                  _context.next = 6;
                  break;
                }

                _context.next = 6;
                return this.prepareSpouse(spouse);

              case 6:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function _prepareRequesterSpouse(_x) {
        return _ref.apply(this, arguments);
      }

      return _prepareRequesterSpouse;
    }(),
    _prepareAddresses: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(person) {
        var store, _ref3, residenceAddress, mailingAddress;

        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                store = this.get('store');
                _context2.next = 3;
                return hash(person.getProperties('residenceAddress', 'mailingAddress'));

              case 3:
                _ref3 = _context2.sent;
                residenceAddress = _ref3.residenceAddress;
                mailingAddress = _ref3.mailingAddress;


                if (residenceAddress == null) {
                  person.set('residenceAddress', store.createRecord('loan/home-refi/bkf/address'));
                }

                if (!(mailingAddress == null)) {
                  _context2.next = 13;
                  break;
                }

                _context2.t0 = person;
                _context2.next = 11;
                return person.get('residenceAddress');

              case 11:
                _context2.t1 = _context2.sent;

                _context2.t0.set.call(_context2.t0, 'mailingAddress', _context2.t1);

              case 13:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function _prepareAddresses(_x2) {
        return _ref2.apply(this, arguments);
      }

      return _prepareAddresses;
    }(),
    _prepareEmployment: function () {
      var _ref4 = _asyncToGenerator(regeneratorRuntime.mark(function _callee3(person) {
        var store, employment;
        return regeneratorRuntime.wrap(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                store = this.get('store');
                _context3.next = 3;
                return person.get('currentEmployment');

              case 3:
                employment = _context3.sent;


                if (employment == null) {
                  employment = store.createRecord('loan/home-refi/bkf/employment');
                  person.set('currentEmployment', employment);
                }

                _context3.next = 7;
                return employment.handleCompanyAddress();

              case 7:
              case 'end':
                return _context3.stop();
            }
          }
        }, _callee3, this);
      }));

      function _prepareEmployment(_x3) {
        return _ref4.apply(this, arguments);
      }

      return _prepareEmployment;
    }()
  });
});