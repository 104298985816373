define('middle-office/pods/admin/users/edit/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      return this.store.peekRecord('crm/consultant', params.user_id);
    },
    deactivate: function deactivate() {
      this._super();
      this.currentModel.rollbackAttributes();
    }
  });
});