define('middle-office/pods/auto-refi/partners', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    BV: {
      hasInstallmentValue: false,

      hasReturnValue: false,

      hasInsuranceValue: false
    },

    FIDC: {
      hasInstallmentValue: true,

      hasReturnValue: true,

      hasInsuranceValue: true,

      interestTable: _lodash.default.range(175, 801).map(function (i) {
        return 'Bkf' + i;
      })
    },

    'Banco Pan': {
      hasInstallmentValue: true,

      hasReturnValue: true,

      hasInsuranceValue: true,

      interestTable: ['81966', '81967', '81968', '81969', '82094', '82100']
    },

    Santana: {
      hasInstallmentValue: false,

      hasReturnValue: true,

      hasInsuranceValue: true,

      interestTable: ['VL4010', 'VL4020', 'VL4030', 'VL4040', 'VL4050', 'VL4060', 'VLA010', 'VLA020', 'VLA030', 'VLA040', 'VLA050', 'VLA060', 'VLE491', 'VLE492', 'VLE493', 'VLE494', 'VLE495', 'VLE496', 'VAP491', 'VAP492', 'VAP493', 'VAP494', 'VAP495', 'VAP496', 'VLP491', 'VLP492', 'VLP493', 'VLP494', 'VLP495', 'VLP496', 'VPE491', 'VPE492', 'VPE493', 'VPE494', 'VPE495', 'VPE496']
    }
  };
});