define('middle-office/pods/components/app-check/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var equal = Ember.computed.equal;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['app-check', 'ui', 'checkbox'],

    classNameBindings: ['isRadio:radio'],

    attributeBindings: ['type'],

    tagName: ['span'],

    type: 'checkbox',

    isRadio: equal('type', 'radio')
  });
});