define('middle-office/enums/loan/auto-refi/bkf/financing-time-months', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.financingTimeMonths = undefined;
  var financingTimeMonths = exports.financingTimeMonths = [{
    text: '6',
    value: 6
  }, {
    text: '12',
    value: 12
  }, {
    text: '18',
    value: 18
  }, {
    text: '24',
    value: 24
  }, {
    text: '30',
    value: 30
  }, {
    text: '36',
    value: 36
  }, {
    text: '42',
    value: 42
  }, {
    text: '48',
    value: 48
  }, {
    text: '54',
    value: 54
  }, {
    text: '60',
    value: 60
  }];

  exports.default = financingTimeMonths;
  var values = exports.values = _lodash.default.map(financingTimeMonths, 'value');
});