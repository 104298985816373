define('middle-office/pods/auto-refi/inspections/list/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var sort = Ember.computed.sort;
  var notEmpty = Ember.computed.notEmpty;
  var filterBy = Ember.computed.filterBy;
  var computed = Ember.computed;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    headers: computed(function () {
      return ['Data do Agendamento', 'Parceiro', 'Status', ''];
    }),
    inspectionsOrder: computed(function () {
      return ['current:desc', 'date:desc'];
    }),

    approvedInspections: filterBy('model.inspections', 'status', 'approved'),
    hasApprovedInspections: notEmpty('approvedInspections'),

    scheduledInspections: filterBy('model.inspections', 'status', 'scheduled'),
    hasScheduledInspections: notEmpty('scheduledInspections'),

    sortedInpections: sort('model.inspections', 'inspectionsOrder')
  });
});