define('middle-office/pods/components/app-file-upload/selection-upload/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['selection-upload'],
    layout: Ember.HTMLBars.template({
      "id": "OdSx6LVs",
      "block": "{\"symbols\":[],\"statements\":[[0,\"\\n    \"],[1,[25,\"app-button\",null,[[\"classNames\",\"click\",\"name\",\"text\"],[\"primary\",[25,\"action\",[[19,0,[]],\"onButtonClick\"],null],\"app-file-upload-button\",[19,0,[\"text\"]]]]],false],[0,\"\\n    \\n\"],[4,\"if\",[[19,0,[\"isMultiple\"]]],null,{\"statements\":[[0,\"      \"],[6,\"input\"],[9,\"type\",\"file\"],[10,\"onchange\",[25,\"action\",[[19,0,[]],\"onFilesSelected\"],null],null],[10,\"accept\",[18,\"formatList\"],null],[9,\"multiple\",\"\"],[9,\"hidden\",\"\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[6,\"input\"],[9,\"type\",\"file\"],[10,\"onchange\",[25,\"action\",[[19,0,[]],\"onFilesSelected\"],null],null],[10,\"accept\",[18,\"formatList\"],null],[9,\"hidden\",\"\"],[7],[8],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"]],\"hasEval\":false}",
      "meta": {}
    }),
    attributeBindings: ['dataUploader:data-uploader'],

    acceptedFormats: computed(function () {
      return [];
    }),
    dataUploader: true,
    isMultiple: false,

    formatList: computed('acceptedFormats', function () {
      var acceptedFormats = this.get('acceptedFormats');
      var formatList = acceptedFormats.join(',');

      return formatList;
    }),

    actions: {
      onButtonClick: function onButtonClick() {
        var fileInput = this.$('input');

        fileInput.click();
      },
      onFilesSelected: function onFilesSelected(_ref2) {
        var target = _ref2.target;
        var files = target.files;


        this.sendAction('onFilesSelected', files);

        target.value = null;
      }
    }
  });
});