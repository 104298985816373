define('middle-office/models/servicing/billing/installment', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var equal = Ember.computed.equal;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;


  var isOverdue = function isOverdue(comparison, base) {
    var baseDate = (0, _moment.default)(base);
    var comparisonDate = (0, _moment.default)(comparison);

    return comparisonDate.diff(baseDate, 'days') > 0;
  };

  exports.default = Model.extend({
    createdAt: attr('date'),
    updatedAt: attr('date'),
    dueDate: attr('pure-date'),
    pmt: attr('number'),
    amortization: attr('number'),
    interestAmount: attr('number'),
    mip: attr('number'),
    dfi: attr('number'),
    fee: attr('number'),
    latePaymentInterest: attr('number'),
    latePaymentPenalty: attr('number'),
    latePaymentRemuneratoryInterest: attr('number'),
    paidAt: attr('pure-date'),
    number: attr('number'),
    pmtInflationAdjusted: attr('number'),
    combinedAmount: attr('number'),
    feeInflationAdjusted: attr('number'),
    presentValue: attr('number'),
    remainingBalance: attr('number'),
    referenceIndex: attr('number'),

    status: computed('dueDate', 'paidAt', function () {
      var paidAt = this.get('paidAt');
      var dueDate = this.get('dueDate');

      if (paidAt) return 'paid';

      return isOverdue(new Date(), dueDate) ? 'overdue' : 'open';
    }),
    isPaid: equal('status', 'paid'),

    loan: belongsTo('servicing/onboarding/loan', { polymorphic: true }),
    repayments: hasMany('servicing/billing/repayment')
  });
});