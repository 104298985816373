define('middle-office/utils/address-description', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fullAddressDescription = fullAddressDescription;
  exports.simplifiedAddressDescription = simplifiedAddressDescription;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function fullAddressDescription(address) {
    if (address == null) {
      return null;
    }

    var streetAddress = address.get('streetAddress');
    var streetNumber = address.get('streetNumber');
    var streetComplement = address.get('streetComplement');
    var zipCode = address.get('zipcode');
    var city = address.get('city');
    var state = address.get('state');

    return _concatNonBlanks(streetAddress, [streetNumber, function () {
      return ', ' + streetNumber;
    }], [streetComplement, function () {
      return ' - ' + streetComplement;
    }], [zipCode, function () {
      return ' - ' + zipCode;
    }], [city, function () {
      return ', ' + city;
    }], [state, function () {
      return '/' + state;
    }]);
  }

  function simplifiedAddressDescription(address) {
    if (address == null) {
      return null;
    }

    var streetAddress = address.get('streetAddress');
    var streetNumber = address.get('streetNumber');
    var zipCode = address.get('zipcode');

    return _concatNonBlanks(streetAddress, [streetNumber, function () {
      return ', ' + streetNumber;
    }], [zipCode, function () {
      return ' - ' + zipCode;
    }]);
  }

  function _concatNonBlanks(initial) {
    if (initial == null || initial.trim() === '') {
      return null;
    }

    for (var _len = arguments.length, pairs = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      pairs[_key - 1] = arguments[_key];
    }

    return pairs.reduce(function (description, _ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          value = _ref2[0],
          compute = _ref2[1];

      if (value != null && value.trim() !== '') {
        return description + compute();
      }

      return description;
    }, initial);
  }
});