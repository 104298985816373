define('middle-office/pods/components/app-documents-list/partials/file-upload/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['file-upload'],
    attributeBindings: ['dataUploader:data-uploader'],
    dataUploader: true,

    actions: {
      onButtonClick: function onButtonClick() {
        var fileInput = this.$('input');
        fileInput.click();
      },
      onFileSelected: function onFileSelected(event) {
        var files = event.target.files;
        if (files.length > 0) {
          this.sendAction('on-files-selected', files);
        }
      }
    }
  });
});