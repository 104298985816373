define('middle-office/pods/admin/auto-refi/branches/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model() {
      var parent = this.modelFor('admin.auto-refi.branches');

      return parent;
    }
  });
});