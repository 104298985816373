define('middle-office/utils/analysis/auto-refi/data/credit-reference-visitor', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  exports.default = EmberObject.extend({
    visited: null,

    init: function init() {
      this.set('visited', {});
    },
    visitApplication: function visitApplication(application) {
      var creditReference = this.get('creditReference');
      creditReference.fillWithApplication(application);
      this.get('visited').application = application;
    },
    visitRequester: function visitRequester(requester) {
      var creditReference = this.get('creditReference');
      creditReference.fillWithRequester(requester);
      this.get('visited').requester = requester;
    },
    visitFirstEmployment: function visitFirstEmployment(employment) {
      var creditReference = this.get('creditReference');
      creditReference.fillWithEmployment(employment);
      this.get('visited').firstEmployment = employment;
    },
    visitProfessionalAddress: function visitProfessionalAddress(address) {
      var creditProfessionalAddress = this.get('creditProfessionalAddress');
      creditProfessionalAddress.fillWith(address);
      this.get('visited').professionalAddress = address;
    },
    visitResidenceAddress: function visitResidenceAddress(address) {
      var creditReference = this.get('creditReference');
      var creditResidenceAddress = this.get('creditResidenceAddress');
      creditReference.fillWithAddress(address);
      creditResidenceAddress.fillWith(address);
      this.get('visited').residenceAddress = address;
    }
  });
});