define('middle-office/pods/components/app-dropdown/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var isNone = Ember.isNone;
  var bind = Ember.run.bind;
  var debounce = Ember.run.debounce;
  var or = Ember.computed.or;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var getWithDefault = Ember.getWithDefault;
  var computed = Ember.computed;
  var get = Ember.get;
  exports.default = Component.extend({
    textKey: 'text',
    valueKey: 'value',

    store: service('store'),
    auth: service('auth'),

    isSearch: true,
    classNames: ['app-dropdown', 'ui', 'dropdown', 'selection'],
    classNameBindings: ['isSearch:search'],

    defaultText: 'Selecione um item',

    _searchOptions: or('search-options', 'searchOptions'),

    semanticUiSearch: computed('_searchOptions', function () {
      var _this = this;

      var store = this.get('store');
      var _searchOptions = this.get('_searchOptions');

      return function () {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(settings, callback) {
          var query, items, queryOptions, records;
          return regeneratorRuntime.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  query = settings.urlData.query;
                  items = [];

                  if (!(query.length >= 3)) {
                    _context.next = 9;
                    break;
                  }

                  queryOptions = {};

                  queryOptions['filter[' + _searchOptions.filterBy + ']'] = query;

                  _context.next = 7;
                  return store.query(_searchOptions.model, queryOptions);

                case 7:
                  records = _context.sent;


                  items = records.map(function (item) {
                    return {
                      name: item.get(_searchOptions.textKey),
                      value: item.get(_searchOptions.valueKey)
                    };
                  });

                case 9:

                  callback({ results: items || [] });

                case 10:
                case 'end':
                  return _context.stop();
              }
            }
          }, _callee, _this);
        }));

        return function (_x, _x2) {
          return _ref.apply(this, arguments);
        };
      }();
    }),

    didInsertElement: function didInsertElement() {
      var dropdownOptions = {};

      dropdownOptions.onChange = bind(this, '_onChange');
      dropdownOptions.message = { noResults: 'Nenhum resultado' };

      if (this.get('_searchOptions')) {
        var responseAsync = this.get('semanticUiSearch');
        dropdownOptions.apiSettings = { responseAsync: responseAsync };
      }

      this._dropdown(dropdownOptions);
    },
    didRender: function didRender() {
      debounce(this, '_setSelectedOnSemantic', 10);
    },
    willDestroyElement: function willDestroyElement() {
      this._dropdown('destroy');
    },
    findItem: function findItem(items, valueKey, selectedValue) {
      var selectedItem = items.find(function (item) {
        var value = getWithDefault(item, valueKey, '');

        if (typeof value !== 'string') {
          value = JSON.stringify(value);
        }

        return value.trim() === selectedValue.trim();
      });

      return isNone(selectedItem) ? null : get(selectedItem, valueKey);
    },
    getSelectedItem: function getSelectedItem(_ref2) {
      var selectedValue = _ref2.selectedValue;

      var valueKey = this.get('valueKey');
      var items = this.get('items');
      var selected = void 0;

      if (items.length > 0) {
        selected = this.findItem(items, valueKey, selectedValue);
      } else {
        selected = selectedValue === 'null' ? null : selectedValue;
      }

      return selected;
    },
    _onChange: function _onChange(selectedValue) {
      var selected = this.getSelectedItem({ selectedValue: selectedValue });
      var fnChange = this.get('on-change') || this.get('onChange') || function () {};
      var oldSelected = this.get('selected');

      fnChange.call(this, selected);

      var newSelected = this.get('selected');

      // just set selected if it was not set by the onChange function
      if (oldSelected !== newSelected) {
        return;
      }

      this.set('selected', selected);
      this.sendAction('after-change');
    },
    _setSelectedOnSemantic: function _setSelectedOnSemantic() {
      var selected = this.get('selected');
      this._dropdown('set selected', selected);
    },
    _dropdown: function _dropdown() {
      var $el = this.$();

      if ($el != null && typeof $el.dropdown === 'function') {
        return $el.dropdown.apply($el, arguments);
      }

      return null;
    }
  });
});