define('middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/personal', ['exports', 'middle-office/enums/auto-refi/rounded-up-time-ranges', 'middle-office/enums/auto-refi/marital-statuses'], function (exports, _roundedUpTimeRanges, _maritalStatuses) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function ageByBirthDate(birthDate) {
    var ageInMilisseconds = new Date() - new Date(birthDate);
    var milissecondsInAYear = 60 * 60 * 24 * 365 * 1000;

    return parseInt(ageInMilisseconds / milissecondsInAYear, 10);
  }

  function humanizedStableUnion(stableUnion) {
    return stableUnion ? ' , vive em união estável' : '';
  }

  function humanizedResidenceOwner(residenceOwner) {
    return residenceOwner ? 'própria' : 'não própria';
  }

  function humanizedUnionTime(maritalStatus, stableUnion) {
    var isMarried = maritalStatus === 'married';
    return isMarried || stableUnion ? ' há [NÚMERO DE ANOS] anos' : '';
  }

  function personal(application) {
    var requester = application.requester;
    var fullName = requester.fullName;
    var age = ageByBirthDate(requester.birthDate);
    var maritalStatus = (0, _maritalStatuses.textForMaritalStatus)(requester.maritalStatus);
    var stableUnion = humanizedStableUnion(requester.stableUnion);
    var residenceOwner = humanizedResidenceOwner(requester.residenceOwner);
    var timeOfResidence = (0, _roundedUpTimeRanges.textForTimeRange)(requester.residenceAddress.timeOfResidence);
    var unionTime = humanizedUnionTime(requester.maritalStatus, requester.stableUnion);

    return 'Cliente ' + fullName + ' possui ' + age + ' anos, \xE9 ' + maritalStatus + stableUnion + unionTime + ' e mora em resid\xEAncia ' + residenceOwner + ' h\xE1 ' + timeOfResidence + '.';
  }

  exports.default = personal;
});