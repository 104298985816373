define('middle-office/pods/main/auto-refi/submission-queue/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  var equal = Ember.computed.equal;
  exports.default = Controller.extend({
    queue: reads('model'),

    queueIsEmpty: equal('queue.size', 0),

    buttonMessage: computed('queueIsEmpty', 'queue.size', function () {
      if (this.get('queueIsEmpty')) {
        return 'Fila vazia';
      }

      var size = this.get('queue.size');
      return 'Novo cadastro na fila (' + size + ')';
    }),

    actions: {
      getFirstSubmission: function () {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
          var submission, application;
          return regeneratorRuntime.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  this.set('_pullFailed', false);

                  _context.next = 3;
                  return this.get('queue').belongsTo('firstSubmission').reload();

                case 3:
                  submission = _context.sent;

                  if (!(submission == null)) {
                    _context.next = 7;
                    break;
                  }

                  this.set('_pullFailed', true);
                  return _context.abrupt('return');

                case 7:
                  _context.next = 9;
                  return submission.get('application');

                case 9:
                  application = _context.sent;


                  this.transitionToRoute('auto-refi.partner-applications', application.get('id'), { queryParams: { partner: submission.get('partnerName') } });

                case 11:
                case 'end':
                  return _context.stop();
              }
            }
          }, _callee, this);
        }));

        function getFirstSubmission() {
          return _ref.apply(this, arguments);
        }

        return getFirstSubmission;
      }(),
      refreshCount: function refreshCount() {
        this.set('_pullFailed', false);
        return this.get('queue').reload();
      }
    }
  });
});