define('middle-office/pods/auto-refi/documents/route', ['exports', 'middle-office/pods/auto-refi/document-mixin'], function (exports, _documentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var hash = Ember.RSVP.hash;
  var Route = Ember.Route;
  exports.default = Route.extend(_documentMixin.default, {
    model: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
        var application, requester, guarantor, bkfDocuments, requesterDocuments, guarantorDocuments;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                application = this.modelFor('auto-refi').application;
                _context.next = 3;
                return application.get('requester');

              case 3:
                requester = _context.sent;
                _context.next = 6;
                return application.get('guarantor');

              case 6:
                guarantor = _context.sent;
                bkfDocuments = this._loadDocuments(application);
                requesterDocuments = this._loadDocuments(requester);
                guarantorDocuments = this._loadDocuments(guarantor);
                return _context.abrupt('return', hash({
                  application: application,
                  requester: requester,
                  guarantor: guarantor,
                  bkfDocuments: bkfDocuments,
                  requesterDocuments: requesterDocuments,
                  guarantorDocuments: guarantorDocuments
                }));

              case 11:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function model() {
        return _ref.apply(this, arguments);
      }

      return model;
    }()
  });
});