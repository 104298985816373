define('middle-office/pods/components/app-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: 'button',
    classNames: ['ui', 'button', 'nowrap', 'app-button'],
    classNameBindings: ['isDisabled:disabled:active', 'isLoading:loading:active'],
    attributeBindings: ['data-test'],

    isLoading: false,
    isDisabled: false,

    click: function click(event) {
      this.sendAction('onClick', event);
    }
  });
});