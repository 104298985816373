define('middle-office/pods/components/app-forms/application/auto-refi/requester/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var equal = Ember.computed.equal;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['requester'],

    isGuarantor: equal('elementId', 'guarantor'),

    actions: {
      deleteGuarantor: function deleteGuarantor() /* guarantor */{
        this.set('application.guarantor', null);
      }
    }
  });
});