define('middle-office/pods/auto-refi/document-mixin', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Mixin = Ember.Mixin;
  var all = Ember.RSVP.all;
  var resolve = Ember.RSVP.resolve;
  var isNone = Ember.isNone;
  exports.default = Mixin.create({
    _loadDocuments: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(model) {
        var documents, nestedAttachments;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                if (!isNone(model)) {
                  _context.next = 2;
                  break;
                }

                return _context.abrupt('return', resolve());

              case 2:
                _context.next = 4;
                return model.get('documents');

              case 4:
                _context.t0 = _context.sent;

                if (_context.t0) {
                  _context.next = 7;
                  break;
                }

                _context.t0 = [];

              case 7:
                documents = _context.t0;
                _context.next = 10;
                return all(documents.getEach('attachments'));

              case 10:
                nestedAttachments = _context.sent;
                _context.next = 13;
                return all((0, _lodash.default)(nestedAttachments).map(function (rel) {
                  return rel.toArray();
                }).flatten().value());

              case 13:
                return _context.abrupt('return', documents);

              case 14:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function _loadDocuments(_x) {
        return _ref.apply(this, arguments);
      }

      return _loadDocuments;
    }()
  });
});