define('middle-office/services/context-headers', ['exports', 'ember-cli-bkf-core/headers-container'], function (exports, _headersContainer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;


  var Headers = (0, _headersContainer.buildHeadersContainer)({
    'x-bkf-auto-refi-application': {
      property: 'autoRefiApplicationId',
      resetOnNull: true
    }
  });

  exports.default = Service.extend(Headers);
});