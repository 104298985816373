define('middle-office/models/loan/auto-refi/bkf/assignment', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var reads = Ember.computed.reads;


  var TimelineEvent = EmberObject.extend({
    type: 'application-assignment',
    createdAt: reads('_model.createdAt'),
    model: reads('_model'),

    _model: null
  });

  exports.default = _model.default.extend({
    previousConsultant: (0, _relationships.belongsTo)('crm/consultant'),
    currentConsultant: (0, _relationships.belongsTo)('crm/consultant'),
    bkfApplication: (0, _relationships.belongsTo)('loan/auto-refi/bkf/application'),

    reason: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('date'),

    toTimelineEvent: function toTimelineEvent() {
      return TimelineEvent.create({ _model: this });
    }
  });
});