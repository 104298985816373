define('middle-office/models/crm/consultant', ['exports', 'ember-data', 'ember-cli-bkf-core/models/consultant', 'ember-cp-validations'], function (exports, _emberData, _consultant, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var Validations = (0, _emberCpValidations.buildValidations)({
    fullName: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      regex: /\s/,
      message: 'O nome deve ser completo'
    })],
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
      type: 'email',
      min: 6,
      max: 100,
      regex: /@creditas\.com\.br$/,
      message: 'O email deve ter o domínio "creditas.com.br"'
    })],
    password: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 6,
      message: 'A sua senha deve ter no mínimo 6 caracteres'
    })],
    confirmedPassword: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: 'As senhas não batem'
    })],
    extension_number: [(0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      max: 4,
      message: 'O ramal do consultor deve ter no máximo 4 dígitos'
    })]
  });

  exports.default = _consultant.default.extend(Validations, {
    autoRefiPortfolio: _emberData.default.hasMany('loan/auto-refi/bkf/application'),
    autoRefiTasks: _emberData.default.hasMany('loan/auto-refi/workflow/pipeline/task'),

    _createUrl: function _createUrl(_ref) {
      var page = _ref.page,
          perPage = _ref.perPage,
          type = _ref.type,
          statusFlow = _ref.statusFlow,
          discarded = _ref.discarded,
          host = _ref.host;

      var offset = (page - 1) * perPage;

      var url = new URL(this.hasMany(type).link(), host);
      url.searchParams.append('page[offset]', offset);
      url.searchParams.append('page[limit]', perPage);
      statusFlow.forEach(function (status) {
        return url.searchParams.append('filter[status-flow][]', status);
      });
      discarded.forEach(function (discard) {
        return url.searchParams.append('filter[discarded][]', discard);
      });

      return url;
    },
    getPortfolio: function getPortfolio(args) {
      var _this = this;

      var modelName = this.constructor.modelName;
      var adapter = this.store.adapterFor(modelName);
      var serializer = this.store.serializerFor(modelName);

      var host = adapter.get('host') || document.location.origin;
      var url = this._createUrl(_extends({}, args, { host: host }));

      return adapter.ajax(url, 'GET').then(function (payload) {
        var normalized = serializer.normalizeArrayResponse(_this.store, modelName, payload);
        return {
          data: _this.store.push(normalized),
          meta: payload.meta
        };
      });
    }
  });
});