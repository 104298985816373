define('middle-office/models/crm/auto-refi/lead', ['exports', 'ember-data', 'ember-cli-bkf-core/models/crm/auto-refi/lead', 'lodash'], function (exports, _emberData, _lead, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  var filterBy = computed.filterBy;


  var CreationEvent = EmberObject.extend({
    type: 'lead-created',

    model: null,
    createdAt: reads('model.createdAt')
  });

  exports.default = _lead.default.extend({
    records: _emberData.default.hasMany('timeline/auto-refi/record'),
    contacts: filterBy('records', 'type', 'contact'),
    registries: filterBy('records', 'type', 'registry'),
    successfulContacts: filterBy('contacts', 'contactResult', 'success'),
    lastSuccessfulContact: computed('successfulContacts.@each.createdAt', function () {
      var successfulContacts = this.get('successfulContacts').toArray();
      return _lodash.default.maxBy(successfulContacts, function (contact) {
        return contact.get('createdAt');
      });
    }),

    toTimelineEvent: function toTimelineEvent() {
      return CreationEvent.create({ model: this });
    }
  });
});