define('middle-office/pods/components/app-modal/confirmation/delete/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    callbackData: null,

    actions: {
      onCancel: function onCancel() {
        this.sendAction('onDeny', this.get('callbackData'));
      },
      onDelete: function onDelete() {
        this.sendAction('onApprove', this.get('callbackData'));
      }
    }
  });
});