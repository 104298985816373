define('middle-office/enums/auto-refi/vehicle-category', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.vehicleCategory = undefined;
  var vehicleCategory = exports.vehicleCategory = [{
    text: 'Pesado',
    value: 'heavyweight'
  }, {
    text: 'Leve ou utilitário',
    value: 'lightweight_or_utility'
  }];

  exports.default = vehicleCategory;
  var values = exports.values = _lodash.default.map(vehicleCategory, 'value');
});