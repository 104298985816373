define('middle-office/pods/components/app-timeline/application-penalization/component', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  exports.default = Component.extend({
    attributeBindings: ['data-date'],
    classNames: ['app-timeline-penalization'],

    penalizationStart: reads('record.createdAt'),

    penalizationEnd: computed('record.{createdAt,durationInSeconds}', function () {
      var createdAt = this.get('record.createdAt');
      var durationInSeconds = this.get('record.durationInSeconds');

      return (0, _moment.default)(createdAt).add(durationInSeconds, 'seconds');
    })
  });
});