define('middle-office/pods/components/app-side-menu/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['ui', 'vertical', 'secondary', 'labeled', 'icon', 'menu', 'sidebar__menu']
  });
});