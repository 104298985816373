define('middle-office/pods/components/app-forms/application/auto-refi/component', ['exports', 'ember-cli-bkf-core/utils/pusher'], function (exports, _pusher) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var map = Ember.RSVP.map;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['app-form', 'ui', 'form'],

    tagName: 'form',

    store: service('store'),

    personPreparation: service('auto-refi/person-preparation'),

    _pusherChannelName: computed('application.id', function () {
      return 'auto-refi-application-' + this.get('application.id');
    }),

    _customerFormStatusUpdated: _pusher.onEvent(_pusher.fromProperty('_pusherChannelName'), 'customer-form-status-updated', reloadOnApplication('customerFormStatus')),

    _consultantFormStatusUpdated: _pusher.onEvent(_pusher.fromProperty('_pusherChannelName'), 'consultant-form-status-updated', reloadOnApplication('consultantFormStatus')),

    _eligibilityUpdated: _pusher.onEvent(_pusher.fromProperty('_pusherChannelName'), 'eligibility-updated', _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.next = 2;
              return this._reloadEvaluation();

            case 2:
              _context.next = 4;
              return this._reloadBranches();

            case 4:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    }))),

    _reloadEvaluation: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2() {
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                return _context2.abrupt('return', this.get('application').belongsTo('evaluation').reload());

              case 1:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function _reloadEvaluation() {
        return _ref2.apply(this, arguments);
      }

      return _reloadEvaluation;
    }(),
    _reloadBranches: function () {
      var _ref3 = _asyncToGenerator(regeneratorRuntime.mark(function _callee3() {
        var evaluatedPartnerApplications;
        return regeneratorRuntime.wrap(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                evaluatedPartnerApplications = this.get('application.evaluatedPartnerApplications');
                _context3.next = 3;
                return map(evaluatedPartnerApplications, function (pa) {
                  // cannot be run in parallel because defaultBranch endpoint can also change sentBranch
                  return pa.belongsTo('defaultBranch').reload().then(function () {
                    return pa.belongsTo('sentBranch').reload();
                  });
                });

              case 3:
              case 'end':
                return _context3.stop();
            }
          }
        }, _callee3, this);
      }));

      function _reloadBranches() {
        return _ref3.apply(this, arguments);
      }

      return _reloadBranches;
    }(),


    actions: {
      addGuarantor: function () {
        var _ref4 = _asyncToGenerator(regeneratorRuntime.mark(function _callee4(event) {
          var store, application, guarantor;
          return regeneratorRuntime.wrap(function _callee4$(_context4) {
            while (1) {
              switch (_context4.prev = _context4.next) {
                case 0:
                  event.preventDefault();

                  store = this.get('store');
                  application = this.get('application');
                  guarantor = store.createRecord('loan/auto-refi/bkf/person');
                  _context4.next = 6;
                  return this.get('personPreparation').prepareRequester(guarantor);

                case 6:

                  application.set('guarantor', guarantor);

                case 7:
                case 'end':
                  return _context4.stop();
              }
            }
          }, _callee4, this);
        }));

        function addGuarantor(_x) {
          return _ref4.apply(this, arguments);
        }

        return addGuarantor;
      }()
    }
  });


  function reloadOnApplication(applicationRelationship) {
    return function () {
      return this.get('application').belongsTo(applicationRelationship).reload();
    };
  }
});