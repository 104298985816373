define('middle-office/models/servicing/onboarding/loan', ['exports', 'ember-data', 'moment', 'lodash'], function (exports, _emberData, _moment, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  var notEmpty = Ember.computed.notEmpty;
  var filterBy = Ember.computed.filterBy;
  var map = Ember.computed.map;
  var lt = Ember.computed.lt;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    createdAt: attr('date'),
    updatedAt: attr('date'),
    product: attr('string'),
    interestRate: attr('number'),
    requestedAmount: attr('number'),
    financedAmount: attr('number'),
    tac: attr('number'),
    iof: attr('number'),
    maturity: attr('number'),
    amortizationMethod: attr('string'),
    feePayer: attr('string'),
    originator: attr('string'),
    underwriter: attr('string'),

    overdueInstallments: filterBy('installments', 'status', 'overdue'),
    isOverdue: notEmpty('overdueInstallments'),
    firstCustomer: alias('customers.firstObject'),
    lastAssignment: alias('assignments.lastObject'),
    productLink: computed('product', function () {
      var product = this.get('product');

      return 'servicing.' + product + '-loan.installments';
    }),
    installmentsOverdueDays: map('overdueInstallments', function (installment) {
      var overdueDate = installment.get('dueDate');
      var daysPastDue = (0, _moment.default)().diff(overdueDate, 'days');

      return daysPastDue;
    }),
    overdueDays: computed('installmentsOverdueDays', function () {
      var installmentsOverdueDays = this.get('installmentsOverdueDays');
      var oldestInstallmentOverdueDays = _lodash.default.max(installmentsOverdueDays) || 0;

      return oldestInstallmentOverdueDays;
    }),
    isFriendlyCollection: lt('overdueDays', 15),

    contract: belongsTo('servicing/onboarding/contract', { inverse: 'loan' }),
    customers: hasMany('servicing/onboarding/customer', { inverse: 'loans' }),
    assignments: hasMany('servicing/onboarding/assignment', { inverse: 'loan' }),
    installments: hasMany('servicing/billing/installment', { inverse: 'loan' })
  });
});