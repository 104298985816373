define('middle-office/services/polymorphic-find', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var getOwner = Ember.getOwner;
  var EmberObject = Ember.Object;
  var get = Ember.get;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  var isNone = Ember.isNone;
  var isEmpty = Ember.isEmpty;
  var ArrayProxy = Ember.ArrayProxy;
  exports.default = Service.extend({
    session: service(),
    store: service(),

    findAll: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(modelName) {
        var adapter, url, response, data;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                adapter = this._getAdapter();
                url = this._createURL(adapter, modelName);
                _context.next = 4;
                return this._XMLHttpRequest(adapter, url);

              case 4:
                _context.t0 = _context.sent;

                if (_context.t0) {
                  _context.next = 7;
                  break;
                }

                _context.t0 = {};

              case 7:
                response = _context.t0;
                data = response.data;

                if (!isNone(data)) {
                  _context.next = 11;
                  break;
                }

                return _context.abrupt('return', ArrayProxy.create());

              case 11:
                return _context.abrupt('return', this._normalizeResponse(response));

              case 12:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function findAll(_x) {
        return _ref.apply(this, arguments);
      }

      return findAll;
    }(),
    _getAdapter: function _getAdapter() {
      var owner = getOwner(this);

      return owner.lookup('adapter:application');
    },
    _createURL: function _createURL(adapter, modelName) {
      var host = get(adapter, 'host');
      var pluralizedModelName = modelName.pluralize();
      var fullUrl = host + '/' + pluralizedModelName;

      return fullUrl;
    },
    _XMLHttpRequest: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(adapter, url) {
        var payload;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _context2.prev = 0;
                _context2.next = 3;
                return adapter.ajax(url, 'GET');

              case 3:
                payload = _context2.sent;
                return _context2.abrupt('return', payload);

              case 7:
                _context2.prev = 7;
                _context2.t0 = _context2['catch'](0);
                throw new Error('Error on XMLHttpRequest: ' + _context2.t0.message);

              case 10:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this, [[0, 7]]);
      }));

      function _XMLHttpRequest(_x2, _x3) {
        return _ref2.apply(this, arguments);
      }

      return _XMLHttpRequest;
    }(),
    _normalizeStore: function _normalizeStore(model) {
      var store = this.get('store');
      var modelType = model.type.replace(/\s/g, '/').singularize();
      var normalizedModel = store.normalize(modelType, model);

      return store.push(normalizedModel);
    },
    _normalizeResponse: function _normalizeResponse(_ref3) {
      var data = _ref3.data,
          included = _ref3.included,
          meta = _ref3.meta;

      var content = data.map(this._normalizeStore, this);
      var response = ArrayProxy.create({
        content: content
      });

      if (!isEmpty(included)) {
        var normalizedIncluded = included.map(this._normalizeStore, this);

        response.set('included', normalizedIncluded);
      }

      if (!isNone(meta)) {
        response.set('meta', EmberObject.create(meta));
      }

      return response;
    }
  });
});