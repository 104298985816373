define('middle-office/enums/loan/auto-refi/workflow/pipeline/task-status', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.taskStatus = undefined;
  var taskStatus = exports.taskStatus = [{
    text: 'Retrabalho',
    value: 'rework'
  }, {
    text: 'Incompleto',
    value: 'not_done'
  }, {
    text: 'Completo',
    value: 'done'
  }, {
    text: 'Em Andamento',
    value: 'doing'
  }];

  exports.default = taskStatus;
  var values = exports.values = _lodash.default.map(taskStatus, 'value');
});