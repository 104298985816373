define('middle-office/pods/lead/status/controller', ['exports', 'middle-office/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    operationalUrl: _environment.default.APP.bkfOperational
  });
});