define('middle-office/mixins/drag-and-drop', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    classNameBindings: ['isDragging'],
    attributeBindings: ['dataUploader:data-uploader'],
    dataUploader: true,

    dragOver: function dragOver(event) {
      this._super(event);

      event.preventDefault();
      this.set('isDragging', true);
    },
    dragLeave: function dragLeave(event) {
      this._super(event);
      event.preventDefault();

      this.set('isDragging', false);
    },
    drop: function drop(event) {
      this._super(event);

      event.preventDefault();
      this.set('isDragging', false);
    }
  });
});