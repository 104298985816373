define('middle-office/pods/home-refi/eligibility-parameters/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    eligibilityParametersService: service('home-refi/eligibility-parameters'),

    actions: {
      submitEligibilityParameters: function submitEligibilityParameters() {
        var eligibilityParameters = this.get('eligibilityParameters');
        return this.get('eligibilityParametersService').save(eligibilityParameters);
      }
    }
  });
});