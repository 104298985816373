define('middle-office/pods/auto-refi/settings', ['exports', 'middle-office/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.modalSettings = exports.appLayoutConfig = undefined;


  var sideMenuItems = [{
    label: 'Buscar Clientes',
    route: 'main.auto-refi.leads',
    icon: 'search icon',
    production: true
  }, {
    label: 'Criar Novo Lead',
    icon: 'user icon',
    route: 'lead.auto-refi',
    production: true
  }, {
    label: 'Minha Carteira',
    route: 'main.auto-refi.portfolio',
    icon: 'users icon',
    production: true
  }, {
    label: 'Propostas em Crédito',
    route: 'main.auto-refi.partner-applications',
    icon: 'file text outline icon',
    production: true
  }, {
    label: 'Listagem de Tarefas',
    icon: 'list icon',
    route: 'main.auto-refi.partner-tasks',
    production: true
  }, {
    label: 'Busca na Molicar',
    icon: 'car icon',
    route: 'main.auto-refi.molicar',
    production: true
  }];

  var applicationTabs = [{
    text: 'Linha do tempo',
    route: 'auto-refi.timeline',
    production: true
  }, {
    text: 'Ficha',
    route: 'auto-refi.application-form',
    production: true
  }, {
    text: 'Documentos',
    route: 'auto-refi.documents',
    production: true
  }, {
    text: 'Vistoria',
    route: 'auto-refi.inspections',
    production: false
  }, {
    text: 'Fichas de parceiros',
    route: 'auto-refi.partner-applications',
    production: true
  }, {
    text: 'Lista de tarefas',
    route: 'auto-refi.workflow',
    production: true
  }];

  var filterProdItems = function filterProdItems(items) {
    return _environment.default.APP.mdoFeatureFlag ? items : items.filter(function (item) {
      return item.production;
    });
  };

  var appLayoutConfig = exports.appLayoutConfig = {
    headerMenu: false,
    sideMenu: {
      items: filterProdItems(sideMenuItems)
    },
    menu: {
      links: filterProdItems(applicationTabs)
    }
  };

  var modalSettings = exports.modalSettings = {
    revive: {
      type: 'revive',
      header: 'Resgatar solicitação',
      name: 'revive',
      approveAction: 'reviveApplication'
    },
    discard: {
      type: 'discard',
      header: 'Descartar solicitação',
      name: 'discard',
      approveAction: 'discardApplication'
    },
    readDiscard: {
      type: 'readDiscard',
      header: 'Solicitação descartada',
      name: 'discard'
    },
    scheduling: {
      type: 'scheduling',
      header: 'Agendar próximo contato',
      name: 'scheduling',
      approveAction: 'scheduleApplication'
    }
  };
});