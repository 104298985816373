define('middle-office/pods/components/app-inspection/status/scheduled/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['ui', 'two', 'column', 'centered', 'grid', 'scheduled'],

    layout: Ember.HTMLBars.template({
      "id": "a8yq4w/5",
      "block": "{\"symbols\":[],\"statements\":[[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"nine wide column\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ui compact tiny info message\"],[7],[0,\"\\n        \"],[6,\"i\"],[7],[0,\"O cliente agendou a vistoria que ainda será realizada.\"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"]],\"hasEval\":false}",
      "meta": {}
    })
  });
});