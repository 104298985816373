define('middle-office/enums/timeline/media-type', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.mediaType = undefined;
  var mediaType = exports.mediaType = [{
    text: 'Telefone',
    value: 'phone_call'
  }, {
    text: 'Email',
    value: 'email'
  }, {
    text: 'Whatsapp',
    value: 'whatsapp'
  }, {
    text: 'Chat',
    value: 'chat'
  }];

  exports.default = mediaType;
  var values = exports.values = _lodash.default.map(mediaType, 'value');
});