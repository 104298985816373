define('middle-office/helpers/translate-from-enum', ['exports', 'ember-cli-bkf-core/helpers/translate-from-enum'], function (exports, _translateFromEnum) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _translateFromEnum.default;
    }
  });
  Object.defineProperty(exports, 'translateFromEnum', {
    enumerable: true,
    get: function () {
      return _translateFromEnum.translateFromEnum;
    }
  });
});