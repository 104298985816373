define('middle-office/pods/components/app-table/loan-installments/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Component = Ember.Component;
  var service = Ember.inject.service;
  var get = Ember.get;
  var all = Ember.RSVP.all;
  exports.default = Component.extend({
    classNames: ['loan-installments'],
    tagName: 'tbody',

    rollbar: service(),

    isSaving: null,
    selectedInstallment: null,
    showConfirmationModal: false,
    feedbackMessage: null,

    _closeModal: function _closeModal() {
      this.set('selectedInstallment', null);
      this.set('showConfirmationModal', false);
    },
    _clearModalErrors: function _clearModalErrors() {
      this.set('feedbackMessage', null);
    },
    _isInvalid: function _isInvalid(model) {
      return get(model, 'validations.isInvalid');
    },
    _reloadInstallments: function _reloadInstallments(repayment) {
      var installments = get(repayment, 'installments');
      var promises = [];

      installments.forEach(function (installment) {
        promises.push(installment.reload());
      });

      return all(promises);
    },
    _handleError: function _handleError(e) {
      var message = e.message;

      var feedbackMessage = {
        type: 'error',
        title: 'Erro ao marcar parcela como paga.',
        text: message
      };

      this.set('feedbackMessage', feedbackMessage);
      this._logToRollbar(e);
    },
    _logToRollbar: function _logToRollbar(e) {
      var errors = e.errors;

      var message = 'Error while trying to mark an installment as paid.';

      this.get('rollbar').error(message, e, { errors: errors });
    },
    _handleValidationError: function _handleValidationError() {
      var feedbackMessage = {
        type: 'error',
        title: 'Erro ao marcar parcela como paga.',
        text: 'Todos os campos devem ser preenchidos corretamente.'
      };

      this.set('feedbackMessage', feedbackMessage);
    },
    _updateRepayment: function _updateRepayment(_ref) {
      var amount = _ref.amount,
          paidAt = _ref.paidAt,
          repayment = _ref.repayment,
          nossoNumero = _ref.nossoNumero;

      repayment.setProperties({
        amount: amount,
        paidAt: paidAt,
        nossoNumero: nossoNumero
      });

      return repayment;
    },
    _saveRepayment: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee(repayment) {
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.prev = 0;
                _context.next = 3;
                return repayment.save();

              case 3:
                _context.next = 5;
                return this._reloadInstallments(repayment);

              case 5:

                this._closeModal();
                _context.next = 11;
                break;

              case 8:
                _context.prev = 8;
                _context.t0 = _context['catch'](0);

                this._handleError(_context.t0);

              case 11:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this, [[0, 8]]);
      }));

      function _saveRepayment(_x) {
        return _ref2.apply(this, arguments);
      }

      return _saveRepayment;
    }(),


    actions: {
      onShowModal: function onShowModal(installment) {
        this.set('selectedInstallment', installment);
        this.toggleProperty('showConfirmationModal');
      },
      onMarkAsPaid: function () {
        var _ref3 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(data) {
          var repayment;
          return regeneratorRuntime.wrap(function _callee2$(_context2) {
            while (1) {
              switch (_context2.prev = _context2.next) {
                case 0:
                  this._clearModalErrors();

                  repayment = this._updateRepayment(data);

                  if (!this._isInvalid(repayment)) {
                    _context2.next = 5;
                    break;
                  }

                  this._handleValidationError();

                  return _context2.abrupt('return');

                case 5:
                  _context2.next = 7;
                  return this._saveRepayment(repayment);

                case 7:
                case 'end':
                  return _context2.stop();
              }
            }
          }, _callee2, this);
        }));

        function onMarkAsPaid(_x2) {
          return _ref3.apply(this, arguments);
        }

        return onMarkAsPaid;
      }(),
      onDismissModal: function onDismissModal() {
        this._clearModalErrors();
        this._closeModal();
      }
    }
  });
});