define('middle-office/models/loan/auto-refi/bkf/application-change-request', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    application: (0, _relationships.belongsTo)('loan/auto-refi/bkf/application'),
    partner: (0, _relationships.belongsTo)('underwriting/partner'),

    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),
    completed: (0, _attr.default)('boolean'),
    requestedOnStatusFlow: (0, _attr.default)('string')
  });
});