define('middle-office/pods/home-refi/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Route = Ember.Route;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    model: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(params) {
        var store, transactionCode, application;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                store = this.get('store');
                transactionCode = params['transaction-code'];
                _context.prev = 2;
                _context.next = 5;
                return store.queryRecord('loan/home-refi/bkf/application', {
                  'filter[transaction-code]': transactionCode
                });

              case 5:
                application = _context.sent;
                _context.next = 8;
                return application.setupPeopleRelationships();

              case 8:
                return _context.abrupt('return', application);

              case 11:
                _context.prev = 11;
                _context.t0 = _context['catch'](2);

                if (!_context.t0.isAdapteError) {
                  _context.next = 16;
                  break;
                }

                this.replaceWith('application-not-found');
                return _context.abrupt('return', null);

              case 16:
                throw _context.t0;

              case 17:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this, [[2, 11]]);
      }));

      function model(_x) {
        return _ref.apply(this, arguments);
      }

      return model;
    }()
  });
});