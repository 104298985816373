define('middle-office/pods/auto-refi/inspections/new/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var hash = Ember.RSVP.hash;
  var Route = Ember.Route;
  var isEmpty = Ember.isEmpty;
  exports.default = Route.extend({
    model: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
        var application, inspections;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                application = this.modelFor('auto-refi').application;
                _context.next = 3;
                return application.get('inspections');

              case 3:
                inspections = _context.sent;
                return _context.abrupt('return', hash({
                  application: application,
                  inspections: inspections
                }));

              case 5:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function model() {
        return _ref.apply(this, arguments);
      }

      return model;
    }(),
    afterModel: function afterModel(_ref2) {
      var inspections = _ref2.inspections;

      var scheduledInspections = inspections.filterBy('status', 'scheduled');

      if (!isEmpty(scheduledInspections)) {
        var inspectionId = scheduledInspections.get('lastObject.id');
        this.transitionTo('auto-refi.inspections.cancel', inspectionId);
      }
    },
    resetController: function resetController(controller, isExiting) {
      var inspection = controller.get('inspection');

      if (isExiting && inspection.get('isNew')) {
        inspection.deleteRecord();
      }

      controller.setProperties({
        feedbackMessage: null,
        showErrors: false
      });
    }
  });
});