define('middle-office/pods/components/app-calendar/component', ['exports', 'moment', 'middle-office/pods/components/app-calendar/labels'], function (exports, _moment, _labels) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var scheduleOnce = Ember.run.scheduleOnce;
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['ui', 'calendar'],
    minDate: new Date(),
    inline: false,
    ampm: false,
    labels: _labels.default,

    settings: computed(function () {
      var _this = this;

      var onChange = function onChange(date) {
        _this.sendAction('onChange', _moment.default.utc(date).toDate());
      };

      return {
        ampm: this.get('ampm'),
        inline: this.get('inline'),
        minDate: this.get('minDate'),
        type: this.get('type'),
        text: this.get('labels'),
        today: true,
        onChange: onChange
      };
    }),

    didInsertElement: function didInsertElement() {
      var _this2 = this;

      var settings = this.get('settings');
      scheduleOnce('afterRender', function () {
        _this2.$().calendar(settings);
      });
    }
  });
});