define('middle-office/pods/components/auto-refi/customers-leads/component', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var map = Ember.RSVP.map;
  var Component = Ember.Component;
  var get = Ember.get;
  exports.default = Component.extend({
    customersPromise: null,

    _searchedCpf: null,
    _searchPromise: null,

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var customersPromise = this.get('customersPromise');
      var searchPromise = null;

      if (customersPromise != null) {
        searchPromise = customersPromise.then(function (results) {
          return _this._searchLeads(results);
        });
      }

      this.set('_searchPromise', searchPromise);
    },
    _searchLeads: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(_ref) {
        var _this2 = this;

        var customers = _ref.customers,
            searchQuery = _ref.searchQuery;
        var withLeads;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                this.set('_searchedCpf', searchQuery);

                _context2.next = 3;
                return map(customers, function () {
                  var _ref3 = _asyncToGenerator(regeneratorRuntime.mark(function _callee(customer) {
                    var leads;
                    return regeneratorRuntime.wrap(function _callee$(_context) {
                      while (1) {
                        switch (_context.prev = _context.next) {
                          case 0:
                            _context.next = 2;
                            return customer.get('autoRefinancingLeads');

                          case 2:
                            leads = _context.sent;
                            return _context.abrupt('return', leads.map(function (lead) {
                              return { customer: customer, lead: lead };
                            }));

                          case 4:
                          case 'end':
                            return _context.stop();
                        }
                      }
                    }, _callee, _this2);
                  }));

                  return function (_x2) {
                    return _ref3.apply(this, arguments);
                  };
                }());

              case 3:
                withLeads = _context2.sent;
                return _context2.abrupt('return', (0, _lodash.default)(withLeads).flatten().sortBy(function (result) {
                  return get(result, 'lead.createdAt');
                }).reverse().value());

              case 5:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function _searchLeads(_x) {
        return _ref2.apply(this, arguments);
      }

      return _searchLeads;
    }()
  });
});