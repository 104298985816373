define('middle-office/pods/components/branch-responsible-line/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['ui', 'two', 'column', 'grid', 'container', 'list_item'],

    actions: {
      setResponsible: function setResponsible(branch, id) {
        this.sendAction('setResponsible', { branch: branch, id: id });
      }
    }
  });
});