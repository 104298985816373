define('middle-office/pods/components/app-forms/application/auto-refi/steps/contact/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['contact-data', 'form-step'],

    store: service('store'),

    _requesterAddress: reads('application.requester.residenceAddress'),
    _residenceAddress: reads('person.residenceAddress'),

    isGuarantorWithSameAddress: computed('isGuarantor', '_requesterAddress.content', '_residenceAddress.content', function () {
      if (!this.get('isGuarantor')) {
        return false;
      }

      var requesterResidenceAddress = this.get('_requesterAddress.content');
      var residenceAddress = this.get('_residenceAddress.content');

      if (requesterResidenceAddress == null || residenceAddress == null) {
        return null;
      }

      return residenceAddress === requesterResidenceAddress;
    }),

    actions: {
      updateGuarantorAddress: function () {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(shouldBeEqual) {
          var store, person, personAddress, requester, requesterAddress, areEqual;
          return regeneratorRuntime.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  store = this.get('store');
                  person = this.get('person');
                  _context.next = 4;
                  return person.get('residenceAddress');

                case 4:
                  personAddress = _context.sent;
                  _context.next = 7;
                  return this.get('application.requester');

                case 7:
                  requester = _context.sent;
                  _context.next = 10;
                  return requester.get('residenceAddress');

                case 10:
                  requesterAddress = _context.sent;
                  areEqual = requesterAddress === personAddress;


                  if (areEqual && !shouldBeEqual) {
                    person.set('residenceAddress', store.createRecord('loan/auto-refi/bkf/address'));
                  } else if (!areEqual && shouldBeEqual) {
                    person.set('residenceAddress', requesterAddress);
                  }

                case 13:
                case 'end':
                  return _context.stop();
              }
            }
          }, _callee, this);
        }));

        function updateGuarantorAddress(_x) {
          return _ref.apply(this, arguments);
        }

        return updateGuarantorAddress;
      }()
    }
  });
});