define('middle-office/pods/main/auto-refi/partner-tasks/controller', ['exports', 'middle-office/pods/main/auto-refi/partner-tasks/query-params'], function (exports, _queryParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  exports.default = Controller.extend({
    queryParams: _queryParams.controller,
    page: 1,
    team: ['Aprovação'],
    perPage: 20,
    statusFlow: ['formalization'],
    taskStatus: ['not_done', 'rework', 'doing'],
    taskAvailability: true,
    leadOrigins: computed('isFromPromoter', function () {
      return this.get('isFromPromoter') ? ['promoter'] : [];
    }),
    discarded: true,
    sortBy: ['scheduling', '-done-tasks', 'most-recent-contact', 'updated-at', 'created-at'],
    headers: ['Nome', 'CPF', 'Último contato', 'Próximo contato', 'Nº de tarefas feitas', 'Retrabalho', ''],

    isFromPromoter: false,

    resetPage: function resetPage() {
      this.set('page', 1);
    },


    actions: {
      onFilterChange: function onFilterChange(filter, values) {
        this.set(filter, values);
        this.resetPage();
      },
      onLeadOriginsFilterChange: function onLeadOriginsFilterChange(values) {
        this.set('isFromPromoter', values.includes('promoter'));
        this.resetPage();
      },
      onRefresh: function onRefresh() {
        this.send('refresh');
      }
    }
  });
});