define('middle-office/pods/components/app-forms/application/auto-refi/credit-defense/component', ['exports', 'middle-office/pods/components/app-forms/application/auto-refi/credit-defense/defense-template', 'middle-office/pods/components/app-forms/application/auto-refi/credit-defense/build-plain-application'], function (exports, _defenseTemplate, _buildPlainApplication) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Component = Ember.Component;
  var service = Ember.inject.service;
  var reads = Ember.computed.reads;
  var not = Ember.computed.not;
  var equal = Ember.computed.equal;
  var computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['auto-refi-credit-defense'],

    store: service(),

    showModal: false,

    renderingCreditDefense: false,

    consultantFormStatus: reads('application.consultantFormStatus'),
    isFormComplete: equal('consultantFormStatus.completed', true),
    isFormIncomplete: not('isFormComplete'),

    renderingButtonDisabled: computed('isFormIncomplete', 'renderingCreditDefense', function () {
      var isRendering = this.get('renderingCreditDefense');
      var isIncomplete = this.get('isFormIncomplete');

      return isRendering || isIncomplete;
    }),

    modalSettings: {
      header: 'Sobrescrever defesa',
      type: 'credit-defense'
    },

    _showModal: function _showModal() {
      this.set('showModal', true);
    },
    _hideModal: function _hideModal() {
      this.set('showModal', false);
    },
    _renderCreditDefense: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
        var isRendering, plainParameters, renderedTemplate;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                isRendering = this.get('renderingCreditDefense');

                if (!isRendering) {
                  _context.next = 3;
                  break;
                }

                return _context.abrupt('return');

              case 3:

                this.set('renderingCreditDefense', true);
                _context.next = 6;
                return (0, _buildPlainApplication.default)(this.get('application'), this.get('store'));

              case 6:
                plainParameters = _context.sent;
                renderedTemplate = (0, _defenseTemplate.default)(plainParameters);

                this.setProperties({
                  'application.creditDefense': renderedTemplate,
                  renderingCreditDefense: false
                });

              case 9:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function _renderCreditDefense() {
        return _ref.apply(this, arguments);
      }

      return _renderCreditDefense;
    }(),


    actions: {
      overwriteCreditDefense: function overwriteCreditDefense() {
        this._renderCreditDefense();
        this._hideModal();
      },
      hideModal: function hideModal() {
        this._hideModal();
      },
      renderCreditDefense: function () {
        var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(event) {
          var creditDefenseValue;
          return regeneratorRuntime.wrap(function _callee2$(_context2) {
            while (1) {
              switch (_context2.prev = _context2.next) {
                case 0:
                  event.preventDefault();
                  creditDefenseValue = this.get('application.creditDefense');


                  if (creditDefenseValue) {
                    this._showModal();
                  } else {
                    this._renderCreditDefense();
                  }

                case 3:
                case 'end':
                  return _context2.stop();
              }
            }
          }, _callee2, this);
        }));

        function renderCreditDefense(_x) {
          return _ref2.apply(this, arguments);
        }

        return renderCreditDefense;
      }()
    }
  });
});