define('middle-office/pods/components/app-forms/application/auto-refi/application-submit/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Component = Ember.Component;
  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  var service = Ember.inject.service;
  exports.default = Component.extend({
    showWarningModal: false,

    applicationForm: service('auto-refi/application-form'),

    consultantFormStatus: reads('application.consultantFormStatus'),

    missingInformation: computed('application.creditDefense', 'application.consultant.id', function () {
      var creditDefenseValue = this.get('application.creditDefense');
      var consultantId = this.get('application.consultant.id');

      var information = [];

      if (!creditDefenseValue) {
        information.push('Preencher defesa');
      }

      if (!consultantId) {
        information.push('Carteirizar');
      }

      return information;
    }),

    loadingClass: computed('applicationForm.isSaving', function () {
      if (this.get('applicationForm.isSaving')) {
        return 'loading';
      }

      return null;
    }),

    _showWarningModal: function _showWarningModal() {
      this.set('showWarningModal', true);
    },
    _hideWarningModal: function _hideWarningModal() {
      this.set('showWarningModal', false);
    },
    _saveAndSubmit: function _saveAndSubmit() {
      var saveAndSubmit = this.get('saveAndSubmit') || Ember.k;
      saveAndSubmit();
    },


    actions: {
      submitApplication: function () {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
          var hasCreditDefenseValue, hasConsultantId;
          return regeneratorRuntime.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  hasCreditDefenseValue = this.get('application.creditDefense');
                  hasConsultantId = this.get('application.consultant.id');


                  if (!hasConsultantId || !hasCreditDefenseValue) {
                    this._showWarningModal();
                  } else {
                    this._saveAndSubmit();
                  }

                case 3:
                case 'end':
                  return _context.stop();
              }
            }
          }, _callee, this);
        }));

        function submitApplication() {
          return _ref.apply(this, arguments);
        }

        return submitApplication;
      }(),
      saveAndSubmit: function saveAndSubmit() {
        this._hideWarningModal();
        this._saveAndSubmit();
      },
      hideWarningModal: function hideWarningModal() {
        this._hideWarningModal();
      }
    }
  });
});