define('middle-office/pods/servicing/assignments/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    acceptedFormats: computed(function () {
      return ['.csv'];
    }),

    resource: 'servicing/assignments'
  });
});