define('middle-office/pods/components/app-inspection/status/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['form-field', 'field', 'inspection-status'],

    layout: Ember.HTMLBars.template({
      "id": "OnBKdIdl",
      "block": "{\"symbols\":[],\"statements\":[[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui middle aligned grid\"],[7],[0,\"\\n      \"],[6,\"label\"],[9,\"class\",\"five wide right aligned column\"],[7],[0,\"\\n        Status:\\n      \"],[8],[0,\"\\n\\n      \"],[6,\"div\"],[9,\"class\",\"ten wide left aligned column\"],[7],[0,\"\\n        \"],[1,[25,\"app-dropdown\",null,[[\"items\",\"selected\",\"on-change\"],[[25,\"enum-options\",[\"loan/auto-refi/bkf/inspection-status\"],null],[25,\"readonly\",[[19,0,[\"inspection\",\"status\"]]],null],[25,\"action\",[[19,0,[]],[25,\"mut\",[[19,0,[\"inspection\",\"status\"]]],null]],null]]]],false],[0,\"\\n        \"],[1,[25,\"app-errors\",null,[[\"model\",\"attribute\",\"showErrors\"],[[19,0,[\"inspection\"]],\"status\",[19,0,[\"showErrors\"]]]]],false],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\\n    \"],[1,[25,\"component\",[[25,\"concat\",[\"app-inspection/status/\",[19,0,[\"inspection\",\"status\"]]],null]],[[\"inspection\",\"showErrors\"],[[19,0,[\"inspection\"]],[19,0,[\"showErrors\"]]]]],false],[0,\"\\n  \"]],\"hasEval\":false}",
      "meta": {}
    })
  });
});