define('middle-office/enums/servicing/onboarding/loans/product-filters', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.filter = undefined;
  var filter = exports.filter = [{
    text: 'Home',
    value: 'home'
  }, {
    text: 'Auto',
    value: 'auto'
  }];

  exports.default = filter;
  var values = exports.values = _lodash.default.map(filter, 'value');
});