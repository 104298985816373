define('middle-office/pods/components/app-forms/application/auto-refi/partials/action-bar/component', ['exports', 'vanilla-masker', 'ember-data'], function (exports, _vanillaMasker, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var reject = Ember.RSVP.reject;
  var later = Ember.run.later;
  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var ConflictError = _emberData.default.ConflictError;
  exports.default = Component.extend({
    classNames: ['action-bar', 'ui', 'grid', 'container', 'centered', 'middle', 'aligned'],

    applicationForm: service('auto-refi/application-form'),
    scroller: service(),
    rollbar: service(),
    store: service(),

    consultantFormStatus: reads('application.consultantFormStatus'),
    selectedRequester: null,

    _requester: reads('application.requester'),
    _firstEmployment: reads('_requester.employments.firstObject'),

    requestersList: computed('application.requester.fullName', 'application.guarantor.fullName', function () {
      var requester = this.get('application.requester.content');
      var guarantor = this.get('application.guarantor.content');

      var requesters = new Map();
      requesters.set(requester, 'requester');
      requesters.set(guarantor, 'guarantor');

      return Array.from(requesters).filter(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 1),
            person = _ref2[0];

        return person;
      }).map(function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 2),
            person = _ref4[0],
            type = _ref4[1];

        return {
          text: person.get('fullName'),
          value: type
        };
      });
    }),

    feedbackMessage: null,

    _buildStatusPath: function _buildStatusPath() {
      var applicationId = this.get('application.id');
      return '/auto-refi/' + applicationId + '/fichas-parceiros';
    },
    _fetchAndFormatCpf: function _fetchAndFormatCpf() {
      var cpfValue = this.get('application.requester.cpf');
      var maskedCpf = _vanillaMasker.default.toPattern(cpfValue, '999.999.999-99');

      return maskedCpf;
    },
    setFeedbackMessage: function setFeedbackMessage(message, type) {
      var _this = this;

      this.set('feedbackMessage', { message: message, type: type });

      later(function () {
        return _this.set('feedbackMessage', null);
      }, 3500);
    },


    saveLoadingClass: computed('applicationForm.isSaving', function () {
      if (this.get('applicationForm.isSaving')) {
        return 'loading';
      }

      return null;
    }),

    _save: function () {
      var _ref5 = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
        var applicationForm;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return (this.get('on-save') || Ember.k)();

              case 2:
                applicationForm = this.get('applicationForm');


                if (applicationForm.get('isSaved')) {
                  this.setFeedbackMessage('Salvo com sucesso!', 'success');
                }

                if (applicationForm.get('isError')) {
                  this.setFeedbackMessage('Falha ao salvar o formulário :(', 'error');
                }

              case 5:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function _save() {
        return _ref5.apply(this, arguments);
      }

      return _save;
    }(),
    _saveAndSubmit: function () {
      var _ref6 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2() {
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _context2.next = 2;
                return this._save();

              case 2:

                this._createSubmission();

              case 3:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function _saveAndSubmit() {
        return _ref6.apply(this, arguments);
      }

      return _saveAndSubmit;
    }(),
    _createSubmission: function () {
      var _ref7 = _asyncToGenerator(regeneratorRuntime.mark(function _callee3() {
        var application, submission;
        return regeneratorRuntime.wrap(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                application = this.get('application');
                submission = this.get('store').createRecord('loan/auto-refi/bkf/application-submission');


                submission.set('application', application);
                _context3.prev = 3;
                _context3.next = 6;
                return submission.save();

              case 6:

                this.setFeedbackMessage('Ficha gerada e enviada com sucesso!', 'success');
                _context3.next = 17;
                break;

              case 9:
                _context3.prev = 9;
                _context3.t0 = _context3['catch'](3);

                if (!(_context3.t0 instanceof ConflictError)) {
                  _context3.next = 15;
                  break;
                }

                this.setFeedbackMessage('Ficha já foi enviada para este parceiro', 'error');
                _context3.next = 17;
                break;

              case 15:
                this.setFeedbackMessage('Algo deu errado, tente novamente', 'error');
                throw new Error(_context3.t0);

              case 17:
              case 'end':
                return _context3.stop();
            }
          }
        }, _callee3, this, [[3, 9]]);
      }));

      function _createSubmission() {
        return _ref7.apply(this, arguments);
      }

      return _createSubmission;
    }(),


    actions: {
      selectRequester: function selectRequester(value) {
        this.set('selectedRequester', value);
      },
      save: function () {
        var _ref8 = _asyncToGenerator(regeneratorRuntime.mark(function _callee4() {
          var validations, errorInputSelector;
          return regeneratorRuntime.wrap(function _callee4$(_context4) {
            while (1) {
              switch (_context4.prev = _context4.next) {
                case 0:
                  validations = this.get('application.validations');

                  if (!validations.get('isValid')) {
                    _context4.next = 3;
                    break;
                  }

                  return _context4.abrupt('return', this._save());

                case 3:
                  errorInputSelector = '.error.item';
                  _context4.next = 6;
                  return this.get('scroller').scrollVertical(errorInputSelector);

                case 6:

                  this.setFeedbackMessage('A ficha possui campos com dados inválidos...', 'warning');
                  return _context4.abrupt('return', reject());

                case 8:
                case 'end':
                  return _context4.stop();
              }
            }
          }, _callee4, this);
        }));

        function save() {
          return _ref8.apply(this, arguments);
        }

        return save;
      }(),
      saveAndSubmit: function saveAndSubmit() {
        this._saveAndSubmit();
      }
    }
  });
});