define('middle-office/pods/components/app-checkgroup/component', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var isArray = Ember.isArray;
  var on = Ember.on;
  var computed = Ember.computed;
  var Component = Ember.Component;


  var DEFAULT_GROUP = [{
    text: 'Sim',
    value: true
  }, {
    text: 'Não',
    value: false
  }];

  exports.default = Component.extend({
    classNames: ['app-checkgroup'],

    attributeBindings: ['type'],

    type: 'radio',

    _items: computed('items', function () {
      return this.get('items') || DEFAULT_GROUP;
    }),

    _errorHandler: on('didReceiveAttrs', function () {
      if (this.get('type') === 'checkbox' && this.get('value') && !isArray(this.get('value'))) {
        throw Error('The value should be an array or set as radio.');
      }
    }),

    actions: {
      checkItem: function checkItem(value) {
        this.sendAction('on-change', value);
        var isRadio = this.get('type') === 'radio';

        if (isRadio) {
          this.set('value', value);

          return;
        }

        this.toggleChekboxValue(value);
      }
    },

    toggleChekboxValue: function toggleChekboxValue(value) {
      var currentValue = this.get('value') || [];
      var index = currentValue.indexOf(value);
      var newValue = void 0;

      if (index < 0) {
        newValue = [].concat(_toConsumableArray(currentValue), [value]);
      } else {
        newValue = _lodash.default.without(currentValue, value);
      }

      this.set('value', newValue);
    }
  });
});