define('middle-office/pods/components/app-forms/application/home-refi/steps/contact/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['contact-data', 'form-step'],

    store: service('store'),

    isMainRequester: true,

    isSameResidenceAddressRealEstate: computed('application.realEstateAddress.content', 'person.residenceAddress.content', function () {
      var realEstateAddress = this.get('application.realEstateAddress.content');
      var residenceAddress = this.get('person.residenceAddress.content');

      return realEstateAddress === residenceAddress;
    }),

    actions: {
      updateMailingAddress: function () {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(check) {
          var residenceAddress, mailingAddress;
          return regeneratorRuntime.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  residenceAddress = this.get('person.residenceAddress.content');
                  mailingAddress = this.get('person.mailingAddress.content');

                  if (!check) {
                    _context.next = 4;
                    break;
                  }

                  return _context.abrupt('return');

                case 4:

                  if (!mailingAddress || mailingAddress === residenceAddress) {
                    this.set('person.mailingAddress', this.get('store').createRecord('loan/home-refi/bkf/address'));
                  }

                case 5:
                case 'end':
                  return _context.stop();
              }
            }
          }, _callee, this);
        }));

        function updateMailingAddress(_x) {
          return _ref.apply(this, arguments);
        }

        return updateMailingAddress;
      }(),
      updateResidenceAddressRealEstate: function () {
        var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(equalRealEstate) {
          var residenceAddress, realEstateAddress, newAddress;
          return regeneratorRuntime.wrap(function _callee2$(_context2) {
            while (1) {
              switch (_context2.prev = _context2.next) {
                case 0:
                  residenceAddress = this.get('person.residenceAddress.content');
                  realEstateAddress = this.get('application.realEstateAddress.content');

                  if (!equalRealEstate) {
                    _context2.next = 6;
                    break;
                  }

                  this.set('prevResidenceAddress', residenceAddress);
                  this.set('person.residenceAddress', realEstateAddress);
                  return _context2.abrupt('return');

                case 6:
                  newAddress = this.get('prevResidenceAddress') || this.get('store').createRecord('loan/home-refi/bkf/address');


                  this.set('person.residenceAddress', newAddress);

                case 8:
                case 'end':
                  return _context2.stop();
              }
            }
          }, _callee2, this);
        }));

        function updateResidenceAddressRealEstate(_x2) {
          return _ref2.apply(this, arguments);
        }

        return updateResidenceAddressRealEstate;
      }()
    }
  });
});