define('middle-office/utils/build-enum', ['exports', 'ember-cli-bkf-core/enum-options', 'lodash'], function (exports, _enumOptions, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = buildEnum;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var get = Ember.get;
  function buildEnum() {
    var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : { items: [], textKey: '', valueKey: '', includeBlank: false };

    if (config == null || !_lodash.default.isArray(config.items)) {
      return [];
    }

    var items = config.items.map(function (item) {
      var text = void 0;
      var value = void 0;

      if (_lodash.default.isString(item)) {
        text = item;
        value = item;
      } else {
        text = get(item, config.textKey);
        value = get(item, config.valueKey);
      }

      return { text: text, value: value };
    });

    if (config.includeBlank === true) {
      return [_enumOptions.blankOption].concat(_toConsumableArray(items));
    }

    return items;
  }
});