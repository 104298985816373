define('middle-office/utils/all-check/normalize', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var empty = {};

  var normalizers = {
    normalizeTelephone: function normalizeTelephone(telephoneAddress) {
      return {
        installationDate: telephoneAddress.dataInstalacao,
        address: telephoneAddress.endereco + ', ' + telephoneAddress.numero,
        complement: telephoneAddress.complemento,
        neighborhood: telephoneAddress.bairro,
        postalCode: telephoneAddress.cep,
        city: telephoneAddress.cidade,
        state: telephoneAddress.estado,
        telephone: telephoneAddress.ddd + '-' + telephoneAddress.telefone
      };
    },
    normalizeJob: function normalizeJob(job) {
      return {
        company: job.nomeEmpresa,
        cnpj: job.cnpj,
        startDate: job.dataAdmissao,
        endDate: job.dataDemissao
      };
    },
    normalizeBank: function normalizeBank(bank) {
      return {
        code: bank.banco,
        name: bank.nomeBanco,
        branch: {
          code: bank.agencia,
          name: bank.nomeAgencia
        },
        address: bank.enderecoAgencia,
        city: bank.cidade,
        postalCode: bank.cep,
        state: bank.estado,
        telephone: bank.ddd + '-' + bank.telefone,
        fax: bank.fax
      };
    },
    normalizeVehicle: function normalizeVehicle(vehicle) {
      return {
        brand: vehicle.marca,
        databaseYear: vehicle.ano_base,
        factoryYear: vehicle.ano_fabricacao,
        chassis: vehicle.chassis,
        licensePlate: vehicle.placa,
        renavam: vehicle.renavam,
        fuel: vehicle.combustivel,
        movementDate: vehicle.data_movimento
      };
    },
    normalizePartnership: function normalizePartnership(partnership) {
      return {
        date: {
          entrance: partnership.data_ingresso,
          initiation: partnership.data_inicio
        },
        company: {
          name: partnership.nome,
          cnpj: partnership.cnpj,
          ie: partnership.inscricao_estadual,
          neighborhood: partnership.bairro_empresa,
          postalCode: partnership.cep_empresa,
          complement: partnership.complemento_empresa,
          address: partnership.endereco_empresa,
          state: partnership.estado_empresa,
          number: partnership.numero_empresa
        },
        partner: {
          name: partnership.socio,
          participation: partnership.participacao,
          cpf: partnership.cpf_socio,
          rg: partnership.rg_socio,
          neighborhood: partnership.bairro_socio,
          postalCode: partnership.cep_socio,
          complement: partnership.complemento_socio,
          address: partnership.endereco_socio,
          state: partnership.estado_socio,
          number: partnership.numero_socio
        },
        partners: partnership.socios_da_empresa
      };
    },
    normalizeConsultation: function normalizeConsultation(consultation) {
      return {
        date: consultation.dataConsulta,
        institution: consultation.cliente,
        product: consultation.produto,
        telephone: consultation.telefone
      };
    }
  };

  var _mapper = function _mapper(data, normalize) {
    if (_lodash.default.isArray(data)) {
      return data.map(normalize);
    }

    return [normalize(data)];
  };

  var allCheckKeys = {
    root: 'consulta',

    personData: {
      parentKey: 'banco_dadosCadastrais',
      childKey: 'dadosCadastrais'
    },

    addressData: {
      parentKey: 'banco_endereco',
      childKey: 'consta_endereco',

      mapper: function mapper(_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            address = _ref2[0],
            number = _ref2[1];

        return address + ', ' + number;
      }
    },

    telephoneAddressData: {
      parentKey: 'banco_telefone',
      childKey: 'novo_telefone',

      mapper: function mapper(telephoneAddresses) {
        return _mapper(telephoneAddresses, normalizers.normalizeTelephone);
      }
    },

    jobData: {
      parentKey: 'historicoTrabalho',
      childKey: 'novo_historicoTrabalho',

      mapper: function mapper(jobs) {
        return _mapper(jobs, normalizers.normalizeJob);
      }
    },

    bankData: {
      parentKey: 'dadosBancarios',
      childKey: 'novo_dadosBancarios',

      mapper: function mapper(banks) {
        return _mapper(banks, normalizers.normalizeBank);
      }
    },

    vehicleData: {
      parentKey: 'banco_veiculos',
      childKey: 'novo_veiculo',

      mapper: function mapper(vehicles) {
        return _mapper(vehicles, normalizers.normalizeVehicle);
      }
    },

    partnershipData: {
      parentKey: 'banco_sociedades',
      childKey: 'novo_socios',

      mapper: function mapper(partnerships) {
        return _mapper(partnerships, normalizers.normalizePartnership);
      }
    },

    consultationData: {
      parentKey: 'redeVigiada',
      childKey: 'registro',

      mapper: function mapper(consultations) {
        return _mapper(consultations, normalizers.normalizeConsultation);
      }
    },

    obituaryData: {
      parentKey: 'banco_obitos',
      childKey: 'consta_obito'
    }
  };

  var _head = function _head(args) {
    var _args = _slicedToArray(args, 1),
        head = _args[0];

    return head;
  };

  var _getValuesForKeys = function _getValuesForKeys(json, keys) {
    return keys.map(function (key) {
      return json[key];
    });
  };

  var _hasOneKey = function _hasOneKey(keys) {
    return keys.length === 1;
  };

  var _hasNoKeysWithMapper = function _hasNoKeysWithMapper(keys, helpers) {
    return !keys.length && helpers.mapper;
  };

  var _parseChildJSON = function _parseChildJSON(json, keys, helpers) {
    if (_hasOneKey(keys)) {
      return _head(_getValuesForKeys(json, keys));
    }

    if (_hasNoKeysWithMapper(keys, helpers)) {
      return helpers.mapper(json);
    }

    return helpers.mapper(_getValuesForKeys(json, keys));
  };

  var _parseJSON = function _parseJSON(json) {
    for (var _len = arguments.length, properties = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      properties[_key - 1] = arguments[_key];
    }

    var helpers = properties[0],
        keys = properties.slice(1);

    var data = json[allCheckKeys.root];

    if (_lodash.default.isEmpty(helpers)) {
      return data[_head(keys)];
    }

    var parentKey = helpers.parentKey;
    var childKey = helpers.childKey;

    if (!data[parentKey]) {
      return null;
    }

    var childData = data[parentKey][childKey];

    return _parseChildJSON(childData, keys, helpers);
  };

  var _getPartials = function _getPartials(json) {
    return {
      queryData: function queryData(key) {
        return _parseJSON(json, empty, key);
      },
      personData: function personData(key) {
        return _parseJSON(json, allCheckKeys.personData, key);
      },
      addressData: function addressData(key) {
        for (var _len2 = arguments.length, rest = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
          rest[_key2 - 1] = arguments[_key2];
        }

        return _parseJSON.apply(undefined, [json, allCheckKeys.addressData, key].concat(rest));
      },
      telephoneAddressData: function telephoneAddressData() {
        return _parseJSON(json, allCheckKeys.telephoneAddressData);
      },
      jobData: function jobData() {
        return _parseJSON(json, allCheckKeys.jobData);
      },
      bankData: function bankData() {
        return _parseJSON(json, allCheckKeys.bankData);
      },
      vehicleData: function vehicleData() {
        return _parseJSON(json, allCheckKeys.vehicleData);
      },
      partnershipData: function partnershipData() {
        return _parseJSON(json, allCheckKeys.partnershipData);
      },
      consultationData: function consultationData() {
        return _parseJSON(json, allCheckKeys.consultationData);
      },
      obituaryData: function obituaryData(key) {
        return _parseJSON(json, allCheckKeys.obituaryData, key);
      }
    };
  };

  exports.default = function (json) {
    if (!json) {
      return empty;
    }

    var _getPartials2 = _getPartials(json),
        queryData = _getPartials2.queryData,
        personData = _getPartials2.personData,
        addressData = _getPartials2.addressData,
        telephoneAddressData = _getPartials2.telephoneAddressData,
        jobData = _getPartials2.jobData,
        bankData = _getPartials2.bankData,
        vehicleData = _getPartials2.vehicleData,
        partnershipData = _getPartials2.partnershipData,
        consultationData = _getPartials2.consultationData,
        obituaryData = _getPartials2.obituaryData;

    return {
      query: queryData('numeroConsulta'),
      fullName: personData('nome'),
      rg: personData('rg'),
      birthDate: personData('dataNascimento'),
      age: personData('idade'),
      email: personData('email'),
      employmentDate: personData('dataAdmissao'),
      gradeLevel: personData('grauInstrucao'),
      profession: personData('atividade'),
      maritalStatus: personData('estadoCivil'),
      nationality: personData('nacionalidade'),
      ctps: personData('carteiraProf'),
      cnpj: personData('cnpjEmpregador'),
      filiation: personData('filiacao'),
      pis: personData('pis'),
      documentNumber: personData('numeroDocumento'),
      titleNumber: personData('numeroTitulo'),
      sex: personData('sexo'),
      sign: personData('signo'),
      benefitNumber: personData('numeroBeneficio'),
      benefitValue: personData('valorBeneficio'),
      benefitStart: personData('inicioBeneficio'),
      address: addressData('endereco', 'numero'),
      complement: addressData('complemento'),
      neighborhood: addressData('bairro'),
      postalCode: addressData('cep'),
      city: addressData('cidade'),
      state: addressData('estado'),
      telephoneAddresses: telephoneAddressData(),
      jobs: jobData(),
      banks: bankData(),
      vehicles: vehicleData(),
      partnerships: partnershipData(),
      consultations: consultationData(),
      obituaryBirth: obituaryData('dataNascimento'),
      obituaryDeath: obituaryData('dataObito'),
      obituaryName: obituaryData('nome'),
      obituaryMotherName: obituaryData('nomeMae')
    };
  };
});