define('middle-office/pods/components/partner-applications/partials/statuses/flow/component', ['exports', 'middle-office/enums/auto-refi/status-flow'], function (exports, _statusFlow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['ui', 'grid'],
    statusFlowEnum: _statusFlow.default
  });
});