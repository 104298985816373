define('middle-office/models/calculator/auto-refi/operation', ['exports', 'ember-data', 'ember-cli-bkf-core/models/calculator/auto-refi/operation', 'ember-cp-validations'], function (exports, _emberData, _operation, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    score: [(0, _emberCpValidations.validator)('length', {
      is: 2,
      allowBlank: true
    })],
    familyMonthlyIncome: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      allowBlank: false
    })],
    minimumMonthlyIncome: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      allowBlank: false
    })],
    incomeCommitment: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      lt: 100,
      gt: 0,
      allowBlank: false
    })],
    operationIncomeCommitment: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      lt: 100,
      gt: 0,
      allowBlank: false
    })],
    autoModelYear: [],
    autoValue: [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      allowBlank: true
    })],
    autoDebt: [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: 0,
      allowBlank: true
    })],
    iof: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      allowBlank: false
    })],
    tac: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: 0,
      allowBlank: false
    })],
    priceTaxYear: [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      lt: 100,
      gt: 0,
      allowBlank: true
    })],
    priceTaxMonthly: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      lt: 100,
      gt: 0,
      allowBlank: false
    })],
    totalEffectiveCostYear: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      allowBlank: false
    })],
    totalEffectiveCostMonthly: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      lt: 100,
      gt: 0,
      allowBlank: false
    })],
    loanAmount: [(0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      allowBlank: true
    })],
    loanAmountRequested: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      allowBlank: false
    })],
    contractLoanAmount: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      allowBlank: false
    })],
    pmt: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      allowBlank: false
    })],
    ltvMax: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      lt: 100,
      gt: 0,
      allowBlank: false
    })],
    ltvOperation: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      lt: 100,
      gt: 0,
      allowBlank: false
    })],
    operationMaturity: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      allowBlank: false
    })],
    maturity: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      integer: true
    })],
    paidValue: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gt: 0,
      allowBlank: false
    })],
    state: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      is: 2,
      allowBlank: false
    })]
  });

  exports.default = _operation.default.extend(Validations, {
    contractLoanAmount: _emberData.default.attr('number', { defaultValue: 0 }),
    loanAmountRequested: _emberData.default.attr('number', { defaultValue: 0 }),
    tac: _emberData.default.attr('number', { defaultValue: 0 }),
    iof: _emberData.default.attr('number', { defaultValue: 0 }),
    priceTaxMonthly: _emberData.default.attr('number', { defaultValue: 0 }),
    pmt: _emberData.default.attr('number', { defaultValue: 0 }),
    ltvMax: _emberData.default.attr('number', { defaultValue: 0 }),
    ltvOperation: _emberData.default.attr('number', { defaultValue: 0 }),
    familyMonthlyIncome: _emberData.default.attr('number', { defaultValue: 0 }),
    minimumMonthlyIncome: _emberData.default.attr('number', { defaultValue: 0 }),
    incomeCommitment: _emberData.default.attr('number', { defaultValue: 0 }),
    operationIncomeCommitment: _emberData.default.attr('number', { defaultValue: 0 }),
    totalEffectiveCostMonthly: _emberData.default.attr('number', { defaultValue: 0 }),
    totalEffectiveCostYear: _emberData.default.attr('number', { defaultValue: 0 }),
    paidValue: _emberData.default.attr('number', { defaultValue: 0 }),
    state: _emberData.default.attr('string'),

    setDetranState: function setDetranState(state) {
      this.set('state', state);
    }
  });
});