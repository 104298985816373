define('middle-office/pods/lead/home-refi/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    rollbar: service(),
    feedbackMessage: null,

    feedbackStatusClass: computed('feedbackMessage', function () {
      return this.get('feedbackMessage.type');
    }),

    _isInvalid: function _isInvalid() {
      var customerErrors = this.get('customer.validations.errors');

      return customerErrors.length > 0;
    },
    _saveCustomer: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
        var customer;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return this.store.queryRecord('crm/customer', {
                  'filter[cpf]': this.get('customer.cpf')
                });

              case 2:
                customer = _context.sent;

                if (!(customer != null)) {
                  _context.next = 5;
                  break;
                }

                return _context.abrupt('return', customer);

              case 5:
                return _context.abrupt('return', this.get('customer').save());

              case 6:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function _saveCustomer() {
        return _ref.apply(this, arguments);
      }

      return _saveCustomer;
    }(),
    _handleSubmitError: function _handleSubmitError(error) {
      this._showSubmitError(error);
      this.get('rollbar').error(new Error('Failure on create home-refi lead'), {
        errorEvent: error
      });
    },
    _showSubmitError: function _showSubmitError(e) {
      var defaultError = [{
        source: 'Erro'
      }];
      var error = e.errors ? e.errors : defaultError;
      var message = error[0].source;

      this.set('feedbackMessage', {
        type: 'error',
        title: 'Ooops...',
        text: message
      });
    },
    _submit: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2() {
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                if (!this._isInvalid()) {
                  _context2.next = 3;
                  break;
                }

                this.set('showErrors', true);
                return _context2.abrupt('return');

              case 3:
                _context2.prev = 3;

                this.set('isSaving', true);
                _context2.next = 7;
                return this._saveCustomer();

              case 7:
                _context2.next = 12;
                break;

              case 9:
                _context2.prev = 9;
                _context2.t0 = _context2['catch'](3);

                this._handleSubmitError(_context2.t0);

              case 12:
                _context2.prev = 12;

                this.set('isSaving', false);
                this.set('submitting', false);
                return _context2.finish(12);

              case 16:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this, [[3, 9, 12, 16]]);
      }));

      function _submit() {
        return _ref2.apply(this, arguments);
      }

      return _submit;
    }(),


    actions: {
      submit: function submit() {
        this._submit();
      }
    }
  });
});