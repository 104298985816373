define('middle-office/pods/lead/home-refi/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var hash = Ember.RSVP.hash;
  var Route = Ember.Route;
  exports.default = Route.extend({
    _retrieveCustomer: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(transition) {
        var cpf, existentCustomer, customer;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                cpf = transition.queryParams.cpf;

                if (!(cpf != null)) {
                  _context.next = 7;
                  break;
                }

                _context.next = 4;
                return this.store.queryRecord('crm/customer', {
                  'filter[cpf]': cpf
                });

              case 4:
                existentCustomer = _context.sent;

                if (!existentCustomer) {
                  _context.next = 7;
                  break;
                }

                return _context.abrupt('return', existentCustomer);

              case 7:
                customer = this.store.createRecord('crm/customer', {
                  sendEmailConfirmation: true
                });
                return _context.abrupt('return', customer);

              case 9:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function _retrieveCustomer(_x) {
        return _ref.apply(this, arguments);
      }

      return _retrieveCustomer;
    }(),
    model: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(params, transition) {
        var customer;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _context2.next = 2;
                return this._retrieveCustomer(transition);

              case 2:
                customer = _context2.sent;
                return _context2.abrupt('return', hash({ customer: customer }));

              case 4:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function model(_x2, _x3) {
        return _ref2.apply(this, arguments);
      }

      return model;
    }(),
    setupController: function setupController(controller, model) {
      if (model.customer.id != null) {
        controller.set('customerExists', true);
      }

      controller.set('customer', model.customer);
    }
  });
});