define('middle-office/pods/components/app-file-upload/drop-file-with-selection/component', ['exports', 'middle-office/utils/factories/file', 'lodash'], function (exports, _file, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var notEmpty = Ember.computed.notEmpty;
  var computed = Ember.computed;
  var isEmpty = Ember.isEmpty;
  exports.default = Ember.Component.extend({
    classNames: ['ui', 'grid', 'segment', 'column', 'sixteen', 'wide', 'drop-file-with-selection'],
    layout: Ember.HTMLBars.template({
      "id": "U+pb+f6m",
      "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"ui row\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ui eight wide column\"],[7],[0,\"\\n        \"],[11,1],[0,\"\\n\\n        \"],[1,[25,\"app-file-upload/selection-upload\",null,[[\"acceptedFormats\",\"onFilesSelected\",\"text\",\"isMultiple\"],[[19,0,[\"acceptedFormats\"]],[25,\"action\",[[19,0,[]],\"onFilesSelected\"],null],\"Upload da Cessão dos Créditos\",[19,0,[\"isMultiple\"]]]]],false],[0,\"\\n      \"],[8],[0,\"\\n\\n      \"],[6,\"div\"],[9,\"class\",\"ui eight wide column\"],[7],[0,\"\\n        \"],[1,[25,\"app-drop-file\",null,[[\"hasFile\",\"acceptedFormats\",\"showErrorMessage\",\"clearFile\",\"clearErrors\",\"onError\",\"onFilesSelected\",\"isMultiple\"],[[19,0,[\"hasFile\"]],[19,0,[\"acceptedFormats\"]],false,[25,\"action\",[[19,0,[]],\"clearFile\"],null],[25,\"action\",[[19,0,[]],\"clearErrors\"],null],[25,\"action\",[[19,0,[]],\"handleDragAndDropError\"],null],[25,\"action\",[[19,0,[]],\"onFilesSelected\"],null],[19,0,[\"isMultiple\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[19,0,[\"dragAndDropUploadError\"]]],null,{\"statements\":[[0,\"          \"],[1,[25,\"app-message\",null,[[\"feedbackMessage\"],[[19,0,[\"dragAndDropUploadError\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\\n\"],[4,\"if\",[[19,0,[\"hasFiles\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,\"app-file-upload/drop-file-with-selection/file-list\",null,[[\"selectedFiles\",\"clearFile\",\"clearAllFiles\"],[[19,0,[\"selectedFiles\"]],[25,\"action\",[[19,0,[]],\"clearFile\"],null],[25,\"action\",[[19,0,[]],[25,\"mut\",[[19,0,[\"selectedFiles\"]]],null],null],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"]],\"hasEval\":false}",
      "meta": {}
    }),

    acceptedFormats: computed(function () {
      return [];
    }),
    resource: '',

    isMultiple: false,
    selectedFiles: null,
    dragAndDropUploadError: null,
    hasFiles: notEmpty('selectedFiles'),

    _createFile: function _createFile(fileData, resource) {
      var name = fileData.name,
          lastModified = fileData.lastModified;

      var file = _file.default.create({
        name: name,
        resource: resource,
        lastModified: lastModified,
        data: fileData
      });

      return file;
    },


    actions: {
      clearFile: function clearFile(file) {
        var selectedFiles = this.get('selectedFiles');

        if (isEmpty(selectedFiles)) return;

        var filteredFiles = selectedFiles.rejectBy('name', file.name);

        this.set('selectedFiles', filteredFiles);
      },
      clearErrors: function clearErrors() {
        this.set('dragAndDropUploadError', null);
      },
      handleDragAndDropError: function handleDragAndDropError(message) {
        this.set('dragAndDropUploadError', message);
      },
      onFilesSelected: function onFilesSelected(files) {
        var _this = this;

        var result = void 0;
        var isMultiple = this.get('isMultiple');
        var fileDataList = Array.from(files);
        var resource = this.get('resource');

        if (isMultiple) {
          result = fileDataList.map(function (file) {
            return _this._createFile(file, resource);
          });
        } else {
          var fileData = _lodash.default.first(fileDataList);

          result = [this._createFile(fileData, resource)];
        }

        this.send('clearErrors');
        this.set('selectedFiles', result);
      }
    }
  });
});