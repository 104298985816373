define('middle-office/pods/components/app-sidebar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var observer = Ember.observer;
  var computed = Ember.computed;
  var later = Ember.run.later;
  var schedule = Ember.run.schedule;
  var on = Ember.on;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var $ = Ember.$;
  exports.default = Component.extend({
    classNames: ['app-sidebar'],

    store: service('store'),

    scroller: service('scroller'),

    _bindEvents: on('didInsertElement', function () {
      var _this = this;

      var $sticky = this.$('.sticky');
      var $accordion = this.$('.accordion');

      if ($sticky == null || $accordion == null) {
        return;
      }

      $sticky.sticky({
        context: '.application-form',
        observeChanges: true
      });

      $accordion.accordion({
        exclusive: false,
        onChange: function onChange() {
          $sticky.sticky('refresh');
        }
      });

      later(function () {
        _this.bindSidebarVisibility();
      });
    }),

    bindSidebarVisibility: function bindSidebarVisibility() {
      var $sidebar = $('.app-sidebar');
      var $steps = $('.form-step');
      var self = this;

      function toggleActiveItem() {
        var link = $sidebar.find('[href="#' + this.id + '"]');
        var accordionIndex = link.parents('.item:first').index();
        var $accordion = self.$('.accordion');

        if (link.length > 0 && $accordion != null) {
          $sidebar.find('.item').removeClass('active');
          $accordion.accordion('open', accordionIndex);
          link.addClass('active');
        }
      }

      $steps.visibility({
        once: false,
        onTopPassedReverse: toggleActiveItem,
        onTopVisible: toggleActiveItem,
        onRefresh: function onRefresh() {
          self.bindSidebarVisibility();
        }
      });
    },


    rebindSidebar: observer('application.bariguiApplication', 'application.bancoPanApplication', 'application.bancoPaulistaApplication', 'application.chbApplication', 'application.domusApplication', 'application.fapaApplication', 'application.requester.spouse', 'application.additionalRequester.spouse', 'application.otherAdditionalRequester.spouse', function () {
      var _this2 = this;

      schedule('afterRender', function () {
        _this2.bindSidebarVisibility();
      });
    }),

    requesterLinks: computed('application.requester.fullName', 'application.requester.shouldHaveSpouse', function () {
      return this.getRequesterLinks();
    }),

    additionalRequesterLinks: computed('application.additionalRequester.fullName', 'application.additionalRequester.shouldHaveSpouse', function () {
      return this.getAdditionalRequesterLinks();
    }),

    otherAdditionalRequesterLinks: computed('application.otherAdditionalRequester.fullName', 'application.otherAdditionalRequester.shouldHaveSpouse', function () {
      return this.getOtherAdditionalRequesterLinks();
    }),

    realEstateLinks: [{
      text: 'Dados da garantia (imóvel)',
      href: '#real-estate-data'
    }, {
      text: 'Dados do empréstimo',
      href: '#loan-data'
    }],

    summaryLinks: [{
      text: 'Dados do Solicitante Principal',
      href: '#summary-requester-data'
    }, {
      text: 'Dados da garantia (imóvel)',
      href: '#sumary-real-estate-data'
    }, {
      text: 'Dados da operação',
      href: '#summary-loan-data'
    }],

    getRequesterLinks: function getRequesterLinks() {
      var requester = this.get('application.requester');

      var requesterLinks = [{
        text: 'Dados pessoais',
        href: '#requester-personal-data'
      }, {
        text: 'Dados de contato',
        href: '#requester-contact-data'
      }, {
        text: 'Dados financeiros',
        href: '#requester-financial-data'
      }, {
        text: 'Dados profissionais',
        href: '#requester-professional-data'
      }];

      if (requester.get('shouldHaveSpouse')) {
        requesterLinks.push({
          text: 'Dados do cônjuge',
          href: '#requester-spouse-data'
        });
      }

      return requesterLinks;
    },
    getAdditionalRequesterLinks: function getAdditionalRequesterLinks() {
      var additionalRequester = this.get('application.additionalRequester');

      return this.getExtraRequestersLinks(additionalRequester, 'additional-requester');
    },
    getOtherAdditionalRequesterLinks: function getOtherAdditionalRequesterLinks() {
      var otherAdditionalRequester = this.get('application.otherAdditionalRequester');

      return this.getExtraRequestersLinks(otherAdditionalRequester, 'other-additional-requester');
    },
    getExtraRequestersLinks: function getExtraRequestersLinks(requester, id) {
      var extraRequesterLinks = [{
        text: 'Dados pessoais',
        href: '#' + id + '-personal-data'
      }, {
        text: 'Dados de contato',
        href: '#' + id + '-contact-data'
      }, {
        text: 'Dados profissionais',
        href: '#' + id + '-professional-data'
      }];

      if (requester.get('shouldHaveSpouse')) {
        extraRequesterLinks.push({
          text: 'Dados do cônjuge',
          href: '#' + id + '-spouse-data'
        });
      }

      return extraRequesterLinks;
    },


    personPreparation: service('home-refi/person-preparation'),

    createRequester: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
        var requester;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                requester = this.get('store').createRecord('loan/home-refi/bkf/person');
                _context.next = 3;
                return this.get('personPreparation').prepareRequester(requester);

              case 3:
                return _context.abrupt('return', requester);

              case 4:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function createRequester() {
        return _ref.apply(this, arguments);
      }

      return createRequester;
    }(),


    actions: {
      updateAdditionalRequester: function () {
        var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(evt) {
          var _this3 = this;

          var toggle = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
          var additionalRequester;
          return regeneratorRuntime.wrap(function _callee2$(_context2) {
            while (1) {
              switch (_context2.prev = _context2.next) {
                case 0:
                  _context2.next = 2;
                  return this.get('application.additionalRequester');

                case 2:
                  additionalRequester = _context2.sent;

                  if (!(toggle && additionalRequester == null)) {
                    _context2.next = 9;
                    break;
                  }

                  _context2.t0 = this;
                  _context2.next = 7;
                  return this.createRequester();

                case 7:
                  _context2.t1 = _context2.sent;

                  _context2.t0.set.call(_context2.t0, 'application.additionalRequester', _context2.t1);

                case 9:

                  this.set('application.hasAdditionalRequester', toggle);

                  schedule('afterRender', function () {
                    _this3.bindSidebarVisibility();
                    _this3.get('scroller').scrollVertical('#additional-requester');
                  });

                case 11:
                case 'end':
                  return _context2.stop();
              }
            }
          }, _callee2, this);
        }));

        function updateAdditionalRequester(_x2) {
          return _ref2.apply(this, arguments);
        }

        return updateAdditionalRequester;
      }(),
      updateOtherAdditionalRequester: function () {
        var _ref3 = _asyncToGenerator(regeneratorRuntime.mark(function _callee3(evt) {
          var _this4 = this;

          var toggle = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
          var otherAdditionalRequester;
          return regeneratorRuntime.wrap(function _callee3$(_context3) {
            while (1) {
              switch (_context3.prev = _context3.next) {
                case 0:
                  _context3.next = 2;
                  return this.get('application.otherAdditionalRequester');

                case 2:
                  otherAdditionalRequester = _context3.sent;

                  if (!(toggle && otherAdditionalRequester == null)) {
                    _context3.next = 9;
                    break;
                  }

                  _context3.t0 = this;
                  _context3.next = 7;
                  return this.createRequester();

                case 7:
                  _context3.t1 = _context3.sent;

                  _context3.t0.set.call(_context3.t0, 'application.otherAdditionalRequester', _context3.t1);

                case 9:

                  this.set('application.hasOtherAdditionalRequester', toggle);

                  schedule('afterRender', function () {
                    _this4.bindSidebarVisibility();
                    _this4.get('scroller').scrollVertical('#other-additional-requester');
                  });

                case 11:
                case 'end':
                  return _context3.stop();
              }
            }
          }, _callee3, this);
        }));

        function updateOtherAdditionalRequester(_x4) {
          return _ref3.apply(this, arguments);
        }

        return updateOtherAdditionalRequester;
      }()
    }
  });
});