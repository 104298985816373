define('middle-office/pods/components/app-sticky-bar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['app-sticky-bar', 'ui', 'sticky', 'fixed'],

    classNameBindings: ['_position'],

    _position: computed('position', function () {
      return this.get('position') || 'bottom';
    })
  });
});