define('middle-office/pods/components/app-forms/application/home-refi/steps/spouse/component', ['exports', 'lodash', 'ember-cli-bkf-core/enums/document-type'], function (exports, _lodash, _documentType) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var service = Ember.inject.service;
  var bool = Ember.computed.bool;
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['spouse-data', 'form-step'],

    showDocumentInfo: computed('spouse.documentType', function () {
      return ['rg', 'cnh'].includes(this.get('spouse.documentType'));
    }),

    isRequesterSpouse: bool('spouse.isRequesterSpouse'),

    documentTypeAlias: computed('spouse.documentType', function () {
      var options = _documentType.default;
      var docType = this.get('spouse.documentType');
      var obj = _lodash.default.find(options, { value: docType });

      if (obj) {
        return obj.text;
      }

      return null;
    }),

    isAdditionalRequester: computed('spouse', 'application.additionalRequester', function () {
      return this.get('spouse.id') === this.get('application.additionalRequester.id');
    }),

    isOtherAdditionalRequester: computed('spouse', 'application.otherAdditionalRequester', function () {
      return this.get('spouse.id') === this.get('application.otherAdditionalRequester.id');
    }),

    canBeAdditionalRequester: computed('spouse', 'application.requester', 'application.additionalRequester', 'application.otherAdditionalRequester', function () {
      var application = this.get('application');
      var spouse = this.get('spouse');

      if (spouse.get('spouse.id') !== application.get('requester.id')) {
        return false;
      }

      if (!application.get('hasAdditionalRequester') || !application.get('hasOtherAdditionalRequester') || this.get('isRequesterSpouse')) {
        return true;
      }

      return false;
    }),

    personPreparation: service('home-refi/person-preparation'),

    clearAdditionalRequester: function clearAdditionalRequester(application) {
      if (this.get('isAdditionalRequester')) {
        application.set('additionalRequester', null);
      }

      if (this.get('isOtherAdditionalRequester')) {
        application.set('otherAdditionalRequester', null);
      }
    },
    setAdditionalRequester: function setAdditionalRequester(application, spouse) {
      if (!application.get('hasAdditionalRequester')) {
        application.set('additionalRequester', spouse);
        return;
      }

      if (!application.get('hasOtherAdditionalRequester')) {
        application.set('otherAdditionalRequester', spouse);
      }
    },


    actions: {
      setSpouseAsRequester: function () {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(check) {
          var spouse, application;
          return regeneratorRuntime.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  spouse = this.get('spouse');
                  application = this.get('application');

                  if (!check) {
                    _context.next = 8;
                    break;
                  }

                  _context.next = 5;
                  return this.get('personPreparation').prepareRequester(spouse);

                case 5:
                  this.setAdditionalRequester(application, spouse);
                  _context.next = 9;
                  break;

                case 8:
                  this.clearAdditionalRequester(application);

                case 9:
                case 'end':
                  return _context.stop();
              }
            }
          }, _callee, this);
        }));

        function setSpouseAsRequester(_x) {
          return _ref.apply(this, arguments);
        }

        return setSpouseAsRequester;
      }()
    }
  });
});