define('middle-office/pods/components/app-forms/application/home-refi/steps/professional/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['professional-data', 'form-step'],

    store: service('store'),

    professionalStatusForm: computed('person.currentEmployment.professionalStatus', function () {
      var professionalStatus = this.get('person.currentEmployment.professionalStatus');
      return 'app-forms/application/home-refi/steps/professional/' + professionalStatus;
    }),

    actions: {
      updateCompanyAddress: function () {
        var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
          var employment;
          return regeneratorRuntime.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  _context.next = 2;
                  return this.get('person.currentEmployment');

                case 2:
                  employment = _context.sent;
                  return _context.abrupt('return', employment.handleCompanyAddress());

                case 4:
                case 'end':
                  return _context.stop();
              }
            }
          }, _callee, this);
        }));

        function updateCompanyAddress() {
          return _ref.apply(this, arguments);
        }

        return updateCompanyAddress;
      }()
    }
  });
});