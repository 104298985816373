define('middle-office/pods/components/field-errors/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var notEmpty = Ember.computed.notEmpty;
  exports.default = Component.extend({
    classNames: ['field-errors'],
    showErrors: notEmpty('errors'),
    errors: []
  });
});