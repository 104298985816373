define('middle-office/pods/auto-refi/inspections/cancel/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var service = Ember.inject.service;
  var alias = Ember.computed.alias;
  var reads = Ember.computed.reads;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    store: service(),
    rollbar: service(),

    inspection: alias('model'),
    isInvalid: reads('inspection.validations.isInvalid'),

    _handleError: function _handleError(error, payload) {
      var message = error.message;

      var rollbar = this.get('rollbar');

      this.set('feedbackMessage', {
        type: 'error',
        title: 'Erro ao cancelar agendamento de vistoria...',
        stackTrace: message
      });
      rollbar.error('Failure while updating an inspection', error, {
        message: message,
        payload: payload
      });
    },
    _saveInspection: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(inspection) {
        var payload;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.prev = 0;
                _context.next = 3;
                return inspection.save();

              case 3:
                this._transitionToCreateInspection();
                _context.next = 10;
                break;

              case 6:
                _context.prev = 6;
                _context.t0 = _context['catch'](0);
                payload = JSON.stringify(inspection);

                this._handleError(_context.t0, payload);

              case 10:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this, [[0, 6]]);
      }));

      function _saveInspection(_x) {
        return _ref.apply(this, arguments);
      }

      return _saveInspection;
    }(),
    _transitionToCreateInspection: function _transitionToCreateInspection() {
      this.transitionToRoute('auto-refi.inspections.new');
    },
    _transitionToInspections: function _transitionToInspections() {
      this.transitionToRoute('auto-refi.inspections.list');
    },


    actions: {
      updateInspection: function () {
        var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2() {
          var _getProperties, inspection, isInvalid;

          return regeneratorRuntime.wrap(function _callee2$(_context2) {
            while (1) {
              switch (_context2.prev = _context2.next) {
                case 0:
                  _getProperties = this.getProperties('inspection', 'isInvalid'), inspection = _getProperties.inspection, isInvalid = _getProperties.isInvalid;

                  if (!isInvalid) {
                    _context2.next = 3;
                    break;
                  }

                  return _context2.abrupt('return', this.set('showErrors', isInvalid));

                case 3:
                  return _context2.abrupt('return', this._saveInspection(inspection));

                case 4:
                case 'end':
                  return _context2.stop();
              }
            }
          }, _callee2, this);
        }));

        function updateInspection() {
          return _ref2.apply(this, arguments);
        }

        return updateInspection;
      }(),
      cancel: function cancel() {
        this._transitionToInspections();
      }
    }
  });
});