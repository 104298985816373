define('middle-office/pods/admin/users/new/controller', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var later = Ember.run.later;
  var reads = Ember.computed.reads;
  var Controller = Ember.Controller;
  var ConflictError = _emberData.default.ConflictError;
  exports.default = Controller.extend({
    showErrors: false,
    isLoading: reads('model.isSaving'),
    errorMessage: null,

    setErrorMessage: function setErrorMessage(e) {
      var _this = this;

      if (e instanceof ConflictError) {
        this.set('errorMessage', 'Falha ao criar novo consultor. Este email parece já estar cadastrado.');
        later(function () {
          return _this.set('errorMessage', null);
        }, 5000);
      } else {
        this.set('errorMessage', 'Falha ao criar novo consultor :(  Por favor tente novamente.');
        later(function () {
          return _this.set('errorMessage', null);
        }, 5000);
      }
    },


    actions: {
      save: function save(event) {
        event.preventDefault();
        var isConsultantValid = this.get('model.validations.isValid');

        if (!isConsultantValid) {
          this.set('showErrors', true);
          return;
        }

        this._saveConsultant(this.get('model'));
        this.set('showErrors', false);
      }
    },

    _saveConsultant: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(consultant) {
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.prev = 0;
                _context.next = 3;
                return consultant.save();

              case 3:
                this.transitionToRoute('admin.users');
                _context.next = 9;
                break;

              case 6:
                _context.prev = 6;
                _context.t0 = _context['catch'](0);

                this.setErrorMessage(_context.t0);

              case 9:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this, [[0, 6]]);
      }));

      function _saveConsultant(_x) {
        return _ref.apply(this, arguments);
      }

      return _saveConsultant;
    }()
  });
});