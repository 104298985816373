define('middle-office/services/external-information/all-check-response', ['exports', 'middle-office/utils/all-check/normalize'], function (exports, _normalize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;


  var Response = EmberObject.extend({});

  Response.reopenClass({
    createWithRawData: function createWithRawData(json) {
      var properties = (0, _normalize.default)(json);
      return this.create(properties);
    }
  });

  exports.default = Response;
});