define('middle-office/pods/components/app-tags/tags/motoboy-correios', ['exports', 'middle-office/pods/components/app-tags/tags/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var reads = Ember.computed.reads;
  var match = Ember.computed.match;
  var equal = Ember.computed.equal;
  var computed = Ember.computed;
  exports.default = _base.default.extend({
    name: computed('isMotoboy', function () {
      var isMotoboy = this.get('isMotoboy');

      return isMotoboy ? 'Motoboy' : 'Correios';
    }),
    key: 'motoboy-correios',

    currentPartnerApplication: reads('application.currentPartnerApplication'),
    sentBranch: reads('currentPartnerApplication.sentBranch'),
    selectedPartnerName: reads('currentPartnerApplication.partnerName'),

    isFidc: match('selectedPartnerName', /FIDC/i),
    isMotoboy: match('sentBranch.name', /\(Motoboy\)/i),
    available: equal('isFidc', true)
  });
});