define('middle-office/pods/components/app-forms/application/home-refi/partners/fapa/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['fapa-application', 'form-step'],

    abroadCountryOptions: computed(function () {
      var abroadCountry = this.get('fapaApplication.abroadCountry');
      var otherCountry = abroadCountry === 'Estados Unidos' ? 'other' : abroadCountry;

      return [{
        text: 'Estados Unidos',
        value: 'Estados Unidos'
      }, {
        text: 'Outro',
        value: otherCountry
      }];
    }),

    abroadCountry: computed(function () {
      return this.get('fapaApplication.abroadCountry');
    }),

    lastAbroadCountry: null,

    actions: {
      updateAbroadCountry: function updateAbroadCountry(value) {
        if (value === 'Estados Unidos') {
          var abroadCountry = this.get('fapaApplication.abroadCountry');

          this.set('lastAbroadCountry', abroadCountry);
          this.set('fapaApplication.abroadCountry', value);

          return;
        }

        var lastAbroadCountry = this.get('lastAbroadCountry');
        this.set('fapaApplication.abroadCountry', lastAbroadCountry);
      }
    }
  });
});