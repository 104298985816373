define('middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/eligibility', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  function isEligible(application, partner) {
    var partnerApplication = application[partner + 'Application'];
    return partnerApplication.status === 'ready';
  }

  function eligibility(application) {
    var eligibleFidc = isEligible(application, 'fidc') ? 'X' : ' ';
    var eligibleSantana = isEligible(application, 'santana') ? 'X' : ' ';
    var eligibleBV = isEligible(application, 'bv') ? 'X' : ' ';

    return 'Eleg\xEDvel para:\n(' + eligibleFidc + ') FIDC\n(' + eligibleBV + ') BV\n(' + eligibleSantana + ') Santana';
  }

  exports.default = eligibility;
});