define('middle-office/models/crm/customer', ['exports', 'ember-cli-bkf-core/models/crm/customer', 'ember-cp-validations', 'ember-data'], function (exports, _customer, _emberCpValidations, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CustomerValidations = (0, _emberCpValidations.buildValidations)({
    password: []
  });

  exports.default = _customer.default.extend(CustomerValidations, {
    autoFinancingApplications: _emberData.default.hasMany('auto/financing/application'),
    sendEmailConfirmation: _emberData.default.attr('boolean')
  });
});