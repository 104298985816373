define('middle-office/pods/components/app-forms/closing-loan/component', ['exports', 'middle-office/mixins/close-loan'], function (exports, _closeLoan) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend(_closeLoan.default, {
    classNames: ['ui', 'grid', 'container', 'main'],
    tagName: 'main'
  });
});