define('middle-office/enums/auto-refi/income-proofs', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.textForIncomeProof = exports.incomeProofs = undefined;
  var incomeProofs = exports.incomeProofs = [{
    text: 'holerite',
    value: 'paycheck'
  }, {
    text: 'extrato bancário',
    value: 'bank_statement'
  }, {
    text: 'imposto de renda',
    value: 'income_tax_return'
  }];

  exports.default = incomeProofs;
  var textForIncomeProof = exports.textForIncomeProof = function textForIncomeProof(value) {
    return _lodash.default.find(incomeProofs, { value: value }).text;
  };
  var values = exports.values = _lodash.default.map(incomeProofs, 'value');
});