define('middle-office/models/analysis/auto-refi/data/credit-reference', ['exports', 'ember-data', 'ember-data-copyable', 'ember-cp-validations'], function (exports, _emberData, _emberDataCopyable, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var hash = Ember.RSVP.hash;


  var Validations = (0, _emberCpValidations.buildValidations)({
    residenceAddress: (0, _emberCpValidations.validator)('belongs-to'),
    companyAddress: (0, _emberCpValidations.validator)('belongs-to'),

    fullName: [(0, _emberCpValidations.validator)('length', {
      max: 200,
      allowBlank: true
    })],
    motherName: [(0, _emberCpValidations.validator)('length', {
      max: 200,
      allowBlank: true
    })],
    rg: [(0, _emberCpValidations.validator)('length', {
      min: 8,
      max: 15,
      allowBlank: true
    })],
    cnh: [(0, _emberCpValidations.validator)('length', {
      min: 6,
      max: 14,
      allowBlank: true
    })],
    cpf: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      is: 11,
      allowBlank: false
    })],
    pis: [(0, _emberCpValidations.validator)('length', {
      min: 11,
      max: 15,
      allowBlank: true
    })],
    professionalStatus: [(0, _emberCpValidations.validator)('length', {
      max: 20,
      allowBlank: true
    })],
    cnpj: [(0, _emberCpValidations.validator)('length', {
      max: 14,
      allowBlank: true
    })],
    jobTitle: [(0, _emberCpValidations.validator)('length', {
      max: 50,
      allowBlank: true
    })],
    residencePhoneCode: [(0, _emberCpValidations.validator)('length', {
      is: 2,
      allowBlank: true
    })],
    residencePhone: [(0, _emberCpValidations.validator)('length', {
      min: 8,
      max: 9,
      allowBlank: true
    })],
    companyName: [(0, _emberCpValidations.validator)('length', {
      max: 200,
      allowBlank: true
    })],
    companyPhoneCode: [(0, _emberCpValidations.validator)('length', {
      is: 2,
      allowBlank: true
    })],
    companyPhone: [(0, _emberCpValidations.validator)('length', {
      min: 8,
      max: 9,
      allowBlank: true
    })],
    referencePhoneCode: [(0, _emberCpValidations.validator)('length', {
      is: 2,
      allowBlank: true
    })],
    referencePhone: [(0, _emberCpValidations.validator)('length', {
      min: 8,
      max: 9,
      allowBlank: true
    })],
    chassi: [(0, _emberCpValidations.validator)('length', {
      max: 20,
      allowBlank: true
    })],
    renavam: [(0, _emberCpValidations.validator)('length', {
      max: 11,
      allowBlank: true
    })],
    plate: [(0, _emberCpValidations.validator)('length', {
      max: 8,
      allowBlank: true
    })],
    ipvaSituation: [(0, _emberCpValidations.validator)('length', {
      max: 20,
      allowBlank: true
    })],
    vehicleOwner: [(0, _emberCpValidations.validator)('length', {
      max: 200,
      allowBlank: true
    })],
    vehicleBrand: [(0, _emberCpValidations.validator)('length', {
      max: 200,
      allowBlank: true
    })],
    vehicleModel: [(0, _emberCpValidations.validator)('length', {
      max: 200,
      allowBlank: true
    })],
    vehicleMolicarCode: [(0, _emberCpValidations.validator)('length', {
      max: 50,
      allowBlank: true
    })],
    additionalInformation: [(0, _emberCpValidations.validator)('length', {
      max: 2048,
      allowBlank: true
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, _emberDataCopyable.default, {
    duplicate: function duplicate() {
      return this.copy(false);
    },
    duplicateWithoutCommittee: function duplicateWithoutCommittee() {
      return this.copy(false, {
        ignoreAttributes: ['committee']
      });
    },
    fillWithApplication: function fillWithApplication(application) {
      return this.setProperties({
        serasaScoreAlias: application.get('serasaScoreRange'),
        vehicleBrand: application.get('vehicleBrand'),
        vehicleModel: application.get('vehicleModel'),
        vehicleManufacturingYear: application.get('vehicleManufacturingYear'),
        vehicleMolicarCode: application.get('molicarCode'),
        vehicleMolicarValue: application.get('purchaseValue'),
        plate: application.get('licensePlate'),
        renavam: application.get('renavam'),
        alienation: application.get('vehicleDebt')
      });
    },
    fillWithRequester: function fillWithRequester(requester) {
      return this.setProperties({
        fullName: requester.get('fullName'),
        motherName: requester.get('motherFullName'),
        rg: requester.get('rgNumber'),
        cnh: requester.get('cnh'),
        cpf: requester.get('cpf'),
        birthDate: requester.get('birthDate'),
        residencePhoneCode: requester.get('contactResidentialTelephoneCode'),
        residencePhoneNumber: requester.get('contactResidentialTelephone'),
        companyPhoneCode: requester.get('contactProfessionalPhoneCode'),
        companyPhoneNumber: requester.get('contactProfessionalPhone'),
        referencePhoneCode: requester.get('personalReferencePhoneCode'),
        referencePhoneNumber: requester.get('personalReferencePhone')
      });
    },
    fillWithEmployment: function fillWithEmployment(employment) {
      return this.setProperties({
        cnpj: employment.get('companyCnpj'),
        professionalStatus: employment.get('professionalStatus'),
        jobTitle: employment.get('jobTitle'),
        companyName: employment.get('companyName'),
        timeOfActivity: employment.get('timeOfEmployment')
      });
    },
    fillWithAddress: function fillWithAddress(address) {
      return this.setProperties({
        timeOfResidence: address.get('timeOfResidence')
      });
    },
    addresses: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
        var creditResidenceAddress, creditProfessionalAddress;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return this.get('residenceAddress');

              case 2:
                creditResidenceAddress = _context.sent;
                _context.next = 5;
                return this.get('companyAddress');

              case 5:
                creditProfessionalAddress = _context.sent;
                return _context.abrupt('return', hash({
                  creditResidenceAddress: creditResidenceAddress,
                  creditProfessionalAddress: creditProfessionalAddress
                }));

              case 7:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function addresses() {
        return _ref.apply(this, arguments);
      }

      return addresses;
    }(),


    residenceAddress: _emberData.default.belongsTo('analysis/auto-refi/data/address'),
    companyAddress: _emberData.default.belongsTo('analysis/auto-refi/data/address'),
    fidcApplication: _emberData.default.belongsTo('underwriting/auto-refi/fidc/application'),

    serasaScoreAlias: _emberData.default.attr('string'),
    cadinActiveDebt: _emberData.default.attr('number'),
    fullName: _emberData.default.attr('string'),
    motherName: _emberData.default.attr('string'),
    rg: _emberData.default.attr('string'),
    cnh: _emberData.default.attr('string'),
    cpf: _emberData.default.attr('string'),
    pis: _emberData.default.attr('string'),
    birthDate: _emberData.default.attr('pure-date'),
    reportedAsDead: _emberData.default.attr('boolean'),
    unemploymentInsuranceSituation: _emberData.default.attr('string'),
    unemploymentInsuranceInitialDate: _emberData.default.attr('pure-date'),
    unemploymentInsuranceFinalDate: _emberData.default.attr('pure-date'),
    declaresIncomeTax: _emberData.default.attr('boolean'),
    professionalStatus: _emberData.default.attr('string'),
    cnpj: _emberData.default.attr('string'),
    jobTitle: _emberData.default.attr('string'),
    timeOfActivity: _emberData.default.attr('string'),
    tjCpfSituation: _emberData.default.attr('string'),
    tjCnpjSituation: _emberData.default.attr('string'),
    residencePhoneCode: _emberData.default.attr('string'),
    residencePhoneNumber: _emberData.default.attr('string'),
    timeOfResidence: _emberData.default.attr('string'),
    companyName: _emberData.default.attr('string'),
    companyPhoneCode: _emberData.default.attr('string'),
    companyPhoneNumber: _emberData.default.attr('string'),
    referencePhoneCode: _emberData.default.attr('string'),
    referencePhoneNumber: _emberData.default.attr('string'),
    serasaFinancialPendingQuantity: _emberData.default.attr('number'),
    serasaFinancialPendingTotal: _emberData.default.attr('number'),
    serasaRefinancingPendingQuantity: _emberData.default.attr('number'),
    serasaRefinancingPendingTotal: _emberData.default.attr('number'),
    nationalProtestQuantity: _emberData.default.attr('number'),
    nationalProtestTotal: _emberData.default.attr('number'),
    badCheck: _emberData.default.attr('number'),
    bacenOverdueDebt: _emberData.default.attr('number'),
    bacenLoss: _emberData.default.attr('number'),
    bacenTotalDebt: _emberData.default.attr('number'),
    debtServiceRatio: _emberData.default.attr('number'),
    vehicleAuction: _emberData.default.attr('boolean'),
    chassi: _emberData.default.attr('string'),
    renavam: _emberData.default.attr('string'),
    plate: _emberData.default.attr('string'),
    trafficTicket: _emberData.default.attr('number'),
    ipvaSituation: _emberData.default.attr('string'),
    ipvaDebt: _emberData.default.attr('number'),
    alienation: _emberData.default.attr('boolean'),
    alienationTotal: _emberData.default.attr('number'),
    vehicleOwner: _emberData.default.attr('string'),
    vehicleBrand: _emberData.default.attr('string'),
    vehicleModel: _emberData.default.attr('string'),
    vehicleManufacturingYear: _emberData.default.attr('number'),
    vehicleMolicarCode: _emberData.default.attr('string'),
    vehicleMolicarValue: _emberData.default.attr('number'),
    additionalInformation: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    committee: _emberData.default.attr('boolean'),
    requestCreditReferenceAttributes: _emberData.default.attr('boolean')
  });
});