define('middle-office/pods/components/app-forms/application/home-refi/bacen-authorizations/component', ['exports', 'middle-office/utils/build-enum'], function (exports, _buildEnum) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var later = Ember.run.later;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    store: service('store'),
    blobDownload: service('blob-download'),

    classNames: ['bacen-authorizations'],

    feedbackMessage: null,

    partners: [{ label: 'Barigui', name: 'barigui' }, { label: 'Máxima', name: 'maxima' }, { label: 'BV', name: 'bv' }],
    bariguiSelectedPerson: null,
    maximaSelectedPerson: null,
    bvSelectedPerson: null,

    peopleList: computed('allPeople', function () {
      return (0, _buildEnum.default)({
        items: this.get('allPeople'),
        textKey: 'fullName',
        valueKey: 'id',
        includeBlank: true
      });
    }),

    _setFeedbackMessage: function _setFeedbackMessage(message) {
      var _this = this;

      this.set('feedbackMessage', {
        type: 'error',
        title: 'Ooops D:',
        text: message
      });

      later(function () {
        return _this.set('feedbackMessage', null);
      }, 3500);
    },
    _downloadFile: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(selectedPerson, partner) {
        var store, person, url;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                store = this.get('store');
                person = store.peekRecord('loan/home-refi/bkf/person', selectedPerson);
                url = person.get('bacenAuthorizations' + partner.capitalize() + 'DocumentUrl');
                _context.prev = 3;
                _context.next = 6;
                return this.get('blobDownload').downloadFile(url, person.get('cpf') + '-autorizacao-bacen-' + partner);

              case 6:
                _context.next = 11;
                break;

              case 8:
                _context.prev = 8;
                _context.t0 = _context['catch'](3);

                this._setFeedbackMessage('Falha ao realizar download da autorização do BACEN :(');

              case 11:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this, [[3, 8]]);
      }));

      function _downloadFile(_x, _x2) {
        return _ref.apply(this, arguments);
      }

      return _downloadFile;
    }(),
    _generateBacenAuthorization: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(partner) {
        var selectedPerson;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                selectedPerson = this.get(partner + 'SelectedPerson');

                if (selectedPerson) {
                  _context2.next = 4;
                  break;
                }

                this.$('.' + partner + '-people-list').dropdown('show');
                return _context2.abrupt('return');

              case 4:
                _context2.next = 6;
                return this._downloadFile(selectedPerson, partner);

              case 6:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function _generateBacenAuthorization(_x3) {
        return _ref2.apply(this, arguments);
      }

      return _generateBacenAuthorization;
    }(),


    actions: {
      onSelectPerson: function onSelectPerson(partner, personId) {
        this.set(partner + 'SelectedPerson', personId);
      },
      generateBacenAuthorization: function generateBacenAuthorization(partner, event) {
        event.preventDefault();

        this._generateBacenAuthorization(partner);
      }
    }
  });
});