define('middle-office/pods/components/application-modal/auto-refi/timeline/contact/component', ['exports', 'middle-office/enums/timeline/contact-result-type', 'middle-office/enums/timeline/interlocutor-type', 'ember-cli-bkf-core/enum-options', 'middle-office/pods/components/application-modal/auto-refi/timeline/record-mixin'], function (exports, _contactResultType, _interlocutorType, _enumOptions, _recordMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend(_recordMixin.default, {
    contactSuccess: true,

    failedContactResults: _contactResultType.default.filter(function (item) {
      return item.value !== 'success';
    }),
    filteredInterlocutor: computed('record.from', 'record.to', function () {
      var from = this.get('record.from');
      var to = this.get('record.to');
      return _interlocutorType.default.filter(function (item) {
        return item.value !== from && item.value !== to;
      });
    }),
    interlocutorTypes: computed('filteredInterlocutor', function () {
      var filteredInterlocutor = this.get('filteredInterlocutor');
      return [_enumOptions.blankOption].concat(_toConsumableArray(filteredInterlocutor));
    }),

    actions: {
      onApprove: function onApprove() {
        this._super();
      },
      onChangeContactSuccess: function onChangeContactSuccess(selected) {
        var contactResult = selected ? 'success' : null;
        this.setProperties({
          'record.contactResult': contactResult,
          contactSuccess: selected
        });
      },
      onChangeContactFailure: function onChangeContactFailure(selected) {
        this.set('record.contactResult', selected);
      }
    }
  });
});