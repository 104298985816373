define('middle-office/enums/loan/auto-refi/bkf/document-status', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.status = undefined;
  var status = exports.status = [{
    text: 'Validado',
    value: 'validated'
  }, {
    text: 'Completo',
    value: 'complete'
  }, {
    text: 'Em validação',
    value: 'validating'
  }, {
    text: 'Incompleto',
    value: 'incomplete'
  }];

  exports.default = status;
  var values = exports.values = _lodash.default.map(status, 'value');
});