define('middle-office/enums/underwriting/auto-refi/rejection-reasons/formalization', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.reasons = undefined;
  var reasons = exports.reasons = [{
    text: 'Vistoria: Leilão',
    value: 'appraisal_auction'
  }, {
    text: 'Vistoria: Sinistro',
    value: 'appraisal_claim'
  }, {
    text: 'Vistoria: Avarias',
    value: 'appraisal_damage'
  }, {
    text: 'Formalização: Suspeita de fraude',
    value: 'fraud_suspect'
  }, {
    text: 'Formalização: Documentos do cliente',
    value: 'customer_documentation'
  }, {
    text: 'Formalização: Documentos do veículo',
    value: 'vehicle_documentation'
  }, {
    text: 'Sem Interesse: Taxa/PMT Alta',
    value: 'high_rates'
  }, {
    text: 'Sem Interesse: Valor insuficiente',
    value: 'loan_value_lower_than_expected'
  }, {
    text: 'Sem Interesse: Cliente não localizado',
    value: 'customer_not_found'
  }, {
    text: 'Sem Interesse: Vendeu o veículo',
    value: 'vehicle_sold'
  }, {
    text: 'Sem Interesse: Fechou com outras instituições',
    value: 'closed_with_competitor'
  }, {
    text: 'Sem Interesse: Quer outro produto',
    value: 'interest_in_another_product'
  }, {
    text: 'Sem Interesse: Desistência',
    value: 'quit'
  }];

  exports.default = reasons;
  var values = exports.values = _lodash.default.map(reasons, 'value');
});