define('middle-office/pods/servicing/auto-loan/installments/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
        var loan;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                loan = this.modelFor('servicing/auto-loan');
                return _context.abrupt('return', loan);

              case 2:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function model() {
        return _ref.apply(this, arguments);
      }

      return model;
    }()
  });
});