define('middle-office/pods/admin/auto-refi/branches/edit/controller', ['exports', 'ember-changeset'], function (exports, _emberChangeset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var map = Ember.RSVP.map;
  var computedMap = Ember.computed.map;
  var notEmpty = Ember.computed.notEmpty;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    isSaving: false,
    saved: false,
    edited: false,
    changedPartners: computedMap('model.partners', function (partner) {
      var branches = partner.get('orderedBranches').map(function (branch) {
        return new _emberChangeset.default(branch);
      });

      return {
        name: partner.get('name'),
        branches: branches
      };
    }),

    hasPartners: notEmpty('model.partners'),

    actions: {
      setResponsible: function setResponsible(_ref) {
        var branch = _ref.branch,
            id = _ref.id;

        var consultant = this.get('model.consultants').findBy('id', id);

        branch.set('responsible', consultant);

        this.set('saved', false);
        this.set('edited', true);
      },
      submit: function () {
        var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
          return regeneratorRuntime.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  if (!(!this.get('edited') || this.get('isSaving'))) {
                    _context.next = 2;
                    break;
                  }

                  return _context.abrupt('return');

                case 2:

                  this.set('isSaving', true);

                  _context.next = 5;
                  return map(this.get('changedPartners'), function (partner) {
                    return map(partner.branches, function (branch) {
                      var isDirty = branch.get('isDirty');

                      if (isDirty) {
                        return branch.save();
                      }

                      return branch;
                    });
                  });

                case 5:

                  this.finishedSubmit();

                case 6:
                case 'end':
                  return _context.stop();
              }
            }
          }, _callee, this);
        }));

        function submit() {
          return _ref2.apply(this, arguments);
        }

        return submit;
      }(),
      discard: function discard() {
        this.get('changedPartners').forEach(function (partner) {
          partner.branches.forEach(function (branch) {
            branch.rollback();
          });
        });
      }
    },

    finishedSubmit: function finishedSubmit() {
      this.set('isSaving', false);
      this.set('saved', true);
    }
  });
});