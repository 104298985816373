define('middle-office/pods/servicing/auto-loan/installments/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var alias = Ember.computed.alias;
  var computed = Ember.computed;
  exports.default = Controller.extend({
    loan: alias('model'),
    installments: alias('loan.installments'),

    headers: computed(function () {
      return ['Parcela', 'Data de vencimento', 'Valor da parcela', 'Status', 'Data de pagamento', ' '];
    })
  });
});