define('middle-office/models/auto/financing/application', ['exports', 'ember-data/attr', 'ember-data/relationships', 'ember-data/model'], function (exports, _attr, _relationships, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    createdAt: (0, _attr.default)('date'),
    statusFlow: (0, _attr.default)('string'),
    comment: (0, _attr.default)('string'),
    contactHistory: (0, _attr.default)('string'),

    customer: (0, _relationships.belongsTo)('crm/customer'),
    responsibleConsultant: (0, _relationships.belongsTo)('crm/consultant'),

    // vehicle
    vehicleBrand: (0, _attr.default)('string'),
    vehicleModel: (0, _attr.default)('string'),
    vehicleManufacturingYear: (0, _attr.default)('number'),
    vehicleModelYear: (0, _attr.default)('number'),
    vehicleDebt: (0, _attr.default)('number'),
    purchaseValue: (0, _attr.default)('number'),
    licensePlate: (0, _attr.default)('string'),
    renavam: (0, _attr.default)('string'),

    // operation
    downPayment: (0, _attr.default)('number'),
    loanAmount: (0, _attr.default)('number'),
    interestTable: (0, _attr.default)('string'),
    financingTimeMonths: (0, _attr.default)('number'),
    installmentValue: (0, _attr.default)('number'),

    // requester
    requesterFullName: (0, _attr.default)('string'),
    requesterCpf: (0, _attr.default)('string'),
    requesterRgNumber: (0, _attr.default)('string'),
    requesterRgExpeditionDate: (0, _attr.default)('date'),
    requesterBirthDate: (0, _attr.default)('pure-date'),
    requesterCnh: (0, _attr.default)('string'),
    requesterCityOfBirth: (0, _attr.default)('string'),
    requesterMotherFullName: (0, _attr.default)('string'),
    requesterMaritalStatus: (0, _attr.default)('string'),
    requesterStableUnion: (0, _attr.default)('boolean'),
    requesterSpouseFullName: (0, _attr.default)('string'),
    requesterSpouseBirthDate: (0, _attr.default)('pure-date'),
    requesterContactResidentialTelephoneCode: (0, _attr.default)('string'),
    requesterContactResidentialTelephoneNumber: (0, _attr.default)('string'),
    requesterContactCellphoneCode: (0, _attr.default)('string'),
    requesterContactCellphoneNumber: (0, _attr.default)('string'),
    requesterContactProfessionalPhoneCode: (0, _attr.default)('string'),
    requesterContactProfessionalPhoneNumber: (0, _attr.default)('string'),
    requesterContactEmail: (0, _attr.default)('string'),
    requesterPersonalReferenceName: (0, _attr.default)('string'),
    requesterPersonalReferenceConnection: (0, _attr.default)('string'),
    requesterPersonalReferencePhoneCode: (0, _attr.default)('string'),
    requesterPersonalReferencePhoneNumber: (0, _attr.default)('string'),
    requesterMonthlyIncome: (0, _attr.default)('number'),
    requesterIncomeProof: (0, _attr.default)('string'),
    requesterFinanceBankNumber: (0, _attr.default)('string'),
    requesterFinanceBankAgencyNumber: (0, _attr.default)('string'),
    requesterFinanceBankAccountNumber: (0, _attr.default)('string'),
    requesterFinanceBankAccountTime: (0, _attr.default)('string'),
    requesterAddressZipCode: (0, _attr.default)('string'),
    requesterAddressStreetAddress: (0, _attr.default)('string'),
    requesterAddressStreetNumber: (0, _attr.default)('string'),
    requesterAddressStreetComplement: (0, _attr.default)('string'),
    requesterAddressNeighborhood: (0, _attr.default)('string'),
    requesterAddressCity: (0, _attr.default)('string'),
    requesterAddressState: (0, _attr.default)('string'),
    requesterAddressTimeOfResidence: (0, _attr.default)('string'),
    requesterResidenceOwner: (0, _attr.default)('boolean'),
    requesterEmploymentCompanyName: (0, _attr.default)('string'),
    requesterEmploymentProfessionalStatus: (0, _attr.default)('string'),
    requesterEmploymentCompanyCnpj: (0, _attr.default)('string'),
    requesterEmploymentTimeOfEmployment: (0, _attr.default)('string'),
    requesterEmploymentJobTitle: (0, _attr.default)('string'),
    requesterEmploymentClassEntityRegistrationNumber: (0, _attr.default)('string'),
    requesterEmploymentRetirementNumber: (0, _attr.default)('string'),
    requesterCompanyAddressZipCode: (0, _attr.default)('string'),
    requesterCompanyAddressStreetAddress: (0, _attr.default)('string'),
    requesterCompanyAddressStreetNumber: (0, _attr.default)('string'),
    requesterCompanyAddressStreetComplement: (0, _attr.default)('string'),
    requesterCompanyAddressNeighborhood: (0, _attr.default)('string'),
    requesterCompanyAddressCity: (0, _attr.default)('string'),
    requesterCompanyAddressState: (0, _attr.default)('string'),
    requesterSerasaScore: (0, _attr.default)('number'),

    // seller
    sellerCpf: (0, _attr.default)('string'),
    sellerFullName: (0, _attr.default)('string'),
    sellerPhoneCode: (0, _attr.default)('string'),
    sellerPhoneNumber: (0, _attr.default)('string'),
    sellerContactEmail: (0, _attr.default)('string'),
    sellerFinanceBankNumber: (0, _attr.default)('string'),
    sellerFinanceBankAgencyNumber: (0, _attr.default)('string'),
    sellerFinanceBankAccountNumber: (0, _attr.default)('string'),

    // credit analysis
    pendingDocuments: (0, _attr.default)('string'),
    calculatorAutoValue: (0, _attr.default)('number'),
    calculatorAutoModelYear: (0, _attr.default)('number'),
    calculatorContractLoanAmount: (0, _attr.default)('number'),
    calculatorFamilyMonthlyIncome: (0, _attr.default)('number'),
    calculatorMaturity: (0, _attr.default)('number'),
    calculatorPriceTaxMonthly: (0, _attr.default)('number'),
    calculatorDetranValue: (0, _attr.default)('number'),
    calculatorTac: (0, _attr.default)('number'),
    calculatorIof: (0, _attr.default)('number'),
    calculatorPmt: (0, _attr.default)('number'),
    calculatorPaidValue: (0, _attr.default)('number'),
    calculatorTotalEffectiveCostMonthly: (0, _attr.default)('number'),
    calculatorTotalEffectiveCostYear: (0, _attr.default)('number'),
    rejectionReason: (0, _attr.default)('string'),
    rejectionDescription: (0, _attr.default)('string'),

    shouldHaveSpouse: computed('requesterMaritalStatus', 'requesterStablen', function () {
      return this.get('requesterMaritalStatus') === 'married' || this.get('requesterStableUnion');
    }),

    vehicleHasNoDebts: computed('vehicleDebt', function () {
      var debt = this.get('vehicleDebt') || 0;

      return debt === 0;
    })
  });
});