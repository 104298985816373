define('middle-office/enums/auto-refi/partner', ['exports', 'lodash', 'middle-office/pods/auto-refi/partners'], function (exports, _lodash, _partners) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.partner = undefined;
  var partner = exports.partner = _lodash.default.map(_partners.default, function (value, key) {
    return {
      text: key,
      value: key
    };
  });

  exports.default = partner;
  var values = exports.values = _lodash.default.map(partner, 'value');
});