define('middle-office/pods/home-refi/application-form/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    applicationForm: service('home-refi/application-form'),

    actions: {
      submitApplicationForm: function submitApplicationForm() {
        var application = this.get('application');
        return this.get('applicationForm').save(application);
      }
    }
  });
});