define('middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/professional/clt', ['exports', 'middle-office/enums/auto-refi/time-ranges', 'middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/helpers/humanized'], function (exports, _timeRanges, _humanized) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function clt(employment) {
    var companyName = employment.companyName;
    var jobTitle = employment.jobTitle;
    var timeOfEmployment = (0, _timeRanges.textForTimeRange)(employment.timeOfEmployment);
    var address = (0, _humanized.humanizedAddress)(employment.companyAddress);

    return 'Trabalha como colaborador(a) CLT registrado(a) na empresa ' + companyName + ', ' + address + ' ' + timeOfEmployment + '. Exerce o cargo de ' + jobTitle + '.';
  }

  exports.default = clt;
});