define('middle-office/pods/components/auto-refi/application-molicar-modal/component', ['exports', 'middle-office/pods/components/app-modal/abstract-modal', 'middle-office/utils/build-enum', 'middle-office/utils/vehicle'], function (exports, _abstractModal, _buildEnum, _vehicle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var isNone = Ember.isNone;
  exports.default = _abstractModal.default.extend({
    selectedVehicle: null,
    application: null,

    possibleManufacturingYears: computed(function () {
      return (0, _buildEnum.default)({
        items: (0, _vehicle.possibleManufacturingYears)().map(function (y) {
          return y.toString();
        }),
        includeBlank: true
      });
    }),

    possibleModelYears: computed('_manufacturingYear', function () {
      var manufacturingYear = parseInt(this.get('_manufacturingYear'), 10);

      return (0, _vehicle.possibleModelYears)(manufacturingYear);
    }),

    actions: {
      cancel: function cancel() {
        this.sendAction('on-cancel');
      },
      applyChanges: function applyChanges() {
        var selectedVehicle = this.get('selectedVehicle');
        if (isNone(selectedVehicle)) {
          return;
        }

        var application = this.get('application');
        application.setProperties({
          vehicleManufacturingYear: parseInt(this.get('_manufacturingYear'), 10),
          vehicleModelYear: selectedVehicle.modelYear,
          vehicleBrand: selectedVehicle.brand,
          vehicleModel: selectedVehicle.model + ' ' + selectedVehicle.fullModelVersion,
          molicarCode: selectedVehicle.molicarCode,
          purchaseValue: selectedVehicle.value
        });
        application.updateDownPayment();

        this.sendAction('on-vehicle-selected');
      }
    }
  });
});