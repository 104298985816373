define('middle-office/pods/home-refi/documents/controller', ['exports', 'lodash', 'ember-cli-bkf-core/enums/checklist-document'], function (exports, _lodash, _checklistDocument) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  var Controller = Ember.Controller;


  var FORM_DOCUMENTS = _lodash.default.map(_checklistDocument.formDocuments, 'value');
  var PERSONAL_DOCUMENTS = _lodash.default.map(_checklistDocument.personalDocuments, 'value');
  var REAL_ESTATE_DOCUMENTS = _lodash.default.map(_checklistDocument.realEstateDocuments, 'value');
  var CREDIT_ANALYSIS_DOCUMENTS = _lodash.default.map(_checklistDocument.creditAnalysisDocuments, 'value');
  var PERSON_LEGAL_DOCUMENTS = _lodash.default.map(_checklistDocument.personLegalDocuments, 'value');
  var APPLICATION_LEGAL_DOCUMENTS = _lodash.default.map(_checklistDocument.applicationLegalDocuments, 'value');

  var filterByDocumentGroup = function filterByDocumentGroup(groupList, items) {
    return items.filter(function (item) {
      return groupList.includes(item.get('name'));
    });
  };

  var filterPersonItems = function filterPersonItems(items, person) {
    return items.filter(function (item) {
      return item.get('person.id') === person.get('id');
    });
  };

  var documentListByPerson = function documentListByPerson(items, person) {
    return { person: person, items: filterPersonItems(items, person) };
  };

  var peopleDocuments = function peopleDocuments(people, documentList) {
    return people.map(documentListByPerson.bind(null, documentList));
  };

  exports.default = Controller.extend({
    checklistDocumentItems: reads('model.checklistDocumentItems'),
    people: reads('model.people'),

    checklists: computed('people.[]', function () {
      var items = this.get('checklistDocumentItems');
      var personalItems = filterByDocumentGroup(PERSONAL_DOCUMENTS, items);
      var creditAnalysisItems = filterByDocumentGroup(CREDIT_ANALYSIS_DOCUMENTS, items);
      var personLegalItems = filterByDocumentGroup(PERSON_LEGAL_DOCUMENTS, items);
      var people = this.get('people');

      return {
        formDocuments: filterByDocumentGroup(FORM_DOCUMENTS, items),
        personalDocuments: peopleDocuments(people, personalItems),
        creditAnalysisDocuments: peopleDocuments(people, creditAnalysisItems),
        realEstateDocuments: filterByDocumentGroup(REAL_ESTATE_DOCUMENTS, items),
        personLegalDocuments: peopleDocuments(people, personLegalItems),
        applicationLegalDocuments: filterByDocumentGroup(APPLICATION_LEGAL_DOCUMENTS, items)
      };
    }),

    showConfirmationModal: false,

    confirmationSettings: {
      header: 'Excluir documento',
      type: 'delete'
    },

    _deletableDocument: null,

    actions: {
      onCancelModal: function onCancelModal() {
        this.toggleProperty('showConfirmationModal');
      },
      onDeleteModal: function onDeleteModal() {
        this.send('deleteDocument', this.get('_deletableDocument'));
      },
      confirmDeletion: function confirmDeletion(document, uploadsList) {
        this.toggleProperty('showConfirmationModal');
        this.set('_deletableDocument', {
          document: document,
          uploadsList: uploadsList
        });
      },
      deleteDocument: function () {
        var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee(_ref) {
          var document = _ref.document,
              uploadsList = _ref.uploadsList;
          return regeneratorRuntime.wrap(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  this.toggleProperty('showConfirmationModal');
                  _context.next = 3;
                  return document.destroyRecord();

                case 3:
                  uploadsList.removeObject(document);

                case 4:
                case 'end':
                  return _context.stop();
              }
            }
          }, _callee, this);
        }));

        function deleteDocument(_x) {
          return _ref2.apply(this, arguments);
        }

        return deleteDocument;
      }()
    }
  });
});