define('middle-office/pods/components/app-input/component', ['exports', 'middle-office/utils/masks'], function (exports, _masks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var on = Ember.on;
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: 'input',

    classNames: ['app-input'],

    attributeBindings: ['type', 'defaultPlaceholder:placeholder', 'name', 'readonly', 'disabled', 'maskedValue:value'],

    maskHandler: computed('mask', function () {
      var mask = this.get('mask');

      if (mask == null) {
        return _masks.nullMask.create();
      }

      var presetMask = _masks[mask];

      if (!presetMask) {
        var defaultMask = _masks.default.create();
        defaultMask.set('pattern', mask);

        return defaultMask;
      }

      return presetMask.create();
    }),

    maskedValue: computed('value', function () {
      var value = this.get('value');
      var _value = value == null ? '' : value;
      return this.get('maskHandler').parseIn(_value);
    }),

    defaultPlaceholder: computed('placeholder', function () {
      var placeholder = this.get('placeholder') || this.get('maskHandler').placeholder;

      if (placeholder) {
        return placeholder;
      }

      return null;
    }),

    _setMask: on('didInsertElement', function () {
      this.get('maskHandler').setup(this.element, this['mask-options']);
    }),

    _parseOut: on('focusOut', function () {
      var value = this.element.value;
      var _value = value == null ? '' : value;
      var parsedValue = this.get('maskHandler').parseOut(_value);

      if (typeof this.get('on-change') === 'function') {
        this.sendAction('on-change', parsedValue);
        return;
      }

      this.set('value', parsedValue);
    }),

    _input: on('input', function (event) {
      this.sendAction('on-input', event);
    })
  });
});