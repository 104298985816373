define('middle-office/pods/components/app-consultant-row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var reads = Ember.computed.reads;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: 'tr',
    isLoading: reads('consultant.isSaving'),
    isActive: reads('consultant.active'),

    actions: {
      toggleActiveStatus: function toggleActiveStatus(consultant) {
        this.sendAction('toggleActiveStatus', consultant);
      }
    }
  });
});