define('middle-office/services/home-refi/partner-applications', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    store: service('store'),

    createPartnerApplication: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(partner, application) {
        var partnerApplication;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                partnerApplication = this.get('store').createRecord('underwriting/home-refi/' + partner + '/application');

                application.set(partner.camelize() + 'Application', partnerApplication);

                return _context.abrupt('return', partnerApplication);

              case 3:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function createPartnerApplication(_x, _x2) {
        return _ref.apply(this, arguments);
      }

      return createPartnerApplication;
    }()
  });
});