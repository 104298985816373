define('middle-office/pods/components/app-inspection/status/frustrated/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['ui', 'two', 'column', 'centered', 'form', 'grid', 'refused'],
    readonly: false,

    layout: Ember.HTMLBars.template({
      "id": "eHdDhrGC",
      "block": "{\"symbols\":[],\"statements\":[[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"nine wide column\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ui compact tiny info message\"],[7],[0,\"\\n        \"],[6,\"i\"],[7],[0,\"O cliente ou o avaliador não compareceu à vistoria\"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"nine wide column\"],[7],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"ui middle aligned grid\"],[7],[0,\"\\n        \"],[6,\"label\"],[9,\"class\",\"four wide column\"],[7],[0,\"\\n          Quem não compareceu ?\\n        \"],[8],[0,\"\\n\\n        \"],[6,\"div\"],[9,\"class\",\"eleven wide column\"],[7],[0,\"\\n\"],[4,\"if\",[[19,0,[\"readonly\"]]],null,{\"statements\":[[0,\"            \"],[1,[25,\"translate-from-enum\",[\"loan/auto-refi/bkf/inspection-person\",[19,0,[\"inspection\",\"frustratedBy\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[25,\"app-checkgroup\",null,[[\"type\",\"value\",\"items\",\"on-change\"],[\"radio\",[19,0,[\"inspection\",\"frustratedBy\"]],[25,\"enum-options\",[\"loan/auto-refi/bkf/inspection-person\"],null],[25,\"action\",[[19,0,[]],[25,\"mut\",[[19,0,[\"inspection\",\"frustratedBy\"]]],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n      \"],[1,[25,\"app-errors\",null,[[\"model\",\"attribute\",\"showErrors\"],[[19,0,[\"inspection\"]],\"frustratedBy\",[19,0,[\"showErrors\"]]]]],false],[0,\"\\n    \"],[8],[0,\"\\n  \"]],\"hasEval\":false}",
      "meta": {}
    })
  });
});