define('middle-office/serializers/engine/home-refi/eligibility-parameters', ['exports', 'ember-cli-bkf-core/serializers/bkf-core'], function (exports, _bkfCore) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _bkfCore.default.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey() /* payloadKey */{
      return 'engine/home-refi/eligibility-parameters';
    },
    payloadKeyFromModelName: function payloadKeyFromModelName() /* modelName */{
      return 'engine home-refi eligibility-parameters';
    }
  });
});