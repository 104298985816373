define('middle-office/pods/components/app-forms/lead/auto-refi/operational-data/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['app-form', 'ui', 'form'],
    tagName: 'form',

    showAutoDebt: false,

    actions: {
      toggleAutoDebtDisplay: function toggleAutoDebtDisplay(selectedValue) {
        if (selectedValue) {
          this.set('showAutoDebt', false);
          this.set('lead.autoDebt', 0);
        } else {
          this.set('showAutoDebt', true);
          this.set('lead.autoDebt', null);
        }
      }
    }
  });
});