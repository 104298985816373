define('middle-office/pods/components/app-forms/application/home-refi/partners/partials/requesters-select/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    store: service('store'),

    classNames: ['requester-selects', 'field-group'],

    requestersList: computed('partnerApplication.bkfApplication.requester.fullName', 'partnerApplication.bkfApplication.additionalRequester.fullName', 'partnerApplication.bkfApplication.otherAdditionalRequester.fullName', function () {
      var application = this.get('partnerApplication.bkfApplication');
      var requester = application.get('requester.content');
      var additionalRequester = application.get('additionalRequester.content');
      var otherAdditionalRequester = application.get('otherAdditionalRequester.content');

      var requestersList = [requester, additionalRequester, otherAdditionalRequester].filter(function (applicationRequester) {
        return applicationRequester;
      }).map(function (applicationRequester) {
        return {
          text: applicationRequester.get('fullName'),
          value: applicationRequester.get('id')
        };
      });

      var blankOption = {
        text: 'Selecione',
        value: null
      };

      return [blankOption].concat(_toConsumableArray(requestersList));
    }),

    actions: {
      selectRequester: function selectRequester(slot, id) {
        var store = this.get('store');
        var requester = store.peekRecord('loan/home-refi/bkf/person', id);
        var partnerApplication = this.get('partnerApplication');
        var slotCamelized = slot.camelize();

        partnerApplication.set(slotCamelized, requester || null);
      }
    }
  });
});