define('middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/professional/freelancer', ['exports', 'middle-office/enums/auto-refi/time-ranges'], function (exports, _timeRanges) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function freelancer(employment) {
    var timeOfEmployment = (0, _timeRanges.textForTimeRange)(employment.timeOfEmployment);
    var jobTitle = employment.jobTitle;
    var classEntityRegistrationNumber = employment.classEntityRegistrationNumber;
    return '\xC9 ' + jobTitle + ' da carteira de classe: ' + classEntityRegistrationNumber + '. Atua nessa profiss\xE3o ' + timeOfEmployment + '.\n\nAcrescentar informa\xE7\xF5es sobre o neg\xF3cio:\nRenda l\xEDquida mensal da atividade (ap\xF3s custos):';
  }

  exports.default = freelancer;
});