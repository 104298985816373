define('middle-office/pods/main/auto-refi/settings', ['exports', 'middle-office/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.appLayoutConfig = undefined;


  function getMenuItems() {
    var items = [{
      label: 'Buscar Clientes',
      route: 'main.auto-refi.leads',
      icon: 'search icon',
      production: true
    }, {
      label: 'Criar Novo Lead',
      icon: 'user icon',
      route: 'lead.auto-refi',
      production: true
    }, {
      label: 'Minha Carteira',
      route: 'main.auto-refi.portfolio',
      icon: 'users icon',
      production: true
    }, {
      label: 'Propostas em Crédito',
      route: 'main.auto-refi.partner-applications',
      icon: 'file text outline icon',
      production: true
    }, {
      label: 'Listagem de Tarefas',
      icon: 'list icon',
      route: 'main.auto-refi.partner-tasks',
      production: true
    }, {
      label: 'Busca na Molicar',
      icon: 'car icon',
      route: 'main.auto-refi.molicar',
      production: true
    }];

    return {
      items: _environment.default.APP.mdoFeatureFlag ? items : items.filter(function (item) {
        return item.production;
      })
    };
  }

  var appLayoutConfig = exports.appLayoutConfig = {
    headerMenu: false,
    sideMenu: getMenuItems()
  };

  exports.default = appLayoutConfig;
});