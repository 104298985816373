define('middle-office/models/servicing/onboarding/address', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    createdAt: attr('date'),
    updatedAt: attr('date'),
    fullAddress: attr('string'),
    zipCode: attr('string'),

    customer: belongsTo('servicing/onboarding/customer')
  });
});