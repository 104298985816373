define('middle-office/pods/auto-refi/application-form/controller', ['exports', 'middle-office/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    applicationForm: service('auto-refi/application-form'),

    application: reads('model.application'),
    requester: reads('model.requester'),
    requesterDocuments: reads('model.requesterDocuments'),
    bacenAuthorizationSantana: reads('requester.bacenAuthorizationSantana'),

    hasBacenDocument: computed('requesterDocuments.@each.name', function () {
      var bacenForm = this.get('requesterDocuments').findBy('name', 'bacen_form');
      return bacenForm ? bacenForm.get('attachments.length') > 0 : false;
    }),

    clicksignUrl: computed('bacenAuthorizationSantana.clicksign.document-key', function () {
      var documentKey = this.get('bacenAuthorizationSantana.clicksign.document-key');
      if (documentKey == null) {
        return null;
      }

      var url = new URL(_environment.default.APP.CLICKSIGN_URL);
      url.pathname = '/inbox/' + documentKey;

      return url;
    }),

    // The API doesn't have a way to distinguish between authorization types for now. Since we only
    // have authorization via clicksign implemented on the bacen-authorizations, it is safe to assume
    // that credit-check.authorized == true => credit-check.authorization-method == 'clicksign'.
    //
    // This needs to be changed before implementing the archive authorization method, however.
    authorizedViaClicksign: reads('bacenAuthorizationSantana.authorized'),

    actions: {
      submitApplicationForm: function submitApplicationForm() {
        var application = this.get('application');
        return this.get('applicationForm').save(application);
      }
    }
  });
});