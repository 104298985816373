define('middle-office/pods/components/app-switch/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var reads = Ember.computed.reads;
  var isEmpty = Ember.isEmpty;
  exports.default = Component.extend({
    classNames: ['app-switch'],
    classNameBindings: ['disabled'],

    min: 1,
    max: reads('states.lastObject.key'),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var states = this.get('states');
      var initialState = this.get('initialState');

      if (isEmpty(states) || isEmpty(initialState)) {
        throw Error('Required params: states, initialState');
      }

      this.set('state', states.findBy('value', initialState));
    },


    actions: {
      transition: function transition(_ref) {
        var target = _ref.target;

        var states = this.get('states');
        var oldState = this.get('state');
        var newState = states.findBy('key', target.valueAsNumber);

        this.sendAction('switch', oldState, newState, target.id);
      }
    }
  });
});