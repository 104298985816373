define('middle-office/pods/components/customer-search-form/component', ['exports', 'cpf-cnpj', 'middle-office/utils/masks'], function (exports, _cpfCnpj, _masks) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    store: service(),
    _query: null,

    _validQuery: computed('_query', function () {
      var query = this.get('_query');
      return isValidEmail(query) || isValidCpf(query);
    }),

    _validationErrors: computed('_validQuery', '_query', function () {
      var queryLength = (this.get('_query') || '').length;

      if (this.get('_validQuery') || queryLength < 5) {
        return [];
      }

      return [{ message: 'Näo é um e-mail ou CPF válido' }];
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      var value = this.get('value');

      if (value != null) {
        this.set('_query', value);
        this._triggerSearch();
      }
    },
    _searchUsingQuery: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(query) {
        var result, customer;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                result = {
                  searchQuery: query,
                  customers: []
                };
                _context.next = 3;
                return this.get('store').queryRecord('crm/customer', {
                  filter: { query: query }
                });

              case 3:
                customer = _context.sent;


                if (customer != null) {
                  result.customers = [customer];
                }

                return _context.abrupt('return', result);

              case 6:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function _searchUsingQuery(_x) {
        return _ref.apply(this, arguments);
      }

      return _searchUsingQuery;
    }(),
    _triggerSearch: function _triggerSearch() {
      var query = void 0;
      var rawQuery = this.get('_query');

      if (isValidCpf(rawQuery)) {
        query = cleanupCpf(rawQuery);
      } else if (isValidEmail(rawQuery)) {
        query = rawQuery;
      } else {
        return;
      }

      var promise = this._searchUsingQuery(query);
      this.sendAction('on-search', promise);
    },


    actions: {
      search: function search(event) {
        event.preventDefault();
        this._triggerSearch();
      },
      updateQuery: function updateQuery(query) {
        this.set('_query', query);
      }
    }
  });


  function isValidCpf(query) {
    var cpf = cleanupCpf(query || '');

    return _cpfCnpj.CPF.isValid(cpf);
  }

  function isValidEmail(query) {
    var regex = /\S+@\S+\.\S+/;
    return regex.test(query);
  }

  function cleanupCpf(cpf) {
    var mask = _masks.cpf.create();
    return mask.parseOut(cpf);
  }
});