define('middle-office/enums/auto-refi/status-flow', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.statusFlow = undefined;
  var statusFlow = exports.statusFlow = [{
    text: 'Preparação',
    value: 'preparation'
  }, {
    text: 'Cadastro',
    value: 'submission'
  }, {
    text: 'Análise',
    value: 'approval'
  }, {
    text: 'Formalização',
    value: 'formalization'
  }, {
    text: 'Pago',
    value: 'closed'
  }, {
    text: 'Descartado',
    value: 'no_closed'
  }];

  exports.default = statusFlow;
  var values = exports.values = _lodash.default.map(statusFlow, 'value');
});