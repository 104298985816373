define('middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/professional', ['exports', 'middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/professional/clt', 'middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/professional/businessman', 'middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/professional/self-employed', 'middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/professional/freelancer', 'middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/professional/retired', 'middle-office/pods/components/app-forms/application/auto-refi/credit-defense/partials/professional/civil-servant'], function (exports, _clt, _businessman, _selfEmployed, _freelancer, _retired, _civilServant) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var defaultProfessionalStatusPartials = {
    retired: _retired.default,
    clt: _clt.default,
    self_employed: _selfEmployed.default,
    freelancer: _freelancer.default,
    businessman: _businessman.default,
    civil_servant: _civilServant.default
  };

  function professional(application, professionalStatusPartials) {
    var employments = application.requester.employments;
    var partials = professionalStatusPartials || defaultProfessionalStatusPartials;

    return employments.map(function (employment) {
      var status = employment.professionalStatus;
      var partial = partials[status];
      return partial(employment);
    }).join('\n\n');
  }

  exports.default = professional;
});