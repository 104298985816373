define('middle-office/pods/auto-refi/timeline/controller', ['exports', 'middle-office/pods/auto-refi/timeline/settings'], function (exports, _settings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var service = Ember.inject.service;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    auth: service(),
    rollbar: service(),

    persistModel: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(model) {
        var message;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                message = void 0;
                _context.prev = 1;
                _context.next = 4;
                return model.save();

              case 4:

                message = {
                  type: 'success',
                  title: 'Sucesso!',
                  text: 'A informação foi adicionada na linha do tempo.'
                };
                _context.next = 12;
                break;

              case 7:
                _context.prev = 7;
                _context.t0 = _context['catch'](1);

                Ember.Logger.error(_context.t0);
                this.get('rollbar').error(new Error('Failure while saving an item in timeline'), {
                  errorEvent: _context.t0
                });
                message = {
                  type: 'error',
                  title: 'Ooops...',
                  text: 'Erro ao salvar informação...'
                };

              case 12:

                this.set('feedbackMessage', message);

              case 13:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this, [[1, 7]]);
      }));

      function persistModel(_x) {
        return _ref.apply(this, arguments);
      }

      return persistModel;
    }(),
    _saveRecord: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(type, record) {
        var loadingProp, timeline;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                loadingProp = 'isSaving' + type;


                this.set(loadingProp, true);
                _context2.next = 4;
                return this.persistModel(record);

              case 4:
                this.set(loadingProp, false);

                this.send('onCloseModal');

                _context2.next = 8;
                return this.get('model.application').timelineEvents({ reload: true });

              case 8:
                timeline = _context2.sent;

                this.set('model.timeline', timeline);

              case 10:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function _saveRecord(_x2, _x3) {
        return _ref2.apply(this, arguments);
      }

      return _saveRecord;
    }(),


    actions: {
      onShowModal: function onShowModal(type) {
        this.setProperties({
          showModal: true,
          modalSettings: _settings.modalSettings[type]
        });
      },
      onCloseModal: function onCloseModal() {
        this.set('showModal', false);
      },
      onApproveModal: function onApproveModal(type, modalContent) {
        var actionName = _settings.modalSettings[type].approveAction;
        this.send(actionName, modalContent);
        this.send('onCloseModal');
      },
      newRegister: function newRegister(record) {
        return this._saveRecord('Registry', record);
      },
      newContact: function () {
        var _ref3 = _asyncToGenerator(regeneratorRuntime.mark(function _callee3(record) {
          return regeneratorRuntime.wrap(function _callee3$(_context3) {
            while (1) {
              switch (_context3.prev = _context3.next) {
                case 0:
                  return _context3.abrupt('return', this._saveRecord('Contact', record));

                case 1:
                case 'end':
                  return _context3.stop();
              }
            }
          }, _callee3, this);
        }));

        function newContact(_x4) {
          return _ref3.apply(this, arguments);
        }

        return newContact;
      }()
    }
  });
});