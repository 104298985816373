define('middle-office/pods/components/app-sidebar/partials/partner-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var schedule = Ember.run.schedule;
  var computed = Ember.computed;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    partnerApplications: service('home-refi/partner-applications'),

    scroller: service('scroller'),

    tagName: '',

    dasherizedName: computed('partnerName', function () {
      return this.get('partnerName').dasherize();
    }),

    partnerApplication: computed(function () {
      var partnerName = this.get('partnerName').toLowerCase().camelize();

      return this.get('application.' + partnerName + 'Application');
    }),

    actions: {
      createPartnerApplication: function createPartnerApplication(partner) {
        var _this = this;

        var partnerApplications = this.get('partnerApplications');
        var application = this.get('application');

        var partnerApplication = partnerApplications.createPartnerApplication(partner, application);
        this.set('partnerApplication', partnerApplication);

        schedule('afterRender', function () {
          _this.get('scroller').scrollVertical('#' + partner + '-application');
        });
      }
    }
  });
});