define('middle-office/enums/timeline/contact-result-type', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.contactResultType = undefined;
  var contactResultType = exports.contactResultType = [{
    text: 'Sucesso',
    value: 'success'
  }, {
    text: 'Não responde',
    value: 'no_answer'
  }, {
    text: 'Dados inválidos',
    value: 'invalid_contact_data'
  }, {
    text: 'Retornar contato depois',
    value: 'return_later'
  }];

  exports.default = contactResultType;
  var values = exports.values = _lodash.default.map(contactResultType, 'value');
});