define('middle-office/services/external-information', ['exports', 'middle-office/config/environment', 'middle-office/services/external-information/all-check-response', 'middle-office/services/external-information/serasa-response'], function (exports, _environment, _allCheckResponse, _serasaResponse) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Service = Ember.Service;


  var SOURCES = {
    allcheck: {
      response: _allCheckResponse.default,
      path: '/internal/all_check/consult_raw'
    },
    serasa: {
      response: _serasaResponse.default,
      path: '/internal/serasa/consult'
    }
  };

  exports.default = Service.extend({
    _getBaseURL: function _getBaseURL() {
      return _environment.default.APP.externalData;
    },
    _createRequestHeaders: function _createRequestHeaders() {
      return new Headers({
        'Content-Type': 'application/json'
      });
    },
    _createRequestBody: function _createRequestBody(cpf) {
      var json = {
        user: {
          cpf: cpf
        }
      };

      return JSON.stringify(json);
    },
    _createResponseFor: function _createResponseFor(source, json) {
      var Response = SOURCES[source].response;
      return Response.createWithRawData(json);
    },
    _createUrlFor: function _createUrlFor(source) {
      var path = SOURCES[source].path;
      return new URL(path, this._getBaseURL()).toString();
    },
    consultCpf: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(cpf, source) {
        var url, response, json;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                url = this._createUrlFor(source);
                _context.next = 3;
                return fetch(url, {
                  method: 'POST',
                  headers: this._createRequestHeaders(),
                  body: this._createRequestBody(cpf)
                });

              case 3:
                response = _context.sent;
                _context.next = 6;
                return response.json();

              case 6:
                json = _context.sent;
                return _context.abrupt('return', this._createResponseFor(source, json));

              case 8:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function consultCpf(_x, _x2) {
        return _ref.apply(this, arguments);
      }

      return consultCpf;
    }()
  });
});