define('middle-office/services/application-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var equal = Ember.computed.equal;
  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    rollbar: service(),

    status: null,

    error: null,

    isSaving: equal('status', 'saving'),

    isError: equal('status', 'error'),

    isSaved: equal('status', 'saved'),

    modelDescription: null,

    save: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(application) {
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                if (!this.get('isSaving')) {
                  _context.next = 2;
                  break;
                }

                return _context.abrupt('return');

              case 2:

                this.set('status', 'saving');

                _context.prev = 3;
                _context.next = 6;
                return this.saveModel(application);

              case 6:
                this.set('status', 'saved');
                _context.next = 15;
                break;

              case 9:
                _context.prev = 9;
                _context.t0 = _context['catch'](3);

                this.set('status', 'error');
                this.set('error', _context.t0);
                Ember.Logger.error(_context.t0);

                this.get('rollbar').error(new Error('Failure on save ' + this.get('modelDescription')), {
                  errorEvent: _context.t0
                });

              case 15:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this, [[3, 9]]);
      }));

      function save(_x) {
        return _ref.apply(this, arguments);
      }

      return save;
    }()
  });
});