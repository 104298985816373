define('middle-office/models/underwriting/auto-refi/fidc/application', ['exports', 'ember-data', 'ember-cli-bkf-core/models/underwriting/auto-refi/fidc/application', 'middle-office/models/underwriting/auto-refi/partner-application-mixin', 'ember-cp-validations'], function (exports, _emberData, _application, _partnerApplicationMixin, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CREDIT_APPROVAL_SITUATION_DOING = 'doing';

  var Validations = (0, _emberCpValidations.buildValidations)({
    sig: [(0, _emberCpValidations.validator)('presence', true)]
  });

  exports.default = _application.default.extend(_partnerApplicationMixin.default, Validations, {
    creditReference: _emberData.default.belongsTo('analysis/auto-refi/data/credit-reference'),

    analystFullName: _emberData.default.attr('string'),
    priority: _emberData.default.attr('boolean'),
    sig: _emberData.default.attr('boolean-with-null'),
    partnerOperationCode: _emberData.default.attr('string'),
    partnerName: 'fidc',
    initialCreditApprovalSituation: 'prepending',

    updateCreditApprovalSituationDoing: function updateCreditApprovalSituationDoing() {
      var currentSituation = this.get('creditApprovalSituation');

      if (currentSituation !== CREDIT_APPROVAL_SITUATION_DOING) {
        this.set('creditApprovalSituation', CREDIT_APPROVAL_SITUATION_DOING);
        return this.save();
      }

      return this;
    },
    createCalculatorOperation: function createCalculatorOperation(existentCalculator) {
      var application = this.get('bkfApplication');
      var calculator = this.get('store').createRecord('calculator/auto-refi/operation', {
        fidcApplication: this,
        score: application.get('serasaScoreRange'),
        autoModelYear: application.get('vehicleModelYear')
      });

      if (existentCalculator != null) {
        calculator.setProperties({
          autoModelYear: existentCalculator.get('autoModelYear'),
          autoValue: existentCalculator.get('autoValue'),
          familyMonthlyIncome: existentCalculator.get('familyMonthlyIncome'),
          minimumMonthlyIncome: existentCalculator.get('minimumMonthlyIncome'),
          incomeCommitment: existentCalculator.get('incomeCommitment'),
          operationIncomeCommitment: existentCalculator.get('operationIncomeCommitment'),
          iof: existentCalculator.get('iof'),
          tac: existentCalculator.get('tac'),
          priceTaxMonthly: existentCalculator.get('priceTaxMonthly'),
          totalEffectiveCostYear: existentCalculator.get('totalEffectiveCostYear'),
          totalEffectiveCostMonthly: existentCalculator.get('totalEffectiveCostMonthly'),
          loanAmountRequested: existentCalculator.get('loanAmountRequested'),
          contractLoanAmount: existentCalculator.get('contractLoanAmount'),
          pmt: existentCalculator.get('pmt'),
          ltvMax: existentCalculator.get('ltvMax'),
          ltvOperation: existentCalculator.get('ltvOperation'),
          operationMaturity: existentCalculator.get('operationMaturity'),
          maturity: existentCalculator.get('maturity'),
          paidValue: existentCalculator.get('paidValue')
        });
      }

      return calculator;
    }
  });
});