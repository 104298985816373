define('middle-office/pods/components/auto-refi/lead-card/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  var Component = Ember.Component;
  var computed = Ember.computed;
  var reads = computed.reads;
  exports.default = Component.extend({
    routing: service(),

    classNames: ['ui', 'card'],

    pqResult: computed('lead', function () {
      return this.get('lead').pqResult();
    }),

    cellphone: reads('customer.contactCellphone'),
    cellphoneCode: reads('customer.contactCellphoneCode'),

    hasCellphone: computed('cellphone', 'cellphoneCode', function () {
      return this.get('cellphone') != null && this.get('cellphoneCode') != null;
    }),

    residentialPhone: reads('customer.contactResidentialTelephone'),
    residentialPhoneCode: reads('customer.contactResidentialTelephoneCode'),

    hasResidentialPhone: computed('residentialPhone', 'residentialPhoneCode', function () {
      return this.get('residentialPhone') != null && this.get('residentialPhoneCode') != null;
    }),

    actions: {
      applicationCreated: function applicationCreated(application) {
        this.get('routing').transitionTo('auto-refi.timeline', [application.get('id')]);
      },
      applicationCreationError: function applicationCreationError(reason) {
        this.sendAction('on-application-creation-error', reason);
      }
    }
  });
});