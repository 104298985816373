define('middle-office/pods/components/app-inspection/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['ui', 'equal', 'width', 'form', 'inspection'],
    inspection: null,
    readonly: false,

    layout: Ember.HTMLBars.template({
      "id": "+N1xJ+pW",
      "block": "{\"symbols\":[],\"statements\":[[0,\"\\n    \"],[1,[25,\"app-inspection/date\",null,[[\"inspection\",\"readonly\",\"showErrors\"],[[19,0,[\"inspection\"]],[19,0,[\"readonly\"]],[19,0,[\"showErrors\"]]]]],false],[0,\"\\n\\n    \"],[1,[25,\"app-inspection/period\",null,[[\"inspection\",\"readonly\",\"showErrors\"],[[19,0,[\"inspection\"]],[19,0,[\"readonly\"]],[19,0,[\"showErrors\"]]]]],false],[0,\"\\n\\n    \"],[1,[25,\"app-inspection/partner\",null,[[\"inspection\",\"readonly\",\"showErrors\"],[[19,0,[\"inspection\"]],[19,0,[\"readonly\"]],[19,0,[\"showErrors\"]]]]],false],[0,\"\\n\\n    \"],[1,[25,\"app-inspection/location\",null,[[\"inspection\",\"readonly\",\"showErrors\"],[[19,0,[\"inspection\"]],[19,0,[\"readonly\"]],[19,0,[\"showErrors\"]]]]],false],[0,\"\\n\\n    \"],[1,[25,\"app-inspection/address\",null,[[\"inspection\",\"readonly\",\"showErrors\"],[[19,0,[\"inspection\"]],[19,0,[\"readonly\"]],[19,0,[\"showErrors\"]]]]],false],[0,\"\\n\\n    \"],[1,[25,\"app-inspection/external-code\",null,[[\"inspection\",\"readonly\",\"showErrors\"],[[19,0,[\"inspection\"]],[19,0,[\"readonly\"]],[19,0,[\"showErrors\"]]]]],false],[0,\"\\n  \"]],\"hasEval\":false}",
      "meta": {}
    })
  });
});