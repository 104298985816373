define('middle-office/pods/servicing/loans/controller', ['exports', 'middle-office/enums/servicing/onboarding/loans/product-filters', 'middle-office/enums/servicing/onboarding/loans/repayment-filters'], function (exports, _productFilters, _repaymentFilters) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Controller = Ember.Controller;
  var computed = Ember.computed;
  var alias = Ember.computed.alias;
  exports.default = Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.productFilters = [].concat(_toConsumableArray(_productFilters.values));
      this.repaymentFilters = [].concat(_toConsumableArray(_repaymentFilters.values));
    },


    loans: alias('model'),

    headers: ['Contrato', 'Nome', 'CPF', 'Produto', 'Valor Financiado', 'Prazo (Meses)', 'Dias em Atraso', 'Dia do Vencimento', 'Cessionário Atual', 'Dt. Última Cessão', 'E-mail'],

    filteredLoans: computed.intersect('_loansByProductType', '_loansByRepaymentStatus'),

    _loansByProductType: computed.filter('loans', function (loan) {
      var productFilters = this.get('productFilters');
      var loanType = loan.get('product');

      return productFilters.includes(loanType);
    }).property('productFilters.[]'),

    _loansByRepaymentStatus: computed.filter('loans', function (loan) {
      var repaymentFilters = this.get('repaymentFilters');
      var isOverdue = loan.get('isOverdue');
      var repaymentStatus = isOverdue ? 'overdue' : 'open';

      return repaymentFilters.includes(repaymentStatus);
    }).property('repaymentFilters.[]'),

    actions: {
      onFilterChange: function onFilterChange(filter, values) {
        this.set(filter, values);
      }
    }
  });
});