define('middle-office/pods/components/app-message/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var later = Ember.run.later;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['ui message app-message'],
    classNameBindings: ['feedbackMessage:visible:hidden', 'feedbackMessage.type'],
    attributeBindings: ['data-test'],

    dismissable: true,
    feedbackMessage: null,

    didUpdateAttrs: function didUpdateAttrs() {
      var dismissable = this.get('dismissable');

      if (!Ember.testing && !dismissable) {
        later(this, this._destroyMessage, 3500);
      }
    },
    _destroyMessage: function _destroyMessage() {
      if (!this.get('isDestroyed' || !this.get('isDestroying'))) {
        this.set('feedbackMessage', null);
      }
    },


    actions: {
      closeFeedbackMessage: function closeFeedbackMessage() {
        this.set('feedbackMessage', null);
      }
    }
  });
});