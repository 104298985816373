define('middle-office/models/engine/eligibility/auto-refi/evaluation', ['exports', 'ember-cli-bkf-core/models/engine/eligibility/auto-refi/evaluation'], function (exports, _evaluation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _evaluation.default;
    }
  });
});