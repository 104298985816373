define('middle-office/pods/components/app-drop-file/component', ['exports', 'lodash', 'middle-office/mixins/drag-and-drop'], function (exports, _lodash, _dragAndDrop) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  exports.default = Component.extend(_dragAndDrop.default, {
    classNames: ['app-drop-file'],
    classNameBindings: ['errorMessage:error'],

    isMultiple: false,
    errorMessage: null,
    showErrorMessage: true,

    acceptedFormats: computed(function () {
      return [];
    }),

    drop: function drop(event) {
      this._super(event);

      this.send('clearFile');
      this.send('clearErrors');
      this.send('onFilesSelected', event);
    },
    _selectFiles: function _selectFiles(files, isMultiple) {
      return isMultiple ? files : [_lodash.default.first(files)];
    },
    _checkFilesFormatAcceptance: function _checkFilesFormatAcceptance(selectedFiles, acceptedFormats) {
      var response = { isValid: true };
      var selectedFilesArray = Array.from(selectedFiles);
      var isUnacceptedFormat = this._isUnacceptedFormat(acceptedFormats);
      var invalidFile = selectedFilesArray.find(isUnacceptedFormat);

      if (invalidFile) {
        response.isValid = false;
        response.errorMessage = this._invalidExtensionMessage(acceptedFormats);
      }

      return response;
    },
    _isUnacceptedFormat: function _isUnacceptedFormat(acceptedFormats) {
      var _this = this;

      return function (file) {
        var extension = _this._getFileExtension(file.name);

        return !acceptedFormats.includes(extension);
      };
    },
    _getFileExtension: function _getFileExtension(name) {
      var regex = /\.[0-9a-z]+$/i;

      return name.match(regex)[0];
    },
    _invalidExtensionMessage: function _invalidExtensionMessage(acceptedFormats) {
      var formatsList = acceptedFormats.join(', ');
      var errorMessage = {
        title: 'Extensão de arquivo inválida encontrada',
        text: 'Por favor, tente novamente. Tipos de arquivo suportados: ' + formatsList,
        type: 'error'
      };

      return errorMessage;
    },


    actions: {
      onFilesSelected: function onFilesSelected(_ref) {
        var dataTransfer = _ref.dataTransfer;

        var acceptedFormats = this.get('acceptedFormats');
        var isMultiple = this.get('isMultiple');
        var files = this._selectFiles(dataTransfer.files, isMultiple);

        var _checkFilesFormatAcce = this._checkFilesFormatAcceptance(files, acceptedFormats),
            isValid = _checkFilesFormatAcce.isValid,
            errorMessage = _checkFilesFormatAcce.errorMessage;

        if (isValid) {
          this.sendAction('onFilesSelected', files);
        } else {
          this.send('onError', errorMessage);
        }
      },
      clearFile: function clearFile() {
        this.sendAction('clearFile');
      },
      clearErrors: function clearErrors() {
        this.set('errorMessage', null);
        this.sendAction('clearErrors');
      },
      onError: function onError(errorMessage) {
        this.set('errorMessage', errorMessage);
        this.sendAction('onError', errorMessage);
      }
    }
  });
});