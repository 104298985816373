define('middle-office/pods/main/auto-refi/partner-applications/controller', ['exports', 'middle-office/pods/main/auto-refi/partner-applications/query-params'], function (exports, _queryParams) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  exports.default = Controller.extend({
    queryParams: _queryParams.controller,
    page: 1,
    perPage: 20,
    partner: 'FIDC',
    statusFlow: ['approval'],
    creditSituation: ['pending', 'reanalysis'],
    isFromPromoter: false,
    removeDiscardedApplications: true,
    committee: false,
    committeeFilter: computed('committee', function () {
      return this.get('committee') ? ['committee'] : [];
    }),
    sortBy: ['updated_at'],
    headers: ['Solicitante', 'CPF', 'Status', 'Crédito', 'Analista', 'Loja', 'Acessar'],

    resetPage: function resetPage() {
      this.set('page', 1);
    },


    actions: {
      onFilterChange: function onFilterChange(filter, values) {
        this.set(filter, values);
        this.resetPage();
      },
      onCommitteeFilterChange: function onCommitteeFilterChange(values) {
        this.set('committee', values.includes('committee'));
        this.resetPage();
      }
    }
  });
});