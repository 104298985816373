define('middle-office/pods/components/partner-applications/component', ['exports', 'ember-cli-bkf-core/utils/translate-from-enum'], function (exports, _translateFromEnum) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var Component = Ember.Component;
  exports.default = Component.extend({
    tagName: '',

    isActive: computed('isActive', function () {
      var partner = this.get('partner');
      var partnerName = this.get('partnerApplication.name');
      if (partner === partnerName) {
        return 'active';
      }

      return null;
    }),

    statusFlow: (0, _translateFromEnum.default)('auto-refi/status-flow', 'partnerApplication.statusFlow')
  });
});