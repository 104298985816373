define('middle-office/pods/components/app-documents-list/partials/document/status/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var reads = Ember.computed.reads;
  exports.default = Component.extend({
    classNames: ['ui', 'basic', 'label', 'status'],
    classNameBindings: ['status', 'status:visible:hidden'],

    status: reads('document.status')
  });
});