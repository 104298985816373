define('middle-office/enums/auto-refi/status', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.values = exports.status = undefined;
  var status = exports.status = [{
    text: 'Iniciada',
    value: 'started'
  }, {
    text: 'Pronta',
    value: 'ready'
  }, {
    text: 'Sem serviço',
    value: 'no_service'
  }];

  exports.default = status;
  var values = exports.values = _lodash.default.map(status, 'value');
});