define('middle-office/models/loan/auto-refi/bkf/inspection', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;


  var onlyFrustrated = function onlyFrustrated() {
    return {
      presence: true,
      disabled: computed('model.status', function () {
        var status = this.get('model.status');
        var notFrustrated = status !== 'frustrated';

        return notFrustrated;
      })
    };
  };

  var inspectionValidations = (0, _emberCpValidations.buildValidations)({
    location: (0, _emberCpValidations.validator)('presence', true),
    address: (0, _emberCpValidations.validator)('presence', true),
    externalCode: (0, _emberCpValidations.validator)('presence', true),
    partner: (0, _emberCpValidations.validator)('presence', true),
    period: (0, _emberCpValidations.validator)('presence', true),
    date: (0, _emberCpValidations.validator)('presence', true),
    frustratedBy: (0, _emberCpValidations.validator)('presence', onlyFrustrated())
  });

  exports.default = _emberData.default.Model.extend(inspectionValidations, {
    application: _emberData.default.belongsTo('loan/auto-refi/bkf/application'),
    date: _emberData.default.attr('pure-date'),
    period: _emberData.default.attr('string'),
    current: _emberData.default.attr('boolean', { defaultValue: false }),
    partner: _emberData.default.attr('string'),
    location: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    externalCode: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    observation: _emberData.default.attr('string'),
    frustratedBy: _emberData.default.attr('string')
  });
});