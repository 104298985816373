define('middle-office/pods/components/app-table/auto-inspections/accordion-line/open-content/location/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['ui', 'items'],

    layout: Ember.HTMLBars.template({
      "id": "TL45fJzJ",
      "block": "{\"symbols\":[],\"statements\":[[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"item\"],[7],[0,\"\\n      \"],[6,\"i\"],[9,\"class\",\"big location arrow icon\"],[7],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"content\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"meta\"],[7],[0,\"Local:\"],[8],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"description\"],[7],[0,\"\\n          \"],[6,\"p\"],[7],[1,[25,\"translate-from-enum\",[\"loan/auto-refi/bkf/inspection-location\",[19,0,[\"inspection\",\"location\"]]],null],false],[8],[0,\"\\n        \"],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n  \"]],\"hasEval\":false}",
      "meta": {}
    })
  });
});