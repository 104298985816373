define('middle-office/pods/components/app-forms/application/auto-refi/steps/loan/component', ['exports', 'middle-office/pods/auto-refi/partners', 'middle-office/utils/build-enum'], function (exports, _partners, _buildEnum) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var reads = Ember.computed.reads;
  var computed = Ember.computed;
  var observer = Ember.observer;
  var service = Ember.inject.service;
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['financial-data', 'form-step'],

    rollbar: service('rollbar'),

    partners: computed('partnerApplications.[]', function () {
      var partnerApplications = this.get('partnerApplications');

      var partners = partnerApplications.map(function (pa) {
        return pa.get('name');
      });

      return (0, _buildEnum.default)({
        items: partners,
        includeBlank: true
      });
    }),

    partner: computed('application.partnerName', function () {
      var partnerName = this.get('application.partnerName');
      var partnerConfig = _partners.default[partnerName];

      if (partnerName != null && partnerConfig == null) {
        this.get('rollbar').error(new Error('Partner ' + partnerName + ' not found in partnersConfig.'));
      }

      return partnerConfig;
    }),

    branchItems: computed('possibleBranches.[]', function () {
      return (0, _buildEnum.default)({
        items: this.get('possibleBranches').toArray(),
        textKey: 'name',
        valueKey: 'id',
        includeBlank: true
      });
    }),

    possibleBranches: reads('application.currentPartnerApplication.possibleBranches'),

    branchResponsible: reads('application.sentBranch.responsible'),

    responsibleHint: computed('branchResponsible', function () {
      var branchResponsible = this.get('branchResponsible');

      if (branchResponsible) {
        return 'L4 respons\xE1vel por essa loja: ' + branchResponsible.get('fullName');
      }

      return null;
    }),

    interestTable: computed('partner.interestTable.[]', function () {
      var interestTable = this.get('partner.interestTable');

      return (0, _buildEnum.default)({
        items: interestTable,
        includeBlank: true
      });
    }),

    _cleanupOnPartnerChange: observer('partner', function () {
      var application = this.get('application');

      application.setProperties({
        interestTable: null
      });
    }),

    actions: {
      updateSentBranchId: function updateSentBranchId(partnerApplication, newBranchId) {
        var newBranch = this.get('possibleBranches').findBy('id', newBranchId);
        partnerApplication.set('sentBranch', newBranch);
      },
      updateLoanAttribute: function updateLoanAttribute(propertyName, newValue) {
        var application = this.get('application');
        application.set(propertyName, newValue);
        application.updateDownPayment();
      }
    }
  });
});