define('middle-office/models/timeline/auto-refi/record', ['exports', 'ember-data/model', 'ember-data', 'middle-office/enums/timeline/contact-result-type', 'middle-office/enums/timeline/interlocutor-type', 'middle-office/enums/timeline/media-type', 'middle-office/enums/timeline/record-type', 'ember-cp-validations'], function (exports, _model, _emberData, _contactResultType, _interlocutorType, _mediaType, _recordType, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberObject = Ember.Object;
  var reads = Ember.computed.reads;
  var equal = Ember.computed.equal;


  var onlyForContacts = function onlyForContacts() {
    return {
      presence: true,
      disabled: equal('model.type', 'registry')
    };
  };

  var recordValidations = (0, _emberCpValidations.buildValidations)({
    type: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
      in: _recordType.value
    })],
    from: [(0, _emberCpValidations.validator)('presence', onlyForContacts()), (0, _emberCpValidations.validator)('inclusion', {
      in: _interlocutorType.value
    })],
    to: [(0, _emberCpValidations.validator)('presence', onlyForContacts()), (0, _emberCpValidations.validator)('inclusion', {
      in: _interlocutorType.value
    })],
    contactResult: [(0, _emberCpValidations.validator)('presence', onlyForContacts()), (0, _emberCpValidations.validator)('inclusion', {
      in: _contactResultType.value
    })],
    media: [(0, _emberCpValidations.validator)('presence', onlyForContacts()), (0, _emberCpValidations.validator)('inclusion', {
      in: _mediaType.value
    })],
    comment: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      max: 2000
    })]
  });

  var TimelineEvent = EmberObject.extend({
    model: reads('_model'),
    createdAt: reads('_model.createdAt'),

    _model: null
  });

  var ContactEvent = TimelineEvent.extend({
    type: 'contact'
  });

  var RegistryEvent = TimelineEvent.extend({
    type: 'registry'
  });

  exports.default = _model.default.extend(recordValidations, {
    lead: _emberData.default.belongsTo('crm/auto-refi/lead'),
    partner: _emberData.default.belongsTo('underwriting/partner'),
    consultant: _emberData.default.belongsTo('crm/consultant'),

    type: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    from: _emberData.default.attr('string'),
    to: _emberData.default.attr('string'),
    contactResult: _emberData.default.attr('string'),
    media: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),

    toTimelineEvent: function toTimelineEvent() {
      var type = this.get('type');

      switch (type) {
        case 'contact':
          return ContactEvent.create({ _model: this });
        case 'registry':
          return RegistryEvent.create({ _model: this });
        default:
          throw new Error('Unsupported registry type ' + type);
      }
    }
  });
});