define('middle-office/pods/main/auto-refi/partner-applications/query-params', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var controllerOptions = {
    scope: 'controller'
  };

  var routeOptions = {
    refreshModel: true
  };

  var controller = exports.controller = ['page', 'perPage', 'partner', 'statusFlow', 'creditSituation', 'committee', 'isFromPromoter', 'removeDiscardedApplications', {
    sortBy: controllerOptions
  }];

  var route = exports.route = {
    page: routeOptions,
    perPage: routeOptions,
    partner: routeOptions,
    statusFlow: routeOptions,
    creditSituation: routeOptions,
    isFromPromoter: routeOptions,
    committee: routeOptions
  };

  exports.default = {
    route: route,
    controller: controller
  };
});