define('middle-office/pods/components/application-modal/auto-refi/timeline/record-mixin', ['exports', 'middle-office/enums/auto-refi/partner'], function (exports, _partner) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Mixin = Ember.Mixin;
  var service = Ember.inject.service;
  var computed = Ember.computed;
  var reads = computed.reads;


  var defaultOption = {
    text: 'Nenhum',
    value: null
  };

  exports.default = Mixin.create({
    classNames: ['ui equal width form'],
    store: service(),

    application: reads('model.application'),
    partners: reads('model.partners'),
    partnersSelection: computed('partners', function () {
      var mappedPartners = _partner.default.map(function (item) {
        return {
          text: item.text,
          value: item.value.toLowerCase()
        };
      });
      return [defaultOption].concat(_toConsumableArray(mappedPartners));
    }),
    selectedPartnerName: '',
    record: computed(function () {
      return this.get('store').createRecord('timeline/auto-refi/record', {
        type: this.get('type'),
        lead: this.get('application.lead')
      });
    }),
    isValidComment: reads('record.validations.attrs.comment.isValid'),
    isValidModel: reads('record.validations.isValid'),

    actions: {
      onApprove: function onApprove() {
        var record = this.get('record');
        var selectedPartnerName = this.get('selectedPartnerName');
        var selectedPartner = this.get('partners').find(function (partner) {
          var loweredPartnerName = partner.get('name').toLowerCase();
          return loweredPartnerName === selectedPartnerName;
        });
        var isValid = this.get('isValidModel');

        record.set('partner', selectedPartner);
        this.set('showErrors', !isValid);

        if (isValid) {
          this.sendAction('onApprove', record);
        }
      },
      onDeny: function onDeny() {
        this.get('record').deleteRecord();
        return this.sendAction('onDeny');
      }
    }
  });
});