define('middle-office/services/auto-refi/application-assignment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var Service = Ember.Service;
  var service = Ember.inject.service;
  exports.default = Service.extend({
    store: service(),
    rollbar: service(),

    assignApplicationManually: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(application) {
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.prev = 0;
                _context.next = 3;
                return this._createAssignment(application);

              case 3:
                _context.next = 8;
                break;

              case 5:
                _context.prev = 5;
                _context.t0 = _context['catch'](0);

                this._logAndRethrow('Failure while assigning application manually', _context.t0);

              case 8:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this, [[0, 5]]);
      }));

      function assignApplicationManually(_x) {
        return _ref.apply(this, arguments);
      }

      return assignApplicationManually;
    }(),
    takeOnApplication: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(application) {
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _context2.prev = 0;
                _context2.t0 = this;
                _context2.t1 = application;
                _context2.next = 5;
                return application.get('consultant');

              case 5:
                _context2.t2 = _context2.sent;
                _context2.t3 = {
                  previousConsultant: _context2.t2
                };
                _context2.next = 9;
                return _context2.t0._createAssignment.call(_context2.t0, _context2.t1, _context2.t3);

              case 9:
                _context2.next = 14;
                break;

              case 11:
                _context2.prev = 11;
                _context2.t4 = _context2['catch'](0);

                this._logAndRethrow('Failure while taking on application', _context2.t4);

              case 14:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this, [[0, 11]]);
      }));

      function takeOnApplication(_x2) {
        return _ref2.apply(this, arguments);
      }

      return takeOnApplication;
    }(),
    _createAssignment: function () {
      var _ref3 = _asyncToGenerator(regeneratorRuntime.mark(function _callee3(application) {
        var props = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        var assignment;
        return regeneratorRuntime.wrap(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                assignment = this.get('store').createRecord('loan/auto-refi/bkf/assignment', _extends({
                  bkfApplication: application
                }, props));
                _context3.prev = 1;
                _context3.next = 4;
                return assignment.save();

              case 4:
                _context3.next = 10;
                break;

              case 6:
                _context3.prev = 6;
                _context3.t0 = _context3['catch'](1);

                assignment.rollbackAttributes();

                throw _context3.t0;

              case 10:
                _context3.next = 12;
                return application.belongsTo('consultant').reload();

              case 12:
              case 'end':
                return _context3.stop();
            }
          }
        }, _callee3, this, [[1, 6]]);
      }));

      function _createAssignment(_x4) {
        return _ref3.apply(this, arguments);
      }

      return _createAssignment;
    }(),
    _logAndRethrow: function _logAndRethrow(errorMessage, exception) {
      this.get('rollbar').error(errorMessage, { errorEvent: exception });
      throw exception;
    }
  });
});