define('middle-office/services/hermes-worker', ['exports', 'ember-cli-hermes/services/hermes-worker'], function (exports, _hermesWorker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _hermesWorker.default;
    }
  });
});