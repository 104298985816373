define('middle-office/pods/auto-refi/partner-applications/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var reads = Ember.computed.reads;
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    presentPartnerApplications: reads('model.application.presentPartnerApplications'),

    queryParams: ['partner'],
    partner: null
  });
});