define('middle-office/pods/components/app-table/partner-tasks/accordion-line/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var reads = Ember.computed.reads;
  var isEmpty = Ember.isEmpty;
  exports.default = Component.extend({
    tagName: '',
    openLine: false,

    pipeline: reads('record.pipeline.content'),
    doingTasks: reads('pipeline.doingTasks'),
    isDoing: computed('selectedTeams.[]', 'doingTasks', function () {
      var selectedTeams = this.get('selectedTeams');
      var pipeline = this.get('pipeline');
      var filteredTasks = selectedTeams.filter(function (team) {
        return !isEmpty(pipeline.doingByTeam(team));
      });

      return filteredTasks.length > 0;
    }),

    actions: {
      toggle: function toggle() {
        this.toggleProperty('openLine');
      }
    }
  });
});