define('middle-office/models/loan/home-refi/bkf/application', ['exports', 'lodash', 'ember-data', 'ember-cli-bkf-core/models/loan/home-refi/bkf/application'], function (exports, _lodash, _emberData, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _asyncToGenerator(fn) {
    return function () {
      var gen = fn.apply(this, arguments);
      return new Promise(function (resolve, reject) {
        function step(key, arg) {
          try {
            var info = gen[key](arg);
            var value = info.value;
          } catch (error) {
            reject(error);
            return;
          }

          if (info.done) {
            resolve(value);
          } else {
            return Promise.resolve(value).then(function (value) {
              step("next", value);
            }, function (err) {
              step("throw", err);
            });
          }
        }

        return step("next");
      });
    };
  }

  var isEqual = Ember.isEqual;
  var computed = Ember.computed;
  var bool = Ember.computed.bool;
  var all = Ember.RSVP.all;
  var map = Ember.RSVP.map;
  exports.default = _application.default.extend({
    hasAdditionalRequester: bool('additionalRequester.content'),

    hasOtherAdditionalRequester: bool('otherAdditionalRequester.content'),

    isSameRealEstateOwnerRequester: computed('requester.content', 'realEstateOwner.content', function () {
      var requester = this.get('requester.content');
      var realEstateOwner = this.get('realEstateOwner.content');

      return requester != null && realEstateOwner != null && requester === realEstateOwner;
    }),

    setupPeopleRelationships: function () {
      var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
        var requester, requesterSpouse, additionalRequester, otherAdditionalRequester, additionalIsSpouse, otherAdditionalIsSpouse;
        return regeneratorRuntime.wrap(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.next = 2;
                return this.get('requester');

              case 2:
                requester = _context.sent;
                _context.next = 5;
                return requester.get('spouse');

              case 5:
                requesterSpouse = _context.sent;
                _context.next = 8;
                return this.get('additionalRequester');

              case 8:
                additionalRequester = _context.sent;
                _context.next = 11;
                return this.get('otherAdditionalRequester');

              case 11:
                otherAdditionalRequester = _context.sent;


                if (additionalRequester) {
                  additionalIsSpouse = isEqual(requesterSpouse, additionalRequester);

                  additionalRequester.set('isRequesterSpouse', additionalIsSpouse);
                }

                if (otherAdditionalRequester) {
                  otherAdditionalIsSpouse = isEqual(requesterSpouse, otherAdditionalRequester);

                  otherAdditionalRequester.set('isRequesterSpouse', otherAdditionalIsSpouse);
                }

              case 14:
              case 'end':
                return _context.stop();
            }
          }
        }, _callee, this);
      }));

      function setupPeopleRelationships() {
        return _ref.apply(this, arguments);
      }

      return setupPeopleRelationships;
    }(),
    timelineEvents: function () {
      var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2() {
        var allSources;
        return regeneratorRuntime.wrap(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _context2.next = 2;
                return all([this._leadTimelineEvents()]);

              case 2:
                allSources = _context2.sent;
                return _context2.abrupt('return', _lodash.default.flatten(allSources));

              case 4:
              case 'end':
                return _context2.stop();
            }
          }
        }, _callee2, this);
      }));

      function timelineEvents() {
        return _ref2.apply(this, arguments);
      }

      return timelineEvents;
    }(),
    _leadTimelineEvents: function () {
      var _ref3 = _asyncToGenerator(regeneratorRuntime.mark(function _callee3() {
        var lead, leadRecords, recordEvents;
        return regeneratorRuntime.wrap(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                _context3.next = 2;
                return this.get('lead');

              case 2:
                lead = _context3.sent;

                if (!(lead == null)) {
                  _context3.next = 5;
                  break;
                }

                return _context3.abrupt('return', []);

              case 5:
                _context3.next = 7;
                return lead.hasMany('records').reload();

              case 7:
                leadRecords = _context3.sent;
                _context3.next = 10;
                return map(leadRecords.toArray(), function (record) {
                  return record.toTimelineEvent();
                });

              case 10:
                recordEvents = _context3.sent;
                return _context3.abrupt('return', [].concat(_toConsumableArray(recordEvents)));

              case 12:
              case 'end':
                return _context3.stop();
            }
          }
        }, _callee3, this);
      }));

      function _leadTimelineEvents() {
        return _ref3.apply(this, arguments);
      }

      return _leadTimelineEvents;
    }(),


    eligibilityParameters: _emberData.default.belongsTo('engine/home-refi/eligibility-parameters'),
    lead: _emberData.default.belongsTo('crm/home-refi/lead'),

    inCondominium: _emberData.default.attr('boolean')
  });
});